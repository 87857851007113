import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCsXjAVoF2tBSZE1h4ogZS7jofhhnIfPFw",
  authDomain: "uscomments-d65cb.firebaseapp.com",
  databaseURL: "https://uscomments-d65cb-default-rtdb.firebaseio.com",
  projectId: "uscomments-d65cb",
  storageBucket: "uscomments-d65cb.appspot.com",
  messagingSenderId: "1027673164819",
  appId: "1:1027673164819:web:d2ff40dc7df959bee103e6",
  measurementId: "G-7D4JK8D615",
};

const firstApp = firebase.initializeApp(firebaseConfig, "uscommentsApp");
// const firstApp = !firebase.apps.length
//   ? firebase.initializeApp(firebaseConfig, "uscommentsApp")
//   : firebase.app();
const database = firstApp.database();

const interactWithUSComments = async (method, path, value) => {

  let response = {
    data: null,
    status: "failed",
  };

  const dbRef = database.ref(`${path}`);
  if (method === "GET") {
    await dbRef
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          response = {
            data: snapshot.val(),
            status: "Success",
          };
        } else {
          response.status = "Failed";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else if (method === "POST") {
  } else if (method === "DEL") {
  } else {
  }

  return response;
};


export default interactWithUSComments;
