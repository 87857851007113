import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa'; // Import a spinner icon from React Icons

const ActionButtons = ({checkedSet, actionOccured, item, onApprove, onDecline, onReleasePO }) => {

    const [action, setAction] = useState(null);
    const buttonStyles = (backgroundColor) => ({
        backgroundColor,
        color: 'white',
        border: 'none',
        borderRadius: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: actionOccured ? 'not-allowed' : 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
    });

    const LoadingSpinner = () => (
        <FaSpinner className="spinner" style={{ animation: 'spin 1s linear infinite' }} />
    );

    return (
        <div style={{ display: 'flex', gap: '16px', justifyContent: 'center', margin: '16px 0' }}>
            {item.status === 'Approved' ? (
                <button
                    onClick={async () => {
                        setAction('Release');
                        await onReleasePO();
                    }}
                    style={buttonStyles('#1485AE')}
                    disabled={actionOccured}
                >
                    {actionOccured && action === 'Release' ? <LoadingSpinner /> : 'Release PO'}
                </button>
            ) : item.status === 'Requested' || item.status === 'Declined' ? (
                <>
                    <button
                        onClick={async () => {
                            setAction('Approve');
                            await onApprove();
                        }}
                        style={buttonStyles('#14AE5C')}
                        disabled={actionOccured}
                    >
                        {actionOccured && action === 'Approve' ? <LoadingSpinner /> : 'Approve'}
                    </button>
                    
                    <button
                        onClick={async () => {
                            setAction('Decline');
                            await onDecline();
                        }}
                        style={buttonStyles('#DB3F3D')}
                        disabled={actionOccured}
                    >
                        {actionOccured && action === 'Decline' ? <LoadingSpinner /> : 'Decline'}
                    </button>
                </>
            ) : null}
        </div>
    );
};

export default ActionButtons;
