import { useRef, useState } from "react";
import Label from "../../components/Label/label";
import { TextField } from "@material-ui/core";
import "./index.scss";
import Button from "../../components/Button/button";
import AuthService from "../../api/authService";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import projectService from "../../api/projectService";
import firebase from "../../config/firebase";
import RegisterFourProjects from "./registerFourProjects";

const sampleProjectRooms = [
  {
    "Room Name": "Heading",
    Units: [
      {
        "Unit Name": "Sub-Heading 1",
        Drawings: [],
        Components: [
          {
            description: "Budget",
            quantity: "1",
            rate: "10000",
            unit: "Unit",
            Work: [
              {
                "Unit Name": "Sub-Heading 1",
                heading: "",
                OrderId: 1706528004447,
                workType: "Only Work",
                vendorCategory: "Demo Work Category",
                description: "Work Description",
                quantity: "1",
                rate: "2000",
                unit: "Unit",
                gst: "20",
                milestones: [
                  {
                    name: "Completion",
                    percentage: 100,
                    totalAmount: "2400.00",
                  },
                ],
                tc: "",
                status: "",
                orderStatus: "",
                Drawings: [],
              },
            ],
            Material: [
              {
                OrderId: 1706528098713,
                category: "Carpenter",
                type: "Demo Category",
                item: "",
                specification:
                  "Metarial specification | specification 2 | @specification 3",
                quantity: "1",
                rate: "2500",
                unit: "Unit",
                gst: "10",
                milestones: [
                  {
                    name: "Completion",
                    percentage: "100",
                  },
                ],
                status: "",
                orderStatus: "",
              },
            ],
          },
        ],
      },
      {
        "Unit Name": "Sub-Heading 2",
        Drawings: [],
        Components: [],
      },
    ],
  },
];

function SignUp_user({
  formData,
  onAuthChanged,
}) {
  const [newUserFormDetails, setNewUserFormDetails] = useState({
    imageUrl: null,
    contactNumber: "",
    firm: "",
  });
  const [newUserFormError, setNewUserFormError] = useState({
    image: "",
    contactNumber: "",
    firm: "",
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [firmAvailability, setFirmAvailablity] = useState(false);

  const CheckFirmAvailability = async () => {
    const authService = new AuthService();
    const firmCheckres = await authService.CheckFirmAvailability(
      newUserFormDetails.firm
    );
    console.log("FIRM CHECK", firmCheckres);
    return firmCheckres.available;
  };

  const onContinue = async () => {
    const check = await CheckFirmAvailability();
    if (!check) {
      console.log("SETTING EEROOR", firmAvailability);
      setNewUserFormError((prev) => ({
        ...prev,
        firm: "Firm already exists. Try another",
      }));
      return;
    }
    if (!newUserFormDetails.firm) {
      setNewUserFormError((prev) => ({
        ...prev,
        firm: "Firm Name is Required",
      }));
      return;
    }
    if (!/^\d{10}$/.test(newUserFormDetails.contactNumber)) {
      setNewUserFormError((prev) => ({
        ...prev,
        contactNumber: "Enter a Valid Phone Number",
      }));
      return;
    }
    try {
      setIsLoading(true);
      const authService = new AuthService();
      const id = Date.now();
      const AddFirmRes = await authService.createNewFirm(
       id,
        newUserFormDetails.firm.trim(),
        `${newUserFormDetails.firm}@staart.co.in`,
        formData.email
      );
      
      if (AddFirmRes.status === 200) {
        const addOwnerUserRes = await authService.adminSignup(
          formData.email,
          "password",
          "Owner",
          newUserFormDetails.firm.trim(),
          formData.email.slice(0, formData.email.indexOf("@")),
          0,
          newUserFormDetails.contactNumber,
          id
        );

        if (addOwnerUserRes.status === 200) {
          const res = await new projectService().addProject(
            "Demo Project ",
            "0",
            "Demo Address",
            "",
            "",
            sampleProjectRooms,
            0,
            [],
            newUserFormDetails.firm.trim()
          );
          

          await firebase
            .database()
            .ref("liveBOQ")
            .child(res.payload.ProjectId.toString())
            .set({
              ...res.payload,
              rooms: JSON.stringify(res.payload.Rooms),
            });
          
          await RegisterFourProjects(newUserFormDetails);

          sessionStorage.setItem(
            "User",
            JSON.stringify(addOwnerUserRes.payload)
          );

          localStorage.setItem(
            "User",

            JSON.stringify(addOwnerUserRes.payload)
          );

          localStorage.setItem("Firm", JSON.stringify(newUserFormDetails.firm));
          localStorage.setItem("firm", newUserFormDetails.firm);

          onAuthChanged({
            ...addOwnerUserRes.payload,
          });

          toast.success("Successfully Created");
          history.push("/");
        }
      }
    } catch (e) {
      setIsLoading(false);
      toast.error("Error Occured");
      console.log("ERROR OCCURED", e);
    }
  };

  return (
    <div className="newUserSignUpContainer">
      <Label
        content="Create a New Firm"
        size="30px"
        textAlign="center"
        color="white"
      />
    
      <div style={{ height: "61%" }}>
        <TextField
          style={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "6px",
            minWidth: 80,
            width: "100%",
            fontSize: "17px",
            height: `${newUserFormError.firm ? "64px" : "54px"}`,
          }}
          error={!!newUserFormError.firm}
          id="standard-basic Firm"
          // label="Firm Name"
          type="text"
          variant="standard"
          InputProps={{
            placeholder: "Enter Firm Name",
            style: {
              height: `${newUserFormError.firm ? "75%" : "100%"}`,
              paddingLeft: "5px",
              color: "#505050", // Change tther styles you wanthe color as needed
              // fontStyle: "italic", // Add any o
            },
          }}
          placeholder="Firm Name"
          name="Firm"
          // onKeyDown={onKeyPressed}

          onChange={(e) => {
            let text = e.currentTarget.value;

            setNewUserFormDetails((prev) => ({ ...prev, firm: text }));
            if (text) {
              setNewUserFormError((prev) => ({
                ...prev,
                firm: "",
              }));
            }
          }}
          helperText={newUserFormError.firm}
          value={newUserFormDetails.firm}
          autoComplete=""
        />

        <TextField
          style={{
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: "6px",
            minWidth: 80,
            width: "100%",
            fontSize: "17px",
            marginTop: "7%",
            // height: "54px",
            height: `${newUserFormError.contactNumber ? "64px" : "54px"}`,
          }}
          error={!!newUserFormError.contactNumber}
          id="standard-basic contact-number"
          // label="Phone Number"
          type="tel"
          variant="standard"
          placeholder="Phone Number"
          name="phoneNumber"
          // onKeyDown={onKeyPressed}
          InputProps={{
            placeholder: "Phone Number",
            style: {
              // height: "100%",
              height: `${newUserFormError.contactNumber ? "75%" : "100%"}`,
              paddingLeft: "5px",
              color: "#505050", // Change the color as needed
              // fontStyle: "italic", // Add any other styles you want
            },
          }}
          onChange={(e) => {
            let text = e.currentTarget.value;

            setNewUserFormDetails((prev) => ({
              ...prev,
              contactNumber: text.replace(/[^0-9]+/gm, ""),
            }));
            //   const phoneRegex = /^\d{10}$/; // This example assumes a 10-digit phone number
            if (/^\d{10}$/.test(text)) {
              setNewUserFormError((prev) => ({
                ...prev,
                contactNumber: "",
              }));
            }
          }}
          helperText={newUserFormError.contactNumber}
          value={newUserFormDetails.contactNumber}
          autoComplete="number"
        />
      </div>

      <div style={{ paddingTop: "15px" }}>
        <Button
          content={"Continue"}
          isLoading={isLoading}
          onClick={onContinue}
        />
      </div>
    </div>
  );
}

export default SignUp_user;