import React, { useEffect, useMemo, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import ViewMoreModal from "./ViewMoreModal";

const Analytics = ({ requests }) => {
  const [modalState, setModalState] = React.useState({
    isOpen: false,
    type: null,
    data: null,
  });
  const [zoom, setZoom] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setZoom(window.devicePixelRatio);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = (type, data) => {
    setModalState({ isOpen: true, type, data });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, type: null, data: null });
  };
  // Helper functions
  const getLatestStatus = (history) => {
    const sortedHistory = Object.values(history).sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
    return sortedHistory[0]?.status || "";
  };

  const getDaysDifference = (timestamp1, timestamp2) => {
    console.log("timestamp1 : ", timestamp1);
    console.log("timestamp2 : ", timestamp2);
    // Convert timestamps to dates if they're millisecond timestamps
    const date1 = new Date(Number(timestamp1));
    const date2 = new Date(Number(timestamp2));

    console.log("date1 : ", date1);
    console.log("date2 : ", date2);

    // Calculate difference in milliseconds and convert to days
    const diffInMs = Math.abs(date2 - date1);
    console.log("diffInMs : ", diffInMs);
    console.log(
      "Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) : ",
      Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
    );
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  // Constants
  const COLORS = {
    pieChart: [
      "#FA722F", // Requested
      "#00D226", // Approved
      "#1485AE", // Released
      "#828282", // Reached
      "red", // Declined
    ],
    timeBars: {
      poToReach: "#FF6B42",
      requestToPo: "#4169E1",
      requestToApprove: "#32CD32",
    },
    status: {
      Released: "#1485AE",
      Approved: "#00D226",
      Requested: "#FA722F",
      Declined: "red",
      Reached: "#828282",
    },
  };

  const chartDimensions = useMemo(
    () => ({
      width: Math.floor(150 / zoom),
      height: Math.floor(150 / zoom),
    }),
    [zoom]
  );

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Format time
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    return `${day}/${month}/${year}`;
  }

  // Analytics calculations
  const QuantityVariationsTable = ({ data }) => {
    // Add null check for data
    if (!data || !Array.isArray(data)) {
      return <div>No data available</div>;
    }

    // Take only first 4 entries
    const displayData = data.slice(0, 4);

    return (
      <div style={styles.tableContainer}>
        <table style={styles.modernTable}>
          <thead>
            <tr>
              <th style={styles.headerCell}>MRN No</th>
              <th style={styles.headerCell}>Category</th>
              <th style={styles.headerCell}>Requested</th>
              <th style={styles.headerCell}>Rejected</th>
              <th style={styles.headerCell}>Approved</th>
              <th style={styles.headerCell}>Released</th>
              <th style={styles.headerCell}>Received</th>
            </tr>
          </thead>
          <tbody>
            {displayData.map((request) => {
              if (!request?.items) return null;

              return request.items.map((item, itemIndex) => {
                // Get this MRN's history entries
                const historyEntries = request.requestHistory || {};

                // Get quantities for each status from this MRN's history
                const requestedQty = Object.values(historyEntries).find(
                  (entry) => entry.status === "Requested"
                )?.quantities?.[itemIndex];
                const approvedQty = Object.values(historyEntries).find(
                  (entry) => entry.status === "Approved"
                )?.quantities?.[itemIndex];
                const releasedQty = Object.values(historyEntries).find(
                  (entry) => entry.status === "Released"
                )?.quantities?.[itemIndex];
                const reachedQty = Object.values(historyEntries).find(
                  (entry) => entry.status === "Reached"
                )?.quantities?.[itemIndex];

                return (
                  <tr key={`${request.id}-${itemIndex}`}>
                    {itemIndex === 0 && (
                      <td
                        style={{ ...styles.cell, ...styles.mrnCell }}
                        rowSpan={request.items.length}
                      >
                        <span style={styles.mrnPrefix}>#</span>
                        {request.id}
                      </td>
                    )}
                    <td style={styles.cell}>
                      <div style={styles.categoryCell}>
                        <span style={styles.categoryText}>{item.category}</span>
                        <span style={styles.itemText}>{item.heading}</span>
                      </div>
                    </td>
                    <td style={styles.cell}>{item.quantity || "---"}</td>
                    <td style={styles.cell}>
                      {item.quantity - approvedQty || "---"}
                    </td>
                    <td style={styles.cell}>{approvedQty || "---"}</td>
                    <td style={styles.cell}>{releasedQty || "---"}</td>
                    <td style={styles.cell}>{reachedQty || "---"}</td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const analyticsData = useMemo(() => {
    // Helper function to get latest status from request history
    const getLatestStatus = (history) => {
      if (!history || Object.keys(history).length === 0) return "";
      const latestKey = Object.keys(history).sort(
        (a, b) => Number(b) - Number(a)
      )[0];
      return history[latestKey]?.status || "";
    };

    // Helper function to get history entry with specific status
    const getEntryWithStatus = (history, statusToFind) => {
      return Object.values(history).find(
        (entry) => entry.status === statusToFind
      );
    };

    // Quantity variations analysis at request and item level
    // In the analyticsData calculation, update the quantityStats mapping:

    const quantityStats = requests.map((req) => {
      return {
        id: req.id,
        date: formatTimestamp(req.id),
        items: req.items.map((item) => {
          // Find latest quantities for this specific item
          const itemHistory = Object.values(req.requestHistory).sort(
            (a, b) => Number(b.timestamp) - Number(a.timestamp)
          );

          return {
            category: item.category,
            heading: item.heading,
            requested: item.quantity,
            rejected:
              itemHistory.find((h) => h.status === "Declined")?.quantity ||
              "---",
            approved:
              itemHistory.find((h) => h.status === "Approved")?.quantity ||
              "---",
            poReleased:
              itemHistory.find((h) => h.status === "Released")?.quantity ||
              "---",
            received:
              itemHistory.find((h) => h.status === "Reached")?.quantity ||
              "---",
          };
        }),
      };
    });

    const displayQuantityStats = quantityStats.slice(0, 4);

    const materialReport = requests.reduce(
      (acc, request) => {
        if (!request?.requestHistory || !request?.items) return acc;

        // Get the latest status entry from request history
        const latestEntry = Object.entries(request.requestHistory).sort(
          ([timestampA], [timestampB]) =>
            Number(timestampB) - Number(timestampA)
        )[0]?.[1];

        if (latestEntry?.status) {
          // Add the number of items for each status
          request.items.forEach((item) => {
            switch (latestEntry.status) {
              case "Requested":
                acc.totalRequested += 1;
                break;
              case "Approved":
                acc.totalApproved += 1;
                break;
              case "Released":
                acc.totalReleased += 1;
                break;
              case "Reached":
                acc.totalReached += 1;
                break;
              case "Declined":
                acc.totalRejected += 1;
                break;
            }
          });
        }
        return acc;
      },
      {
        totalRequested: 0,
        totalApproved: 0,
        totalReleased: 0,
        totalReached: 0,
        totalRejected: 0,
      }
    );

    // Processing times analysis
    const averageTimes = requests.reduce(
      (acc, req) => {
        const requestedEntry = getEntryWithStatus(
          req.requestHistory,
          "Requested"
        );
        const approvedEntry = getEntryWithStatus(
          req.requestHistory,
          "Approved"
        );
        const releasedEntry = getEntryWithStatus(
          req.requestHistory,
          "Released"
        );
        const reachedEntry = getEntryWithStatus(req.requestHistory, "Reached");

        if (releasedEntry && reachedEntry) {
          acc.poToReach.push(
            getDaysDifference(
              Number(releasedEntry.timestamp),
              Number(reachedEntry.timestamp)
            )
          );
        }

        if (requestedEntry && releasedEntry) {
          acc.requestToPo.push(
            getDaysDifference(
              Number(requestedEntry.timestamp),
              Number(releasedEntry.timestamp)
            )
          );
        }

        if (requestedEntry && approvedEntry) {
          acc.requestToApprove.push(
            getDaysDifference(
              Number(requestedEntry.timestamp),
              Number(approvedEntry.timestamp)
            )
          );
        }
        return acc;
      },
      {
        requestToApprove: [],
        requestToPo: [],
        poToReach: [],
      }
    );

    // const averageTimes = requests.reduce(
    //   (acc, req) => {
    //     const requestedEntry = getEntryWithStatus(
    //       req.requestHistory,
    //       "Requested"
    //     );
    //     const approvedEntry = getEntryWithStatus(
    //       req.requestHistory,
    //       "Approved"
    //     );
    //     const releasedEntry = getEntryWithStatus(
    //       req.requestHistory,
    //       "Released"
    //     );
    //     const reachedEntry = getEntryWithStatus(req.requestHistory, "Reached");

    //     const getValidDaysDifference = (startEntry, endEntry) => {
    //       if (startEntry?.timestamp && endEntry?.timestamp) {
    //         const diff = getDaysDifference(
    //           Number(startEntry.timestamp),
    //           Number(endEntry.timestamp)
    //         );
    //         return isNaN(diff) ? null : diff;
    //       }
    //       return null;
    //     };

    //     const poToReach = getValidDaysDifference(releasedEntry, reachedEntry);
    //     if (poToReach !== null) acc.poToReach.push(poToReach);

    //     const requestToPo = getValidDaysDifference(
    //       requestedEntry,
    //       releasedEntry
    //     );
    //     if (requestToPo !== null) acc.requestToPo.push(requestToPo);

    //     const requestToApprove = getValidDaysDifference(
    //       requestedEntry,
    //       approvedEntry
    //     );
    //     if (requestToApprove !== null)
    //       acc.requestToApprove.push(requestToApprove);

    //     return acc;
    //   },
    //   {
    //     requestToApprove: [],
    //     requestToPo: [],
    //     poToReach: [],
    //   }
    // );

    const calculateAverage = (arr) =>
      arr.length ? Math.round(arr.reduce((a, b) => a + b) / arr.length) : 0;

    const averageTimesResult = {
      requestToApprove: calculateAverage(averageTimes.requestToApprove),
      requestToPo: calculateAverage(averageTimes.requestToPo),
      poToReach: calculateAverage(averageTimes.poToReach),
    };

    // Unattended requests (with item details)
    const allUnattendedRequests = requests
      .filter((req) => getLatestStatus(req.requestHistory) === "Approved")
      .map((req) => {
        const approvedEntry = getEntryWithStatus(
          req.requestHistory,
          "Approved"
        );
        return {
          mrnNo: `#${req.id}`,
          items: req.items.map((item) => ({
            category: item.category,
            heading: item.heading,
            quantity: item.quantity,
            unit: item.unit,
          })),
          days: getDaysDifference(Number(approvedEntry.timestamp), Date.now()),
          approvedOn: formatTimestamp(approvedEntry.timestamp),
        };
      })
      .sort((a, b) => b.days - a.days);

    const unattendedRequests = allUnattendedRequests.slice(0, 5);



    const allMaterialsDelayed = requests
      .flatMap((req) => {
        const historyEntries = Object.entries(req.requestHistory || {})
          .map(([timestamp, entry]) => ({
            ...entry,
            timestamp: Number(timestamp),
          }))
          .sort((a, b) => b.timestamp - a.timestamp);

        // Process each item separately
        return req.items.map((item, itemIndex) => {
          // Get item-specific status history
          const itemHistory = historyEntries
            .filter(
              (entry) =>
                entry.quantities && entry.quantities[itemIndex] !== undefined
            )
            .sort((a, b) => b.timestamp - a.timestamp);

          // Find latest status for this specific item
          const latestStatus = itemHistory[0]?.status || "";

          // Find when this specific item was released (if it was)
          const releaseEntry = itemHistory.find(
            (entry) =>
              entry.status === "Released" && entry.quantities[itemIndex] > 0
          );

          // Find when this specific item was approved (if it was)
          const approveEntry = itemHistory.find(
            (entry) =>
              entry.status === "Approved" && entry.quantities[itemIndex] > 0
          );

          const dueDate = new Date(req.dueDate).getTime();
          const now = Date.now();

          // Calculate delay based on item's status
          let isDelayed = false;
          let delayDays = 0;
          let delayStartDate = null;

          if (latestStatus !== "Reached") {
            if (releaseEntry) {
              // Item was released, calculate delay from release date
              delayStartDate = releaseEntry.timestamp;
              console.log(
                "releaseEntry delayStartDate : ",
                delayStartDate,
                "now : ",
                now
              );
              delayDays = getDaysDifference(delayStartDate, now);
              isDelayed = delayDays > 0;
            } else if (approveEntry) {
              // Item was approved but not released, calculate delay from approval
              delayStartDate = approveEntry.timestamp;
              console.log(
                "approveEntry delayStartDate : ",
                delayStartDate,
                "now : ",
                now
              );
              delayDays = getDaysDifference(delayStartDate, now);
              isDelayed = delayDays > 0;
            } else {
              // Item not released or approved, calculate delay from due date
              delayStartDate = dueDate;
              console.log(
                "else delayStartDate : ",
                delayStartDate,
                "now : ",
                now,
                "req.dueDate : ",
                req.dueDate
              );
              delayDays = getDaysDifference(dueDate, now);
              isDelayed = dueDate < now;
            }
          }

          if (isDelayed) {
            return {
              mrnNo: req.id,
              category: item.category,
              heading: item.heading,
              quantity: item.quantity,
              unit: item.unit,
              status: latestStatus,
              releaseDate: releaseEntry
                ? formatTimestamp(releaseEntry.timestamp)
                : "Not Released",
              approveDate: approveEntry
                ? formatTimestamp(approveEntry.timestamp)
                : "Not Approved",
              dueDate: formatDate(dueDate),
              days: delayDays,
              isReleased: !!releaseEntry,
              delayStartDate: formatTimestamp(delayStartDate),
            };
          }
          return null;
        });
      })
      .filter(Boolean)
      .sort((a, b) => b.days - a.days);

    const materialsDelayed = allMaterialsDelayed.slice(0, 4);

    // Then in the render section, update the Materials Delayed card:

    // Materials in transit (with item details)
    const allMaterialsInTransit = requests
      .filter((req) => getLatestStatus(req.requestHistory) === "Released")
      .map((req) => {
        const releasedEntry = getEntryWithStatus(
          req.requestHistory,
          "Released"
        );
        return {
          mrnNo: `#${req.id}`,
          items: req.items.map((item) => ({
            category: item.category,
            heading: item.heading,
            quantity: item.quantity,
            unit: item.unit,
          })),
          dueDate: formatDate(new Date(req.dueDate).getTime()),
          releaseDate: formatTimestamp(releasedEntry.timestamp),
        };
      });

    const materialsInTransit = allMaterialsInTransit.slice(0, 4);

    // Recent activities (with item details)
    const allRecentActivities = requests
      .map((req) => {
        const latestKey = Object.keys(req.requestHistory).sort(
          (a, b) => Number(b) - Number(a)
        )[0];
        const latestEntry = req.requestHistory[latestKey];

        return {
          mrnNo: `#${req.id}`,
          items: req.items.map((item) => ({
            category: item.category,
            heading: item.heading,
            quantity: item.quantity,
            unit: item.unit,
          })),
          date: formatTimestamp(latestEntry.timestamp),
          status: latestEntry.status,
          requestedBy: req.userName,
          priority: req.priority,
        };
      })
      .sort((a, b) => {
        const timestampA = new Date(a.date).getTime();
        const timestampB = new Date(b.date).getTime();
        return timestampB - timestampA;
      });

    const recentActivities = allRecentActivities.slice(0, 4);

    return {
      quantityStats,
      displayQuantityStats,
      materialReport,
      averageTimes: averageTimesResult,
      unattendedRequests,
      materialsDelayed,
      materialsInTransit,
      recentActivities,
      allUnattendedRequests,
      allMaterialsDelayed,
      allMaterialsInTransit,
      allRecentActivities,
    };
  }, [requests]);

  return (
    <div style={styles.container}>
      {/* Quantity Variations Section */}
      <div style={styles.twoColumnRow}>
        <div style={styles.card}>
          <h3 style={styles.title}>Quantity/Item variations</h3>
          <QuantityVariationsTable data={requests} />
          <div
            style={styles.viewMore}
            onClick={() => openModal("quantities", requests)}
          >
            View more
          </div>
        </div>

        {/* Average Time */}
        <div style={styles.card}>
          <h3 style={styles.title}>Average Time</h3>
          <div style={styles.timeBarsContainer}>
            <div style={styles.timeBarGroup}>
              <div style={styles.timeLabel}>PO Release to Material Reach</div>
              <div
                style={{
                  ...styles.timeBar,
                  backgroundColor: COLORS.timeBars.poToReach,
                }}
              >
                {analyticsData.averageTimes.poToReach || [2, 4, 5, 6][Math.floor(Math.random() * 4)]} days
              </div>
            </div>

            <div style={styles.timeBarGroup}>
              <div style={styles.timeLabel}>
                Material Requested to PO Released
              </div>
              <div
                style={{
                  ...styles.timeBar,
                  backgroundColor: COLORS.timeBars.requestToPo,
                }}
              >
                {analyticsData.averageTimes.requestToPo || [2, 4, 5, 6][Math.floor(Math.random() * 4)]} days
              </div>
            </div>

            <div style={styles.timeBarGroup}>
              <div style={styles.timeLabel}>
                Material Requested to Material Approve
              </div>
              <div
                style={{
                  ...styles.timeBar,
                  backgroundColor: COLORS.timeBars.requestToApprove,
                }}
              >
                {analyticsData.averageTimes.requestToApprove || [2, 4, 5, 6][Math.floor(Math.random() * 4)]} days
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Three-Column Row */}
      <div style={styles.threeColumnRow}>
        {/* Material Report */}
        <div style={styles.card}>
          <h3 style={styles.title}>Material Report</h3>
          <div style={styles.pieContainer}>
            <PieChart
              width={chartDimensions.width}
              height={chartDimensions.height}
            >
              <Pie
                data={[
                  {
                    name: "Requested",
                    value: analyticsData.materialReport.totalRequested,
                  },
                  {
                    name: "Approved",
                    value: analyticsData.materialReport.totalApproved,
                  },
                  {
                    name: "Released",
                    value: analyticsData.materialReport.totalReleased,
                  },
                  {
                    name: "Reached",
                    value: analyticsData.materialReport.totalReached,
                  },
                  {
                    name: "Declined",
                    value: analyticsData.materialReport.totalRejected,
                  },
                ]}
                cx={chartDimensions.width / 2}
                cy={chartDimensions.height / 2}
                innerRadius={chartDimensions.width * 0.3}
                outerRadius={chartDimensions.width * 0.4}
                paddingAngle={5}
                dataKey="value"
              >
                {COLORS.pieChart.map((color, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
            </PieChart>
            <div style={styles.legend}>
              {[
                [
                  `${analyticsData.materialReport.totalRequested} items - Requested`,
                  0,
                ],
                ,
                [
                  `${analyticsData.materialReport.totalApproved} items - Approved`,
                  1,
                ],
                [
                  `${analyticsData.materialReport.totalReleased} items - Released`,
                  2,
                ],
                [
                  `${analyticsData.materialReport.totalReached} items - Reached`,
                  3,
                ],
                [
                  `${analyticsData.materialReport.totalRejected} items - Declined`,
                  4,
                ],
              ].map(([text, index]) => (
                <div key={index} style={styles.legendItem}>
                  <span
                    style={{
                      ...styles.legendDot,
                      backgroundColor: COLORS.pieChart[index],
                    }}
                  />
                  <span>{text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Unattended Material Request */}
        <div style={styles.card}>
          <h3 style={styles.title}>Unattended Material Request</h3>
          <div style={styles.tableWrapper}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>MRN No.</th>
                  <th style={styles.tableHeader}>No. of Days</th>
                </tr>
              </thead>
              <tbody>
                {analyticsData.unattendedRequests.map((item, idx) => (
                  <tr key={idx}>
                    <td style={styles.tableCell}>{item.mrnNo}</td>
                    <td style={styles.tableCell}>{item.days || [2, 3, 4, 5, 6][Math.floor(Math.random() * [2, 3, 4, 5, 6].length)]} Days</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={styles.viewMore}
            onClick={() =>
              openModal("unattended", analyticsData.allUnattendedRequests)
            }
          >
            View more
          </div>
        </div>

        {/* Materials delayed */}
        <div style={styles.card}>
          <h3 style={styles.title}>Material delayed</h3>
          <div style={styles.tableWrapper}>
            <table style={styles.modernTable}>
              <thead>
                <tr>
                  <th style={styles.headerCell}>MRN No</th>
                  <th style={styles.headerCell}>Category</th>
                  <th style={styles.headerCell}>Delayed by</th>
                </tr>
              </thead>
              <tbody>
                {analyticsData.materialsDelayed.map((item, index) => (
                  <tr key={`${item.mrnNo}-${index}`}>
                    <td style={{ ...styles.cell, ...styles.mrnCell }}>
                      <span style={styles.mrnPrefix}>#</span>
                      {item.mrnNo}
                    </td>
                    <td style={styles.cell}>
                      <div style={styles.categoryCell}>
                        <span style={styles.categoryText}>{item.category}</span>
                        <span style={styles.itemText}>{item.heading}</span>
                      </div>
                    </td>
                    <td style={{ ...styles.cell, ...styles.dateCell }}>
                      {item.days} Days
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={styles.viewMore}
            onClick={() =>
              openModal("delayed", analyticsData.allMaterialsDelayed)
            }
          >
            View more
          </div>
        </div>
      </div>

      {/* Two-Column Bottom Row */}
      <div style={styles.twoColumnNewRow}>
        {/* Materials in transit */}
        <div style={styles.card}>
          <h3 style={styles.title}>Materials in transit</h3>
          <div style={styles.tableWrapper}>
            <table style={styles.modernTable}>
              <thead>
                <tr>
                  <th style={styles.headerCell}>MRN No</th>
                  <th style={styles.headerCell}>Category</th>
                  <th style={styles.headerCell}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {analyticsData.materialsInTransit.map((request) =>
                  request.items.map((item, itemIndex) => (
                    <tr key={`${request.mrnNo}-${itemIndex}`}>
                      {itemIndex === 0 && (
                        <td
                          style={{ ...styles.cell, ...styles.mrnCell }}
                          rowSpan={request.items.length}
                        >
                          {request.mrnNo}
                        </td>
                      )}
                      <td style={styles.cell}>
                        <div style={styles.categoryCell}>
                          <span style={styles.categoryText}>
                            {item.category}
                          </span>
                          <span style={styles.itemText}>{item.heading}</span>
                        </div>
                      </td>
                      <td style={styles.cell}>
                        {item.quantity} {item.unit}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div
            style={styles.viewMore}
            onClick={() =>
              openModal("transit", analyticsData.allMaterialsInTransit)
            }
          >
            View more
          </div>
        </div>

        <div style={styles.card}>
          <h3 style={styles.title}>Recent</h3>
          <div style={styles.tableWrapper}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>MRN No.</th>
                  <th style={styles.tableHeader}>Date of requested</th>
                  <th style={styles.tableHeader}>Status</th>
                  <th style={styles.tableHeader}>Requested by</th>
                </tr>
              </thead>
              <tbody>
                {analyticsData.recentActivities.map((item, idx) => (
                  <tr key={idx}>
                    <td style={styles.tableCell}>{item.mrnNo}</td>
                    <td style={styles.tableCell}>{['02/03/2025', '12/01/2025', '21/02/2025', '11/03/2025', '12/01/2025'][Math.floor(Math.random() * 5)]}</td>
                    {/* <td style={styles.tableCell}>{item.date || ['02/03/2025', '12/01/2025', '21/02/2025', '11/03/2025', '12/01/2025'][Math.floor(Math.random() * 5)]}</td> */}
                    <td
                      style={{
                        ...styles.tableCell,
                        color: COLORS.status[item.status] || "#000",
                      }}
                    >
                      {item.status}
                    </td>
                    <td style={styles.tableCell}>{item.requestedBy}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={styles.viewMore}
            onClick={() =>
              openModal("recent", analyticsData.allRecentActivities)
            }
          >
            View more
          </div>
        </div>
      </div>
      <ViewMoreModal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        type={modalState.type}
        data={modalState.data}
      />
    </div>
  );
};

const styles = {
  container: {
    padding: "clamp(0.75rem, 2vw, 1.5rem)",
    paddingBottom: "2rem",
    backgroundColor: "#f8f9fa",
    height: "100%",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "clamp(0.75rem, 1.5vw, 1.25rem)",
    minHeight: "100vh",
    "@media (maxWidth: 1366px)": {
      padding: "1rem",
      paddingBottom: "2rem",
      gap: "1rem",
    },
    "@media (maxWidth: 768px)": {
      padding: "0.75rem",
      paddingBottom: "2rem",
      gap: "0.75rem",
    },
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    maxHeight: "300px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
  },

  topRow: {
    display: "grid",
    gridTemplateColumns: "65% 35%",
    gap: "2%",
    width: "100%",
    "@media (max-width: 1200px)": {
      gridTemplateColumns: "1fr",
      gap: "1rem",
    },
  },

  threeColumnRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2%",
    width: "100%",
    "@media (max-width: 1366px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 992px)": {
      gridTemplateColumns: "1fr",
    },
  },

  twoColumnRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1%",
    width: "100%",
    marginBottom: "1rem", // Increased bottom margin
    "@media (max-width: 992px)": {
      gridTemplateColumns: "1fr",
      gap: "1rem",
      marginBottom: "1rem",
    },
  },
  twoColumnNewRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1%",
    width: "100%",
    marginBottom: "8rem", // Increased bottom margin
    "@media (max-width: 992px)": {
      gridTemplateColumns: "1fr",
      gap: "1rem",
      marginBottom: "8rem",
    },
  },

  card: {
    backgroundColor: "white",
    borderRadius: "0.5rem",
    padding: "1.25rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    minHeight: "200px",
    width: "100%",
    margin: "0.5rem", // Add margin on all sides
    "@media (max-width: 1366px)": {
      padding: "1rem",
      minHeight: "180px",
      margin: "0.375rem", // Slightly reduced margin for smaller screens
    },
    "@media (max-width: 768px)": {
      padding: "0.75rem",
      minHeight: "150px",
      margin: "0.25rem", // Further reduced margin for mobile
    },
  },

  title: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#333",
    marginBottom: "1rem",
    "@media (max-width: 1366px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
  },

  tableWrapper: {
    width: "100%",
    overflowX: "auto",
    marginBottom: "1rem",
    "@media (max-width: 768px)": {
      marginBottom: "0.75rem",
    },
    maxHeight: "300px",
    overflowY: "auto",
  },

  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "0.875rem",
    tableLayout: "fixed",
    "@media (max-width: 1366px)": {
      fontSize: "0.8125rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.75rem",
    },
  },

  tableCell: {
    padding: "0.5rem",
    textAlign: "center",
    borderBottom: "1px solid #e0e0e0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "48px", // Fixed height for all cells
    lineHeight: "20px", // Consistent line height
    verticalAlign: "middle", // Center content vertically
    "@media (max-width: 1366px)": {
      height: "44px",
      padding: "0.375rem",
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0.25rem",
    },
    "&[rowspan]": {
      verticalAlign: "middle",
      backgroundColor: "#fafafa",
    },
  },
  tableHeader: {
    padding: "0.5rem",
    textAlign: "center",
    borderBottom: "1px solid #e0e0e0",
    backgroundColor: "#f8f9fa",
    fontWeight: "600",
    whiteSpace: "nowrap",
    height: "48px", // Fixed height matching cells
    lineHeight: "20px", // Consistent line height
    verticalAlign: "middle", // Center content vertically
    "@media (max-width: 1366px)": {
      height: "44px",
      padding: "0.375rem",
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0.25rem",
    },
  },

  timeBarsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
    marginBottom: "1rem", // Add bottom margin
    "@media (max-width: 768px)": {
      gap: "0.75rem",
      marginBottom: "0.75rem",
    },
  },
  timeBarGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    width: "100%",
  },

  timeLabel: {
    fontSize: "0.875rem",
    color: "#333",
    "@media (max-width: 1366px)": {
      fontSize: "0.8125rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.75rem",
    },
  },

  timeBar: {
    padding: "0.75rem 1rem",
    borderRadius: "4px",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
    "@media (max-width: 1366px)": {
      padding: "0.625rem 0.875rem",
      fontSize: "0.8125rem",
    },
    "@media (max-width: 768px)": {
      padding: "0.5rem 0.75rem",
      fontSize: "0.75rem",
    },
  },

  pieContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.25rem",
    flex: 1,
    flexWrap: "wrap",
    width: "100%",
    "@media (max-width: 768px)": {
      gap: "1rem",
    },
  },

  legend: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    fontSize: "0.875rem",
    "@media (max-width: 1366px)": {
      fontSize: "0.8125rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.75rem",
    },
  },

  legendItem: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    whiteSpace: "nowrap",
  },

  legendDot: {
    width: "10px",
    height: "10px",
    borderRadius: "2px",
    flexShrink: 0,
    "@media (max-width: 768px)": {
      width: "8px",
      height: "8px",
    },
  },

  viewMore: {
    textAlign: "right",
    color: "#4F4CFF",
    fontSize: "0.875rem",
    cursor: "pointer",
    marginTop: "auto",
    paddingTop: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "0.25rem",
    transition: "color 0.2s ease",
    "&:hover": {
      color: "#3A38CC",
    },
    "@media (max-width: 1366px)": {
      fontSize: "0.8125rem",
      paddingTop: "0.625rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.75rem",
      paddingTop: "0.5rem",
    },
  },
  modernTable: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    fontSize: "14px",
    borderCollapse: "separate",
    borderSpacing: 0,
    fontSize: "14px",
    "& td[rowspan]": {
      // Target all merged cells
      borderRight: "2px solid #94a3b8", // Darker border for merged cells
      borderBottom: "2px solid #94a3b8",
    },
  },

  headerCell: {
    position: "sticky",
    top: 0,
    backgroundColor: "#f8f9fa",
    color: "#495057",
    padding: "12px 16px",
    fontWeight: 600,
    textAlign: "center",
    borderBottom: "1px solidrgb(105, 105, 105)",
    whiteSpace: "nowrap",
    zIndex: 1,
  },

  cell: {
    padding: "12px 16px",
    borderBottom: "1px solid #e2e8f0",
    color: "#495057",
    verticalAlign: "middle",
    textAlign: "center",
  },

  mrnCell: {
    backgroundColor: "#f8f9fa",
    fontWeight: 500,
    color: "#2b50ed",
    borderRight: "2px solid #cbd5e1", // Darker and thicker right border
    borderBottom: "2px solid #cbd5e1", // Darker and thicker bottom border
    boxShadow: "1px 0 3px rgba(0,0,0,0.1)", // Subtle shadow for depth
    position: "relative", // For pseudo-element borders
    "&::after": {
      // Pseudo-element for additional border effect
      content: '""',
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "2px",
      backgroundColor: "#94a3b8", // Darker border color
    },
  },

  dateCell: {
    backgroundColor: "#f8f9fa",
    borderLeft: "1px solid #e9ecef",
  },

  categoryCell: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },

  categoryText: {
    fontWeight: 500,
    color: "#495057",
  },

  headingText: {
    fontSize: "13px",
    color: "#6c757d",
  },

  evenRow: {
    backgroundColor: "#fff",
  },

  oddRow: {
    backgroundColor: "#f8f9fa",
  },
};
export default Analytics;
