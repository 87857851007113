import { secondDatabase } from "../../../config/firebase";

const fetchRequests = async (selectedProject) => {
    
    try {
        console.log('Fetching the request for:', selectedProject);

        // Construct the Firebase path
        const path = `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}`;

        // Fetch the data from Firebase
        const snapshot = await secondDatabase.ref(path).once('value');

        // Extract and return the data
        const receivedData = snapshot.val();
        let data = receivedData !== null ? Object.values(receivedData) : [];

        data = data.map((entry) => {
            if (entry.items && typeof entry.items === 'object') {
                return {
                    ...entry,
                    items: Object.values(entry.items),
                };
            }
            return entry;
        });

        return data.reverse();
    } catch (error) {
        console.error('Error fetching requests:', error);
        throw error; // Propagate the error to the caller
    }
};

export default fetchRequests;
