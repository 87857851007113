import { secondDatabase } from "../../config/firebase";

const updateItemOfFirebase = async (firmId, updatedItem) => {
  // write the code here
  const useRef = secondDatabase.ref(`${firmId}/projectMasterFile/materials/${updatedItem.descriptionID}`).update(updatedItem);



};

export default updateItemOfFirebase;
