import interactWithUSComments from "../../config/liveFirebase";
import checkDateExists from "./checkDateExists";

function getPreviousSevenDays() {
    const dates = [];
    const today = new Date();

    for (let i = 1; i <= 7; i++) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        const dd = String(pastDate.getDate()).padStart(2, '0');
        const mm = String(pastDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const yyyy = pastDate.getFullYear();
        dates.push(`${dd}-${mm}-${yyyy}`);
    }
    return dates;
}

function getDateRangeArray(startDate, endDate) {
    console.log('lastSevenDays startDate : ', startDate);
    console.log('lastSevenDays endDate : ', endDate);
    const dateArray = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Loop from end date to start date (descending order)
    for (let d = new Date(end); d >= start; d.setDate(d.getDate() - 1)) {
        dateArray.push(formatDate(d));
    }

    return dateArray;
}

// Helper function to format date as "DD-MM-YYYY"
function formatDate(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
}

const structureNMRData = async (projectIds, startDate, endDate, setProcessedDays) => {

    // let firm = JSON.parse(localStorage.getItem("Firm"));
    // let lastSevenDays = await getPreviousSevenDays();
    let lastSevenDays = await getDateRangeArray(startDate, endDate);
    console.log('lastSevenDays : ', JSON.stringify(lastSevenDays))
    let sampleData = {};
    for(let projectId of projectIds){
        let data = await interactWithUSComments('GET', projectId[0]);
        if(data.data !== null){
            for(let day of lastSevenDays){
                let status = await checkDateExists(day, data.data);
                if(sampleData.hasOwnProperty(projectId[1])){
                    sampleData[projectId[1]][day] = status ? 'Yes' : 'No';
                }else{
                    sampleData[projectId[1]] = {
                        [day] : status ? 'Yes' : 'No'
                    }
                }
            }
        }
        setProcessedDays(prevState => prevState + 1);
    }
    return sampleData;
}

export default structureNMRData;