import React from 'react';

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {

  const styles = {
    dateInput: {
      padding: '0.5rem',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      alignItems: 'center',
    },
    label: {
      marginRight: '0.5rem',
    },
    field: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  return (
    <div style={styles.container}>
      <div style={styles.field}>
        <label htmlFor="start-date" style={styles.label}>Start Date:</label>
        <input
          id="start-date"
          type="date"
          value={startDate}
          onChange={(e) => {
            const selectedDate = e.target.value;
            if (endDate && new Date(selectedDate) > new Date(endDate)) {
              alert("Start date cannot be after the end date.");
            } else {
              setStartDate(selectedDate);
            }
          }}
          style={styles.dateInput}
          max={today} // Prevent selection of future dates
        />
      </div>

      <div style={styles.field}>
        <label htmlFor="end-date" style={styles.label}>End Date:</label>
        <input
          id="end-date"
          type="date"
          value={endDate}
          onChange={(e) => {
            const selectedDate = e.target.value;
            if (startDate && new Date(selectedDate) < new Date(startDate)) {
              alert("End date cannot be before the start date.");
            } else {
              setEndDate(selectedDate);
            }
          }}
          style={styles.dateInput}
          min={startDate} // Ensure end date cannot be before start date
          max={today} // Prevent selection of future dates
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
