import React, { useState, useEffect } from 'react';

const ProgressBar = ({ isOpen = false, progress = { completed: 0, total: 0 }, setClose }) => {
  console.log('progress ProgressBar : ', progress);

  const [messageIndex, setMessageIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);

  const messages = [
    "We're processing your Excel file with care...",
    "Converting rows and columns into structured data...",
    "Organizing your information beautifully...",
    "Almost there! Adding finishing touches...",
    "Thank you for your patience, this may take a few minutes...",
    "Creating a clean, structured JSON format...",
    "Making sure everything is perfectly organized..."
  ];

  const percentage = progress.total === 0 ? 0 : Math.round((progress.completed / progress.total) * 100);
  const isCompleted = percentage === 100;

  const containerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 50,
    backgroundColor: 'transparent'
  };

  const progressBarContainerStyle = {
    width: '90%',
    backgroundColor: 'white',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    marginBottom: 0,
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    border: '1px solid #e5e7eb',
    borderBottom: 'none',
    justifyContent: 'space-between'
  };

  const contentWrapperStyle = {
    flex: 1,
    marginRight: '16px'
  };

  const progressTrackStyle = {
    position: 'relative',
    height: '8px',
    backgroundColor: '#f3f4f6',
    borderRadius: '9999px',
    overflow: 'hidden'
  };

  const progressFillStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    backgroundColor: isCompleted ? '#22c55e' : '#3b82f6',
    transition: 'width 300ms ease-in-out',
    width: `${percentage}%`
  };

  const textContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '4px'
  };

  const messageStyle = {
    fontSize: '14px',
    color: isCompleted ? '#22c55e' : '#4b5563',
    fontWeight: isCompleted ? 'bold' : 'normal'
  };

  const percentageStyle = {
    fontSize: '14px',
    fontWeight: 500,
    color: isCompleted ? '#22c55e' : '#374151'
  };

  const iconStyle = {
    width: '20px',
    height: '20px'
  };

  const toastStyle = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#22c55e',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    zIndex: 1000,
    display: showToast ? 'flex' : 'none',
    alignItems: 'center',
    gap: '8px',
    animation: 'slideIn 0.3s ease-out'
  };

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
      @keyframes slideIn {
        from {
          transform: translateX(100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    `;
    document.head.appendChild(styleSheet);
    return () => styleSheet.remove();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setMessageIndex(0);
      return;
    }

    const messageTimer = setInterval(() => {
      setMessageIndex(prev => (prev + 1) % messages.length);
    }, 8000);

    return () => clearInterval(messageTimer);
  }, [isOpen]);

  useEffect(() => {
    if (isCompleted) {
      setShowToast(true);
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isCompleted]);

  if (!isOpen) return null;

  // SVG Icons
  const AlertIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={iconStyle}>
      <circle cx="12" cy="12" r="10"/>
      <line x1="12" y1="8" x2="12" y2="12"/>
      <line x1="12" y1="16" x2="12.01" y2="16"/>
    </svg>
  );

  const CheckIcon = () => (
    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={iconStyle}>
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/>
      <polyline points="22 4 12 14.01 9 11.01"/>
    </svg>
  );

  const CloseIcon = () => (
    <svg onClick={setClose} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ ...iconStyle, cursor: 'pointer' }}>
      <line x1="18" y1="6" x2="6" y2="18"/>
      <line x1="6" y1="6" x2="18" y2="18"/>
    </svg>
  );

  return (
    <>
      <div style={containerStyle}>
        <div style={progressBarContainerStyle}>
          <div style={contentWrapperStyle}>
            <div style={progressTrackStyle}>
              <div style={progressFillStyle} />
            </div>
            <div style={textContainerStyle}>
              <span style={messageStyle}>
                {isCompleted ? "Processing complete! 🎉" : messages[messageIndex]}
              </span>
              <span style={percentageStyle}>{percentage}%</span>
            </div>
          </div>
          
          {isCompleted ? <CloseIcon /> : <AlertIcon />}
        </div>
      </div>

      <div style={toastStyle}>
        <CheckIcon />
        <div>
          <div style={{ fontWeight: 500 }}>Processing Complete</div>
          <div style={{ fontSize: '14px' }}>Your file has been successfully processed!</div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
