import React, { useState, useRef, useEffect } from 'react';
import { GiExplosiveMaterials } from 'react-icons/gi';

const MaterialButton = ({name, options, onSubmit, icon}) => {
    
    const [materialOption, setMaterialOption] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setMaterialOption(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleMaterialButtonClick = (event) => {
        event.stopPropagation();
        setMaterialOption(!materialOption);
    };

    const toggleSelection = (option) => {
        
        setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.includes(option)
                ? prevSelectedOptions.filter(item => item !== option)
                : [...prevSelectedOptions, option]
        );
        onSubmit(option)
    };

    const materialOptions = options;

    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 10,
                    borderRadius: 5,
                    backgroundColor: '#F6E6E6',
                    padding: 10,
                    cursor: 'pointer'
                }}
                onClick={handleMaterialButtonClick}
            >
                <p style={{marginRight: 5}}>
                    {name}
                </p>
                {icon}
            </div>

            {materialOption && (
                <div
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        marginTop: 5,
                        padding: 10,
                        backgroundColor: '#FFF',
                        borderRadius: 5,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        width: '200px',
                    }}
                >
                    {materialOptions.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px 0', cursor: 'pointer' }}
                            onClick={() => toggleSelection(item)}>
                            <input
                                type="checkbox"
                                checked={selectedOptions.includes(item)}
                                onChange={() => toggleSelection(item)}
                                style={{ marginRight: 10 }}
                            />
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MaterialButton;
