import React from "react";

const RequestHistoryModal = ({
  requestHistory,
  onClose,
  onImageClick,
  isRequestRecord = false,
}) => {
  // If no request history is available
  if (!requestHistory || Object.keys(requestHistory).length === 0) {
    return (
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          Close
        </button>
        <h3 style={styles.title}>Request History</h3>
        <p>No request history available</p>
      </div>
    );
  }

  // Format date consistently
  const formatDate = (timestamp) => {
    if (!timestamp) return "Not provided";

    try {
      let date;
      if (typeof timestamp === "string" && timestamp.includes("T")) {
        // ISO date string
        date = new Date(timestamp);
      } else if (typeof timestamp === "number" || !isNaN(Number(timestamp))) {
        // Unix timestamp (milliseconds)
        date = new Date(Number(timestamp));
      } else {
        // Other string format
        date = new Date(timestamp);
      }

      if (isNaN(date.getTime())) return timestamp; // Return original if invalid

      return date.toLocaleString();
    } catch (error) {
      return timestamp; // Return original on error
    }
  };

  // For request-converted records
  if (isRequestRecord) {
    return (
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          Close
        </button>
        <h3 style={styles.title}>Request History</h3>
        {Object.entries(requestHistory).map(([key, history], index) => (
          <div key={index} style={styles.historyItem}>
            <p>
              <strong>ID:</strong> {history.id || "Not provided"}
            </p>
            <p>
              <strong>Name:</strong> {history.name || "Not provided"}
            </p>
            <p>
              <strong>Status:</strong> {history.status || "Not provided"}
            </p>
            <p>
              <strong>Updated At:</strong>{" "}
              {history.updatedAt
                ? formatDate(history.updatedAt)
                : "Not provided"}
            </p>
            <p>
              <strong>Remark:</strong> {history.remark || "Not provided"}
            </p>
            {history.quantities && (
              <p>
                <strong>Quantities:</strong> {history.quantities.join(", ")}
              </p>
            )}
          </div>
        ))}
      </div>
    );
  }

  // For regular inward entries
  return (
    <div style={styles.modal}>
      <button style={styles.closeButton} onClick={onClose}>
        Close
      </button>
      <h3 style={styles.title}>Request History</h3>
      {Object.values(requestHistory).map((history, index) => (
        <div key={index} style={styles.historyItem}>
          <p>
            <strong>Done By:</strong> {history.doneBy || "Not provided"}
          </p>
          <p>
            <strong>Status:</strong> {history.status || "Not provided"}
          </p>
          <p>
            <strong>Timestamp:</strong>{" "}
            {history.timestamp ? formatDate(history.timestamp) : "Not provided"}
          </p>
          {history.edit && (
            <p>
              <strong>Edit:</strong> {history.edit}
            </p>
          )}
          {history.remark && (
            <p>
              <strong>Remark:</strong> {history.remark}
            </p>
          )}
          {history.images &&
            Object.values(history.images).map((image, i) => (
              <button
                style={styles.imageButton}
                key={i}
                onClick={() => onImageClick(image.uri)}
              >
                View Image
              </button>
            ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "600px",
    margin: "20px auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    color: "#fff",
    backgroundColor: "#e57373",
    border: "none",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
    float: "right",
  },
  title: {
    fontSize: "18px",
    color: "#333",
    marginBottom: "10px",
  },
  historyItem: {
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
  },
  imageButton: {
    color: "#007bff",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
};

export default RequestHistoryModal;
