import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import config from "../../config/config";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { FiPlusSquare } from "react-icons/fi";
import fetchIndexcesForAddNewItem from "../../helpers/fetchIndexcesForAddNewItem";
import toast from "react-hot-toast";
import { FiInfo } from "react-icons/fi";

const AddItemsForm = ({
  handleClose,
  data,
  sendItems,
  orderType,
  addedItems
}) => {
  console.log("added items  ", addedItems);
  
  const [workType, setWorkType] = useState(
    orderType == "Purchase Order" || orderType == "Material Quotation"
      ? "Only Material"
      : "Only Work"
  );
  const [workMaterialCategories, setWorkMaterialCategories] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [specification, setSpecification] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [rate, setRate] = useState("");
  const [gst, setGST] = useState("");
  const [itemsAdded, setItemsAdded] = useState("");

  useEffect(() => {
    fetch(`${config.utilService}categories`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          orderType == "Purchase Order" || orderType == "Material Quotation"
            ? setMaterialCategories(res.materialCategories)
            : setWorkMaterialCategories(res.vendorCategories);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: 20, gap: 20 }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginBottom: 40,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            gap: "10px",
          }}
        >
          <FormControl
            variant="filled"
            required
            style={{ borderRadius: "8px", width: "300px", fontSize: "20px" }}
          >
            <InputLabel>Work Type</InputLabel>
            {orderType == "Purchase Order" ||
            orderType == "Material Quotation" ? (
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={"Only Material"}
              >
                <MenuItem value={"Only Material"}>Only Material</MenuItem>
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={(event) => setWorkType(event.target.value)}
                value={workType}
              >
                <MenuItem value={"Only Work"}>Only Work</MenuItem>
                <MenuItem value={"Work + Material"}>Work + Material</MenuItem>
              </Select>
            )}
          </FormControl>
          {workType === "Only Work" || workType === "Work + Material" ? (
            <FormControl
              variant="filled"
              required
              style={{ borderRadius: "8px", width: "300px", fontSize: "20px" }}
            >
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                {workMaterialCategories.map((item, index) => (
                  <MenuItem value={item.Category}>{item.Category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              variant="filled"
              required
              style={{ borderRadius: "8px", width: "300px", fontSize: "20px" }}
            >
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                {materialCategories.map((item, index) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {workType === "Only Work" || workType === "Work + Material" ? (
          <TextField
            multiline
            label="Description"
            variant="filled"
            required
            onChange={(e) => {
              let val = e.currentTarget.value;
              val = val.replace(/\n/g, "<new-line>");
              val = val.replace(/'/g, "<single-quote>");
              val = val.replace(/"/g, "<double-quote>");
              setDescription(val);
            }}
            value={description
              .replace(/<new-line>/g, "\n")
              .replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')}
            style={{ alignSelf: "flex-start", width: "100%" }}
          />
        ) : (
          <TextField
            multiline
            label="Specification"
            variant="filled"
            required
            onChange={(e) => {
              let val = e.currentTarget.value;
              val = val.replace(/\n/g, "<new-line>");
              val = val.replace(/'/g, "<single-quote>");
              val = val.replace(/"/g, "<double-quote>");
              setSpecification(val);
            }}
            value={specification
              .replace(/<new-line>/g, "\n")
              .replace(/<single-quote>/g, "'")
              .replace(/<double-quote>/g, '"')}
            style={{ alignSelf: "flex-start", width: "100%" }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 20,
          }}
        >
          <TextField
            label="Quantity"
            variant="filled"
            required
            onChange={(e) =>
              setQuantity(e.currentTarget.value.replace(/[^0-9]+/gm, ""))
            }
            value={quantity}
          />
          <TextField
            label="Unit"
            variant="filled"
            required
            onChange={(e) => {
              setUnit(e.currentTarget.value.replace(/[0-9]/g, ""));
            }}
            value={unit}
          />
          <TextField
            label="Rate"
            variant="filled"
            required
            value={rate}
            onChange={(e) => {
              setRate(e.currentTarget.value.replace(/[^0-9]+/gm, ""));
            }}
          />
          <TextField
            label="GST"
            variant="filled"
            required
            onChange={(e) =>
              setGST(e.currentTarget.value.replace(/[^0-9]+/gm, ""))
            }
            value={gst}
          />
          <FiPlusSquare
            size={80}
            style={{ cursor: "pointer" }}
            onClick={() => {
              let indexces = fetchIndexcesForAddNewItem({ rooms: data.rooms });

              if (category && quantity && unit && gst && rate) {
                if (
                  orderType == "Purchase Order" ||
                  orderType == "Material Quotation"
                ) {
                  const materialObject = {
                    OrderId: new Date().getTime(),
                    roomName: 'Non tender items',
                    "UnitName": "Extra items",
                    specification: specification,
                    indexces: {
                      roomIndex: indexces.roomIndex,
                      unitIndex: indexces.extraItemsIndex,
                      componentIndex: indexces.componentIndex,
                      materialIndex:
                        itemsAdded.length > 0
                          ? itemsAdded[itemsAdded.length - 1].indexces
                              .materialIndex + 1
                          : addedItems.length > 0
                          ? addedItems[addedItems.length - 1].indexces
                              .materialIndex + 1
                          : 0,
                    },
                    quantity: quantity,
                    unit: unit,
                    rate: rate,
                    gst: gst,
                    type: category,
                    workType: workType,
                    status: "",
                    orderStatus: "",
                    item: "",
                  };
              
                  setItemsAdded([...itemsAdded, materialObject]);
                }
                else {
                  const workObject = {
                    Drawings: [],
                    OrderId: new Date().getTime(),
                    roomName: 'Non tender items',
                    "UnitName": "Extra items",
                    description: description,
                    indexces: {
                      roomIndex: indexces.roomIndex,
                      unitIndex: indexces.extraItemsIndex,
                      componentIndex: indexces.componentIndex,
                      workIndex:
                        itemsAdded.length > 0
                          ? itemsAdded[itemsAdded.length - 1].indexces
                              .workIndex + 1
                          : addedItems.length > 0
                          ? addedItems[addedItems.length - 1].indexces
                              .workIndex + 1
                          : 0,
                    },
                    quantity: quantity,
                    unit: unit,
                    rate: rate,
                    gst: gst,
                    vendorCategory: category,
                    workType: workType,
                    status: "",
                    orderStatus: "",
                    heading: "",
                  };
                  setItemsAdded([...itemsAdded, workObject]);
                  console.log("here it sis added W", workObject)
                }
              } else {
                toast.error("Please fill mandatory fileds!", {
                  duration: 4000,
                  position: "bottom-center",
                  style: {
                    borderRadius: "8px",
                    padding: "16px",
                    color: "#E72424",
                    backgroundColor: "#FEF0F0",
                    maxWidth: "100%",
                  },
                  icon: <FiInfo color={"#E72424"} />,
                });
              }
            }}
          />
        </div>
      </form>

      {itemsAdded &&
        itemsAdded.map((item, index) => (
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                gap: "10px",
              }}
            >
              <FormControl
                variant="filled"
                required
                style={{
                  borderRadius: "8px",
                  width: "300px",
                  fontSize: "20px",
                }}
              >
                <InputLabel>Work Type</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  onChange={(event) => {
                    itemsAdded[index].workType = event.target.value;
                    setItemsAdded([...itemsAdded]);
                  }}
                  value={item.workType}
                >
                  <MenuItem value={"Only Work"}>Only Work</MenuItem>
                  <MenuItem value={"Only Material"}>Only Material</MenuItem>
                  <MenuItem value={"Work + Material"}>Work + Material</MenuItem>
                </Select>
              </FormControl>
              {item.workType === "Only Work" ||
              item.workType === "Work + Material" ? (
                <FormControl
                  variant="filled"
                  required
                  style={{
                    borderRadius: "8px",
                    width: "300px",
                    fontSize: "20px",
                  }}
                >
                  <InputLabel>Category</InputLabel>
                  <Select value={item.vendorCategory} disabled>
                    {workMaterialCategories.map((item, index) => (
                      <MenuItem value={item.Category}>{item.Category}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  variant="filled"
                  required
                  style={{
                    borderRadius: "8px",
                    width: "300px",
                    fontSize: "20px",
                  }}
                >
                  <InputLabel>Category</InputLabel>
                  <Select value={item.type} disabled>
                    {materialCategories.map((item, index) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            {item.workType === "Only Work" ||
            item.workType === "Work + Material" ? (
              <TextField
                multiline
                label="Description"
                variant="filled"
                required
                value={item.description
                  .replace(/<new-line>/g, "\n")
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')}
                onChange={(event) => {
                  let val = event.currentTarget.value;
                  val = val.replace(/\n/g, "<new-line>");
                  val = val.replace(/'/g, "<single-quote>");
                  val = val.replace(/"/g, "<double-quote>");
                  itemsAdded[index].description = val;
                  setItemsAdded([...itemsAdded]);
                }}
                style={{ alignSelf: "flex-start", width: "100%" }}
              />
            ) : (
              <TextField
                multiline
                label="Specification"
                variant="filled"
                required
                value={item.specification
                  .replace(/<new-line>/g, "\n")
                  .replace(/<single-quote>/g, "'")
                  .replace(/<double-quote>/g, '"')}
                onChange={(event) => {
                  let val = event.currentTarget.value;
                  val = val.replace(/\n/g, "<new-line>");
                  val = val.replace(/'/g, "<single-quote>");
                  val = val.replace(/"/g, "<double-quote>");
                  itemsAdded[index].specification = val;
                  setItemsAdded([...itemsAdded]);
                }}
                style={{ alignSelf: "flex-start", width: "100%" }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 20,
              }}
            >
              <TextField
                label="Quantity"
                variant="filled"
                required
                value={item.quantity}
                onChange={(event) => {
                  itemsAdded[index].quantity = event.target.value.replace(
                    /[^0-9]+/gm,
                    ""
                  );
                  setItemsAdded([...itemsAdded]);
                }}
              />
              <TextField
                label="Unit"
                variant="filled"
                required
                value={item.unit}
                onChange={(event) => {
                  itemsAdded[index].unit = event.target.value.replace(
                    /[0-9.]/g,
                    ""
                  );
                  setItemsAdded([...itemsAdded]);
                }}
              />
              <TextField
                label="Rate"
                variant="filled"
                required
                value={item.rate}
                onChange={(event) => {
                  itemsAdded[index].rate = event.target.value.replace(
                    /[^0-9]+/gm,
                    ""
                  );
                  setItemsAdded([...itemsAdded]);
                }}
              />
              <TextField
                label="GST"
                variant="filled"
                required
                value={item.gst}
                onChange={(event) => {
                  if (event.target.value < 100) {
                    itemsAdded[index].gst = event.target.value.replace(
                      /[^0-9]+/gm,
                      ""
                    );
                    setItemsAdded([...itemsAdded]);
                  }
                }}
              />
            </div>
          </form>
        ))}

      {itemsAdded && (
        <div
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "rgb(255, 211, 77)",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 3px grey",
            color: "00aaff",
            fontWeight: "500",
            padding: "10px",
            cursor: "pointer",
            position: "sticky",
          }}
          onClick={() => {
            handleClose();
            console.log("Items added", itemsAdded);
            sendItems(itemsAdded);
          }}
        >
          Add Items
        </div>
      )}

    </div>
  );
};

export default AddItemsForm;