import React from "react";

const LoadingComponent = ({ progressState }) => {
  console.log('LoadingComponent progressState : ', progressState);

  const progressPercentage = Math.round(progressState * 100);
  
  // Calculate the circle circumference and stroke-dasharray for progress
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progressPercentage / 100) * circumference;
  
  const loaderContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "#f8f9fa",
    color: "#333",
    fontFamily: "'Roboto', 'Segoe UI', sans-serif",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.05)",
    maxWidth: "400px",
    width: "100%",
  };

  const svgContainerStyle = {
    position: "relative",
    height: "120px",
    width: "120px",
    marginBottom: "24px",
  };

  const progressTextStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "22px",
    fontWeight: "600",
    color: "#424242",
  };

  const messageStyle = {
    fontSize: "20px",
    fontWeight: "600",
    color: "#424242",
    marginBottom: "8px",
    textAlign: "center",
  };

  const subTextStyle = {
    fontSize: "14px",
    color: "#70757a",
    textAlign: "center",
  };

  const pulseStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    backgroundColor: "rgba(66, 133, 244, 0.05)",
    animation: "pulse 2s ease-in-out infinite",
  };

  return (
    <div style={loaderContainerStyle}>
      <div style={cardStyle}>
        <div style={svgContainerStyle}>
          <div style={pulseStyle}></div>
          <svg width="120" height="120" viewBox="0 0 100 100">
            {/* Background Circle */}
            <circle 
              cx="50" 
              cy="50" 
              r={radius} 
              fill="none" 
              stroke="#f0f0f0" 
              strokeWidth="8"
            />
            
            {/* Progress Circle */}
            <circle 
              cx="50" 
              cy="50" 
              r={radius} 
              fill="none" 
              stroke="#4285f4" 
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              transform="rotate(-90 50 50)"
              style={{
                transition: "stroke-dashoffset 0.3s ease"
              }}
            />
          </svg>
          <div style={progressTextStyle}>{progressPercentage}%</div>
        </div>
        <div style={messageStyle}>Fetching the latest data</div>
        <div style={subTextStyle}>Please wait while we load your information</div>
      </div>
      <style>
        {`
          @keyframes pulse {
            0% { transform: translate(-50%, -50%) scale(0.95); opacity: 0.7; }
            50% { transform: translate(-50%, -50%) scale(1.05); opacity: 0.3; }
            100% { transform: translate(-50%, -50%) scale(0.95); opacity: 0.7; }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingComponent;