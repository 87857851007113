import ProjectService from "../../api/projectService";
import structureNMRData from "./fetchNMRstructure";


const projectService = new ProjectService();


const getProjectsHere = async (startDate, endDate, setProcessedDays, setNoDays) => {
    try {
        let res = await projectService.getProjects();
        
        let allProjects = res.payload;
        setNoDays(allProjects.length);
        if (allProjects.length === 0) {
            return {};
        } else {
            let structuredData = await structureNMRData(allProjects.map(item => [item.ProjectId, item.ClientName]), startDate, endDate, setProcessedDays);
            return structuredData;
        }
    } catch (error) {
        console.error("Error fetching projects:", error);
        return {};
    }
};


export default getProjectsHere;