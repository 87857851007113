let arrayOfUrls = [
  'https://next-images.123rf.com/index/_next/image/?url=https://assets-cdn.123rf.com/index/static/assets/top-section-bg.jpeg&w=3840&q=75',
  'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg',
  'https://fps.cdnpk.net/images/home/subhome-ai.webp?w=649&h=649',
  'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLQUQ6g6NjGqj3qncgsJGpxzzRrL_qDAc1qQ&s'
]

function generateRandomId() {
  return Math.floor(Math.random() * 1e12) + 1e12;
}

function generateRandomItem() {
  const categories = ["CPfitting", "Electrical", "Plumbing", "Woodwork"];
  const units = ["kgs", "pcs", "meters", "cums"];
  

  return {
    category: categories[Math.floor(Math.random() * categories.length)],
    heading: `Heading ${Math.random().toString(36).substring(7)}`,
    subHeading: `SubHeading ${Math.random().toString(36).substring(7)}`,
    description: `This is a random description for ${Math.random().toString(36).substring(7)}`,
    orderId: generateRandomId(),
    quantity: Math.floor(Math.random() * 100).toString(),
    unit: units[Math.floor(Math.random() * units.length)],
    status: Math.random() > 0.5,
  };
}

function generateRandomRequestHistory(id) {
  const historyEntries = Array.from({ length: Math.floor(Math.random() * 3) + 2 }, () => ({
    id: generateRandomId(),
    name: `User ${Math.random().toString(36).substring(7)}`,
    remark: `Remark ${Math.random().toString(36).substring(7)}`,
    secondPersons: Array.from({ length: Math.floor(Math.random() * 3) + 1 }, () => ({
      name: `Person ${Math.random().toString(36).substring(7)}`,
      number: (Math.floor(Math.random() * 1e10)).toString()
    })),
    status: ["Requested", "Approved", "Released", "Reached"][Math.floor(Math.random() * 4)]
  }));

  return Object.fromEntries(historyEntries.map((entry) => [entry.id, entry]));
}

function generateRandomDueDate() {
  const today = new Date();
  const randomDays = Math.floor(Math.random() * 30) + 1; // Random number between 1 and 30
  const dueDate = new Date(today.setDate(today.getDate() + randomDays));
  return dueDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
}

function generateRandomRequest() {
  const id = generateRandomId();
  const itemsLength = Math.random() > 0.8 ? Math.floor(Math.random() * 3) + 10 : Math.floor(Math.random() * 4) + 1;
  const items = Array.from({ length: itemsLength }, generateRandomItem);

  // Randomly pick the number of images to add (between 0 and 9)
  // const imageCount = Math.floor(Math.random() * 10);
  const imageCount = 12;
  const images = {};

  // Add the random images to the images object
  for (let i = 0; i < imageCount; i++) {
    const randomUrl = arrayOfUrls[Math.floor(Math.random() * arrayOfUrls.length)];
    const imageId = generateRandomId();
    images[imageId] = { url: randomUrl };
  }

  return {
    dueDate: generateRandomDueDate(), // Assign a random due date
    id: id,
    items: items,
    images: images,
    requestHistory: generateRandomRequestHistory(id),
    remark: `Remark ${Math.random().toString(36).substring(7)}`,
    userName: `User ${Math.random().toString(36).substring(7)}`
  };
}

const requests = Array.from({ length: 10 }, generateRandomRequest);

export default requests;

