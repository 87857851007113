import React, { useState } from "react";
import * as XLSX from "xlsx";

const ImportViaExcel = ({ items, isOpen, onClose, addSuggestiveTextMaterial }) => {

  const [excelData, setExcelData] = useState(null);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const expectedHeaders = ["Category", "Specification", "Rate", "Unit", "GST"];

  const parseExcelFile = (fileContent) => {
    // Split the content into rows based on newline
    const rows = fileContent
      .split("\n")
      .map((row) => row.trim())
      .filter((row) => row.length > 0); // Remove empty rows

    // Split each row into columns based on comma
    return rows.map((row) => row.split(",").map((cell) => cell.trim()));
  };

  const validateHeaders = (headers) => {

    if (!headers || !Array.isArray(headers) || headers.length === 0) {
      return "No headers found in file";
    }

    if (headers.length !== 5) {
      return `Expected 5 columns but found ${
        headers.length
      } columns. Required columns: ${expectedHeaders.join(", ")}`;
    }

    for (let i = 0; i < expectedHeaders.length; i++) {
      if (headers[i] !== expectedHeaders[i]) {
        return `Invalid header at position ${i + 1}. Expected "${
          expectedHeaders[i]
        }" but found "${headers[i]}"`;
      }
    }

    return null;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file type
    if (
      !file.name.endsWith(".csv") &&
      !file.name.endsWith(".xls") &&
      !file.name.endsWith(".xlsx")
    ) {
      setError("Please upload a valid Excel or CSV file (.xlsx, .xls, .csv)");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const content = e.target.result;

        let workbook;
        if (file.name.endsWith(".csv")) {
          // Parse CSV
          workbook = XLSX.read(content, { type: "string" });
        } else {
          // Parse Excel (binary)
          const binaryContent = new Uint8Array(content);
          workbook = XLSX.read(binaryContent, { type: "array" });
        }

        // Get the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // `header: 1` includes raw rows

        if (!data || data.length === 0) {
          setError("File appears to be empty");
          return;
        }

        // Get headers (first row)
        const headers = data[0];

        // Validate headers
        const headerError = validateHeaders(headers);
        if (headerError) {
          setError(headerError);
          return;
        }

        // Validate each row has 5 columns
        const rowError = data.slice(1).findIndex((row) => row.length !== 5);
        if (rowError !== -1) {
          setError(
            `Row ${rowError + 2} has ${
              data[rowError + 1]?.length || 0
            } columns instead of required 5 columns`
          );
          return;
        }

        setExcelData(data);
        setError(null);
      } catch (err) {
        setError(
          "Error parsing file. Please ensure it's a valid Excel/CSV file with correct format"
        );
        console.error("File parsing error:", err);
      }
    };

    reader.onerror = () => {
      setError("Error reading file. Please try again.");
    };

    if (file.name.endsWith(".csv")) {
      reader.readAsText(file); // Read CSV as text
    } else {
      reader.readAsArrayBuffer(file); // Read Excel as binary
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files?.length > 0) {
      const event = { target: { files: [files[0]] } };
      handleFileUpload(event);
    }
  };

  const handleSave = () => {
    // Add save functionality here

    let unsavedItems = [];
    for(let value of excelData){

      let item = {
        category : value[0],
        specification: value[1],
        rate: value[2],
        unit: value[3],
        gst: value[4]
      }
      if (
        item.category.length <= 0 ||
        item.specification.length <= 0 ||
        item.rate.length <= 0 ||
        item.unit.length <= 0 ||
        item.gst.length <= 0
      ) {
        console.log('this is not going to be the case here...');
      } else {
        if (items.length > 0) {
          // let isSpecEqual = items.map((val) =>
          //   val.specification.localeCompare(
          //     item.specification
          //   )
          // );
          let isSpecEqual = items.map((val) => {
            console.log('val : ', JSON.stringify(val));
            
            // Check if val.specification or val.description is an empty string
            const compareValue = (val.specification && val.specification !== "") ? val.specification : (val.description && val.description !== "") ? val.description : null;
            const itemCompareValue = (item.specification && item.specification !== "") ? item.specification : (item.description && item.description !== "") ? item.description : null;
          
            // Skip if neither value is valid (both are empty)
            if (compareValue === null || itemCompareValue === null) {
              return null; // Skip this iteration by returning null
            }
          
            return compareValue.localeCompare(itemCompareValue);
          }).filter(val => val !== null); // Remove null results from the array
          

          isSpecEqual.every((x) => x != 0)
            ? addSuggestiveTextMaterial(
                item.category,
                item.specification,
                item.rate,
                item.unit,
                item.gst
              )
            : unsavedItems.push(item);
        } else {
          addSuggestiveTextMaterial(
            item.category,
            item.specification,
            item.rate,
            item.unit,
            item.gst
          );
        }
      }
    }

    onClose();
  };

  const handleCancel = () => {
    setExcelData(null);
    setError(null);
    onClose();
  };

  // Modal and style definitions remain the same...
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle = {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "24px",
    width: "90%",
    maxWidth: "800px",
    maxHeight: "90vh",
    overflow: "auto",
    position: "relative",
  };

  const uploadAreaStyle = {
    border: `2px dashed ${isDragging ? "#2563eb" : "#e2e8f0"}`,
    borderRadius: "8px",
    padding: "32px",
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: isDragging ? "#f0f9ff" : "transparent",
    transition: "all 0.2s ease",
  };

  const errorStyle = {
    backgroundColor: "#fef2f2",
    color: "#dc2626",
    padding: "12px 16px",
    borderRadius: "6px",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    fontSize: "14px",
  };

  const floatingButtonsStyle = {
    position: "absolute",
    bottom: "24px",
    right: "24px",
    display: "flex",
    gap: "16px",
  };

  const buttonStyle = {
    padding: "12px 24px",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "8px",
    cursor: "pointer",
    border: "none",
    transition: "background-color 0.3s",
  };

  const saveButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#4caf50",
    color: "#fff",
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#e2e8f0",
    color: "#333",
  };

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <button
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            background: "none",
            border: "none",
            fontSize: "24px",
            cursor: "pointer",
            color: "#64748b",
          }}
          onClick={() => {
            setExcelData(null);
            setError(null);
            setIsDragging(false);
            onClose();
          }}
        >
          ×
        </button>

        <h2
          style={{ fontSize: "20px", fontWeight: "600", marginBottom: "16px" }}
        >
          Import Excel Data <span style={{
            fontSize: 14,
            color: 'red'
          }}>{excelData && `, ${excelData.length} Items entered.`}</span>
        </h2>

        <div
          style={uploadAreaStyle}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragging(false);
          }}
          onDrop={handleDrop}
        >
          {excelData === null ? (
            <>
              <div
                style={{
                  backgroundColor: "#f8fafc",
                  borderRadius: "50%",
                  padding: "16px",
                  marginBottom: "16px",
                  width: "64px",
                  height: "64px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "32px",
                  color: "#64748b",
                }}
              >
                ⬆️
              </div>
              <label
                htmlFor="file-upload"
                style={{
                  color: "#2563eb",
                  cursor: "pointer",
                  marginBottom: "8px",
                }}
              >
                Click to upload or drag and drop
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                accept=".xlsx,.xls,.csv"
                onChange={handleFileUpload}
              />
              <p style={{ color: "#64748b", fontSize: "14px" }}>
                Excel or CSV files only (*.xlsx, *.xls, *.csv)
              </p>
            </>
          ) : (
            <p
              style={{
                padding: "16px",
                backgroundColor: "#4caf50", // Green background for success
                color: "#000", // White text
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
                margin: "20px 0",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                opacity: 0.6
              }}
            >
              Successfully uploaded the material! click on save to continue!
            </p>
          )}

          {error && (
            <div style={errorStyle}>
              <span style={{ fontSize: "16px" }}>⚠️</span>
              <span>{error}</span>
            </div>
          )}
        </div>

        <div
          style={{
            marginTop: "32px",
            border: "1px solid #e2e8f0",
            borderRadius: "8px",
            overflow: "hidden",
            height: "600px", // Set a fixed height for the scrollable content area
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {expectedHeaders.map((header) => (
                  <th
                    key={header}
                    style={{
                      backgroundColor: "#f8fafc",
                      padding: "12px 16px",
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#64748b",
                      textTransform: "uppercase",
                      borderBottom: "1px solid #e2e8f0",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(excelData
                ? excelData.slice(1)
                : Array(3).fill(Array(5).fill("---"))
              ).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      style={{
                        padding: "12px 16px",
                        fontSize: "14px",
                        color: "#64748b",
                        borderBottom: "1px solid #e2e8f0",
                        textAlign: "left",
                      }}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {excelData && (
          <div style={floatingButtonsStyle}>
            <button
              style={saveButtonStyle}
              onClick={handleSave}
            >
              Save
            </button>
            <button
              style={cancelButtonStyle}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportViaExcel;
