import React from "react";

const TooltipIcon = ({
  tooltipText,
  icon: Icon,
  onClick,
  iconStyle = {},
  tooltipStyle = {},
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex", // Use flexbox to center the icon
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically if needed
      }}
    >
      {/* Tooltip */}
      <div
        style={{
          visibility: "hidden",
          backgroundColor: "#555",
          color: "#fff",
          textAlign: "center",
          borderRadius: "4px",
          padding: "5px",
          position: "absolute",
          zIndex: 1,
          top: "50%", // Align tooltip vertically with the icon
          left: "110%", // Position tooltip to the right of the icon
          transform: "translateY(-50%)", // Center tooltip vertically
          opacity: 0,
          transition: "opacity 0.3s",
          whiteSpace: "nowrap",
          ...tooltipStyle,
        }}
        className="tooltip-text"
      >
        {tooltipText}
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "100%", // Position the arrow on the left of the tooltip
            transform: "translateY(-50%)",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "transparent transparent transparent #555", // Arrow pointing right
          }}
        ></div>
      </div>

      {/* Icon */}
      <Icon
        style={{
          fontSize: 24,
          cursor: "pointer",
          ...iconStyle,
        }}
        onMouseEnter={(e) => {
          const tooltip = e.currentTarget.parentElement.querySelector(
            ".tooltip-text"
          );
          tooltip.style.visibility = "visible";
          tooltip.style.opacity = 1;
        }}
        onMouseLeave={(e) => {
          const tooltip = e.currentTarget.parentElement.querySelector(
            ".tooltip-text"
          );
          tooltip.style.visibility = "hidden";
          tooltip.style.opacity = 0;
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default TooltipIcon;
