import React, { useState, useEffect, useRef } from "react";
import { IoSendSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const ChatComponent = ({ messages, setMessages, setShowChat }) => {
  const [inputText, setInputText] = useState("");
  const [isThinking, setIsThinking] = useState(false);
  const [currentTypingMessage, setCurrentTypingMessage] = useState(null);
  const [displayedText, setDisplayedText] = useState("");
  const chatContainerRef = useRef(null);
  
  // Static dummy data for questions and answers
  const dummyData = [
    {
      question: "What is the status of my project?",
      answer: "Your project is currently 65% complete. The team is on track to deliver by the end of the month."
    },
    {
      question: "How many tasks are pending?",
      answer: "You have 12 pending tasks in your current sprint. 5 of them are high priority."
    },
    {
      question: "What's my next deadline?",
      answer: "Your next deadline is the client presentation on Friday at 2 PM. All materials should be ready by Thursday evening."
    },
    {
      question: "Can you summarize yesterday's meeting?",
      answer: "Yesterday's meeting covered the new feature requests, budget allocation, and timeline adjustments. Action items were assigned to team members and the next check-in is scheduled for Monday."
    },
    {
      question: "Who's working on the frontend?",
      answer: "Sarah and Michael are currently assigned to the frontend tasks. They've completed 8 out of 14 tickets so far."
    }
  ];
  
  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, displayedText]);
  
  // Send a welcome message when component mounts
  useEffect(() => {
    if (messages.length === 0) {
      setTimeout(() => {
        const welcomeMessage = { 
          text: "Hi there! I'm your project assistant. How can I help you today?", 
          sender: "ai" 
        };
        setCurrentTypingMessage(welcomeMessage);
      }, 1000);
    }
  }, []);
  
  // Handle typing effect
  useEffect(() => {
    if (!currentTypingMessage) return;
    
    let fullText = currentTypingMessage.text;
    let charIndex = 0;
    setDisplayedText("");
    
    const typingInterval = setInterval(() => {
      if (charIndex < fullText.length) {
        setDisplayedText(prev => prev + fullText.charAt(charIndex));
        charIndex++;
      } else {
        clearInterval(typingInterval);
        setMessages(prev => [...prev, currentTypingMessage]);
        setCurrentTypingMessage(null);
        setDisplayedText("");
      }
    }, 30); // Adjust typing speed here
    
    return () => clearInterval(typingInterval);
  }, [currentTypingMessage]);

  const handleSendMessage = () => {
    if (inputText.trim() === "" || currentTypingMessage) return;

    const newMessages = [...messages, { text: inputText, sender: "user" }];
    setMessages(newMessages);
    setInputText("");
    setIsThinking(true);

    // Simulate AI thinking before responding
    setTimeout(() => {
      setIsThinking(false);
      
      // Find a relevant answer or use a default response
      let aiResponse = "I don't have enough information about that. Can you provide more details?";
      
      // Check for keywords in the input to match with dummy data
      const userInput = inputText.toLowerCase();
      const matchedItem = dummyData.find(item => 
        userInput.includes(item.question.toLowerCase()) || 
        userInput.includes(item.question.toLowerCase().split(" ")[1]) ||
        userInput.includes(item.question.toLowerCase().split(" ")[2])
      );
      
      if (matchedItem) {
        aiResponse = matchedItem.answer;
      } else if (userInput.includes("hello") || userInput.includes("hi")) {
        aiResponse = "Hello! How can I assist you with your project today?";
      } else if (userInput.includes("thank")) {
        aiResponse = "You're welcome! Is there anything else you'd like to know?";
      } else if (userInput.includes("bye") || userInput.includes("goodbye")) {
        aiResponse = "Goodbye! Feel free to chat again when you need assistance.";
      } else {
        // Suggest a question from the dummy data
        const randomQuestion = dummyData[Math.floor(Math.random() * dummyData.length)].question;
        aiResponse = `I'm not sure about that. Perhaps you could ask me something like: "${randomQuestion}"`;
      }
      
      // Start typing effect with the response
      setCurrentTypingMessage({ text: aiResponse, sender: "ai" });
    }, 1500);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", margin: 10 }}>
        <p style={{ fontSize: 24, fontWeight: 600 }}>AI Chat</p>
        <IoMdClose
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowChat(false);
            setMessages([]);
          }}
        />
      </div>
      <div 
        ref={chatContainerRef}
        style={{ 
          flex: 1, 
          margin: 10, 
          overflowY: "auto", 
          padding: 10, 
          backgroundColor: "#fff", 
          borderRadius: 10, 
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", 
          height: "400px", 
          display: "flex", 
          flexDirection: "column", 
          gap: 10 
        }}
      >
        {messages.length === 0 && !currentTypingMessage ? (
          <p style={{ textAlign: "center", color: "#888", marginTop: 20 }}>
            Ask me anything about your projects data!
          </p>
        ) : (
          <>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  alignSelf: msg.sender === "user" ? "flex-end" : "flex-start",
                  backgroundColor: msg.sender === "user" ? "#DCF8C6" : "#F0F0F0",
                  padding: "10px 15px",
                  borderRadius: 15,
                  maxWidth: "80%",
                  marginBottom: 8
                }}
              >
                {msg.text}
              </div>
            ))}
            
            {/* Typing indicator */}
            {currentTypingMessage && (
              <div
                style={{
                  alignSelf: "flex-start",
                  backgroundColor: "#F0F0F0",
                  padding: "10px 15px",
                  borderRadius: 15,
                  maxWidth: "80%",
                  marginBottom: 8
                }}
              >
                {displayedText}
                <span className="typing-cursor" style={{ opacity: 1, animation: "blink 1s infinite" }}>|</span>
              </div>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center", margin: 10, backgroundColor: "#fff", borderRadius: 20, padding: "10px 15px", boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)" }}>
        <input
          type="text"
          placeholder="Type your message..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ flex: 1, border: "none", outline: "none", fontSize: 16, backgroundColor: "transparent" }}
          disabled={isThinking || currentTypingMessage}
        />
        <IoSendSharp
          size={25}
          style={{ 
            cursor: (isThinking || currentTypingMessage) ? "not-allowed" : "pointer", 
            color: (isThinking || currentTypingMessage) ? "#ccc" : "#555" 
          }}
          onClick={(isThinking || currentTypingMessage) ? null : handleSendMessage}
        />
      </div>
      <style jsx global>{`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
      `}</style>
    </>
  );
};

export default ChatComponent;