const getUniqueCategories = (requests) => {
    // Use a Set to store unique categories
    const categories = new Set();
  
    // Iterate through each request and its items
    requests.forEach(request => {
      request.items.forEach(item => {
        categories.add(item.category);
      });
    });
    return Array.from(categories);
};

export default getUniqueCategories;
  