import React, { useState } from "react";
import { LiaBrainSolid } from "react-icons/lia";
import { TbPencilPlus } from "react-icons/tb";
import { BiSolidCircleQuarter } from "react-icons/bi";
import { IoSendSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import DateRangeSelector from "../../helperComponents/DateRangeSelector";
import ExportButton from "./ExportButton";
import ChatComponent from "./ChatComponent";

const AnalyticsBrowser = ({
  width,
  setSelectedGraph,
  selectedGraph,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  exportJsonToExcel,
  progressNumber,
  numLoading,
}) => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isThinking, setIsThinking] = useState(false);

  const handleSendMessage = () => {
    if (inputText.trim() === "") return;

    // Add user message
    const newMessages = [...messages, { text: inputText, sender: "user" }];
    setMessages(newMessages);
    setInputText("");

    // Simulate AI thinking
    setIsThinking(true);

    // Simulate AI response after 2 seconds
    setTimeout(() => {
      setMessages([
        ...newMessages,
        { text: "Please wait while I am thinking...", sender: "ai" },
      ]);
      setIsThinking(false);
    }, 2000);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const GraphOption = ({ title, isSelected, onClick }) => (
    <div
      className="graph-option"
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 20px",
        marginBottom: 10,
        backgroundColor: isSelected ? "#f0f4ff" : "#ffffff",
        borderRadius: 12,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
        cursor: "pointer",
        transition: "all 0.2s ease",
        border: isSelected ? "1px solid #3b82f6" : "1px solid transparent",
      }}
    >
      <p
        style={{
          fontFamily: "Montserrat, sans-serif",
          fontSize: 16,
          fontWeight: isSelected ? 600 : 500,
          color: isSelected ? "#3b82f6" : "#4b5563",
          margin: 0,
        }}
      >
        {title}
      </p>
      <BiSolidCircleQuarter
        style={{ 
          transform: "rotate(-135deg)",
          color: isSelected ? "#3b82f6" : "#9ca3af"
        }}
        size={22}
      />
    </div>
  );

  return (
    <div
      style={{
        width: width,
        maxWidth: width,
        minWidth: width,
        backgroundColor: "#f9fafb",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.03)",
        borderLeft: "1px solid #e5e7eb",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "24px 24px 16px",
          borderBottom: "1px solid #e5e7eb",
        }}
      >
        <p
          style={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: 24,
            fontWeight: 700,
            color: "#111827",
            margin: 0,
          }}
        >
          Analytics
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            backgroundColor: "#eff6ff",
            padding: "8px 12px",
            borderRadius: 20,
          }}
        >
          <LiaBrainSolid size={20} color="#3b82f6" />
          <p style={{ margin: 0, fontSize: 14, fontWeight: 500, color: "#3b82f6" }}>AI Bot</p>
        </div>
      </div>

      <div
        style={{
          padding: "16px 24px",
          backgroundColor: "#ffffff",
          margin: "16px 24px",
          borderRadius: 12,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
        }}
      >
        <DateRangeSelector
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>

      <div style={{ padding: "0 24px", marginBottom: 16 }}>
        <ExportButton exportJsonToExcel={exportJsonToExcel} progressNumber={progressNumber} loading={numLoading} />
      </div>

      {!showChat ? (
        <div style={{ padding: "0 24px", flex: 1, overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#3b82f6",
              padding: "16px 20px",
              borderRadius: 12,
              color: "white",
              cursor: "pointer",
              marginBottom: 20,
              boxShadow: "0 4px 6px rgba(59, 130, 246, 0.25)",
              transition: "transform 0.2s ease",
            }}
            onClick={() => setShowChat(true)}
            onMouseOver={(e) => e.currentTarget.style.transform = "translateY(-2px)"}
            onMouseOut={(e) => e.currentTarget.style.transform = "translateY(0)"}
          >
            <p
              style={{ 
                fontFamily: "Montserrat, sans-serif", 
                fontSize: 18, 
                fontWeight: 600,
                margin: 0,
              }}
            >
              Create New Graph
            </p>
            <TbPencilPlus size={22} />
          </div>

          <h3 style={{ 
            fontFamily: "Montserrat, sans-serif", 
            fontSize: 16, 
            fontWeight: 600, 
            color: "#6b7280",
            marginBottom: 12,
            paddingLeft: 10,
          }}>
            VISUALIZATIONS
          </h3>

          <GraphOption 
            title="Amount vs Date Graph" 
            isSelected={selectedGraph === "poGraph"}
            onClick={() => setSelectedGraph("poGraph")}
          />
          
          <GraphOption 
            title="No. of PO vs Date Graph" 
            isSelected={selectedGraph === "nopovsdate"}
            onClick={() => setSelectedGraph("nopovsdate")}
          />
          
          <GraphOption 
            title="Paid/ Unpaid vs Date Graph" 
            isSelected={selectedGraph === "paidvsunpaid"}
            onClick={() => setSelectedGraph("paidvsunpaid")}
          />
          
          <GraphOption 
            title="NMR Update Status" 
            isSelected={selectedGraph === "nmrGraph"}
            onClick={() => setSelectedGraph("nmrGraph")}
          />
        </div>
      ) : (
        <ChatComponent messages={messages} setMessages={setMessages} setShowChat={setShowChat} />
      )}
    </div>
  );
};

export default AnalyticsBrowser;