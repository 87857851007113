import { useState, useContext } from "react";
import config from "../../config/config";
import { PopOverView } from "../../components";
import {
  FiChevronDown,
  FiChevronUp,
  FiInfo,
  FiMoreVertical,
  FiTruck,
} from "react-icons/fi";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import {
  AiFillCheckCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useRazorpay from "react-razorpay";
import PaymentOption from "./paymentOption";
import TransactionDetails from "./transactionDetails";
import ViewDetails from "./viewDetails";
import { environment } from "../../config/config";
import toast from "react-hot-toast";
import { useAnalyticsContext } from "../../Context/Analytics";
import "./OrderItem.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { VendorStatement } from "./VendorStatement";
import RequestedPayments from "./RequestedPayments";
import firebase from "../../config/firebase";
import OpportunityService from "../../api/opportunityService";
import NotificationService from "../../api/notificationService";
import { UserContext } from "../../Context/UserContext";

const OrderItemOngoing = ({
  releasedOrderIndex,
  item,
  fetchReleasedOrdersOngoing,
  isCompletedOrder,
  allOrders,
  setReleasedOrders,
  ProjectId,
  ClientName,
  client
}) => {

  const [commonMilestonestoDisplay, setCommonMilestonestoDisplay] = useState(
    JSON.parse(item.CommonMilestones)
  );
  const [orderTotalAmounttoDisplay, setOrderTotalAmounttoDisplay] = useState(
    item.OrderTotalAmount
  );
  const Razorpay = useRazorpay();
  const [transactionDetailsModal, setTransactionDetailsModal] = useState(false);
  const [transactionDetailsData, setTransactionDetailData] = useState({});

  const fetchReleasedOrders = async () => {
    setSpinner(true);

    fetch(`${config.orderService}getServiceOrdersById/${item.OrderId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from getServiceOrders API: ", json);
      })
      .catch((e) => {
        console.log("getServiceOrders catch: ", e.toString());
        toast.error("Error fetching released orders data!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
    setSpinner(false);
  };

  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewDetailsItem, setViewDetaisItem] = useState({});
  const [openBankDetailsModel, setOpenBankDetailsModel] = useState(false);
  const [bankAccountDetails, setBankAccountDetails] = useState({});
  const [vendorFormOpen, setVendorFormOpen] = useState(false);
  const { record } = useAnalyticsContext();
  const [milestoneIndex, setMilestoneIndex] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [paymentOption, setPaymentOption] = useState({
    isModalOpen: false,
    isRequest: true,
  });

  const [selectedOrderId, setSelectedOrderId] = useState();
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState();
  const [selectedOrderMilestoneData, setSelectedOrderMilestoneData] = useState(
    []
  );

  const [isSpinner, setSpinner] = useState(false);
  const [vendorStatementModalisOpen, setVendorStatementModal] = useState(false);
  const [vendorDetailsForStatement, setVendorDetailsForStatement] = useState(
    {}
  );

  const [paymentRequestedModal, setPaymentsRequestedModal] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [dateofPayment, setDateofPayment] = useState(new Date().getTime());
  const [remarks, setRemarks] = useState("");
  const userContext = useContext(UserContext);

  const styles = {
    outerCircle: {
      height: 25,
      width: 25,
      borderRadius: 50,
      border: "1px solid #FFA000",
      backgroundColor: "#FFF8E1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    innerCircle: {
      height: 15,
      width: 15,
      borderRadius: 50,
      backgroundColor: "#FFA000",
    },
    leftRightDashedUnit: {
      height: 4,
      width: 100,
      border: "2px dashed #FFECB3",
    },
    leftRightUnit: {
      height: 4,
      width: 130,
      backgroundColor: "#FFECB3",
    },
    centerUnit: {
      height: 25,
      width: 10,
      backgroundColor: "#FFECB3",
      cursor: "pointer",
    },
    leftRightUnitSelected: {
      height: 7.5,
      width: 30,
      backgroundColor: "#FFECB3",
    },
    selectedMilestone: {
      height: 30,
      width: 30,
      borderRadius: 50,
      border: "1px solid #FFA000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const updateTheDataToTheFirebase = async (requestId, pay, utrNumber) => {
    let amount = pay / 100;
    console.log('updateTheDataToTheFirebase requestId ', requestId)
    console.log('updateTheDataToTheFirebase pay ', pay/100)
    console.log('updateTheDataToTheFirebase utrNumber ', utrNumber)
    console.log('updateTheDataToTheFirebase selectedOrderId ', selectedOrderId)
    console.log('updateTheDataToTheFirebase objecttobewritten ', {
      paymentMilestoneName:
        selectedOrderMilestoneData[selectedMilestoneIndex],
      requestedAmount: 0,
      totalPaidAmount: amount,
      utrDetails: utrNumber,
      status: "Bill Paid",
    })


    firebase
      .database()
      .ref("poRequests/" + selectedOrderId + "/" + requestId)
      .set({
        paymentMilestoneName:
          selectedOrderMilestoneData[selectedMilestoneIndex],
        requestedAmount: 0,
        totalPaidAmount: amount,
        utrDetails: utrNumber,
        status: "Bill Paid",
      });


    var useRef = firebase
      .database()
      .ref(`poRequests/${selectedOrderId}/balances`);

    useRef
      .once("value", function (snapshot) {

        var data = snapshot.val();

        if (data != null) {
          if (!data.hasOwnProperty("paidAmount")) {
          } else {
            amount = Number(data.paidAmount) + Number(amount);
          }
        }

      })
      .then(async (data) => {
        await firebase
          .database()
          .ref("poRequests/" + selectedOrderId + "/balances/paidAmount/")
          .set(amount);


        if (data === null) {
          await firebase
            .database()
            .ref("poRequests/" + selectedOrderId + "/balances/requestedAmount/")
            .set("0");
        }

      });
    toast.success("Updated payment successfully");
  };

  const generateNewRequest = async (orderAmountPaid, utrNumber, requestId) => {

    if (orderAmountPaid === undefined || orderAmountPaid <= 0) {
      return window.alert('Please enter an amount greater than "0"');
    }

    setIsProgress(true);

    let dataObject = {};
    
    dataObject["OrderId"] = selectedOrderId;
    dataObject["RequestId"] = requestId;
    dataObject["vendorCompanyName"] = vendorCompanyName;
    dataObject["Status"] = "Record";

    const remarksValue = remarks === null || remarks === undefined || remarks.trim() === '' ? 'remark' : remarks;
    console.log("remark value", remarksValue)
    setRemarks(remarksValue);

    dataObject["History"] = {
      [requestId]: {
        accountType: userContext.user.accountType,
        raised: userContext.user.email,
        status: "Record",
        date: dateofPayment,
        remarks: remarksValue,
        By: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ),
      },
    };


    dataObject["AmountPaid"] = orderAmountPaid;
    dataObject["AmountRequested"] = parseInt(orderAmountPaid / 100);
    dataObject["ProjectDetails"] = { [ProjectId]: ClientName };
    dataObject["Firm"] = userContext.user.Firm;
    dataObject["details"] = {
      milestoneIndexRequested: selectedMilestoneIndex,
      PaidOn: new Date().getTime(),
    };
    dataObject["DOFReq"] = new Date();

    const opportunityService = new OpportunityService();

    const responseUpdate = await opportunityService.addFinanceRequest(
      dataObject
    );

    if (responseUpdate.status === 200) {

      const notificationService = new NotificationService();

      updateTheDataToTheFirebase(requestId, orderAmountPaid, utrNumber);
      notificationService.financeNotifications(
        userContext.user.Firm,
        {
          message: `Amount Paid for payment of Rs.${orderAmountPaid} in ${ClientName}`,
          Status: "NotRead",
          sectionSource: "Record",
          user: userContext.user.email,
          accountType: "Admin",
          requestId: responseUpdate.results.insertId,
          time: new Date().getTime(),
        },
        "Admin"
      );
      toast.success("Amount Paid");
    } else {
      toast.error("Error while generating a Request");
    }

    setIsProgress(false);

    setPaymentOption((prev) => {
      return { ...prev, isModalOpen: false };
    });
  };

  const updateMilestonePayment = async (
    orderMilestoneData,
    orderId,
    milestoneIndex,
    paymentID,
    orderAmount,
    dateofPayment,
    remarks,
    modeOfPayment,
    multiple,
    utrNumber
  ) => {

    let requestId = new Date().getTime();

    const tempdataObjecet = orderMilestoneData;
    tempdataObjecet.map((data, index) => {
      if (index === milestoneIndex) {
        if (data.payments) {
          data.payments.push({
            requestId: requestId,
            paymentID: paymentID,
            date: dateofPayment,
            remarks: remarks,
            amount: orderAmount / 100,
            status: "Done",
            mode: modeOfPayment,
          });
        } else {
          // console.log('payments not found')
          data.payments = [
            {
              requestId: requestId,
              paymentID: paymentID,
              date: dateofPayment,
              remarks: remarks,
              amount: orderAmount / 100,
              status: "Done",
              mode: modeOfPayment,
            },
          ];
        }
      } else {
        console.log("Error");
      }
    });

    await fetch(`${config.orderService}serviceOrder/${orderId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CommonMilestones: tempdataObjecet,
      }),
    })
      .then((response) => {
        return response.json();
      })

      .then((json) => {
        if (json.status === 200) {
          setPaymentOption((prev) => {
            return { ...prev, isModalOpen: false };
          });
          generateNewRequest(orderAmount, utrNumber, requestId);
          sendPaymentMessage(orderAmount / 100, utrNumber, item)
          toast.success(`${orderAmount / 100} paid successfully!`, {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1F9A5E",
              backgroundColor: "#F2FFF9",
              maxWidth: "100%",
            },
            icon: <AiFillCheckCircle color={"#1F9A5E"} />,
          });

          if (multiple) {
            // write the code here
          } else {
            fetchReleasedOrdersOngoing();
          }
        }
      })
      .catch((e) => {
        console.log("updateMilestonePayment error: ", e.toString());
      });
  };

  const payment = (
    orderMilestoneData,
    orderId,
    milestoneIndex,
    razorpayOrderId,
    orderAmount,
    dateofPayment,
    remarks,
    onlineMode,
    modeOfPayment,
    utrNumber
  ) => {
  

    if (onlineMode) {
      if (orderAmount > 0) {
        const options = {
          key:
            environment == "staging"
              ? "rzp_test_NT8hdIJJv8h4io"
              : "rzp_live_5zu6ALnDKySSQb", // Enter the Key ID generated from the Dashboard
          // key: "rzp_test_NT8hdIJJv8h4io", // Enter the Key ID generated from the Dashboard
          Amount: orderAmount,
          currency: "INR",
          name: "Staart Buildtech",
          description: "Vendor Payment",
          image:
            "https://static.wixstatic.com/media/1797c0_bc28110ef07142e7807421547ca506de~mv2.png/v1/fill/w_108,h_60,al_c,q_85,usm_0.66_1.00_0.01/Staart-Logo_edited.webp",
          order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().

          // paymentID,
          // orderAmount,
          // dateofPayment,
          // remarksValue,
          // modeOfPayment,
          // multiple,
          // utrNumber
          handler: function (response) {
            updateMilestonePayment(
              orderMilestoneData,
              orderId,
              milestoneIndex,
              response.razorpay_payment_id,
              orderAmount,
              dateofPayment,
              remarks,
              "online",
              false,
              utrNumber
            );
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzp1.open();
      } else {
        alert("Please enter amount");
      }

      // console.log('online mode use razorpay')
    } else {

      updateMilestonePayment(
        orderMilestoneData,
        orderId,
        milestoneIndex,
        `${modeOfPayment}_mode_of_payment`,
        orderAmount,
        dateofPayment,
        remarks,
        modeOfPayment,
        false,
        utrNumber
      );
    }
  };

  const sendPaymentMessage = async (amountToUpdate, utrNumber, request) => {
    console.log("thsi is value of item", item)

    console.log('sendPaymentMessage is addressed...');

    let milestoneName = selectedOrderMilestoneData[selectedMilestoneIndex].name

    try {
      const response = await (
        await fetch(`${config.whatsAppService.URL}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: request.VendorMobile,
            type: "template",
            template: {
              name: environment === "staging" ? "vendor_payment_done_stg" : "vendor_payment_done",
              language: {
                code: "en",
              },
              components: [
                {
                  type: "header",
                  parameters: [
                    {
                      type: "text",
                      text: JSON.parse(localStorage.getItem("Firm")) // name of the firm
                    }
                  ]
                },
                {
                  type: "button",
                  sub_type: "url",
                  index: 0,
                  parameters: [
                    {
                      type: "text",
                      text: request.OrderId, // po_id
                    }
                  ]
                },
                {
                  type: "body",
                  parameters: [
                    {
                      type: "text",
                      text: request.VendorName ? request.VendorName : 'Dear Vendor' // name of the vendor
                    },
                    {
                      type: "text",
                      text: amountToUpdate.toLocaleString('en-IN') // paid amount
                    },
                    {
                      type: "text",
                      text: request.OrderId ? request.OrderId : 'Your order Id' // PO order ID
                    },
                    {
                      type: "text",
                      text: milestoneName // milestone name
                    },
                    {
                      type: "text",
                      text: userContext.user.email.slice(
                        0,
                        userContext.user.email.indexOf("@")
                      ) // name of the payee
                    },
                    {
                      type: "text",
                      text: request.OrderTotalAmount ? request.OrderTotalAmount.toLocaleString('en-IN') : ""     // total po amount
                    },
                    {
                      type: "text",
                      text: utrNumber.length > 0 ? utrNumber : "Not Provided"     // utr details of the transaction
                    }
                  ]
                }

              ]
            },
          }),
        })
      ).json();
      console.log("Response form sendWhatsapp API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  };

  const [razorpayOrder, setRazorpayOrder] = useState({});
  const [showStatus, setShowStatus] = useState(false);

  const fetchRazorpayOrder = (
    orderMilestoneData,
    orderId,
    index,
    milestoneIndex,
    orderAmount,
    dateofPayment,
    remarks,
    utrNumber,
    onlineMode,
    modeOfPayment,
  ) => {

    remarks = remarks === undefined ? 'NA' : remarks
    
    fetch(

      `${config.transactionService}` + "createRazorpayOrder/" + orderAmount,
      {
        method: "GET",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
          Accept: "/",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setRazorpayOrder(json.payload);
        if (json.status == 200) {

          payment(
            orderMilestoneData,
            orderId,
            milestoneIndex,
            razorpayOrder.id,
            orderAmount,
            dateofPayment,
            remarks,
            onlineMode,
            modeOfPayment,
            utrNumber
          );

        } else {

          payment(
            orderMilestoneData,
            orderId,
            milestoneIndex,
            "not available",
            orderAmount,
            dateofPayment,
            remarks,
            onlineMode,
            modeOfPayment,
            utrNumber
          );

        }
      })
      .catch((e) => {
        console.log("fetchRazorpayOrder catch: ", e.toString());
        toast.error("Error fetching razorpay order data!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      });
  
  };

  const payClickHanlder = (milestone, milestoneIndex, vendorCompany) => {
    if (milestone.status && item.Status == "Order accepted") {
      if (milestone.status == "confirmed") {
        setPaymentData(milestone);
        setMilestoneIndex(milestoneIndex);
        setSelectedOrderId(item.OrderId);
        setSelectedMilestoneIndex(milestoneIndex);
        setSelectedOrderMilestoneData(commonMilestonestoDisplay);
        setVendorCompanyName(vendorCompany);
        // setPaymentOption(true);
        setPaymentOption((prev) => {
          return { isRequest: false, isModalOpen: true };
        });
      } else {

      }
    } else if (item.Status == "Order Placed") {
      toast.error("Vendor has not accepted yet", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0 ",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    } else {
      if (
        window.confirm(
          "Site Engineer has not confirmed yet! Do you want to continue"
        )
      ) {
        setPaymentData(milestone);
        setMilestoneIndex(milestoneIndex);
        setSelectedOrderId(item.OrderId);
        setSelectedMilestoneIndex(milestoneIndex);
        setSelectedOrderMilestoneData(commonMilestonestoDisplay);
        setVendorCompanyName(vendorCompany);
        setPaymentOption((prev) => {
          return { isRequest: false, isModalOpen: true };
        });
      } else {
        toast.error("Site Engineer has not confirmed yet!", {
          duration: 4000,
          position: "bottom-center",
          style: {
            borderRadius: "8px",
            padding: "16px",
            color: "#E72424",
            backgroundColor: "#FEF0F0 ",
            maxWidth: "100%",
          },
          icon: <FiInfo color={"#E72424"} />,
        });
      }
    }
  };

  const [vendorCompanyName, setVendorCompanyName] = useState(null);

  const requestClickHandler = (milestone, milestoneIndex, vendorCompany) => {
    if (item.Status == "Order Placed") {
      toast.error("Vendor has not accepted yet", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "8px",
          padding: "16px",
          color: "#E72424",
          backgroundColor: "#FEF0F0 ",
          maxWidth: "100%",
        },
        icon: <FiInfo color={"#E72424"} />,
      });
    } else {
      setVendorCompanyName(vendorCompany);
      setPaymentData(milestone);
      setMilestoneIndex(milestoneIndex);
      setSelectedOrderId(item.OrderId);
      setSelectedMilestoneIndex(milestoneIndex);
      setSelectedOrderMilestoneData(commonMilestonestoDisplay);
      setPaymentOption((prev) => {
        return { isRequest: true, isModalOpen: true };
      });
    }
  };

  const FetchMilestonesforOldOrder = async (item) => {
    setSpinner(true);
    let temptotalOrderAmount = 0;
    const tempCommonMilestones = [];

    JSON.parse(item.Data).data.map((dataItem, dataIndex) => {
      // console.log('dataItem,',dataItem)
      (temptotalOrderAmount += Number(
        (
          dataItem?.quantity * dataItem?.rate +
          dataItem?.quantity * dataItem?.rate * (dataItem?.gst / 100) || 0
        ).toFixed(2)
      )).toFixed(2);
    });
    // console.log('temptotalOrderAmount after updting',temptotalOrderAmount)

    JSON.parse(item.Data).data[0].milestones.map((mItem, mIndex) => {
      tempCommonMilestones.push({
        ...mItem,
        totalAmount: ((temptotalOrderAmount * mItem.percentage) / 100).toFixed(
          2
        ),
      });
    });
    //  console.log('tempCommonMilestones after updating',tempCommonMilestones)
    setCommonMilestonestoDisplay(tempCommonMilestones);

    // console.log('item : ',item)
    // const opportunityService = new OpportunityService();
    // await opportunityService.updateOpportunityById(item.OrderId,{OrderTotalAmount : temptotalOrderAmount,CommonMilestones : tempCommonMilestones}).then((res)=>{console.log('responscdfdf',res)})
    // await opportunityService.updateOpportunityById(item.OrderId,{ "OrderTotalAmount" : temptotalOrderAmount})
    await fetch(`${config.orderService}serviceOrder/${item.OrderId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CommonMilestones: tempCommonMilestones,
        // OrderTotalAmount : temptotalOrderAmount
      }),
    })
      .then((res) => {
        console.log("updating old orders", res);
      })
      .catch((e) => {
        console.log(e);
      });
    // here calling the same api two times to update two diff values, need to fix the api to update multiple values/check it

    await fetch(`${config.orderService}serviceOrder/${item.OrderId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // CommonMilestones: tempCommonMilestones,
        OrderTotalAmount: `${temptotalOrderAmount}`,
      }),
    })
      .then((res) => {
        console.log("updated the amount", res);
      })
      .catch((e) => {
        console.log("error", e);
      });
    setOrderTotalAmounttoDisplay(temptotalOrderAmount);
    fetchReleasedOrders();
    fetchReleasedOrdersOngoing();

    setSpinner(false);
  };

  return (
    <div
      key={item.OrderId}
      style={{
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FDD34D",
        borderRadius: "4px",
        marginBottom: "20px",
      }}
    >
      <div className="order-heading-container">
        
        <div className="order-details">

          <div className="order-details-vendor">
            <div className="order-details-icon">
              {JSON.parse(item.Data)?.data[0]?.workType == "Only Work" ? (
                <GrUserWorker size={15} />
              ) : JSON.parse(item.Data)?.data[0]?.workType ==
                "Work + Material" ? (
                <>
                  <GrUserWorker size={10} />
                  <FiTruck size={10} />
                </>
              ) : (
                <FiTruck size={15} />
              )}
            </div>
            <p style={{ fontWeight: "500", width: "auto" }}>
              {item?.VendorName == null ||
                (item.VendorName == "null" &&
                  item?.VendorMobileFromId == null &&
                  item?.VendorMobile == null) ||
                (item.VendorMobile == "null" && item?.VendorNameFromId == null)
                ? "Not accepted by any vendor yet!"
                : `${(item?.VendorName !== "null" && item?.VendorName) ||
                item?.VendorNameFromId
                } (${item?.VendorMobile || item?.VendorMobileFromId})`}
            </p>
          </div>

          <div className="order-heading-dates">
            <p>
              Start Date:{" "}
              {JSON.parse(item.Data)
                ?.StartDate.substring(0, 10)
                .split("-")
                .reverse()
                .join(" / ")}
            </p>
            <p>
              End Date:{" "}
              {JSON.parse(item.Data)
                ?.EndDate.substring(0, 10)
                .split("-")
                .reverse()
                .join(" / ")}
            </p>
            <p>
              Released Date:{" "}
              {JSON.parse(item.Data)?.releasedDate
                ? JSON.parse(item.Data)
                  ?.releasedDate?.substring(0, 10)
                  .split("-")
                  .reverse()
                  .join(" / ")
                : "-"}
            </p>
          </div>

        </div>

        <div className="order-heading-amount">
          <p style={{ paddingTop: 5, fontWeight: "bold" }}>Amount</p>
          <p>
            {Number(
              orderTotalAmounttoDisplay && orderTotalAmounttoDisplay
            ).toFixed(2)}
          </p>
        </div>

        <div className="order-heading-util">
          <div style={{ width: "2%", paddingTop: 5, cursor: "pointer" }}>
            <PopOverView
              position="left"
              component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    fontSize: 12,
                    color: "grey",
                    alignItems: "flex-start",
                    width: "140",
                  }}
                >
                  <p
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      // const win = window.open(
                      //   `/PO/${item?.Id}`,
                      //   "_blank"
                      // );
                      // win.focus();

                      // console.log("Environment:", environment);

                      const url =
                        environment === "staging"
                          ? "https://staartstaging.netlify.app/?po_id=" +
                          item?.Id
                          : "https://staartbuild.netlify.app/?po_id=" +
                          item?.Id;

                      console.log("url is reading", url);
                      const win = window.open(url, "_blank");
                      win.focus();
                    }}
                    className="menuItem"
                  >
                    View Order
                  </p>
                  {/* {item.VendorMobile && (
                              <p
                                className="menuItem"
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  // setOverlaySpinnerOpen(true)
                                  getBankAccountDetails(item.VendorMobile);
                                  setSelectedVendorDetails(item);
                                  record("@bankDetails_button_click", {
                                    description: "bankdetails button click",
                                  });
                                }}
                              >
                                Bank Details
                              </p>
                            )} */}
                  <p
                    className="menuItem"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setTransactionDetailsModal(true);
                      setTransactionDetailData(item);
                      setSelectedOrderMilestoneData(commonMilestonestoDisplay);
                      record("@transaction_button_click", {
                        description: "transaction button click",
                      });
                    }}
                  >
                    Transaction details
                  </p>
                  <p
                    className="menuItem"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setVendorStatementModal(true);
                      setVendorDetailsForStatement({
                        VendorMobile: item.VendorMobile,
                        VendorName: item.VendorName,
                        VendorCompany: JSON.parse(item.VendorId)
                          ?.vendorCompanyName,
                      });
                      record("@vendor_Statement_button_click", {
                        description: "vendor_Statement button click",
                      });
                    }}
                  >
                    Vendor Statement
                  </p>
                  <p
                    className="menuItem"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setPaymentsRequestedModal(true);
                      // setVendorStatementModal(true);
                      // setVendorDetailsForStatement({
                      //   VendorMobile: item.VendorMobile,
                      //   VendorName: item.VendorName,
                      //   VendorCompany: JSON.parse(item.VendorId)
                      //     ?.vendorCompanyName,
                      // });
                      record("@Payments_Requested_button_click", {
                        description: "payments_requestes button click",
                      });
                    }}
                  >
                    Payments Requested
                  </p>
                </div>
              }
            >
              <FiMoreVertical />
            </PopOverView>
          </div>
          <div>
            {showStatus ? (
              <FiChevronUp
                onClick={() => {
                  setShowStatus((status) => !status);
                }}
              />
            ) : (
              <FiChevronDown
                onClick={() => {
                  setShowStatus((status) => !status);
                }}
              />
            )}
          </div>
        </div>

      </div>

      {showStatus && JSON.parse(item?.Data) && (
        <>
          <div
            className="order-milestones-items-heading"
            style={{ display: "flex" }}
          >
            <p style={{ width: "47%", paddingTop: 5, paddingLeft: "5em" }}>
              Item
            </p>
            <p style={{ width: "10%", paddingTop: 5 }}>Quantity</p>
            <p style={{ width: "10%", paddingTop: 5 }}>Unit</p>
            <p style={{ width: "10%", paddingTop: 5 }}>Rate</p>
            <p style={{ width: "10%", paddingTop: 5 }}>GST</p>
            <p style={{ width: "10%", paddingTop: 5 }}>Amount</p>
          </div>
          {JSON.parse(item?.Data)?.data.map((dataItem, dataIndex) => (
            <div
              key={dataIndex}
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ width: "48%" }}>
                  <p style={{ fontWeight: "600" }}>{dataItem["Unit Name"]}</p>
                  <br />
                  {"type" in dataItem
                    ? dataItem?.type
                    : dataItem?.vendorCategory}
                  {" | "}
                  {dataItem?.description
                    ?.replace(/<new-line>/g, "\n")
                    .replace(/<single-quote>/g, "'")
                    .replace(/<double-quote>/g, '"') ||
                    dataItem?.specification
                      ?.replace(/<new-line>/g, "\n")
                      .replace(/<single-quote>/g, "'")
                      .replace(/<double-quote>/g, '"')}
                </p>

                <p style={{ width: "10%" }}>
                  {dataItem?.quantity.length == 0 ? 0 : dataItem?.quantity}
                </p>
                <p style={{ width: "10%" }}>{dataItem?.unit}</p>
                <p style={{ width: "10%" }}>
                  {"\u20B9 "}
                  {dataItem?.rate}
                </p>
                <p style={{ width: "10%" }}>{dataItem?.gst} %</p>

                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    {"\u20B9 "}
                    {(
                      dataItem?.quantity * dataItem?.rate +
                      dataItem?.quantity *
                      dataItem?.rate *
                      (dataItem?.gst / 100) || 0
                    ).toFixed(2)}
                  </p>
                  {"milestones" in dataItem &&
                    (
                      (
                        dataItem?.quantity * dataItem?.rate +
                        dataItem?.quantity *
                        dataItem?.rate *
                        (dataItem?.gst / 100) || 0
                      ).toFixed(2) -
                      (
                        "milestones" in dataItem &&
                        dataItem?.milestones.map((mItem, mIndex) =>
                          "payments" in mItem
                            ? mItem.payments
                              .reduce(
                                (total, item) =>
                                  (total = total + Number(item.amount)),
                                0
                              )
                              .toFixed(2)
                            : 0
                        )
                      ).reduce(
                        (previousAmount, currentAmount) =>
                          previousAmount + Number(currentAmount),
                        0
                      )
                    ).toFixed(2) <= 0 ? (
                    <p
                      style={{
                        width: "fit-Content",
                        marginTop: 10,
                        backgroundColor: "#fff9e8",
                        color: "#ffc868",
                        padding: 4,
                        fontWeight: 600,
                        border: "2px solid rgba(0, 0, 0, 0.05)",
                        borderRadius: 10,
                      }}
                    >
                      completed
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <p style={{ width: "2%", cursor: "pointer" }}></p>

              </div>
            </div>
          ))}
        </>
      )}
      {isSpinner ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "290px",
          }}
        >
          <CircularProgress
            style={{ width: "30px", height: "30px", color: "#fdd34d" }}
          />
        </div>
      ) : (
        <div
          className="order-item-milestones"
          style={{ margin: "20px", overflow: "scroll" }}
        >
          {client !== true && <h5>Payment Milestones</h5>}
          {commonMilestonestoDisplay == null ? (
            <div className="order-no-milestones">
              <p>This is an Old Order! </p>
              <button
                className="order-no-milestones-fetchmilestones-btn"
                onClick={() => {
                  FetchMilestonesforOldOrder(item);
                }}
              >
                FetchMilestones
              </button>
            </div>
          ) : (
            client == true ? 
            <div>
              <p>Orderwise progress will come soon...</p>
            </div> :
            <div>
              <div style={{ margin: "20px 0px", display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={styles.outerCircle}>
                        <div style={styles.innerCircle} />
                      </div>
                      <div style={styles.leftRightDashedUnit} />
                    </div>
                    <small>Placed</small>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={styles.selectedMilestone}>
                        {item.Status == "Order accepted" && (
                          <FaCheck color={"#FDD34D"} />
                        )}
                      </div>
                      <div style={styles.leftRightUnit} />
                    </div>
                    <small>Accepted</small>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {commonMilestonestoDisplay.map(
                    (milestone, milestoneIndex) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            maxWidth: 170,
                            minWidth: 150,
                          }}
                          key={milestoneIndex}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div style={styles.selectedMilestone}>
                              {"status" in milestone &&
                                milestone?.status == "confirmed" && (
                                  <FaCheck color={"#FDD34D"} />
                                )}
                              {milestone.totalAmount -
                                ("payments" in milestone
                                  ? milestone.payments.reduce((total, item) => {
                                    // console.log('inside the milestones if payment is there',item )
                                    return (total =
                                      total + Number(item.amount));
                                  }, 0)
                                  : 0.0
                                ).toFixed(2) <=
                                0.0 || milestone.name === "Paid" ? (
                                <FaCheck color={"#FDD34D"} />
                              ) : (
                                ""
                              )}
                            </div>
                            {milestoneIndex <
                              commonMilestonestoDisplay.length - 1 && (
                                <div style={styles.leftRightUnit} />
                              )}
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <small>{milestone?.name}</small>
                            <small>({milestone.percentage}%)</small>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <br />
              <div className="order-milestones-totalamount">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      minWidth: 160,
                      maxWidth: 170,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <small style={{ fontWeight: "bold" }}>
                      Total Amount
                      <br />
                      <p style={{ fontWeight: "normal" }}>
                        [{"\u20B9 "}
                        {orderTotalAmounttoDisplay}]
                      </p>
                    </small>
                  </div>
                  <div
                    style={{
                      minWidth: 130,
                      maxWidth: 130,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  />
                  {commonMilestonestoDisplay.map(
                    (milestone, milestoneIndex) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            maxWidth: 170,
                            minWidth: 160,
                          }}
                          key={milestoneIndex}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          ></div>
                          <small>
                            {Number(milestone?.totalAmount).toFixed(2)}
                          </small>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <br />
              <div
                className="order-milestones-paid"
                style={{
                  minWidth: 120,
                  maxWidth: 120,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    minWidth: 160,
                    maxWidth: 120,
                    display: "flex",
                  }}
                >
                  <small style={{ fontWeight: "bold" }}>
                    Paid
                    <br />
                    <p style={{ fontWeight: "normal" }}>
                      [{"\u20B9 "}
                      {Number(
                        commonMilestonestoDisplay.reduce(
                          (mTotalPaid, mItem) => {
                            // console.log('mtotalPaid',mTotalPaid,mItem)
                            return (mTotalPaid =
                              mTotalPaid +
                              (mItem.name !== "Paid" && "payments" in mItem
                                ? mItem.payments.reduce(
                                  (total, item) =>
                                    (total = total + Number(item.amount)),
                                  0
                                )
                                : 0.0));
                          },
                          0
                        ) +
                        Number(
                          commonMilestonestoDisplay[0].name === "Paid"
                            ? commonMilestonestoDisplay[0].totalAmount
                            : 0
                        )
                      ).toFixed(2)}
                      ]
                    </p>
                    <hr
                      style={{
                        width: "288px",
                        margin: "0.3em",
                        opacity: "0.3",
                      }}
                    />
                  </small>
                </div>
                <div
                  style={{
                    minWidth: 130,
                  }}
                ></div>
                <div style={{ display: "flex" }}>
                  {commonMilestonestoDisplay.map(
                    (milestone, milestoneIndex) => {
                      // console.log('aasdf',milestone)
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            maxWidth: 170,
                            minWidth: 160,
                          }}
                          key={milestoneIndex}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          ></div>
                          <small>
                            {milestone.name !== "Paid"
                              ? "payments" in milestone
                                ? Number(
                                  "payments" in milestone
                                    ? milestone.payments
                                      .reduce((total, item) => {
                                        // console.log('inside the milestones if payment is there',item )
                                        return (total =
                                          total + Number(item.amount));
                                      }, 0)
                                      .toFixed(2)
                                    : 0.0
                                ).toFixed(2)
                                : 0.0
                              : "-"}
                          </small>
                          <hr style={{ opacity: "0.3" }} />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="order-milestones-remaining">
                <div
                  style={{
                    minWidth: 160,
                    maxWidth: 170,
                    display: "flex",
                  }}
                >
                  <small style={{ fontWeight: "bold", minWidth: 160 }}>
                    Remaining
                    <br />
                    <p style={{ fontWeight: "normal" }}>
                      [{"\u20B9 "}
                      {orderTotalAmounttoDisplay -
                        Number(
                          commonMilestonestoDisplay.reduce(
                            (totaltobereduced, milestone) => {
                              // console.log('fssdf',milestone,'total',item.OrderTotalAmount)
                              return (totaltobereduced =
                                totaltobereduced +
                                Number(
                                  milestone.name !== "Paid" &&
                                    "payments" in milestone
                                    ? milestone.payments
                                      .reduce((total, item) => {
                                        // console.log('inside the milestones if payment is there',item )
                                        return (total =
                                          total + Number(item.amount));
                                      }, 0)
                                      .toFixed(2)
                                    : 0.0
                                ));
                            },
                            0
                          )
                        ) -
                        Number(
                          commonMilestonestoDisplay[0].name === "Paid"
                            ? commonMilestonestoDisplay[0].totalAmount
                            : 0
                        ).toFixed(2)}
                      ]
                    </p>
                  </small>
                  <div
                    style={{
                      minWidth: 130,
                    }}
                  ></div>
                  <div style={{ display: "flex" }}>
                    {commonMilestonestoDisplay.map(
                      (milestone, milestoneIndex) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                              maxWidth: 170,
                              minWidth: 160,
                            }}
                            key={milestoneIndex}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            ></div>
                            <small>
                              {milestone.name !== "Paid"
                                ? (
                                  milestone.totalAmount -
                                  Number(
                                    "payments" in milestone
                                      ? Number(
                                        milestone.payments.reduce(
                                          (total, item) =>
                                          (total =
                                            total + Number(item.amount)),
                                          0
                                        )
                                      )
                                      : 0.0

                                    // console.log('inside the milestones if payment is there',item )
                                  )
                                ).toFixed(2)
                                : "-"}
                            </small>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div
                className="order-milestones-pay-view"
                style={{ display: "flex" }}
              >
                <div
                  style={{
                    minWidth: 120,
                    maxWidth: 120,
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: 35,
                  }}
                >
                  <small style={{ fontWeight: "bold", minWidth: 150 }}>
                    Pay now
                  </small>
                </div>
                <div
                  style={{
                    minWidth: 150,
                  }}
                ></div>
                {isCompletedOrder ? (
                  <div
                    style={{
                      minWidth: 80,
                      maxWidth: 60,
                      borderRadius: "10px",
                      marginRight: 60,
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      padding: "10px 8px",
                      color: "#43A047",
                      borderRadius: 5,
                      cursor: "pointer",
                      fontWeight: 700,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: "#43A047",
                      }}
                      onClick={() => {
                        // console.log('View Clicked')
                        setViewDetailsModal(true);
                        setViewDetaisItem(item);
                      }}
                    >
                      <AiOutlineCheckCircle />
                      <p
                        style={{
                          color: "#43A047",
                          borderRadius: 5,
                          cursor: "pointer",
                          fontWeight: 700,
                        }}
                      >
                        View
                      </p>
                    </div>
                  </div>
                ) : (
                  commonMilestonestoDisplay.map((milestone, milestoneIndex) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // gap: 10,
                          maxWidth: 170,
                          minWidth: 160,
                        }}
                        key={milestoneIndex}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        ></div>
                        <small>
                          {milestone.name !== "Paid" ? (
                            milestone.totalAmount -
                              ("payments" in milestone
                                ? milestone.payments.reduce((total, item) => {
                                  // console.log('inside the milestones if payment is there',item )
                                  return (total =
                                    total + Number(item.amount));
                                }, 0)
                                : 0.0
                              ).toFixed(2) <=
                              0.0 ? (
                              <div
                                style={{
                                  minWidth: 60,
                                  maxWidth: 60,
                                  marginRight: 60,
                                  display: "flex",
                                  flexWrap: "wrap",
                                  flexDirection: "row",
                                  padding: "10px 8px",
                                  color: "#43A047",
                                  borderRadius: 5,
                                  cursor: "pointer",
                                  fontWeight: 700,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    color: "#43A047",
                                  }}
                                  onClick={() => {
                                    // console.log('View Clicked')
                                    setViewDetailsModal(true);
                                    setViewDetaisItem(item);
                                  }}
                                >
                                  <AiOutlineCheckCircle />
                                  <p
                                    style={{
                                      color: "#43A047",
                                      borderRadius: 5,
                                      cursor: "pointer",
                                      fontWeight: 700,
                                    }}
                                  >
                                    View
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  minWidth: 85,
                                  maxWidth: 85,
                                  marginRight: 60,
                                  display: "flex",
                                  flexWrap: "wrap",
                                  // padding: "10px 15px",
                                  // backgroundColor: "#FFF6DB",
                                  borderRadius: 5,
                                  flexDirection: "column",
                                  gap: "0.6em",
                                  cursor: "pointer",
                                  alignItems: "stretch",
                                }}
                              >
                                <button
                                  className="milestone-pay-btn"
                                  onClick={() => {
                                    payClickHanlder(milestone, milestoneIndex, item.VendorId);
                                  }}
                                >
                                  Pay
                                </button>
                                <button
                                  className="milestone-request-btn"
                                  onClick={() => {
                                    requestClickHandler(
                                      milestone,
                                      milestoneIndex,
                                      item.VendorId
                                    );
                                  }}
                                >
                                  Request
                                </button>
                              </div>
                            )
                          ) : (
                            "-"
                          )}
                        </small>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {viewDetailsModal && (
        <ViewDetails
          viewDetailsModal={viewDetailsModal}
          setViewDetailsModal={setViewDetailsModal}
          viewDetailsItem={item}
          viewDetailsMilestones={commonMilestonestoDisplay}
        />
      )}

      <Modal
        open={openBankDetailsModel}
        onClose={() => setOpenBankDetailsModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "auto",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            minWidth:
              Object.keys(bankAccountDetails).length <= 0 ? "30%" : "40%",
            maxHeight: "90%",
            maxWidth:
              Object.keys(bankAccountDetails).length <= 0 ? "30%" : "40%",
          }}
        >
          {Object.keys(bankAccountDetails).length <= 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <div>
                <FaExclamationCircle color="#E02020" size={40} />
              </div>
              <div style={{ fontWeight: 700, fontSize: 20 }}>
                Error! No Bank Details Found
              </div>
              <div
                style={{
                  width: "80%",
                  maxWidth: "80%",
                  minWidth: "80%",
                  textAlign: "center",
                }}
              >
                There are no Bank Details associated with this vendor
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                  gap: 20,
                  width: "80%",
                  minWidth: "80%",
                  maxWidth: "80%",
                  gap: 10,
                }}
              >
                {/* <div style={{ width: '50%', minWidth: '50%', maxWidth: '50%', border: '2px solid #FDD34D', textAlign: 'center', borderRadius: 5, cursor: 'pointer' }}>
                                                          <p style={{ fontWeight: 700, padding: 5 }}>
                                                              Close
                                                          </p>
                                                      </div> */}
                <div
                  style={{
                    width: "50%",
                    minWidth: "50%",
                    maxWidth: "50%",
                    backgroundColor: "#FDD34D",
                    textAlign: "center",
                    borderRadius: 5,
                    cursor: "pointer",
                    marginTop: "20px",
                    boxShadow: "0 0 5px",
                  }}
                  onClick={() => {
                    setVendorFormOpen(true);
                    setOpenBankDetailsModel(false);
                  }}
                >
                  <p style={{ fontWeight: 700, padding: 5 }}>
                    Add Bank Details
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ marginBottom: 30, fontWeight: 700, fontSize: 25 }}>
                Bank Account Details
              </p>
              <div style={{ display: "flex", flexDirection: "row", gap: 200 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Account Holder Name</p>
                    <p
                      style={{
                        padding: 5,
                        borderColor: "black",
                        backgroundColor: "#f3f3f1",
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AccountHolderName}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Account Number</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AccountNumber}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>IFSC</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.IFSC}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>PAN</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.PAN}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>Aadhar</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.AADHAR}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <p>GSTIN</p>
                    <p
                      style={{
                        padding: 5,
                        border: "1px solid #e0dfdd",
                        backgroundColor: "#f3f3f1",
                        borderRadius: 5,
                      }}
                    >
                      {Object.keys(bankAccountDetails).length <= 0
                        ? "Details Not Found"
                        : bankAccountDetails.GSTIN}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>

      {paymentOption.isModalOpen && (
        <PaymentOption
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
          totalOrderAmount={
            selectedOrderMilestoneData &&
            selectedOrderMilestoneData.reduce((accumulator, currentObject) => {
              return accumulator + Number(currentObject.totalAmount)
            }, 0)
          }
          totalPaidAmount={
            // " 3023"
            selectedOrderMilestoneData.length > 0 &&
            (selectedMilestoneIndex != undefined &&
              "payments" in selectedOrderMilestoneData[selectedMilestoneIndex]
              ? selectedOrderMilestoneData[
                selectedMilestoneIndex
              ]?.payments.reduce(
                (total, item) => (total = total + Number(item.amount)),
                0
              )
              : 0 || 0
            ).toFixed(2)
          }
          totalRemainingAmount={
            //  "32323"
            selectedOrderMilestoneData.length > 0 &&
            (
              selectedOrderMilestoneData[selectedMilestoneIndex]?.totalAmount -
              (selectedOrderMilestoneData &&
                "payments" in selectedOrderMilestoneData[selectedMilestoneIndex]
                ? selectedOrderMilestoneData[
                  selectedMilestoneIndex
                ]?.payments.reduce(
                  (total, item) => (total = total + Number(item?.amount)),
                  0
                )
                : 0 || 0
              ).toFixed(0)
            ).toFixed(2)
          }
          // modeOfPayment={modeOfPayment}
          // setModeOfPayment={setModeOfPayment}
          fetchRazorpayOrder={fetchRazorpayOrder}
          selectedOrderMilestoneData={selectedOrderMilestoneData}
          selectedOrderId={selectedOrderId}
          // selectedOrderDataIndex={selectedOrderDataIndex}
          selectedMilestoneIndex={selectedMilestoneIndex}
          startDate={JSON.parse(item.Data)?.StartDate}
          ProjectId={ProjectId}
          ClientName={ClientName}
          vendorCompanyName={vendorCompanyName}
        />
      )}

      {paymentRequestedModal && (
        <RequestedPayments
          OrderId={item.OrderId}
          paymentRequestedModal={paymentRequestedModal}
          setPaymentsRequestedModal={setPaymentsRequestedModal}
        />
      )}
      
      {transactionDetailsModal && (
        <TransactionDetails
          transactionDetailsModal={transactionDetailsModal}
          setTransactionDetailsModal={setTransactionDetailsModal}
          transactionDetailsData={transactionDetailsData}
          selectedOrderMilestoneData={selectedOrderMilestoneData}
          setSelectedOrderMilestoneData={setSelectedOrderMilestoneData}
          startDate={
            JSON.parse(item.Data)?.StartDate
          }
          endDate={
            JSON.parse(item.Data)?.EndDate
          }
          orderId={item.OrderId}
          setReleasedOrders={setReleasedOrders}
          releasedOrderIndex={releasedOrderIndex}
        />
      )}

      {vendorStatementModalisOpen && (
        <VendorStatement
          vendorStatementModalisOpen={vendorStatementModalisOpen}
          setVendorStatementModal={setVendorStatementModal}
          allOrders={allOrders}
          vendorDetailsForStatement={vendorDetailsForStatement}
        />
      )}

    </div>
  );
};

export default OrderItemOngoing;
