// TransferVehicle.js

import React, { useState, useEffect } from "react";
// TransferVehicle.js
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  TextField,
  Divider,
  Paper,
  Grid, // Add this
  styled,
} from "@mui/material";
import { format } from "date-fns";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  backgroundColor: "#f8f9fa",
  borderRadius: theme.spacing(1),
}));

const VehicleInfoSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiTypography-root": {
    marginBottom: theme.spacing(1),
  },
}));

const TransferDetailsSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StatusIcon = styled(Box)(({ theme, status }) => ({
  display: "inline-flex",
  alignItems: "center",
  color:
    status === "error"
      ? theme.palette.error.main
      : status === "warning"
      ? theme.palette.warning.main
      : theme.palette.success.main,
  marginRight: theme.spacing(1),
}));

const steps = ["Review Vehicle", "Select Destination", "Confirm Transfer"];

const TransferVehicle = ({
  open,
  onClose,
  vehicle,
  projects,
  currentProject,
  onTransfer,
  loading,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProject, setSelectedProject] = useState("");
  const [transferNotes, setTransferNotes] = useState("");
  const [error, setError] = useState("");
  const [confirmationCheck, setConfirmationCheck] = useState(false);

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setSelectedProject("");
      setTransferNotes("");
      setError("");
      setConfirmationCheck(false);
    }
  }, [open]);

  const handleNext = () => {
    if (activeStep === 1 && !selectedProject) {
      setError("Please select a destination project");
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
    setError("");
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setError("");
  };

  const handleTransfer = async () => {
    if (!selectedProject) {
      setError("Please select a destination project");
      return;
    }

    if (vehicle.sos) {
      setError("Vehicle under maintenance cannot be transferred");
      return;
    }

    try {
      await onTransfer({
        destinationProjectId: selectedProject,
        notes: transferNotes,
        timestamp: new Date().toISOString(),
      });
      onClose();
    } catch (error) {
      setError("Failed to transfer vehicle. Please try again.");
    }
  };

  const renderVehicleReview = () => (
    <VehicleInfoSection>
      <StyledPaper elevation={1}>
        <Typography variant="h6" gutterBottom>
          Vehicle Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Vehicle Number:</strong> {vehicle?.vehicleNumber}
            </Typography>
            <Typography variant="body1">
              <strong>Type:</strong> {vehicle?.vehicleType}
            </Typography>
            <Typography variant="body1">
              <strong>Current Project:</strong> {currentProject}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Make:</strong> {vehicle?.make}
            </Typography>
            <Typography variant="body1">
              <strong>Model:</strong> {vehicle?.model}
            </Typography>
            <Typography variant="body1">
              <strong>Chassis Number:</strong> {vehicle?.chassisNumber}
            </Typography>
          </Grid>
        </Grid>

        {vehicle?.sos && (
          <Alert severity="warning" icon={<WarningIcon />} sx={{ mt: 2 }}>
            This vehicle is currently under maintenance
          </Alert>
        )}
      </StyledPaper>
    </VehicleInfoSection>
  );

  const renderProjectSelection = () => (
    <TransferDetailsSection>
      <FormControl fullWidth error={!!error}>
        <InputLabel>Destination Project</InputLabel>
        <Select
          value={selectedProject}
          onChange={(e) => {
            setSelectedProject(e.target.value);
            setError("");
          }}
          label="Destination Project"
        >
          {projects
            .filter((project) => project.projectID !== currentProject)
            .map((project) => (
              <MenuItem key={project.projectID} value={project.projectID}>
                {project.projectName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Transfer Notes"
        value={transferNotes}
        onChange={(e) => setTransferNotes(e.target.value)}
        sx={{ mt: 3 }}
      />
    </TransferDetailsSection>
  );

  const renderConfirmation = () => (
    <Box>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Transfer Summary
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Typography variant="body1" gutterBottom>
          <strong>From:</strong> {currentProject}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>To:</strong>{" "}
          {projects.find((p) => p.projectID === selectedProject)?.projectName}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Vehicle:</strong> {vehicle?.vehicleNumber}
        </Typography>
        {transferNotes && (
          <Typography variant="body1" gutterBottom>
            <strong>Notes:</strong> {transferNotes}
          </Typography>
        )}
      </StyledPaper>

      <Alert severity="info" sx={{ mt: 2 }}>
        Please confirm that all vehicle documents and maintenance records have
        been reviewed.
      </Alert>
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Transfer Vehicle</DialogTitle>

      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {activeStep === 0 && renderVehicleReview()}
        {activeStep === 1 && renderProjectSelection()}
        {activeStep === 2 && renderConfirmation()}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
        {activeStep === steps.length - 1 ? (
          <Button
            onClick={handleTransfer}
            variant="contained"
            sx={{
              padding: "8px 8px",
            }}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm Transfer"}
          </Button>
        ) : (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TransferVehicle;
