// DropdownComponent.js
import React from 'react';
import PropTypes from 'prop-types';
import './DropdownComponent.css'; // Importing the CSS file

const DropdownComponent = ({ items, onSelect }) => {

    const handleChange = (event) => {
        onSelect(event.target.value); // Pass selected value to parent function
    };

    return (
        <div className="dropdown-container">
            <label className="dropdown-label">Project</label>
            <select onChange={handleChange} className="dropdown" defaultValue={items.length > 0 && items[0] ? items[0].projectName : null}>
                {items.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                        {item.projectName}
                    </option>
                ))}
                <option key={items.length} value={'o'}>
                    All site
                </option>
            </select>
        </div>
    );
};

DropdownComponent.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default DropdownComponent;
