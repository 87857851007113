// EntryLogs.js
import React, { useEffect, useState } from "react";
import { secondDatabase } from "../../config/firebase";
import DrawerTemplate from "../../hoc/drawerTemplate";
import "./EntryLogs.css";
import { Box, Typography, IconButton, Button, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";
import DateRangeSelector from "../../helperComponents/DateRangeSelector";
import * as XLSX from "xlsx";

const EntryLogs = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [logsData, setLogsData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [firmId, setFirmId] = useState("");
  const [firmDetailsId, setFirmDetailsId] = useState("");
  const [vehiclesCount, setVehiclesCount] = useState({});
  const [missingDatesInfo, setMissingDatesInfo] = useState({});
  const [exportLoading, setExportLoading] = useState(false);

  // Initialize date range (last 7 days by default)
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 10); // 7 days including today

  const [startDate, setStartDate] = useState(sevenDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const [dateRange, setDateRange] = useState([]);

  // Handle navigation back to Vehicles page
  const handleBackClick = () => {
    history.push("/vehicles");
  };

  // Export data to Excel with improved styling
  const exportToExcel = () => {
    setExportLoading(true);

    setTimeout(() => {
      try {
        // Format data for Excel
        const worksheetData = [
          // Header row
          [
            "Project Name",
            ...dateRange.map((date) => formatDate(date)).reverse(),
          ],
        ];

        // Add data rows with percentage information stored for styling
        logsData.forEach((project) => {
          const rowData = [project.projectName];

          [...dateRange].reverse().forEach((date) => {
            const dateString = date.toISOString().split("T")[0];
            const entry = project.dateEntries[dateString];
            const count = entry ? entry.count : 0;
            const total = entry ? entry.total : 0;

            // Store as string value for the cell
            rowData.push(`${count}/${total}`);
          });

          worksheetData.push(rowData);
        });

        // Create worksheet from data
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();

        // Custom styling functions
        const getPercentage = (value) => {
          const [count, total] = value.split("/").map(Number);
          return total > 0 ? Math.round((count / total) * 100) : 0;
        };

        const getColorStyles = (value) => {
          const percentage = getPercentage(value);

          if (percentage === 0) {
            return {
              font: { color: { rgb: "D32F2F" }, bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "FFEBEE" } },
            };
          } else if (percentage > 80) {
            return {
              font: { color: { rgb: "2E7D32" }, bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "E3FCEF" } },
            };
          } else {
            return {
              font: { color: { rgb: "F57C00" }, bold: true },
              fill: { patternType: "solid", fgColor: { rgb: "FFF8E1" } },
            };
          }
        };

        // Set column widths
        const colWidths = [
          { wch: 40 }, // Project name column width
          ...Array(dateRange.length).fill({ wch: 18 }), // Date columns width
        ];
        worksheet["!cols"] = colWidths;

        // Get the range of the worksheet
        const range = XLSX.utils.decode_range(worksheet["!ref"]);

        // Apply modern styles to each cell
        for (let r = range.s.r; r <= range.e.r; r++) {
          for (let c = range.s.c; c <= range.e.c; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r, c });
            if (!worksheet[cellAddress]) continue;

            // Apply base style for all cells
            worksheet[cellAddress].s = {
              font: { name: "Calibri", sz: 11 },
              alignment: {
                horizontal: c === 0 ? "left" : "center",
                vertical: "center",
                wrapText: true,
              },
              border: {
                top: { style: "thin", color: { rgb: "E5E7EB" } },
                right: { style: "thin", color: { rgb: "E5E7EB" } },
                bottom: { style: "thin", color: { rgb: "E5E7EB" } },
                left: { style: "thin", color: { rgb: "E5E7EB" } },
              },
            };

            // Header row style
            if (r === 0) {
              worksheet[cellAddress].s = {
                font: {
                  name: "Calibri",
                  sz: 12,
                  bold: true,
                  color: { rgb: "FFFFFF" },
                },
                fill: { patternType: "solid", fgColor: { rgb: "4A6CF7" } },
                alignment: {
                  horizontal: c === 0 ? "left" : "center",
                  vertical: "center",
                  wrapText: true,
                },
                border: {
                  top: { style: "thin", color: { rgb: "3B5BD9" } },
                  right: { style: "thin", color: { rgb: "3B5BD9" } },
                  bottom: { style: "thin", color: { rgb: "3B5BD9" } },
                  left: { style: "thin", color: { rgb: "3B5BD9" } },
                },
              };
            }
            // Project name column style
            else if (c === 0) {
              worksheet[cellAddress].s = {
                font: {
                  name: "Calibri",
                  sz: 11,
                  bold: true,
                  color: { rgb: "1F2937" },
                },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: r % 2 === 1 ? "FFFFFF" : "F9FAFB" },
                },
                alignment: { horizontal: "left", vertical: "center" },
                border: {
                  top: { style: "thin", color: { rgb: "E5E7EB" } },
                  right: { style: "thin", color: { rgb: "E5E7EB" } },
                  bottom: { style: "thin", color: { rgb: "E5E7EB" } },
                  left: { style: "thin", color: { rgb: "E5E7EB" } },
                },
              };
            }
            // Data cells
            else if (r > 0 && c > 0) {
              const cellValue = worksheet[cellAddress].v;
              if (typeof cellValue === "string" && cellValue.includes("/")) {
                // Apply color coding based on percentage
                const colorStyles = getColorStyles(cellValue);

                worksheet[cellAddress].s = {
                  ...worksheet[cellAddress].s,
                  ...colorStyles,
                  alignment: { horizontal: "center", vertical: "center" },
                  border: {
                    top: { style: "thin", color: { rgb: "E5E7EB" } },
                    right: { style: "thin", color: { rgb: "E5E7EB" } },
                    bottom: { style: "thin", color: { rgb: "E5E7EB" } },
                    left: { style: "thin", color: { rgb: "E5E7EB" } },
                  },
                };
              }
            }

            // Alternate row background colors (except header)
            if (r > 0 && c > 0 && !worksheet[cellAddress].s.fill) {
              worksheet[cellAddress].s.fill = {
                patternType: "solid",
                fgColor: { rgb: r % 2 === 1 ? "FFFFFF" : "F9FAFB" },
              };
            }
          }
        }

        // Add a row for the document title with merged cells
        XLSX.utils.sheet_add_aoa(worksheet, [["Vehicle Entry Logs Report"]], {
          origin: "A1",
        });
        const titleCell = worksheet.A1;
        titleCell.s = {
          font: {
            name: "Calibri",
            sz: 16,
            bold: true,
            color: { rgb: "4A6CF7" },
          },
          alignment: { horizontal: "center", vertical: "center" },
          fill: { patternType: "solid", fgColor: { rgb: "F8FAFC" } },
        };

        // Move all content down one row to make space for the title
        const newRange = XLSX.utils.decode_range(worksheet["!ref"]);
        worksheet["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: newRange.e.c } },
        ];

        // Add generated timestamp
        const dateStr = new Date().toLocaleString();
        XLSX.utils.sheet_add_aoa(worksheet, [[`Generated: ${dateStr}`]], {
          origin: "A2",
        });
        worksheet.A2.s = {
          font: { name: "Calibri", sz: 10, color: { rgb: "6B7280" } },
          alignment: { horizontal: "right", vertical: "center" },
        };
        worksheet["!merges"].push({
          s: { r: 1, c: 0 },
          e: { r: 1, c: newRange.e.c },
        });

        // Adjust the real header row to be now at row 3
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              "Project Name",
              ...dateRange.map((date) => formatDate(date)).reverse(),
            ],
          ],
          { origin: "A3" }
        );

        for (let c = 0; c <= newRange.e.c; c++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 2, c });
          worksheet[cellAddress].s = {
            font: {
              name: "Calibri",
              sz: 12,
              bold: true,
              color: { rgb: "FFFFFF" },
            },
            fill: { patternType: "solid", fgColor: { rgb: "4A6CF7" } },
            alignment: {
              horizontal: c === 0 ? "left" : "center",
              vertical: "center",
              wrapText: true,
            },
            border: {
              top: { style: "thin", color: { rgb: "3B5BD9" } },
              right: { style: "thin", color: { rgb: "3B5BD9" } },
              bottom: { style: "thin", color: { rgb: "3B5BD9" } },
              left: { style: "thin", color: { rgb: "3B5BD9" } },
            },
          };
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, "Vehicle Entry Logs");

        // Generate file name with current date
        const fileName = `VehicleEntryLogs_${
          new Date().toISOString().split("T")[0]
        }.xlsx`;

        // Save file
        XLSX.writeFile(workbook, fileName);
        setExportLoading(false);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        setExportLoading(false);
      }
    }, 500);
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("User"));
    const tempFirmId = localStorageData.firmId;
    setFirmId(tempFirmId);
  }, []);

  // Generate date range based on selected start and end dates
  useEffect(() => {
    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDateRange(dates);
  }, [startDate, endDate]);

  // Function to convert timestamp to IST date string (YYYY-MM-DD)
  const getISTDateStringFromTimestamp = (timestamp) => {
    // Create date object from timestamp
    const date = new Date(timestamp);

    // Add IST offset (5 hours and 30 minutes)
    const istTime = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

    // Format to YYYY-MM-DD
    return istTime.toISOString().split("T")[0];
  };

  // Generate start of day timestamp in IST for a given date string
  const getStartOfDayTimestamp = (dateString) => {
    // Parse the date string (format: YYYY-MM-DD)
    const [year, month, day] = dateString.split("-").map(Number);

    // Create date with time set to 00:00:00 IST (which is previous day 18:30:00 UTC)
    const date = new Date(Date.UTC(year, month - 1, day, -5, -30, 0));

    return date.getTime();
  };

  // Generate end of day timestamp in IST for a given date string
  const getEndOfDayTimestamp = (dateString) => {
    // Parse the date string (format: YYYY-MM-DD)
    const [year, month, day] = dateString.split("-").map(Number);

    // Create date with time set to 23:59:59 IST (which is next day 18:29:59 UTC)
    const date = new Date(Date.UTC(year, month - 1, day, 18, 29, 59));

    return date.getTime();
  };

  useEffect(() => {
    if (!firmId) return;

    const fetchData = async () => {
      setIsLoading(true);
      setLoadingProgress(0.1); // Start progress
      try {
        // Fetch all necessary data in parallel for faster loading
        const userRef = secondDatabase.ref(`userDetails/${firmId}`);
        const userSnapshot = await userRef.once("value");
        const userData = userSnapshot.val();

        if (!userData) {
          setIsLoading(false);
          return;
        }

        setLoadingProgress(0.3); // Update progress after user data

        const { firmDetailsID } = userData.personalDetails.firmDetails;
        setFirmDetailsId(firmDetailsID);

        const projectsArray = Object.values(userData.projects || {});
        setProjects(projectsArray);

        setLoadingProgress(0.5); // Update progress after projects

        // Fetch firm data
        const firmRef = secondDatabase.ref(`${firmDetailsID}`);
        const firmSnapshot = await firmRef.once("value");
        const firmData = firmSnapshot.val();

        if (!firmData) {
          setIsLoading(false);
          return;
        }

        setLoadingProgress(0.7); // Update progress after firm data

        // Count non-transferred vehicles by project
        const vehiclesByProject = {};

        projectsArray.forEach((project) => {
          const projectId = project.projectID || project.projectId;
          const projectData = firmData[projectId];

          if (projectData?.vehicles) {
            // Count only non-transferred vehicles
            let count = 0;
            Object.values(projectData.vehicles).forEach((vehicle) => {
              if (!vehicle.transferred) {
                count++;
              }
            });
            vehiclesByProject[projectId] = count;
          } else {
            vehiclesByProject[projectId] = 0;
          }
        });

        setVehiclesCount(vehiclesByProject);
        setLoadingProgress(0.8); // Update progress after counting vehicles

        // Process movement records only for non-transferred vehicles
        const { processedData, missingDates } = processMovementRecords(
          firmData,
          projectsArray,
          dateRange,
          vehiclesByProject
        );

        setLogsData(processedData);
        setMissingDatesInfo(missingDates);
        setLoadingProgress(1); // Complete progress

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingProgress(1); // Complete progress even on error
        setIsLoading(false);
      }
    };

    if (dateRange.length > 0) {
      fetchData();
    }
  }, [firmId, dateRange]);

  const processMovementRecords = (
    firmData,
    projects,
    dates,
    vehiclesByProject
  ) => {
    const result = [];
    const missingDates = {};

    // Create a map of non-transferred vehicle tags for faster lookup
    const nonTransferredVehicleTags = {};

    projects.forEach((project) => {
      const projectId = project.projectID || project.projectId;
      nonTransferredVehicleTags[projectId] = new Set();

      if (firmData[projectId]?.vehicles) {
        Object.entries(firmData[projectId].vehicles).forEach(
          ([vehicleId, vehicle]) => {
            if (!vehicle.transferred && vehicle.tag) {
              nonTransferredVehicleTags[projectId].add(vehicle.tag);
            }
          }
        );
      }
    });

    projects.forEach((project) => {
      const projectId = project.projectID || project.projectId;
      const projectName = project.projectName;
      const totalVehicles = vehiclesByProject[projectId] || 0;

      // Initialize missing dates info for this project
      missingDates[projectId] = {
        projectName,
        dates: {},
      };

      const dateEntries = {};
      const dateRanges = {};

      // Initialize with zeros for all dates and create date range timestamps
      dates.forEach((date) => {
        const dateString = date.toISOString().split("T")[0];
        dateEntries[dateString] = { count: 0, total: totalVehicles };

        // Store the start and end timestamps for this date in IST
        dateRanges[dateString] = {
          start: getStartOfDayTimestamp(dateString),
          end: getEndOfDayTimestamp(dateString),
        };
      });

      // Collect all timestamps for finding nearest entries
      const allTimestamps = [];

      // Count movement records by date for non-transferred vehicles only
      if (firmData[projectId]?.vehicleMovementRecords) {
        const records = firmData[projectId].vehicleMovementRecords;

        // Store all timestamps for later processing
        Object.keys(records).forEach((timestamp) => {
          allTimestamps.push(parseInt(timestamp));
        });

        // Sort timestamps for finding nearest entries
        allTimestamps.sort((a, b) => a - b);

        // Debug log timestamps
        console.log(
          `Project ${projectName} timestamps:`,
          allTimestamps.map((ts) => ({
            timestamp: ts,
            date: getISTDateStringFromTimestamp(ts),
          }))
        );

        // Use one-time processing for better performance
        Object.entries(records).forEach(([timestamp, record]) => {
          const ts = parseInt(timestamp);
          // Get IST date string from timestamp
          const dateString = getISTDateStringFromTimestamp(ts);

          // Check if the date is within our range
          if (dateEntries[dateString]) {
            // Use tag as the primary identifier
            const tag = record.tag;

            // Only count if it's a non-transferred vehicle and has a valid tag
            if (tag && nonTransferredVehicleTags[projectId].has(tag)) {
              // Increment counter, ensuring we don't count the same vehicle twice
              if (!dateEntries[dateString].processedVehicles) {
                dateEntries[dateString].processedVehicles = new Set();
              }

              if (!dateEntries[dateString].processedVehicles.has(tag)) {
                dateEntries[dateString].count++;
                dateEntries[dateString].processedVehicles.add(tag);
              }
            }
          }
        });
      }

      // Find nearest timestamps for each date
      Object.entries(dateRanges).forEach(([dateString, range]) => {
        // If no entries for this date and we have vehicles, mark as missing
        if (dateEntries[dateString].count === 0 && totalVehicles > 0) {
          let beforeTimestamp = null;
          let afterTimestamp = null;

          // Find the nearest timestamp before this date
          for (let i = allTimestamps.length - 1; i >= 0; i--) {
            if (allTimestamps[i] < range.start) {
              beforeTimestamp = allTimestamps[i];
              break;
            }
          }

          // Find the nearest timestamp after this date
          for (let i = 0; i < allTimestamps.length; i++) {
            if (allTimestamps[i] > range.end) {
              afterTimestamp = allTimestamps[i];
              break;
            }
          }

          missingDates[projectId].dates[dateString] = {
            dateISOString: dateString,
            startTimestamp: range.start,
            endTimestamp: range.end,
            beforeTimestamp: beforeTimestamp,
            afterTimestamp: afterTimestamp,
            beforeDate: beforeTimestamp
              ? getISTDateStringFromTimestamp(beforeTimestamp)
              : null,
            afterDate: afterTimestamp
              ? getISTDateStringFromTimestamp(afterTimestamp)
              : null,
          };
        }
      });

      // Clean up the processedVehicles sets
      Object.entries(dateEntries).forEach(([dateString, entry]) => {
        delete entry.processedVehicles;
      });

      result.push({
        projectId,
        projectName,
        dateEntries,
      });
    });

    return { processedData: result, missingDates };
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
  };

  const getStatusColor = (percentage) => {
    if (percentage === 0) return "#ffebee"; // No entries - light red
    if (percentage > 80) return "#e3fcef"; // High entries - light green
    if (percentage > 0) return "#fff8e1"; // Low entries - light yellow
    return "#f5f5f5"; // Default - light gray
  };

  const getStatusTextColor = (percentage) => {
    if (percentage === 0) return "#d32f2f"; // No entries - dark red
    if (percentage > 80) return "#2e7d32"; // High entries - dark green
    if (percentage > 0) return "#f57c00"; // Low entries - dark orange
    return "#757575"; // Default - gray
  };

  if (isLoading) {
    return <LoadingComponent progressState={loadingProgress} />;
  }

  return (
    <div className="entry-logs-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
          gap: 2,
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#f9fafb",
          padding: "20px",
          boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
        }}
        className="header-container"
      >
        <div className="header-with-back-button">
          <IconButton
            onClick={handleBackClick}
            className="back-button"
            aria-label="back to vehicles"
            sx={{
              padding: "0.5rem",
              marginRight: "1rem",
              transition: "all 0.2s",
              "&:hover": {
                backgroundColor: "rgba(74, 108, 247, 0.1)",
                color: "#4A6CF7",
              },
            }}
          >
            <ArrowBackIosNewIcon
              fontSize="medium"
              sx={{
                color: "inherit",
              }}
              className="back-icon"
            />
          </IconButton>
          <div className="header-text">
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontWeight: "700",
                color: "#1a1a1a",
                fontSize: { xs: "1.25rem", md: "1.5rem" },
              }}
            >
              Vehicle Entry Logs
            </Typography>
            <Typography variant="body2" sx={{ color: "#6B7280", mt: 0.5 }}>
              Records of vehicle movement entries for the selected date range
            </Typography>
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            width: { xs: "100%", md: "auto" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              p: 1.5,
              borderRadius: 2,
              backgroundColor: "#FFFFFF",
              border: "1px solid #E5E7EB",
              width: { xs: "100%", sm: "auto" },
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
            }}
            className="date-selector-container"
          >
            <DateRangeSelector
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Box>

          <Tooltip title="Download vehicle entry data as Excel file">
            <Button
              variant="contained"
              startIcon={
                exportLoading ? (
                  <div className="spinner-border" />
                ) : (
                  <DownloadIcon />
                )
              }
              onClick={exportToExcel}
              disabled={exportLoading}
              sx={{
                backgroundColor: "#4A6CF7",
                borderRadius: "8px",
                padding: "10px 16px",
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                marginRight: "30px",
                transition: "all 0.2s",
                minWidth: "160px",
                "&:hover": {
                  backgroundColor: "#3b5de7",
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                },
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {exportLoading ? "Exporting..." : "Export to Excel"}
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <div className="table-container">
        <div className="table-scroll-container">
          <table className="entry-logs-table">
            <thead>
              <tr>
                <th className="project-column">Project Name</th>
                {/* Reversed date range to show most recent first */}
                {[...dateRange].reverse().map((date, index) => (
                  <th key={index} className="date-column">
                    <div className="date-header">
                      <div className="weekday">
                        {formatDate(date).split(",")[0]}
                      </div>
                      <div className="full-date">
                        {formatDate(date).split(",")[1]}
                      </div>
                    </div>
                  </th>
                ))}
                {/* Add an empty column at the end to fill any remaining space */}
                <th className="filler-column"></th>
              </tr>
            </thead>
            <tbody>
              {logsData.map((project, rowIndex) => (
                <tr
                  key={project.projectId}
                  className={rowIndex % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td className="project-name">{project.projectName}</td>
                  {/* Reversed date range to show most recent first */}
                  {[...dateRange].reverse().map((date, index) => {
                    const dateString = date.toISOString().split("T")[0];
                    const entry = project.dateEntries[dateString];
                    const count = entry ? entry.count : 0;
                    const total = entry ? entry.total : 0;
                    const percentage =
                      total > 0 ? Math.round((count / total) * 100) : 0;

                    return (
                      <td key={index} className="entry-cell">
                        <div
                          className="entry-value"
                          style={{
                            backgroundColor: getStatusColor(percentage),
                            color: getStatusTextColor(percentage),
                            padding: "8px 12px",
                            borderRadius: "8px",
                            fontWeight: "600",
                            display: "inline-block",
                            minWidth: "75px",
                            boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                            transition: "all 0.2s ease",
                          }}
                        >
                          {count}/{total}
                        </div>
                      </td>
                    );
                  })}
                  {/* Add an empty cell at the end to match the header */}
                  <td className="filler-cell"></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DrawerTemplate(EntryLogs);
