// // eslint-disable-next-line import/no-anonymous-default-export
// export default (data) => {
//   let total = 0;
//   const { rooms } = data;
//   rooms &&
//     Array.isArray(rooms) &&
//     rooms.forEach((room, roomIndex) => {
//       const { Units } = room;
//       Units?.forEach((Unit, unitIndex) => {
//         const { Components } = Unit;
//         if (Components) {
//           Components.forEach((component, componentIndex) => {
//             const { quantity, rate } = component;
//             if (typeof rate === "string" && !isNaN(rate)) {
//               console.log('quantity : ', quantity, 'typeof quantity => ', typeof quantity);
//               const sanitizedQuantity = typeof quantity === "string" ? quantity.trim() : String(quantity).trim();
//               if (
//                 quantity &&
//                 rate &&
//                 quantity?.trim().length > 0 &&
//                 rate?.length > 0 &&
//                 !isNaN(quantity) &&
//                 !isNaN(rate)
//               ) {
//                 total += parseFloat(quantity) * parseFloat(rate);
//               }
//             } else if (
//               quantity &&
//               rate &&
//               quantity?.trim().length > 0 &&
//               !isNaN(quantity) &&
//               !isNaN(rate)
//             ) {
//               total += parseFloat(quantity) * parseFloat(rate);
//             }
//           });
//         }
//       });
//     });

//   return total;
// };


// eslint-disable-next-line import/no-anonymous-default-export

export default (data) => {
  let total = 0;
  const { rooms } = data;
  
  if (!Array.isArray(rooms)) return total;

  rooms.forEach((room) => {
    const { Units } = room;
    if (!Array.isArray(Units)) return;

    Units.forEach((Unit) => {
      const { Components } = Unit;
      if (!Array.isArray(Components)) return;

      Components.forEach((component) => {
        let { quantity, rate } = component;
        
        // Ensure quantity and rate are strings and trim them
        quantity = typeof quantity === "string" ? quantity.trim() : String(quantity).trim();
        rate = typeof rate === "string" ? rate.trim() : String(rate).trim();
        
        if (!quantity || !rate || isNaN(quantity) || isNaN(rate)) return;
        
        total += parseFloat(quantity) * parseFloat(rate);
      });
    });
  });
  
  return total;
};
