import { useEffect, useState, useContext, useRef } from "react";
import "./dashboardPage.scss";
// import { useLocation } from "react-router-dom"
import { Button1, SearchBar, PopOverView } from "../../components";
import DrawerHOC from "../../hoc/drawerTemplate";
import ProjectDetailsModal from "./projectDetailsModal";
import ProjectService from "../../api/projectService";
import { toast } from "react-toast";
import firebase from "../../config/firebase";
import ProjectRow from "../../components/ProjectRow/projectRow";
import AuthService from "../../api/authService";
import addNotification from "react-push-notification";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config/config";
import { UserContext } from "../../Context/UserContext";
import TryOurAppsModal from "./TryOurAppsModal";

const DashboardPage = () => {
  const { record } = useAnalyticsContext();
  const [showProjectDetailsModal, toggleProjetDetailsModal] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);
  const projectService = new ProjectService();
  const [projects, setProjects] = useState([]);
  // console.log("projects:", projects);
  const [copyProjects, setCopyProjects] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showLoadMore, SetShowLoadMore] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const [isloadingnew, setloadingnew] = useState(false);
  const [searchText, SetSearchText] = useState();
  const userContext = useContext(UserContext);
  // console.log('userContext ',userContext)
  // const location = useLocation();
  const [tryOurAppsModal, setTryOurAppsModal] = useState(false);

  const buttonClick = () => {
    addNotification({
      title: "Message",
      subtitle: "This is a subtitle",
      message: "New Project Created",
      theme: "darkblue",
      native: true,
    });
  };

  useEffect(() => {
    if (
      userContext.user.accountType != "Admin" &&
      userContext.user.accountType != "Owner"
    ) {
      // console.log(
      //   "not admin fetch only user assigned projects",
      //   userContext.user.accountType
      // );
      getProjectsByUser();
    } else {
      getProjects();
    }
    console.log('buttonClick userContext : ', userContext)
  }, [paginationCount]);

  const firstUpdate = useRef(true); //  this is to stop calling the search api for the first time and then debounce the api calls
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    let searchtimeout;
    if (
      userContext.user.accountType != "Admin" &&
      userContext.user.accountType != "Owner"
    ) {
      searchtimeout = setTimeout(() => {
        getUserProjectBySearch(searchText);
      }, 300);
    } else {
      searchtimeout = setTimeout(() => {
        getProjectBySearch(searchText); // debouncing the request not to stack up with api calls
      }, 400);
    }
    if (searchText) {
      SetShowLoadMore(false);
    } else {
      SetShowLoadMore(true);
    }
    return () => {
      clearTimeout(searchtimeout);
    };
  }, [searchText]);

  // useEffect(() => {
  //     getProjects();
  // }, [location.key])

  const setProjectsAccordingly = (res) => {
    if (res.status === 200) {
      if (res.payload.length != 10) {
        // console.log('here in th length cnd  not equal to 10')
        // {
        SetShowLoadMore(false);
        // }
      }

      setSearchInput("");

      if (paginationCount == 0) {
        let tempProjects = [...res.payload];
        let activeProjects = [];
        for (let index = 0; index < tempProjects.length; index++) {
          if (tempProjects[index].hasOwnProperty("isArchived")) {
            if (tempProjects[index]["isArchived"] == 0) {
              activeProjects.push(tempProjects[index]);
            } else {
            }
          } else {
            activeProjects.push(tempProjects[index]);
          }
        }
        setProjects(activeProjects);
        setCopyProjects(activeProjects);
        setSpinner(false);
      } else {
        let newTempProjects = [...res.payload];
        let activeProjects = [...projects];
        for (let index = 0; index < newTempProjects.length; index++) {
          if (newTempProjects[index].hasOwnProperty("isArchived")) {
            if (newTempProjects[index]["isArchived"] == 0) {
              activeProjects.push(newTempProjects[index]);
            }
          }
        }
        setProjects(activeProjects);
        setCopyProjects(activeProjects);
      }
    }
    setloadingnew(false);
  };

  const getProjectsByUser = () => {
    let temp = JSON.parse(userContext.user?.AssignedProjects);
    setloadingnew(true);
    // ProjectsObj.map(c => `'${c}'`).join(', ');
    let projectIdstobefetched = temp?.map((p) => `'${p.ProjectId}'`).join(",");
    console.log("assingned projects as a string : ", projectIdstobefetched);
    if (!projectIdstobefetched) {
      setSpinner(false);
      setloadingnew(false);
      SetShowLoadMore(false);
      // setProjects([])
      return;
    }
    projectService
      .getProjectsByUser(projectIdstobefetched, paginationCount)
      .then((res) => {
        setProjectsAccordingly(res);
      })
      .catch((e) => {
        setloadingnew(false);
        console.log(e);
      });
  };

  const getProjects = () => {
    setloadingnew(true);
    projectService
      .get10Projects(paginationCount)
      .then((res) => {
        setProjectsAccordingly(res);
      })
      .catch((e) => {
        setloadingnew(false);
        console.log(e);
      });
  };

  const getProjectBySearch = (text) => {
    projectService
      .searchProject(text)
      .then((res) => {
        if (res.status == 200) {
          setProjects(res.payload);
        } else {
          getProjects();
        }
      })
      .catch(() => {
        alert("somthing went wrong");
      });
  };

  const getUserProjectBySearch = (text) => {
    let temp = JSON.parse(userContext.user?.AssignedProjects);
    let projectIdstobeSearched = temp?.map((p) => `'${p.ProjectId}'`).join(",");
    projectService
      .searchUserProjects(text, projectIdstobeSearched)
      .then((res) => {
        if (res.status == 200) {
          setProjects(res.payload);
        } else {
          getProjectsByUser();
        }
      })
      .catch(() => {
        alert("somthing went wrong");
      });
  };

  const DuplicateCard = async (project) => {
    let clientName = project.ClientName;
    let clientNumber = project.ClientNumber;
    let address = project.Address;
    let amountReceived = JSON.parse(project.Finance).AmountReceived;
    let SiteEngineersId = project.SiteEngineersId;
    let rooms = JSON.parse(project.Rooms).map((room) => {
      return {
        ...room,
        Units: room.Units.map((unit) => {
          return {
            ...unit,
            Components: unit.Components.map((component) => {
              let _tempComponent = { ...component };
              _tempComponent["Work"] = _tempComponent.Work.map((work) => ({
                ...work,
                status: "",
                OrderId: new Date().getTime(),
              }));
              _tempComponent["Material"] = _tempComponent.Material.map(
                (material) => ({
                  ...material,
                  status: "",
                  OrderId: new Date().getTime(),
                })
              );
              return _tempComponent;
            }),
          };
        }),
      };
    });
    let duration = project.duration;

    const response = await projectService.addProject(
      clientName + "_Copy",
      clientNumber,
      address,
      amountReceived,
      SiteEngineersId,
      rooms,
      duration
    );
    console.log(response);
    if (response.status == 200) {
      updateFBRDB(response.payload);
      alert("Duplicate project created successfully!");
    } else {
      alert("Error while duplicating project!");
    }
    setPaginationCount(0);
  };

  const ArchiveCard = async (project) => {
    // write the code here
    console.log("project from Archive Card : ", project);
    console.log("We are trying to archive the project");
    fetch(`${config.projectService}${project.ProjectId}`, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isArchived: 1,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from archive API: ", json);
        console.log("projects from Archive Card : ", projects);
        if (json.status === 200) {
          toast.success("Project Archived Sucessfully!", {
            backgroundColor: "rgb(255, 211, 77)",
            color: "black",
          });
        }
        let newProjects = [];
        for (let index = 0; index < Object.keys(projects).length; index++) {
          // write the code here
          if (project.ProjectId == projects[index].ProjectId) {
            // write the code here
            console.log("Found Ids Equal");
            projects.splice(index, 1);
          } else {
            newProjects.push(projects[index]);
          }
        }
        setProjects(newProjects);
        console.log("newProjects : ", newProjects);
      })
      .catch((e) => {
        console.log("archive API catch: ", e.toString());
      });
  };

  const renderProjects = () => {
    return projects
      .sort((a, b) => b.lastOpen - a.lastOpen)
      .map((project, index) => {
        return (
          <ProjectRow
            key={index}
            project={project}
            DuplicateCard={DuplicateCard}
            ArchiveCard={ArchiveCard}
            load={load}
            firmName={userContext?.user?.Firm}
            userName={userContext?.user?.userName}
          />
        );
      });
  };

  const load = () => {
    // console.log('changing the losding state',!spinner)
    setSpinner((prev) => !prev);
  };

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ")
      .child(payload.ProjectId)
      .set({ ...payload, rooms: JSON.stringify(payload.Rooms) });

  const updateClientFBRDB = (payload) => {
    firebase
      .database()
      .ref(
        `clientUserDetails/${payload.ClientNumber}/projects/${payload.ProjectId}`
      )
      .set({
        projectId: payload.ProjectId,
        projectName: payload.ClientName,
      });
    let timeStamp = new Date().getTime();
    firebase
      .database()
      .ref(`clientDetails/${payload.ProjectId}/projectChatThreads/`)
      .set({
        ClinetFirmsMessages: {
          [timeStamp]: {
            id: timeStamp,
            text: "You have been added to the project",
            user: "firm",
          },
        },
        ClinetOverallMessages: {
          [timeStamp]: {
            id: timeStamp,
            text: "You have been added to the project",
            user: "firm",
          },
        },
        ClinetSEMessages: {
          [timeStamp]: {
            id: timeStamp,
            text: "You have been added to the project",
            user: "firm",
          },
        },

        FirmSiteEngineer: {
          [timeStamp]: {
            id: timeStamp,
            text: "You have been added to the project",
            user: "firm",
          },
        },
      });
  };

  const makeNewProject = async ({
    projectName,
    projectAccessNumber,
    projectAddress,
    seCode,
    additionalSEIds,
  }) => {
    console.log("this is called here");
    const authService = new AuthService();
    const seCodeValue = seCode.trim() === "" ? null : seCode;

    authService
      .getSiteEngineerById(`${seCodeValue}`)
      .then((res) => {
        if (res.status === 200 || seCodeValue === null) {
          const projectService = new ProjectService();
          projectService
            .addProject(
              projectName,
              projectAccessNumber,
              projectAddress,
              "",
              seCodeValue,
              [],
              0,
              additionalSEIds
                .filter((num) => num !== "")
                .map((num) => parseInt(num, 10))
            )
            .then((res1) => {
              if (res1.status === 200) {
                updateFBRDB(res1.payload);
                updateClientFBRDB(res1.payload);
                console.log("Project created successfully");
                updateClientFBRDB(res1.payload);
                toggleProjetDetailsModal(false);
                buttonClick();
                toast.success("Project created successfully", {
                  backgroundColor: "rgb(255, 211, 77)",
                  color: "black",
                });
                getProjects();
              }
            });
        } else {
          alert("Please Enter Registered SE");
          return;
        }
      })
      .catch(() => {
        alert("something went wrong");
      });
  };

  return (
    <>
      {spinner ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress style={{ color: "#fdd34d" }} />
        </div>
      ) : (
        <div id="DashboardPage">
          <div id="dpmain">
            <div id="dpmHeading">
              <div id="dpmhTitle">Your Projects</div>
              <div className="headerBtnsContainer">
                <Button1
                  invert
                  content="Try our apps"
                  onClick={() => {
                    setTryOurAppsModal(true);
                    // toggleProjetDetailsModal(true);
                    // record(TAGS["@new_project_button_click_success", { description: "New project button click success" }])
                  }}
                />
                {(userContext.user.accountType === "Admin" ||
                  userContext.user.accountType === "Owner") && (
                  <Button1
                    content="+ New Project"
                    onClick={() => {
                      toggleProjetDetailsModal(true);
                      record("@new_project_button_click_success", {
                        description: "New project button click success",
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div id="dpmFilterArea">
              <SearchBar
                onChange={(text) => {
                  SetSearchText(text);
                }}
              />
              <PopOverView
                position={"left"}
                component={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      fontSize: 12,
                      color: "grey",
                      alignItems: "flex-start",
                      cursor: "pointer",
                      width: 300,
                    }}>
                    <div>Stage</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                      }}>
                      {["ALL", "BOQ", "Purchase", "Archive"].map(
                        (tempFiler, index) => (
                          <div
                            key={index}
                            style={{
                              borderRadius: 10,
                              padding: "4px 12px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                            }}>
                            {tempFiler}
                          </div>
                        )
                      )}
                    </div>
                    <div>Date</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                      }}>
                      {["ALL", "Latest", "Last month", "Oldest"].map(
                        (tempFiler, index) => (
                          <div
                            key={index}
                            style={{
                              borderRadius: 10,
                              padding: "4px 12px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                            }}>
                            {tempFiler}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                }></PopOverView>
            </div>
            <div id="projectsHeader">
              <div>NAME</div>
              {/* <div>BUDGET</div>
                            <div>ACTUAL</div> */}
              <div style={{ maxWidth: "45px", marginLeft: 10 }}>LIVE</div>
              <div>STAGE</div>
              {/* <div>CURRENT USER</div> */}
              <div />
            </div>
            <div id="projectsRow">
              {projects.length > 0 ? (
                renderProjects()
              ) : userContext.user.accountType === "Admin" ||
                userContext.user.accountType === "Owner" ? (
                <p style={{ textAlign: "center", fontWeight: "700" }}>
                  Nothing to Show!
                </p>
              ) : (
                <p style={{ textAlign: "center", fontWeight: "700" }}>
                  No Projects Assingned! Please Contact Admin.
                </p>
              )}
              {isloadingnew && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CircularProgress style={{ color: "#fdd34d" }} />
                </div>
              )}
              {
                // projects.length >= 8 && showLoadMore &&
                showLoadMore && (
                  <div
                    className="projectRow"
                    style={{ cursor: "pointer" }}
                    onClick={() => setPaginationCount((st) => st + 10)}>
                    <div className="projectHeader">
                      <div>Load More</div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          {showProjectDetailsModal && (
            <ProjectDetailsModal
              onClose={() => toggleProjetDetailsModal(false)}
              onSave={makeNewProject}
            />
          )}
          {tryOurAppsModal && (
            <TryOurAppsModal
              tryOurAppsModal={tryOurAppsModal}
              setTryOurAppsModal={setTryOurAppsModal}
              // onClose={() => toggleProjetDetailsModal(false)}
              // onSave={makeNewProject}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DrawerHOC(DashboardPage);
