import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const BorderBottomInput = ({ width, value, onChange, placeholder }) => {
    const textareaRef = useRef(null);

    // Adjust the height of the textarea based on the content
    const handleInput = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto'; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set to the scrollHeight (content height)
    };

    useEffect(() => {
        handleInput(); // Adjust height when the value changes
    }, [value]);

    return (
        <textarea
            ref={textareaRef}
            value={value}
            onChange={(e) => {
                onChange(e);
                handleInput(); // Adjust height on user input
            }}
            placeholder={placeholder}
            style={{
                width: width,
                border: "none",
                borderBottom: "2px solid #ccc",
                outline: "none",
                padding: "0.5rem 0",
                fontSize: "1rem",
                textAlign: "center",
                transition: "border-bottom-color 0.3s",
                resize: "none", // Disables resizing of the textarea
                minHeight: "50px", // Minimum height for the textarea
                overflow: "hidden", // Hide overflow content, since scrollHeight is used to resize
            }}
            onFocus={(e) => (e.target.style.borderBottomColor = "#007BFF")}
            onBlur={(e) => (e.target.style.borderBottomColor = "#ccc")}
        />
    );
};

BorderBottomInput.propTypes = {
    width: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

BorderBottomInput.defaultProps = {
    placeholder: "Enter text...",
};

export default BorderBottomInput;
