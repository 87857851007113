import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa'; // Import a spinner icon from React Icons

const SaveButton = ({ onSave }) => {
    const [isSaving, setIsSaving] = useState(false);

    const buttonStyles = (backgroundColor) => ({
        backgroundColor,
        color: 'white',
        border: 'none',
        borderRadius: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: isSaving ? 'not-allowed' : 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
    });

    const LoadingSpinner = () => (
        <FaSpinner className="spinner" style={{ animation: 'spin 1s linear infinite' }} />
    );

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await onSave(); // Await the passed onSave function
        } finally {
            setIsSaving(false); // Ensure state resets even if an error occurs
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
            <button
                onClick={handleSave}
                style={buttonStyles('#1485AE')}
                disabled={isSaving}
            >
                {isSaving ? <LoadingSpinner /> : 'Save'}
            </button>
        </div>
    );
};

export default SaveButton;
