import React from "react";

const NoDataComponent = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#f8f9fa",
    padding: "48px 16px",
    textAlign: "center",
  };

  const cardStyle = {
    maxWidth: "480px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    padding: "32px",
    border: "1px solid #e9ecef",
  };

  const iconContainerStyle = {
    marginBottom: "24px",
  };

  const headingStyle = {
    fontSize: "24px",
    fontWeight: "700",
    color: "#9f7f15",
    marginBottom: "12px",
  };

  const descriptionStyle = {
    color: "#6c757d",
    marginBottom: "24px",
    fontSize: "16px",
  };

  const infoBoxStyle = {
    backgroundColor: "#f8f9fa",
    padding: "16px",
    borderRadius: "6px",
    border: "1px solid #e9ecef",
    textAlign: "left",
    marginBottom: "24px",
  };

  const infoHeadingStyle = {
    fontSize: "18px",
    fontWeight: "500",
    color: "#495057",
    marginBottom: "8px",
  };

  const listStyle = {
    color: "#6c757d",
    fontSize: "14px",
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "8px",
  };

  const bulletPointStyle = {
    color: "#4285f4",
    marginRight: "8px",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const buttonStyle = {
    padding: "8px 16px",
    backgroundColor: "#4285f4",
    color: "white",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "500",
    transition: "background-color 0.2s",
  };

  const footerStyle = {
    fontSize: "14px",
    color: "#6c757d",
    marginTop: "24px",
  };

  const linkStyle = {
    color: "#4285f4",
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <div style={iconContainerStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fdd34d"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ margin: "0 auto" }}
          >
            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
            <polyline points="13 2 13 9 20 9"></polyline>
          </svg>
        </div>
        
        <h2 style={headingStyle}>No Data Available</h2>
        
        <p style={descriptionStyle}>
          The requested information could not be found. Please ensure data has been properly uploaded or configured.
        </p>
        
      </div>
      
      <p style={footerStyle}>
        Need assistance? Please contact <span style={linkStyle}>kashyap@staart.co.in(+91-9800270888)</span>
      </p>
    </div>
  );
};

export default NoDataComponent;