import { secondDatabase } from "../../config/firebase";

const dealPOWithoutRequest = async (selectedItems, buildProjectId) => {
  // write the code here
  const localStorageData = JSON.parse(localStorage.getItem("User"));

  const tempFirmId = localStorageData.firmId;
  // const tempFirmId = '9000520144';

  const useRef = await secondDatabase.ref(`userDetails/${tempFirmId}`);

  useRef.once("value", function (snapshot) {
    let data = snapshot.val();

    if (data !== null) {
      
      let selectedProject = Object.values(data.projects).find((item) => {
        return item.buildProjectId === Number(buildProjectId);
      });

      let requestId = new Date().getTime();

      let itemObject = selectedItems.map((item) => ({
        category: item.type || "",
        checked: false,
        description: item.specification || "",
        heading: item.roomName || item.heading || "",
        itemDetails: item || "",
        orderId: item.OrderId || new Date().getTime(),
        quantity: item.quantity || "",
        status: "Released" || "",
        subHeding: item.unitName || item["Unit Name"] || item["UnitName"] || "",
        unit: item.unit || "",
      }));

      const newObject = itemObject.reduce((acc, item) => {
        acc[item.orderId] = item;
        return acc;
      }, {});

      let requestData = {
        id: requestId,
        dueDate: "",
        items: Object.values(newObject),
        requestHistory: {
          [requestId]: {
            id: requestId,
            name: localStorageData.userName || "",
            quantities: selectedItems.map((item) => item.quantity),
            remark: "",
            status: "Requested",
          },
          [requestId + 1]: {
            id: requestId,
            name: localStorageData.userName || "",
            quantities: selectedItems.map((item) => item.quantity),
            remark: "",
            status: "Approved",
          },
          [requestId + 2]: {
            id: requestId,
            name: localStorageData.userName || "",
            quantities: selectedItems.map((item) => item.quantity),
            remark: "",
            status: "Released",
          },
        },
        userName: localStorageData.userName,
      };

      secondDatabase
        .ref(
          // `1726057552427/MRNRequest/1726057643157/${requestData.id}/`
          `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${requestData.id}/`
        )
        .set(requestData);
    } else {
      // write the code if nothing found
    }
  });
};

export default dealPOWithoutRequest;
