import React, { useState } from "react";
import TopInfoBar from "./TopInfoBar";
import ItemsTable from "./ItemsTable";
import RequestHistoryModal from "./RequestHistoryModal";
import ImagePreviewModal from "./ImagePreviewModal";

const InwardCard = ({ itemData, project, userName }) => {
  const [showRequestHistory, setShowRequestHistory] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleRequestHistoryToggle = () =>
    setShowRequestHistory(!showRequestHistory);

  const handleImagePreviewToggle = (image) => {
    setSelectedImage(image);
    setShowImagePreview(!showImagePreview);
  };

  // Check if this is a request-converted record (has dueDate property)
  const isRequestRecord = itemData && "dueDate" in itemData;

  // Add firmId and projectId to the itemData for request-converted records
  if (isRequestRecord && project) {
    // Ensure we have the necessary IDs for database updates
    if (!itemData.firmId) {
      itemData.firmId = project.firmDetailsID;
    }
    if (!itemData.projectId) {
      itemData.projectId = project.projectID;
    }
  }

  // Prepare data for TopInfoBar based on record type
  const topInfoBarData = {
    fields: itemData.fields || {},
    inTime: itemData.inTime || "",
    outTime: itemData.outTime || "",
    itemData: itemData,
  };

  // Prepare data for ItemsTable based on record type
  const itemsTableData = {
    userName: userName,
    itemData: itemData,
    project: project,
    projectName: isRequestRecord
      ? project.projectName || ""
      : itemData.projectName || "",
    invoices: itemData.invoices || {},
    items: isRequestRecord ? itemData.items || [] : [],
    isRequestRecord: isRequestRecord,
    onViewMore: handleRequestHistoryToggle,
    onImageClick: handleImagePreviewToggle,
  };

  return (
    <div style={styles.cardContainer}>
      <TopInfoBar
        fields={topInfoBarData.fields}
        inTime={topInfoBarData.inTime}
        outTime={topInfoBarData.outTime}
        itemData={topInfoBarData.itemData}
        isRequestRecord={isRequestRecord}
      />
      <ItemsTable
        userName={itemsTableData.userName}
        itemData={itemsTableData.itemData}
        project={itemsTableData.project}
        projectName={itemsTableData.projectName}
        invoices={itemsTableData.invoices}
        items={itemsTableData.items}
        isRequestRecord={itemsTableData.isRequestRecord}
        onViewMore={itemsTableData.onViewMore}
        onImageClick={itemsTableData.onImageClick}
      />

      {showRequestHistory && (
        <RequestHistoryModal
          requestHistory={itemData.requestHistory}
          onClose={handleRequestHistoryToggle}
          onImageClick={handleImagePreviewToggle}
          isRequestRecord={isRequestRecord}
        />
      )}

      {showImagePreview && (
        <ImagePreviewModal
          image={selectedImage}
          onClose={() => setShowImagePreview(false)}
        />
      )}
    </div>
  );
};

const styles = {
  cardContainer: {
    border: "1px solid #aaa",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    width: "100%",
    boxSizing: "border-box",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
};

export default InwardCard;
