import { secondDatabase } from "../../config/firebase";

const fetchMaterial = async (firmId, boq) => {
  const useRef = secondDatabase.ref(`${firmId}/projectMasterFile/materials/`);

  return new Promise((resolve, reject) => {
    useRef.once(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        let newData = [];

        if (data !== null) {
          // Convert data to array and handle nested objects
          Object.keys(data).forEach((key) => {
            Object.entries(data[key]).forEach(([id, item]) => {
              if (item && item.category) {
                // Only add items with valid category
                if (boq) {
                  newData.push({
                    Id: parseInt(id),
                    category: item.category,
                    specification: item.specification || item.description,
                    unit: item.unit || item.uom,
                    rate: item.rate || "",
                  });
                } else {
                  newData.push({
                    Id: parseInt(id),
                    category: item.category,
                    specification: item.specification || item.description,
                    rate: item.rate || "",
                    unit: item.unit || item.uom,
                  });
                }
              }
            });
          });
        }

        // Remove duplicates based on Id
        newData = Array.from(
          new Map(newData.map((item) => [item.Id, item])).values()
        );

        // Sort by Id
        newData.sort((a, b) => a.Id - b.Id);

        // Filter out invalid entries
        newData = newData.filter(
          (item) =>
            item.category !== undefined &&
            item.specification !== undefined &&
            item.unit !== undefined
        );

        resolve(newData);
        console.log("Data fetched successfully", newData);
      },
      (error) => {
        console.error("Error fetching data:", error);
        reject(error);
      }
    );
  });
};

export default fetchMaterial;
