import React from 'react';

const RequestCompletionMessage = () => {
  const messageStyle = {
    fontSize: '18px',  // Reduced font size for a more compact message
    fontWeight: 'bold',
    color: '#696969',
    background: '#aeaeae',
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
    padding: '10px 20px',  // Reduced padding for better fit
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    animation: 'fadeIn 1s ease-out',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh',
    backgroundColor: '#f4f7fb',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '0 20px',  // Added horizontal padding for spacing
  };

  return (
    <div style={containerStyle}>
      <p style={messageStyle}>Requests Finished or No more data avaialable at this moment!</p>
    </div>
  );
};

export default RequestCompletionMessage;
