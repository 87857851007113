import { useState, useEffect, useContext } from "react";
import "./projectRow.scss";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import getVendorPrice from "../../helpers/getVendorPrice";
import getProjectStatus from "../../helpers/getProjectStatus";
import { UserContext } from "../../Context/UserContext";
import { useHistory } from "react-router";
import { PopOverView } from "../../components";
import { FiMoreVertical, FiChevronDown, FiChevronUp } from "react-icons/fi";
import OpportunityService from "../../api/opportunityService";
import ProjectService from "../../api/projectService";
import { useLocation } from "react-router-dom";
import firebase from "../../config/firebase";
import environment from "../../config/config";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import { toast } from "react-toast";
import { IconButton } from "@material-ui/core";

export default ({ project, DuplicateCard, ArchiveCard, load, firmName, userName }) => {
  const { record } = useAnalyticsContext();

  const history = useHistory();

  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;

  const [showContent, toggleContent] = useState(false);

  const [upcomingAmount, setupcomingAmount] = useState(0);
  const [ongoingAmount, setongoingAmount] = useState(0); // total PO's value
  const [completedAmount, setcompletedAmount] = useState(0);
  const [pettyCash, setPettyCash] = useState(0);
  const opportunityService = new OpportunityService();
  const { pathname } = useLocation();

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

  const updateFBRDBEditStatus = async (payload) => {
    // to set the currentUser as active user
    await firebase
      .database()
      .ref("liveBOQ/" + project.ProjectId + "/" + "editStatus/")
      // .child("editStatus")
      .set(payload);
  };
  const DateCreated = () => {
    let dateObj = new Date(project.ProjectId);
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let newdate = year + "/" + month + "/" + day;
    return newdate;
  };

  const lastOpen = new Date().getTime();

  const openProject = async () => {
    // load();
    const projectService = new ProjectService();
    await projectService.getProjectById(project.ProjectId).then((response) => {
      projectService
        .setProject({
          ProjectId: project.ProjectId,
          lastOpen,
          // currentUser: (response.payload[0].currentUser == null || response.payload[0].currentUser == 'null') ? userContext.user.email : response.payload[0].currentUser
        })
        .then(async (res) => {
          if (res.status == 200) {
            if (accountType == "Finance") {
              history.push("/Finance/" + project.ProjectId, {
                selectedProject: {
                  ...project,
                  rooms: JSON.parse(project.Rooms),
                },
              });
            } else {
              // await updateFBRDBEditStatus({isEditing : true, currentUser : userContext.user.email, lastEdited: Date.now(), });
              history.push("/ProjectPage/" + project.ProjectId);
              record("@project_page_open_success", {
                desccription: "project page open success",
              });
            }
          } else {
            alert(res.statusMsg);
          }
        })
        .catch((e) => {
          alert("Somthing went wrong!");
          console.log("setProject API catch: ", e);
        });
    });
    load();
  };

  useEffect(() => {
    if (showContent) {
      // console.log("runnin ght calc");
      // setupcomingAmount(0);
      // setongoingAmount(0);
      // setcompletedAmount(0);
      opportunityService
        .getOpportunitiesByProjectId(project.ProjectId)
        .then((res) => {
          if (res.status === 200) {
            let tempTotalAmount = 0;
            let tempTotalSpent = 0;
            res.servicesPayload?.forEach((item) => {
              // const data = JSON.parse(payload.Data).data;
              // payload?.forEach((item) => {
              // if (item.rate.length > 0 && item.quantity.length > 0) {
              //   const totalAmount =
              //     parseInt(item.rate) * parseInt(item.quantity);
              //   let tempcompletedAmount = 0;
              //   let tempupcomingAmount = 0;
              //   let tempongoingAmount = 0;
              //   // item.milestones?.forEach((milestone) => {
              //   //   if (milestone.paid && milestone.paid !== "Unpaid") {
              //   //     tempcompletedAmount =
              //   //       tempcompletedAmount +
              //   //       (totalAmount / 100) * parseInt(milestone.percentage);
              //   //   } else if (
              //   //     milestone.paid !== "Cash" &&
              //   //     milestone.paid !== "Online" &&
              //   //     !!milestone.status
              //   //   ) {
              //   //     tempongoingAmount =
              //   //       tempongoingAmount +
              //   //       (totalAmount / 100) * parseInt(milestone.percentage);
              //   //   } else if (!milestone.status && !milestone.paid) {
              //   //     tempupcomingAmount =
              //   //       tempupcomingAmount +
              //   //       (totalAmount / 100) * parseInt(milestone.percentage);
              //   //   }
              //   // });
              //   setupcomingAmount((amt) =>
              //     Math.round(amt + tempupcomingAmount)
              //   );
              //   setongoingAmount((amt) =>
              //     Math.round(amt + tempongoingAmount)
              //   );
              //   setcompletedAmount((amt) =>
              //     Math.round(amt + tempcompletedAmount)
              //   );
              // }

              const milestoneObj = JSON.parse(item["CommonMilestones"]) || [];
              tempTotalAmount += Number(item["OrderTotalAmount"]);
              for (let mindex = 0; mindex < milestoneObj.length; mindex++) {
                if (
                  milestoneObj[mindex].name !== "Paid" &&
                  milestoneObj[mindex].payments
                ) {
                  for (
                    let pindex = 0;
                    pindex < milestoneObj[mindex].payments.length;
                    pindex++
                  ) {
                    tempTotalSpent += Number(
                      milestoneObj[mindex].payments[pindex].amount
                    );
                  }
                }
              }
              tempTotalSpent = Number(
                tempTotalSpent +
                  (milestoneObj[0].name === "Paid"
                    ? Number(milestoneObj[0].totalAmount)
                    : 0)
              );
              // });
            });
            console.log(
              "CALCULATE THE AMOUNTS",
              tempTotalAmount,
              tempTotalSpent
            );
            // setupcomingAmount(tempTotalAmount);
            setongoingAmount(tempTotalAmount);
            setcompletedAmount(tempTotalSpent);
          }
        })
        .catch((e) => {
          console.log(e);
          //     setupcomingAmount(0);
          // setongoingAmount(0);
          // setcompletedAmount(0);
        });
    }
    //  else {
    //   setupcomingAmount(0);
    //   setongoingAmount(0);
    //   setcompletedAmount(0);
    // }
  }, [showContent]);

  return (
    // <div className="projectRow" key={project.ProjectId}>
    <div className="projectRow" key={project.ProjectId}>
      <div
        className="projectHeader"
        onClick={(e) => {
          load();
          // console.log('UserCOntext',userContext);
          firebase
            .database()
            .ref("liveBOQ/" + project.ProjectId.toString() + "/" + "editStatus")
            .once("value", (snapshot) => {
              //  console.log('Firebase SS', snapshot.val())
              if (snapshot.exists()) {
                let lastEditedtime = new Date(snapshot.val().lastEdited);
                // console.log('time edited',lastEditedtime,'currentTime',new Date())
                // console.log('the value',snapshot.val(),'!snapshot.val().isEditing',!snapshot.val().isEditing,'diff',(Math.abs((new Date() - lastEditedtime  ) / 1000 / 60)))
                if (
                  !snapshot.val().isEditing ||
                  Math.abs((new Date() - lastEditedtime) / 1000 / 60) > 11
                ) {
                  // console.log('opening the project ', (Math.abs((new Date() - lastEditedtime  ) / 1000 / 60)))
                  updateFBRDBEditStatus({
                    isEditing: false,
                    currentUser: null,
                    lastEdited: Date.now(),
                  });
                  openProject();
                } else if (
                  snapshot.val().currentUser === userContext.user.email
                ) {
                  // load();
                  toast.error(
                    `You Can't access You might have opened it another Tab. Please Close it First to access`,
                    {
                      backgroundColor: "red",
                      duration: 7000,
                      color: "black",
                    }
                  );
                } else {
                  // if(snapshot.val().lastEdited)
                  // console.log('timedff',Math.abs((new Date() - lastEditedtime  ) / 1000 / 60));
                  // if(Math.abs((new Date() - lastEditedtime  ) / 1000 / 60) > 11 ){
                  //   openProject();
                  // }
                  // else{
                  // console.log('not accesseble')
                  // load();
                  toast.error(
                    `You Can't access ${snapshot.val().currentUser} is Using.`,
                    {
                      backgroundColor: "red",
                      duration: 7000,
                      color: "black",
                    }
                  );
                  // }
                }
              } else {
                openProject(); // node is created after the inital open of any project // if opening for the first time after this feature directly open
                // console.log('node doesnot exist')
              }
            });
          load();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              visibility: `${
                userContext.user.accountType === "Admin" ||
                userContext.user.accountType === "Owner"
                  ? "visible"
                  : "hidden"
              }  `,
            }}
          >
            {showContent ? (
              <IconButton
                size="small"
                // className="view-po-link-fin-req"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleContent(false);
                }}
              >
                <FiChevronUp />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleContent(true);
                }}
              >
                <FiChevronDown />
              </IconButton>
            )}
          </div>
          <div>{project.ClientName}</div>
        </div>

        <div
          style={
            {
              color: "grey",
              border: "1px solid grey",
              padding: "3px 8px",
              borderRadius: "4px",
              maxWidth: "45px",
            }
            // : { color: "red", border: "1px solid red", padding: "3px 8px", borderRadius: "4px", maxWidth: "45px" }
          }
          onClick={(e) => {
            e.stopPropagation();
            if(environment.environment === "production"){
              window.open(
                `https://staartlive.netlify.app/?ProjectId=${project.ProjectId}&ProjectName=${project.ClientName}&firmName=${firmName}&userName=${userName}`
              );
            }else{
              window.open(
                `https://livestaart.netlify.app/?ProjectId=${project.ProjectId}&ProjectName=${project.ClientName}&firmName=${firmName}&userName=${userName}`
              );
            }
            
            record(
              TAGS[
                ("@live_button_click_success",
                { description: "Live button click success" })
              ]
            );
          }}
        >
          Live
        </div>

        <div
          style={
            getComponentsPrice({
              ...project,
              rooms: JSON.parse(project.Rooms),
            }) == 0
              ? {
                  textDecoration: "underline",
                  textDecorationColor: "Green",
                  textDecorationStyle: "solid",
                  textDecorationThickness: 3,
                  WebkitTextDecorationColor: "red",
                }
              : {
                  textDecoration: "underline",
                  textDecorationColor: "red",
                  textDecorationStyle: "solid",
                  textDecorationThickness: 3,
                  WebkitTextDecorationColor: "green",
                }
          }
        >
          {getComponentsPrice({
            ...project,
            rooms: JSON.parse(project.Rooms),
          }) == 0
            ? "BOQ"
            : getProjectStatus({
                ...project,
                rooms: JSON.parse(project.Rooms),
              })}
        </div>

        {/* <div>
                        {
                            (project.currentUser == 'null' || project.currentUser == null)
                                ? '-'
                                : project.currentUser
                        }
                    </div> */}

        <div
          onClick={(e) => {
            e.stopPropagation();
            record(
              TAGS[
                ("@duplicate_archive_menu_icon_click_success",
                { description: "Live button click success" })
              ]
            );
          }}
        >
          <PopOverView
            component={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  fontSize: 12,
                  color: "grey",
                  alignItems: "flex-start",
                }}
              >
                <div
                  className="activeItem"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    DuplicateCard(project);
                  }}
                >
                  Duplicate
                </div>
                {userContext.user.accountType === "Admin" && (
                  <div
                    className="activeItem"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      ArchiveCard(project);
                    }}
                  >
                    Archive
                  </div>
                )}
              </div>
            }
          >
            <FiMoreVertical size={18} />
          </PopOverView>
        </div>
      </div>
      {showContent && (
        <div
          className="projectDetails"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flex: 1,
              }}
            >
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Attendance:----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Inventory: ----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Date Created:{DateCreated()}
              </div>
            </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flex: 1,
            }}
          >
            <div style={{ flex: 1, fontSize: "15px", fontWeight: "600" }}>
              Total Po Value: {ongoingAmount.toFixed(0)}
            </div>
            <div style={{ flex: 1, fontSize: "15px", fontWeight: "600" }}>
              Paid Amount: {completedAmount.toFixed(0)}
            </div>
            <div style={{ flex: 1, fontSize: "15px", fontWeight: "600" }}>
              Balance :
              <span
                style={{
                  color: "darkseagreen",
                }}
              >
                {(ongoingAmount - completedAmount).toFixed(0)}
              </span>
            </div>
          </div>
          {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flex: 1,
              }}
            >
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Po Accepted: ----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Po Waiting:----
              </div>
              <div
                style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}
              ></div>
            </div> */}
        </div>
      )}
    </div>
    // </div>
  );
};
