import { secondDatabase } from "../../config/firebase";

const writeBuildProjectId = async (selectedProject) => {
  try {
    // Reference to the database path
    const dbRef = secondDatabase.ref(
      `${selectedProject.firmDetailsId}/projectsUserDetails/${selectedProject.projectId}`
    );

    // Fetch the snapshot of the data at the reference
    const snapshot = await dbRef.once("value");

    if (snapshot.exists()) {
      // Retrieve the keys (user IDs) from the snapshot
      const addedIds = Object.keys(snapshot.val());

      // Perform all asynchronous updates sequentially using Promise.all
      const updatePromises = addedIds.map((userId) =>
        secondDatabase
          .ref(`userDetails/${userId}/projects/${selectedProject.projectId}/buildProjectId`)
          .set(selectedProject.projectId)
      );

      await Promise.all(updatePromises); // Wait for all updates to complete
    }
  } catch (error) {
    console.error("Error writing build project ID:", error);
  }
};

export default writeBuildProjectId;
