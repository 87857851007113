function checkDateExists(dateString, projectData) {
    // Convert input date to the format used in keys (dateStamp:dd-mm-yyyy)
    const dateKey = `dateStamp:${dateString}`;
    
    // Helper function to recursively search through object
    function searchObject(obj) {
        if (!obj || typeof obj !== 'object') {
            return false;
        }

        // Check if the current object has the dateKey
        if (Object.keys(obj).includes(dateKey)) {
            return true;
        }

        // Recursively search through all values
        for (let value of Object.values(obj)) {
            if (typeof value === 'object' && value !== null) {
                if (searchObject(value)) {
                    return true;
                }
            }
        }

        return false;
    }

    // Start the search from the root of projectData
    return searchObject(projectData);
}

export default checkDateExists;

