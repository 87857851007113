import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import NoData from "./Components/NoData";

const columnWidths = {
  projectName: "12%",
  invoiceNumber: "10%",
  name: "10%",
  poNumber: "12%",
  purpose: "18%",
  quantity: "8%",
  status: "8%",
  timestamp: "12%",
  entryBy: "8%",
  actions: "4%",
};

const MaterialDetails = ({ material }) => {
  const [materials, setMaterials] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  useEffect(() => {
    if (material) {
      if (material.isAllSites) {
        // All sites view - history details is already an array
        setMaterials(material.historyDetails);
      } else {
        // Single project view - convert object to array
        setMaterials(
          Object.entries(material.historyDetails || {})
            .map(([timestamp, entry]) => ({
              ...entry,
              timeStamp: timestamp,
              quantity: parseFloat(entry.quantity),
            }))
            .sort((a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp))
        );
      }
    }
  }, [material]);

  const handleEdit = (material) => {
    setSelectedMaterial(material);
    setOpenDialog(true);
  };

  const handleDelete = (poNumber) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      setMaterials(
        materials.filter((material) => material.poNumber !== poNumber)
      );
    }
  };

  const handleSave = (updatedMaterial) => {
    setMaterials(
      materials.map((material) =>
        material.poNumber === updatedMaterial.poNumber
          ? updatedMaterial
          : material
      )
    );
    setOpenDialog(false);
  };

  const formatTimestamp = (timestamp) => {
    return new Date(parseInt(timestamp)).toLocaleString();
  };

  const commonCellStyles = {
    whiteSpace: "normal",
    wordWrap: "break-word",
    padding: "16px 8px",
    fontSize: "0.875rem",
  };

  const headerCellStyles = {
    ...commonCellStyles,
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    color: "#1a237e",
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2 style={styles.materialHeader}>
        {material.category} | {material.name} | Current Quantity:{" "}
        {typeof material.quantity === "number"
          ? material.quantity.toFixed(2)
          : material.quantity}{" "}
        {material.unit}
        {material.isAllSites && " (Across All Sites)"}
      </h2>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 100px)" }}>
          <Table stickyHeader sx={{ width: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                {material.isAllSites && (
                  <TableCell
                    sx={{
                      ...headerCellStyles,
                      width: columnWidths.projectName,
                    }}
                  >
                    Project Name
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.invoiceNumber,
                    zIndex: 1,
                  }}
                >
                  Invoice Number
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.poNumber,
                  }}
                >
                  PO Number
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.purpose,
                  }}
                >
                  {material.category === "Fuel" && `Vehicle Tag | `}Purpose
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.quantity,
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.status,
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.entryBy,
                  }}
                >
                  Entry By
                </TableCell>
                <TableCell
                  sx={{
                    ...headerCellStyles,
                    width: columnWidths.actions,
                    position: "sticky",
                    right: 0,
                    zIndex: 2,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {materials.length > 0 ? (
                materials.map((entry, index) => (
                  <TableRow
                    key={`${entry.poNumber}-${index}`}
                    sx={{
                      backgroundColor:
                        entry.status === "Inward" ? "#e8f5e9" : "#ffebee",
                      "&:hover": {
                        backgroundColor:
                          entry.status === "Inward" ? "#c8e6c9" : "#ffcdd2",
                      },
                    }}
                  >
                    {material.isAllSites && (
                      <TableCell sx={commonCellStyles}>
                        {entry.projectName}
                      </TableCell>
                    )}
                    <TableCell sx={commonCellStyles}>
                      {entry.invoiceNumber !== "Not provided"
                        ? entry.invoiceNumber
                        : "-"}
                    </TableCell>
                    <TableCell sx={commonCellStyles}>
                      {entry.poNumber !== "Not provided" ? entry.poNumber : "-"}
                    </TableCell>
                    <TableCell sx={commonCellStyles}>
                      <span style={{ fontWeight: "bold" }}>
                        {entry.vehicleType && `${entry.vehicleType} | `}
                      </span>
                      {entry.purpose}
                    </TableCell>
                    <TableCell sx={commonCellStyles}>
                      {entry.quantity}
                    </TableCell>
                    <TableCell sx={commonCellStyles}>{entry.status}</TableCell>
                    <TableCell sx={commonCellStyles}>{entry.name}</TableCell>
                    <TableCell
                      sx={{
                        ...commonCellStyles,
                        position: "sticky",
                        right: 0,
                        backgroundColor:
                          entry.status === "Inward" ? "#e8f5e9" : "#ffebee",
                        "&:hover": {
                          backgroundColor:
                            entry.status === "Inward" ? "#c8e6c9" : "#ffcdd2",
                        },
                        padding: "8px 4px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(entry)}
                        sx={{ padding: "8px" }}
                      >
                        <EditIcon fontSize="small" color="primary" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(entry.poNumber)}
                        sx={{ padding: "8px" }}
                      >
                        <DeleteIcon fontSize="small" color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <NoData />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Material Entry</DialogTitle>
        <DialogContent>
          {selectedMaterial && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px",
              }}
            >
              <TextField
                label="Invoice Number"
                defaultValue={selectedMaterial.invoiceNumber}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    invoiceNumber: e.target.value,
                  })
                }
              />
              <TextField
                label="Name"
                defaultValue={selectedMaterial.name}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    name: e.target.value,
                  })
                }
              />
              <TextField
                label="Purpose"
                defaultValue={selectedMaterial.purpose}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    purpose: e.target.value,
                  })
                }
              />
              <TextField
                label="Quantity"
                defaultValue={selectedMaterial.quantity}
                onChange={(e) =>
                  setSelectedMaterial({
                    ...selectedMaterial,
                    quantity: e.target.value,
                  })
                }
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSave(selectedMaterial)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = {
  materialHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#2f4858",
  },
};

export default MaterialDetails;
