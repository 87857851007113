import * as XLSX from "xlsx";
import { environment } from "../../config/config";

const processSheet = async (formData) => {
  let apiUrl =
    environment === "production"
      ? "https://us-central1-charming-shield-300804.cloudfunctions.net/boqtoexcel"
      : "https://us-central1-charming-shield-300804.cloudfunctions.net/BoqToExcelStg";
  try {
    const response = await fetch(
      `${apiUrl}`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      console.log("response is not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error processing sheet:", error);
    throw error;
  }
};

export const processWorkbook = async (
  workbook,
  apiKey,
  callbacks,
  setResultCount
) => {
  const { onProgress, onStart, onError, setTotalSheets } = callbacks;

  const sheetNames = workbook.SheetNames;
  setTotalSheets(sheetNames.length);

  // Process all sheets in parallel
  const promises = sheetNames.map(async (sheetName) => {
    onStart(sheetName);

    try {
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        newWorkbook,
        XLSX.utils.aoa_to_sheet(sheetData),
        sheetName
      );
      const sheetFile = XLSX.write(newWorkbook, {
        type: "array",
        bookType: "xlsx",
      });
      const blob = new Blob([sheetFile], { type: "application/octet-stream" });

      const formData = new FormData();
      formData.append("file", blob, "sheet.xlsx");
      formData.append("sheet_name", sheetName);
      formData.append("api_key", apiKey);

      const result = await processSheet(formData);
      onProgress(sheetName, result);
      setResultCount((prevState) => prevState + 1);
      return { sheetName, result };
    } catch (error) {
      onError(sheetName, error);
      return { sheetName, error };
    }
  });

  // Return Promise.all to handle all sheets
  return Promise.all(promises);
};

export default processWorkbook;
