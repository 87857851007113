import React, { useMemo } from "react";

const RenderSharedStatus = ({
  siteStatusData,
  numLoading = false
}) => {
  console.log('RenderSharedStatus siteStatusData : ', JSON.stringify(siteStatusData));
  
  // Normalize status values to lowercase for consistent comparison
  const normalizedData = useMemo(() => {
    const normalized = {};
    Object.entries(siteStatusData).forEach(([site, dateData]) => {
      normalized[site] = {};
      Object.entries(dateData).forEach(([date, status]) => {
        normalized[site][date] =
          typeof status === "string" ? status.toLowerCase() : status;
      });
    });
    return normalized;
  }, [siteStatusData]);

  const dates = useMemo(() => {
    const allDates = new Set();

    Object.values(normalizedData).forEach((siteData) => {
      Object.keys(siteData).forEach((date) => {
        allDates.add(date);
      });
    });

    return Array.from(allDates).sort((a, b) => {
      const [dayA, monthA, yearA] = a.split("/").map(Number);
      const [dayB, monthB, yearB] = b.split("/").map(Number);
      if (yearA !== yearB) return yearA - yearB;
      if (monthA !== monthB) return monthA - monthB;
      return dayA - dayB;
    });
  }, [normalizedData]);

  const getStatusColor = (status) => {
    // Modern color palette
    const baseColors = {
      "n/a": "#f0f2f5",
      "yes": "#69aa38",
      "no": "#ffebee",
      "default": "#f5f5f5"
    };
    
    // If numLoading is true, make colors more dull/faded
    const opacity = numLoading ? 0.5 : 1;
    
    if (!status || status === "n/a") 
      return numLoading ? "rgba(240, 242, 245, 0.5)" : baseColors["n/a"];
    if (status === "yes") 
      return numLoading ? "rgba(227, 242, 253, 0.5)" : baseColors["yes"];
    if (status === "no") 
      return numLoading ? "rgba(255, 235, 238, 0.5)" : baseColors["no"];
    
    return numLoading ? "rgba(245, 245, 245, 0.5)" : baseColors["default"];
  };

  const getStatusIcon = (status) => {
    if (!status || status === "n/a") return "—";
    if (status === "yes") return "✓";
    if (status === "no") return "✗";
    return status;
  };

  const statusCounts = useMemo(() => {
    const counts = {};
    dates.forEach((date) => {
      let yesCount = 0;
      let noCount = 0;
      Object.values(normalizedData).forEach((siteData) => {
        if (siteData[date] === "yes") yesCount++;
        if (siteData[date] === "no") noCount++;
      });
      counts[date] = { yes: yesCount, no: noCount };
    });
    return counts;
  }, [normalizedData, dates]);

  const siteCount = Object.keys(siteStatusData).length;

  const styles = {
    container: {
      overflowX: "auto",
      overflowY: "auto",
      maxHeight: "100vh",
      fontFamily: 'Inter, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif',
      borderRadius: "12px",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      border: "1px solid #edf2f7",
      opacity: numLoading ? 0.8 : 1,
      background: "#ffffff",
    },
    table: {
      width: "100%",
      backgroundColor: "white",
      borderCollapse: "separate",
      borderSpacing: 0,
      borderRadius: "12px",
    },
    headerRow: {
      backgroundColor: numLoading ? "rgba(249, 250, 251, 0.5)" : "#f9fafb",
      position: "sticky",
      top: 0,
      zIndex: 10,
    },
    headerCell: {
      padding: "16px",
      borderBottom: "1px solid #edf2f7",
      fontWeight: 600,
      color: numLoading ? "rgba(55, 65, 81, 0.5)" : "#374151",
      textAlign: "center",
      fontSize: "14px",
      whiteSpace: "nowrap",
      transition: "background-color 0.2s ease",
    },
    siteNameCell: {
      padding: "16px",
      borderBottom: "1px solid #edf2f7",
      fontWeight: 700,
      textAlign: "left",
      position: "sticky",
      left: 0,
      backgroundColor: numLoading ? "rgba(255, 255, 255, 0.5)" : "white",
      zIndex: 5,
      boxShadow: "2px 0 8px rgba(0, 0, 0, 0.03)",
      color: "#353535",
    },
    dataCell: {
      padding: "12px",
      borderBottom: "1px solid #edf2f7",
      textAlign: "center",
      fontWeight: 500,
      transition: "background-color 0.2s ease",
    },
    evenRow: {
      backgroundColor: numLoading ? "rgba(255, 255, 255, 0.5)" : "white",
    },
    oddRow: {
      backgroundColor: numLoading ? "rgba(249, 250, 251, 0.5)" : "#f9fafb",
    },
    countBadge: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px 8px",
      borderRadius: "20px",
      fontSize: "12px",
      fontWeight: 500,
      marginLeft: "5px",
      opacity: numLoading ? 0.5 : 1,
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    },
    yesBadge: {
      backgroundColor: numLoading ? "rgba(209, 250, 229, 0.5)" : "#d1fae5",
      color: numLoading ? "rgba(6, 95, 70, 0.5)" : "#065f46",
    },
    noBadge: {
      backgroundColor: numLoading ? "rgba(254, 226, 226, 0.5)" : "#fee2e2", 
      color: numLoading ? "rgba(153, 27, 27, 0.5)" : "#991b1b",
    },
    statusIcon: {
      fontWeight: "bold",
      opacity: numLoading ? 0.6 : 1,
    },
    legend: {
      display: "flex",
      padding: "12px 16px",
      backgroundColor: numLoading ? "rgba(249, 250, 251, 0.5)" : "#f9fafb",
      borderRadius: "8px",
      margin: "12px 16px",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
    },
    legendItem: {
      display: "flex",
      alignItems: "center",
      marginRight: "16px",
      fontSize: "14px",
      opacity: numLoading ? 0.7 : 1,
    },
    legendColor: {
      width: "16px",
      height: "16px",
      marginRight: "8px",
      borderRadius: "4px",
      border: "1px solid rgba(0,0,0,0.05)",
    },
    headerDate: {
      display: "block",
      marginBottom: "8px",
      fontWeight: 600,
    },
    dateText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
    },
    tableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      borderBottom: "1px solid #edf2f7",
    },
    tableTitle: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#111827",
    },
    tableWrapper: {
      padding: "8px",
    },
    statusCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "32px",
      width: "32px",
      margin: "0 auto",
      borderRadius: "50%",
      fontSize: "14px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.tableHeader}>
        <div style={styles.tableTitle}>NMR Share Status Dashboard</div>
        <div style={styles.legend}>
          <div style={styles.legendItem}>
            <div
              style={{
                ...styles.legendColor,
                backgroundColor: getStatusColor("yes"),
              }}
            ></div>
            <span>Yes (✓)</span>
          </div>
          <div style={styles.legendItem}>
            <div
              style={{
                ...styles.legendColor,
                backgroundColor: getStatusColor("no"),
              }}
            ></div>
            <span>No (✗)</span>
          </div>
          <div style={styles.legendItem}>
            <div
              style={{
                ...styles.legendColor,
                backgroundColor: getStatusColor("n/a"),
              }}
            ></div>
            <span>N/A (—)</span>
          </div>
        </div>
      </div>
      
      <div style={styles.tableWrapper}>
        <table style={styles.table}>
          <thead>
            <tr style={styles.headerRow}>
              <th style={{...styles.headerCell, textAlign: "left", borderRight: "1px solid #edf2f7"}}>
                Site
              </th>
              {dates.map((date) => (
                <th key={date} style={styles.headerCell}>
                  <span style={styles.headerDate}>{date}</span>
                  <div style={styles.dateText}>
                    <span
                      style={{ ...styles.countBadge, ...styles.yesBadge }}
                      title={`${statusCounts[date].yes} sites reported 'Yes'`}
                    >
                      {statusCounts[date].yes}
                    </span>
                    <span
                      style={{ ...styles.countBadge, ...styles.noBadge }}
                      title={`${statusCounts[date].no} sites reported 'No'`}
                    >
                      {statusCounts[date].no}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(normalizedData).map(
              ([siteName, dateStatuses], index) => (
                <tr
                  key={siteName}
                  style={index % 2 === 0 ? styles.evenRow : styles.oddRow}
                >
                  <td
                    style={{
                      ...styles.siteNameCell,
                      ...(index % 2 !== 0 ? { backgroundColor: numLoading ? "rgba(249, 250, 251, 0.5)" : "#f9fafb" } : {}),
                    }}
                  >
                    {siteName}
                  </td>
                  {dates.map((date) => {
                    const status = dateStatuses[date] || "n/a";
                    return (
                      <td
                        key={`${siteName}-${date}`}
                        style={{
                          ...styles.dataCell,
                        }}
                      >
                        <div style={{
                          ...styles.statusCell,
                          backgroundColor: getStatusColor(status),
                        }}>
                          <span style={styles.statusIcon}>
                            {getStatusIcon(status)}
                          </span>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RenderSharedStatus;