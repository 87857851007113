import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const exportJsonToExcel = async (jsonData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Exported Data");

  // Extracting the dates dynamically from the first entry
  const siteNames = Object.keys(jsonData);
  const dates = Object.keys(jsonData[siteNames[0]]);

  // Define headers
  worksheet.addRow(["Site Name", ...dates]);

  // Populate data
  siteNames.forEach((site) => {
    const row = [site];
    dates.forEach((date) => {
      row.push(jsonData[site][date] || "No");
    });
    worksheet.addRow(row);
  });

  // Style headers
  worksheet.getRow(1).font = { bold: true };
  worksheet.columns.forEach((column) => {
    column.width = 15;
  });

  // Generate and download Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, "ExportedData.xlsx");
};

export default exportJsonToExcel;
