const createNewMaterial = async (requestData2, item2, materiIndex) => {
    // write the code here
    let itemToreturn = [];
    let requestData = {...requestData2};
    let item = {...item2};
    Object.keys(requestData.items).forEach((key) => {
        const requestItem = requestData.items[key];
        if (requestItem.itemDetails.OrderId === item.OrderId) {

            let requestedQuantity = Number(requestItem.quantity);
            let maximumQuantity = Number(requestItem.itemDetails.maxQuantity) || requestedQuantity;

            if(requestedQuantity < maximumQuantity){
                item.quantity = String(maximumQuantity - requestedQuantity);
            }

            itemToreturn =  [item];
        }
      });
      return itemToreturn.length > 0 ? itemToreturn[0] : null;
}

export default createNewMaterial;