import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

const ImageWithLoader = ({ src, alt, style = {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  // Check if the image is a base64 signature
  const isSignature = typeof src === "string" && src.startsWith("data:image");

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {isLoading && !hasError && (
        <FaSpinner
          size={50}
          style={{
            animation: "spin 1s linear infinite",
            position: "absolute",
          }}
        />
      )}

      {!hasError ? (
        <img
          src={src}
          alt={alt}
          style={{
            maxHeight: "80vh",
            maxWidth: "80vw",
            borderRadius: isSignature ? "0" : "10px",
            display: isLoading ? "none" : "block",
            ...style,
          }}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      ) : (
        <div
          style={{ textAlign: "center", color: isSignature ? "#333" : "#fff" }}
        >
          <p>Error loading image</p>
          {isSignature && (
            <p style={{ fontSize: "12px" }}>
              The signature data may be corrupted or in an unsupported format.
            </p>
          )}
        </div>
      )}

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default ImageWithLoader;
