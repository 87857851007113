import { secondDatabase } from "../../../config/firebase";

const updateRequestStatus = async (path, data) => {
    console.log("updateRequestStatus is called...", secondDatabase);
  
    try {
      // Use .ref() and .set() as per the legacy Firebase SDK
      await secondDatabase.ref(path).set(data);
      console.log("Successfully written into the database");
    } catch (error) {
      console.error("Error writing to the database:", error);
    }
  };
  
  export default updateRequestStatus;
  