import React from 'react';
import { Tooltip } from '@mui/material';
import { CiExport } from 'react-icons/ci';

const ExportButton = ({ data }) => {
  return (
    <Tooltip title="Export to Excel">
      <button onClick={() => console.log('user pressed the icon...')} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
        <CiExport size={24} />
      </button>
    </Tooltip>
  );
};

export default ExportButton;
