import React, { useState } from "react";
import TopHeader from "./TopHeader";
import ProgressBar from "./ProgressBar";
import ActionButtons from "./ActionButtons";
import RemarkWithHistory from "./RemarkWithHistory";
import updateRequestStatus from "../Helpers/updateRequestStatus";
import { CgFileDocument } from "react-icons/cg";
import toast from "react-hot-toast";
import BorderBottomInput from "./BorderBottomInput";
import { LuHistory } from "react-icons/lu";
import EditHistory from "./EditHistory";
import { useHistory } from "react-router-dom";
import firebase, { updateValues } from "../../../config/firebase";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { MdBlock } from "react-icons/md";
import fetchIndexcesForAddNewItem from "../../../helpers/fetchIndexcesForAddNewItem";

const SingleComponent = ({
  Editing,
  index2,
  category,
  heading,
  subHeading,
  description,
  quantity,
  unit,
  checked,
  status,
  maxQuantity,
  index,
  items,
  setItems,
  checkedIndex,
  setCheckedIndex,
  checkedSet,
  setCheckedSet,
  editedHistory,
  setEditedHistory
}) => {
  const findNewStatus = (oldStatus) => {
    console.log("oldStatus : ", oldStatus);
    let newStatus;
    if (oldStatus === "Approved") {
      newStatus = "Released";
    } else if (oldStatus === "Released") {
      newStatus = "Reached";
    } else {
      newStatus = "Approved";
    }
    console.log("newStatus : ", newStatus);
    return newStatus;
  };

  const findOldStatus = (oldStatus) => {
    let newStatus;
    if (oldStatus === "Approved") {
      newStatus = "Requested";
    } else if (oldStatus === "Released") {
      newStatus = "Approved";
    } else {
      newStatus = "Requested";
    }
    return newStatus;
  };

  const getBackgroundColor = (status, checked, checkedSet) => {
    if (checkedSet === null) {
      if (status === "Approved" && checked) return "#ebffee";
      if (status === "Released" && checked) return "#e0f7ff";
      return null;
    }
    if (status !== checkedSet && checked) return "#ebffeb";
    if (status !== checkedSet && checked) return "#e9ff45";
    if (status === "Approved" && checked) return "#ebffee";
    if (status === "Released" && checked) return "#e0f7ff";
    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "98%",
        marginTop: 10,
        border: "1px solid #ccc",
        padding: "16px",
        borderRadius: "4px",
        marginBottom: "16px",
        margin: 10,
        cursor: "pointer",
        opacity:
          status !== checkedSet && !checked && checkedSet !== null ? 0.4 : 1,
        backgroundColor: getBackgroundColor(status, checked, checkedSet),
      }}
    >
      {checked && checkedIndex === index ? (
        <CheckCircleIcon
          style={{
            fontSize: "35px",
            marginRight: 10,
            cursor: "pointer",
            color:
              status === "Reached"
                ? "#828282"
                : status === "Approved"
                ? "#00D226"
                : status === "Released"
                ? "#1485AE"
                : "#FA722F",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setItems((prevState) => {
              // const newState = [...prevState];
              const newState = [...JSON.parse(JSON.stringify(prevState))];
              const updatedItem = { ...newState[index] };
              updatedItem["items"][index2]["checked"] = false;

              updatedItem["items"][index2]["status"] = findOldStatus(
                updatedItem["items"][index2]["status"]
              );
              newState[index] = updatedItem;
              return newState;
            });
          }}
        />
      ) : checkedIndex === null ? (
        <RadioButtonUncheckedIcon
          style={{
            fontSize: "25px",
            marginRight: 10,
            cursor: "pointer",
            opacity: status !== "Released" && status !== "Reached" ? 1 : 0,
            color:
              status === "Reached"
                ? "#828282"
                : status === "Approved"
                ? "#00D226"
                : status === "Released"
                ? "#1485AE"
                : "#FA722F",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setItems((prevState) => {
              const newState = [...JSON.parse(JSON.stringify(prevState))];
              const updatedItem = { ...newState[index] };
              updatedItem["items"][index2]["checked"] = true;
              updatedItem["items"][index2]["status"] = findNewStatus(
                updatedItem["items"][index2]["status"]
              );
              // setCheckedSet(updatedItem["items"][index2]["status"]);

              newState[index] = updatedItem;
              return newState;
            });

            setCheckedSet(() => (status === true ? "Requested" : status));

            setCheckedIndex(index);
          }}
        />
      ) : checkedIndex === index ? (
        checkedSet === status ? (
          <RadioButtonUncheckedIcon
            style={{
              fontSize: "25px",
              marginRight: 10,
              cursor: "pointer",
              opacity: status !== "Released" ? 1 : 0,
              color:
                status === "Reached"
                  ? "#828282"
                  : status === "Approved"
                  ? "#00D226"
                  : status === "Released"
                  ? "#1485AE"
                  : "#FA722F",
            }}
            onClick={(event) => {
              event.stopPropagation();

              status = status === true ? "Requested" : status;

              if (checkedSet !== status) {
                return;
              }

              setItems((prevState) => {
                const newState = [...JSON.parse(JSON.stringify(prevState))];

                const updatedItem = { ...newState[index] };

                updatedItem["items"][index2]["checked"] = true;

                updatedItem["items"][index2]["status"] = findNewStatus(
                  updatedItem["items"][index2]["status"]
                );

                newState[index] = updatedItem;

                return newState;
              });

              setCheckedSet(() => (status === true ? "Requested" : status));
              setCheckedIndex(index);
            }}
          />
        ) : (
          <span
            title="This is a tooltip"
            style={{
              display: "inline-block",
              marginRight: 10,
              cursor: "pointer",
            }}
          >
            <MdBlock size={25} color={"#353535"} />
          </span>
        )
      ) : null}

      <div
        style={{
          width: "40%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ fontWeight: "500", marginBottom: 3 }}>
          {`${heading} | ${subHeading} | ${category}`}
        </p>
        <p>{description.replaceAll("<single-quote>", "'")}</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "60%",
        }}
      >
        <div
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "400" }}>{unit}</p>
        </div>
        {!Editing ? (
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "400" }}>{quantity}</p>
          </div>
        ) : (
          <BorderBottomInput
            width="25%"
            value={quantity}
            onChange={(e) => {

              let tempItems = { ...items };
              let editableKey = Object.keys(items.items)[index];
              
              if(editedHistory.hasOwnProperty(String(editableKey))){

                editedHistory[String(editableKey)]['updateValues'] = e.target.value;

              }else{
                
                editedHistory[String(editableKey)] = {
                  field : 'quantity',
                  itemDescription: description,
                  previousValue: tempItems.items[editableKey]["quantity"],
                  timestamp: new Date().toISOString(),
                  updatedBy: '',
                  updateValues: e.target.value
                }

              }

              tempItems.items[editableKey]["quantity"] = e.target.value;
              tempItems.items[editableKey]["partApproved"] = true;
              setItems(tempItems);
              
            }}
            placeholder="Enter you quantity"
          />
        )}
        <div
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "400" }}>{maxQuantity}</p>
        </div>
        <div
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              // opacity: 0.4,
              padding: 5,
              fontSize: 16,
              borderRadius: 10,
              fontWeight: "600",
              color: `${
                status === "Reached"
                  ? "#828282"
                  : status === "Approved"
                  ? "#00D226"
                  : status === "Released"
                  ? "#1485AE"
                  : "#FA722F"
              }`,
            }}
          >{`${checked !== true ? status : `to be ${status}`}`}</p>
        </div>
      </div>
    </div>
  );
};

const SingleItem = ({
  index,
  selectedProject,
  item,
  Editing,
  userName,
  setItems,
  roleType,
  updateRequestStatusLocally,
  checkedIndex,
  setCheckedIndex,
  editedHistory,
  setEditedHistory
}) => {
  const [actionOccured, setActionOccured] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);
  const [showEditHistory, setEditHistory] = useState(false);
  const [checkedSet, setCheckedSet] = useState(null);
  const history = useHistory();

  const handleViewMoreClick = () => {
    setShowAllItems(true);
  };

  function replaceObjectById(array, newObject) {
    return array.map((item) => (item.id === newObject.id ? newObject : item));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #FCD24E",
        borderRadius: 10,
        marginBottom: 25,
      }}
    >
      <TopHeader
        index={index}
        selectedProject={selectedProject}
        item={item}
        Editing={Editing}
        userName={userName}
        setItems={setItems}
        roleType={roleType}
        updateRequestStatus={updateRequestStatusLocally}
        checkedIndex={checkedIndex}
        setCheckedIndex={setCheckedIndex}
        checkedSet={checkedSet}
        setCheckedSet={setCheckedSet}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "98%",
          marginTop: 10,
        }}
      >
        <RadioButtonUncheckedIcon
          size={25}
          color="#353535"
          style={{
            opacity: 0,
            marginRight: 10,
          }}
        />
        <div
          style={{
            width: "40%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            border: "1px solid #fff",
            margin: 10,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "60%",
          }}
        >
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "500" }}>Units</p>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "500" }}>Requested Quantity</p>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "500" }}>Maximum Quantity</p>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: "500" }}>Status</p>
          </div>
        </div>
      </div>

      {item.items
        .slice(0, showAllItems ? item.items.length : 3)
        .map((component, index2) => (
          <SingleComponent
            Editing={Editing}
            index2={index2}
            category={component.category}
            heading={component.heading}
            subHeading={component.subHeding}
            description={component.description}
            quantity={component.quantity}
            unit={component.unit}
            checked={component.checked}
            status={component.status === true ? "Requested" : component.status}
            maxQuantity={component?.itemDetails?.quantity || 0}
            index={index}
            items={item}
            setItems={setItems}
            checkedIndex={checkedIndex}
            setCheckedIndex={setCheckedIndex}
            checkedSet={checkedSet}
            setCheckedSet={setCheckedSet}
            editedHistory={editedHistory}
            setEditedHistory={setEditedHistory}
          />
        ))}

      {!showAllItems && item.items.length > 3 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "15px",
            cursor: "pointer",
            color: "#FCD24E",
            fontWeight: "bold",
          }}
          onClick={handleViewMoreClick}
        >
          <span style={{ fontWeight: "500", color: "#696969" }}>
            View More Items...
          </span>
        </div>
      )}

      <RemarkWithHistory item={item} />

      <div style={{ marginTop: 30 }} />

      <ProgressBar
        item={
          Object.values(item.requestHistory)[
            Object.values(item.requestHistory).length - 1
          ]
        }
        completeItem={item}
      />

      <div style={{ marginBottom: 40 }} />

      {!Editing && roleType !== "Viewer" && (
        <ActionButtons
          checkedSet={checkedSet}
          actionOccured={actionOccured}
          item={{
            status: checkedSet,
          }}
          onApprove={async () => {
            setActionOccured(true);
            let timestamp = new Date().getTime();
            let tempItems = JSON.parse(JSON.stringify(item));

            let quantityArray = [];

            Object.keys(tempItems.items).forEach((key) => {
              if (tempItems.items[key].hasOwnProperty("checked")) {
                delete tempItems.items[key].checked;
                quantityArray.push(tempItems.items[key].quantity);
              } else {
                quantityArray.push(0);
              }
            });

            tempItems["requestHistory"][timestamp] = {
              quantities: quantityArray,
              id: timestamp,
              name: userName,
              remark: "",
              status: "Approved",
            };

            if (setItems !== null) {
              // setItems(tempItems);
              setItems((prevState) => {
                let tempPrevState = [...prevState];
                tempPrevState = replaceObjectById(tempPrevState, tempItems);
                return tempPrevState;
              });
            }

            await updateRequestStatus(
              `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${item.id}/`,
              tempItems
            );

            setActionOccured(false);
            updateRequestStatusLocally(tempItems);

            setCheckedIndex(null);
            setCheckedSet(null);

            toast.success("This request has been approved", {
              duration: 4000,
              position: "bottom-center",
              style: {
                borderRadius: "8px",
                padding: "16px",
                color: "#1A85B1",
                backgroundColor: "#F1FBFF",
                maxWidth: "100%",
              },
              icon: <CgFileDocument color={"#1A85B1"} />,
            });
          }}
          onDecline={async () => {
            setActionOccured(true);

            let timestamp = new Date().getTime();
            let tempItems = { ...item };
            let quantityArray = [];

            Object.keys(tempItems.items).forEach((key) => {
              if (tempItems.items[key].hasOwnProperty("checked")) {
                delete tempItems.items[key].checked;
                quantityArray.push(tempItems.items[key].quantity);
                tempItems.items[key]["status"] = "Declined";
              } else {
                quantityArray.push(0);
              }
            });

            tempItems["requestHistory"][timestamp] = {
              quantities: quantityArray,
              id: timestamp,
              name: userName,
              remark: "",
              status: "Declined",
            };

            // setItems(tempItems);

            await updateRequestStatus(
              `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${item.id}/`,
              tempItems
            );

            if (setItems !== null) {
              // setItems(tempItems);
              setItems((prevState) => {
                let tempPrevState = [...prevState];
                tempPrevState = replaceObjectById(tempPrevState, tempItems);
                return tempPrevState;
              });
            }

            setActionOccured(false);
            updateRequestStatusLocally(tempItems);

            setCheckedIndex(null);
            setCheckedSet(null);

            toast.success("This request has been declined", {
              duration: 4000,
              position: "bottom-center",
              style: {
                borderRadius: "8px",
                padding: "16px",
                color: "#1A85B1",
                backgroundColor: "#F1FBFF",
                maxWidth: "100%",
              },
              icon: <CgFileDocument color={"#1A85B1"} />,
            });
            console.log("onDecline pressed...");
          }}
          onReleasePO={async () => {

            console.log(Object.values(item.items)
            .filter((value) => value.checked === true)
            .map((value) => value.itemDetails))
            
            setActionOccured(true);

            const dbRef = firebase
              .database()
              .ref("liveBOQ/" + selectedProject.buildProjectId);

            const snapshot = await dbRef.once("value");

            const data = snapshot.val();

            Object.values(item.items).forEach((currentItem) => {
              const parentQuantity = currentItem.quantity; // Get the quantity from the parent item
              if (currentItem.itemDetails.hasOwnProperty("maxQuantity")) {
                if (currentItem.itemDetails && currentItem.checked) {
                  currentItem.itemDetails["maxQuantity"] =
                    currentItem.itemDetails.quantity;
                  currentItem.itemDetails.quantity = String(parentQuantity); // Update the itemDetails quantity
                }
              } else {

                let indexces = fetchIndexcesForAddNewItem({
                  rooms: JSON.parse(data.rooms), 
                }, true);

                currentItem.itemDetails["indexces"] = {
                  roomIndex: indexces.roomIndex,
                  unitIndex: indexces.extraItemsIndex,
                  componentIndex: indexces.componentIndex,
                  materialIndex: indexces.materialIndex,
                };
                currentItem.itemDetails.quantity = String(currentItem.quantity);
              }
            });

            if (snapshot.exists()) {

              history.push("/OrdersQuotations/" + data.ProjectId, {
                data: {
                  ...data,
                  rooms: JSON.parse(data.rooms),
                  ProjectId: data.ProjectId,
                  ClientName: data.ClientName,
                  Address: data.Address,
                  AmountRecieved: data.AmountReceived,
                  SiteEngineerId: data.SiteEngineersId,
                },
                selectedWorks: [],
                selectedMaterials: Object.values(item.items)
                  .filter((value) => value.checked === true)
                  .map((value) => value.itemDetails),
                orderOrQuotation: "OrderButton",
                materialRequest: true,
                requestItem: item,
                selectedProject: selectedProject, // requestData
              });
            } else {
              toast.success("BOQ Data not found", {
                duration: 4000,
                position: "bottom-center",
                style: {
                  borderRadius: "8px",
                  padding: "16px",
                  color: "#1A85B1",
                  backgroundColor: "#F1FBFF",
                  maxWidth: "100%",
                },
                icon: <CgFileDocument color={"#1A85B1"} />,
              });
            }
            setActionOccured(false);
          }}
        />
      )}

      {item.editHistory && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "flex-end",
            marginRight: 10,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setEditHistory(!showEditHistory)}
        >
          <LuHistory size={25} color="#353535" />
          <p
            style={{
              fontWeight: "600",
              fontSize: 14,
              color: "#353535",
            }}
          >
            {`${showEditHistory ? "close" : "View"}`} edit history
          </p>
        </div>
      )}

      {showEditHistory && (
        <EditHistory
          historyData={item.editHistory ? Object.values(item.editHistory) : []}
        />
      )}
    </div>
  );
};

export default SingleItem;
