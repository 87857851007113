import React, { useState, useMemo, useEffect, useRef } from "react";

const RegisterItemModal = ({ isOpen, onClose, onSubmit, material, editingItem }) => {
  
  const [formData, setFormData] = useState({
    category: "",
    specification: "",
    rate: "",
    unit: "",
    gst: "",
  });

  const [newCategory, setNewCategory] = useState("");
  const [newSpecification, setNewSpecification] = useState("");
  const modalRef = useRef(null); // Reference for modal content

  useEffect(() => {
    if (editingItem) {
      
      setFormData({
        category: editingItem.category || "",
        specification: editingItem.specification || editingItem.description || "",
        rate: editingItem.rate || "",
        unit: editingItem.unit || editingItem.uom || "",
        gst: editingItem.gst || "",
      });
      setNewCategory(editingItem.category || "");
      setNewSpecification(editingItem.specification || editingItem.description || "");
    } else {
      setFormData({
        category: "",
        specification: "",
        rate: "",
        unit: "",
        gst: "",
      });
      setNewCategory("");
      setNewSpecification("");
    }
  }, [editingItem]);

  // Close modal on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setFormData({
          category: "",
          specification: "",
          rate: "",
          unit: "",
          gst: "",
        });
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const categories = useMemo(() => {
    return [...new Set(material.map((item) => item.category))];
  }, [material]);

  const specifications = useMemo(() => {
    return material
      .filter((item) => item.category === formData.category)
      .map((item) => item.specification);
  }, [formData.category, material]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {

    console.log('formData : ', formData);
    if (
      !formData.category ||
      !formData.specification ||
      !formData.rate ||
      !formData.unit ||
      !formData.gst
    ) {
      alert("Please fill all the fields");
      return;
    }

    const updatedItem = {
      Id: editingItem ? editingItem.Id : Date.now(),
      ...formData,
    };

    onSubmit(updatedItem);
    setFormData({ category: "", specification: "", rate: "", unit: "", gst: "" });
    onClose();
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setNewCategory(value);
    setFormData((prev) => ({ ...prev, category: value }));
  };

  const handleSpecificationChange = (e) => {
    const value = e.target.value;
    setNewSpecification(value);
    setFormData((prev) => ({ ...prev, specification: value }));
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        backdropFilter: "blur(4px)",
      }}
    >
      <div
        ref={modalRef} // Attach reference to modal content
        style={{
          backgroundColor: "#f1f1f1",
          padding: "32px",
          borderRadius: "16px",
          width: "450px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            margin: "0 0 24px 0",
            fontSize: "24px",
            fontWeight: "600",
            color: "#4a4a4a",
            textAlign: "center",
          }}
        >
          {editingItem ? "Edit Item" : "Register New Item"}
        </h2>

        {/* Category Field */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "500",
              color: "#6e6e6e",
            }}
          >
            Category:
          </label>
          <input
            list="categories"
            value={newCategory}
            onChange={handleCategoryChange}
            placeholder="Type or select a category"
            style={{
              width: "100%",
              padding: "12px 16px",
              border: "1px solid #aeaeae",
              borderRadius: "8px",
              fontSize: "14px",
              backgroundColor: "#f8f8f8",
              color: "#4a4a4a",
            }}
          />
          <datalist id="categories">
            {categories.map((category, index) => (
              <option key={index} value={category} />
            ))}
          </datalist>
        </div>

        {/* Specification Field */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "500",
              color: "#6e6e6e",
            }}
          >
            Specification:
          </label>
          <input
            list="specifications"
            value={newSpecification}
            onChange={handleSpecificationChange}
            placeholder="Type or select a specification"
            style={{
              width: "100%",
              padding: "12px 16px",
              border: "1px solid #aeaeae",
              borderRadius: "8px",
              fontSize: "14px",
              backgroundColor: "#f8f8f8",
              color: "#4a4a4a",
            }}
            disabled={!formData.category}
          />
          <datalist id="specifications">
            {specifications.map((spec, index) => (
              <option key={index} value={spec} />
            ))}
          </datalist>
        </div>

        {/* Other Fields */}
        {["rate", "unit", "gst"].map((field) => (
          <div key={field} style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#6e6e6e",
                textTransform: "capitalize",
              }}
            >
              {field}:
            </label>
            <input
              type={field === "rate" || field === "gst" ? "number" : "text"}
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "12px 16px",
                border: "1px solid #aeaeae",
                borderRadius: "8px",
                fontSize: "14px",
                backgroundColor: "#f8f8f8",
                color: "#4a4a4a",
              }}
            />
          </div>
        ))}

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginTop: "32px",
          }}
        >
          <button
            onClick={() => {
              setFormData({
                category: "",
                specification: "",
                rate: "",
                unit: "",
                gst: "",
              });
              onClose();
            }}
            style={{
              padding: "10px 20px",
              fontSize: "14px",
              fontWeight: "500",
              border: "1px solid #aeaeae",
              borderRadius: "8px",
              backgroundColor: "#f1f1f1",
              color: "#6e6e6e",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            style={{
              padding: "10px 24px",
              fontSize: "14px",
              fontWeight: "500",
              border: "none",
              borderRadius: "8px",
              backgroundColor: "#aeaeae",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {editingItem ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterItemModal;
