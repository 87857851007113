import registerProjectOne from "../AuthPage/registerProjectOne.json";
import registerProjectTwo from "../AuthPage/registerProjectTwo.json";
import registerPRojectThree from "../AuthPage/registerPRojectThree.json";
import registerProjectFour from "../AuthPage/registerProjectFour.json";
import projectService from "../../api/projectService";
import firebase from "../../config/firebase";

const RegisterFourProjects = async (newUserFormDetails) => {

  const projects = [
    "Construction Project",
    "Concrete Contracting",
    "Warehouse Construction",
    "Industrial Shed",
  ];

  for (let project of projects) {
    let projectData =
      project === "Construction Project"
        ? registerProjectOne
        : project === "Concrete Contracting"
        ? registerProjectTwo
        : project === "Warehouse Construction"
        ? registerPRojectThree
        : registerProjectFour;
    const res = await new projectService().addProject(
      `${project}`,
      "0",
      "Demo Address",
      "",
      "",
      projectData,
      0,
      [],
      newUserFormDetails.firm.trim()
    );

    await firebase
      .database()
      .ref("liveBOQ")
      .child(res.payload.ProjectId.toString())
      .set({
        ...res.payload,
        rooms: JSON.stringify(res.payload.Rooms),
      });
  }
};

export default RegisterFourProjects;
