import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";

const RemarkWithHistory = ({ item }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Extract and sort request history by ID (timestamp)
    const sortedHistory = Object.values(item.requestHistory || {}).sort((a, b) => b.id - a.id);

    // Get the latest remark from the sorted history
    const latestRemark = sortedHistory[0];

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);

        // Format date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        // Format time
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

        return `${day}-${month}-${year} | ${hours}:${minutes} ${ampm}`;
    }

    return (
        <div
            style={{
                margin: 10,
                padding: 15,
                border: '1px solid #e0e0e0',
                borderRadius: 8,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>

                <p style={{ margin: 0 }}>
                    <span style={{ fontWeight: '500' }}>Last remark: </span>
                    {latestRemark?.remark || 'No remarks available'}
                </p>

                {sortedHistory.length > 1 && (
                    <p
                        style={{
                            margin: '10px 0 0',
                            cursor: 'pointer',
                            color: '#007bff',
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? (
                            <IoChevronUpOutline size={20} color={'#696969'} />
                        ) : (
                            <IoChevronDownOutline size={20} color={'#696969'} />
                        )}
                    </p>
                )}
            </div>

            {isExpanded && (
                <div style={{ marginTop: 10, fontSize: 14, color: '#333' }}>
                    <p style={{fontWeight: '600', color: '#353535'}}>Previous remarks</p>
                    {sortedHistory.slice(1).map((historyItem, historyIndex) => (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <p key={historyItem.id} style={{ margin: '5px 0', lineHeight: '1.5'}}>
                                <span style={{ fontWeight: '500' }}>{(historyIndex + 1)} : </span>
                                {historyItem.remark}
                            </p>
                            <p>
                                {`${historyItem.name} | ${formatTimestamp(historyItem.id)}`}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default RemarkWithHistory;
