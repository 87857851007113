import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./projectPage.scss";
import { UserContext } from "../../Context/UserContext";
import {
  FiChevronDown,
  FiChevronUp,
  FiMoreVertical,
  FiUpload,
  FiDownload,
  FiInfo,
} from "react-icons/fi";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import { useHistory, useLocation } from "react-router-dom";
import {
  PopOverView,
  TextArea,
  Input,
  Button1,
  DropDownMUI,
  FileExplorer,
  Tooltip,
} from "../../components";
import firebase from "../../config/firebase";
import {
  addRoom,
  onChangeUnit,
  addNewComponent,
  duplicateComponent,
  deleteComponent,
  onChangeComponent,
  addWork,
  deleteWork,
  onChangeWork,
  addMaterial,
  deleteMaterial,
  onChangeMaterial,
  saveWorkButtonClicked,
  importBOQ,
  getDifference,
} from "./helper";
import ProjectService from "../../api/projectService";
import OpportunityService from "../../api/opportunityService";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import fileDialog from "file-dialog";
import config from "../../config/config";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import getVendorPrice from "../../helpers/getVendorPrice";
import { downloadBOQ } from "./helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import MSwitch from "@material-ui/core/Switch";
import Room from "./room";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import DraftService from "../../api/draftService";
import { Autocomplete, TextField } from "@mui/material";
import { Box, Checkbox } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CiRuler } from "react-icons/ci";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import getFirmId from "../SuggestiveItems/getFirmId";
import fetchMaterial from "../SuggestiveItems/fetchMaterialFromFirebase";
import InventoryDropdown from "./pInventoryDropdown";
import ProgressBar from "./LoadingModal";
import { processWorkbook } from "./helperExcelToBOQ";
import * as XLSX from "xlsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ProjectPage = ({ setSelectedProject, activeRevision }) => {
  const { record } = useAnalyticsContext();
  const location = useLocation();
  const [project, setProject] = useState();
  const _project = useRef();
  const [spinner, setSpinner] = useState(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const [categories, setCategories] = useState(null);
  const [itemOpenIndex, setItemOpenIndex] = useState();
  const [activeUnitIndex, setActiveUnitIndex] = useState();
  const [activeComponentIndex, setActiveComponentIndex] = useState();
  const [showDrawings, setShowDrawings] = useState(false);
  const [showComponents, setShowComponents] = useState(false);
  const [showMaterial, setShowMaterial] = useState(true);
  const [showWork, setShowWork] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [displayPurchaseAmount, setDisplayPurchaseAmount] = useState(true);
  let interval = useRef(0);
  const projectService = new ProjectService();
  const opportunityService = new OpportunityService();
  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;
  const [componentSuggestions, setComponentSuggestions] = useState([]);
  const [materialSuggestions, setMaterialSuggestions] = useState([]);
  const [workSuggestions, setWorkSuggestions] = useState([]);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  const [uploadText, setUploadText] = React.useState("Upload in progress");
  const [suggestions, setSuggestions] = useState(true);
  const [displayScroll, setDisplayScroll] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [shouldAdjustScroll, setShouldAdjustScroll] = useState(false);
  const componentContainerRef = useRef(null);
  const previousShouldAdjustScroll = useRef(shouldAdjustScroll);

  const [importing, setImporting] = useState(false);

  // state written for modifying excel import feature
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState({ completed: 0, total: 0 });
  const [processing, setProcessing] = useState(new Set());

  // const onProgress = useCallback((sheetName, result) => {
  //   setResults((prev) => ({
  //     ...prev,
  //     [sheetName]: result,
  //   }));
  //   setProgress((prev) => ({
  //     ...prev,
  //     completed: prev.completed + 1,
  //   }));
  //   setProcessing((prev) => {
  //     const next = new Set(prev);
  //     next.delete(sheetName);
  //     return next;
  //   });
  // }, []);

  const onProgress = useCallback((sheetName, result) => {
    setSelectedProject((prevProject) => {
      const newRoom = result[sheetName]?.[0];

      if (newRoom === undefined) {
        return prevProject; // Do not update state if newRoom is undefined
      }

      const updatedProject = {
        ...prevProject,
        rooms: [...(prevProject.rooms ?? []), newRoom].filter(Boolean), // Filter out undefined
        Rooms: [...(prevProject.Rooms ?? []), newRoom].filter(Boolean),
      };

      updateFBRDB(updatedProject);

      return updatedProject;
    });
  }, []);

  const onStart = useCallback((sheetName) => {
    setProcessing((prev) => new Set([...prev, sheetName]));
  }, []);

  const onError = useCallback((sheetName, error) => {
    setResults((prev) => ({
      ...prev,
      [sheetName]: { error: error.message },
    }));
    setProcessing((prev) => {
      const next = new Set(prev);
      next.delete(sheetName);
      return next;
    });
    setProgress((prev) => ({
      ...prev,
      completed: prev.completed + 1,
    }));
  }, []);

  const setTotalSheets = useCallback((total) => {
    setProgress((prev) => ({ ...prev, total }));
  }, []);

  const [resultCount, setResultCount] = useState(0);



  const importBOQCheck = async (e) => {
    console.log("importBOQCheck is called...: ");

    const apiKey =
      "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";

    const file = e.currentTarget.files[0];

    setImporting(true);

    if (!file) {
      setError("Please upload a file and provide an API key.");
      return;
    }

    setLoading(true);
    setError(null);
    setResults({});
    setProcessing(new Set());

    try {
      // Convert file directly to ArrayBuffer using modern API
      const buffer = await file.arrayBuffer();
      const data = new Uint8Array(buffer);
      // const workbook = XLSX.read(data, { type: "array" });

      const workbook = XLSX.read(data, {
        type: "array",
        cellFormula: false, // Don't parse formulas
        cellText: false, // Don't store text formula results
        cellDates: true, // Convert dates
        cellNF: false, // Don't store number format strings
        cellStyles: false, // Don't store cell styles
        cellValues: true, // Read the computed values
      });

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        for (let cell in worksheet) {
          if (worksheet[cell].f) {
            // If cell has a formula
            // Get the computed value instead
            worksheet[cell] = {
              t: worksheet[cell].t, // Preserve the type
              v: worksheet[cell].v, // Use the computed value
              w: worksheet[cell].w, // Use the formatted text
            };
          }
        }
      });

      await processWorkbook(workbook, apiKey, {
        onProgress,
        onStart,
        onError,
        setTotalSheets,
        // setResultCount
      });
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    // setProject((prevProject) => ({
    //   ...prevProject, // Spread all other keys and values
    //   rooms: boqObject, // Update the `rooms` key
    //   Rooms: boqObject, // Update the `Rooms` key
    // }));

    // updateFBRDB({ ...project, rooms: boqObject, Rooms: boqObject });

    // setImporting(false);

    // e, project, setProject, updateFBRDB
    // await importBOQ(
    //   e,
    //   project,
    //   setProject,
    //   updateFBRDB,
    //   loading,
    //   setLoading,
    //   error,
    //   setError,
    //   progress,
    //   setProgress,
    //   setProcessing,
    //   processing,
    //   file2,
    //   setFile,
    //   results,
    //   setResults
    // );
  };

  const fractionsArray = [
    0.08, 0.16, 0.25, 0.33, 0.41, 0.5, 0.58, 0.66, 0.75, 0.83, 0.91,
  ];

  const convertFractionIntoInches = (decimalPart) => {
    // write the code here

    let closest = fractionsArray[0];

    let minDifference = Math.abs(decimalPart - closest);

    console.log("feet minDifference : ", minDifference);

    // Iterate over fractions array to find the closest fraction
    for (let fraction of fractionsArray) {
      let difference = Math.abs(decimalPart - fraction);

      // Update closest fraction if current fraction is closer
      if (difference < minDifference) {
        minDifference = difference;
        closest = fraction;
      }
    }
    return closest;
  };

  const handleScroll = (e) => {
    setDisplayScroll(true);
    const componentContainer = componentContainerRef.current;

    if (
      componentContainer.scrollHeight -
        (componentContainer.scrollTop + componentContainer.clientHeight) <
      1
    ) {
      componentContainer.scrollTop = 5;
      setIsScrollingUp(false);
      setShouldAdjustScroll(true);
      updateContent("down");
    } else if (componentContainer.scrollTop <= 0) {
      setIsScrollingUp(true);
      setShouldAdjustScroll(true);
      updateContent("up");
    }
  };

  const updateContent = (direction) => {
    if (direction === "down") {
      if (itemOpenIndex === project.rooms.length - 1) {
        setItemOpenIndex(0);
        setActiveUnitIndex(0);
      } else {
        if (activeUnitIndex === project.rooms[itemOpenIndex].Units.length - 1) {
          setItemOpenIndex((itemOpenIndex) => itemOpenIndex + 1);
          setActiveUnitIndex(0);
        } else {
          setActiveUnitIndex((activeUnitIndex) => activeUnitIndex + 1);
        }
      }
    } else {
      if (itemOpenIndex == 0) {
        setItemOpenIndex(() => project.rooms.length - 1);
        setActiveUnitIndex(
          () => project.rooms[project.rooms.length - 1].Units.length - 1
        );
      } else {
        if (activeUnitIndex == 0) {
          setActiveUnitIndex(
            () => project.rooms[itemOpenIndex - 1].Units.length - 1
          );
          setItemOpenIndex((itemOpenIndex) => itemOpenIndex - 1);
        } else {
          setActiveUnitIndex((activeUnitIndex) => activeUnitIndex - 1);
        }
      }
    }
    setActiveComponentIndex();
  };

  useEffect(() => {
    const componentContainer = componentContainerRef.current;

    if (shouldAdjustScroll !== previousShouldAdjustScroll.current) {
      if (shouldAdjustScroll) {
        if (isScrollingUp) {
          componentContainer.scrollTop =
            componentContainer.scrollHeight -
            componentContainer.clientHeight -
            50;
        }
        setShouldAdjustScroll(false);
      }
      // Update the previous value of shouldAdjustScroll
      previousShouldAdjustScroll.current = shouldAdjustScroll;
    }
  }, [shouldAdjustScroll, isScrollingUp]);

  useEffect(() => {
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString() + "/" + "editStatus")
      .once("value", (snapshot) => {
        // console.log('Firebase value ', snapshot.val())

        if (snapshot.exists()) {
          // console.log('snapshot : ',snapshot.val())
          if (
            snapshot.val().currentUser == null ||
            snapshot.val().currentUser == "null"
              ? false
              : snapshot.val().currentUser !== userContext.user.email
          ) {
            history.push("/");
            toast.error(
              `This project is currently being used by ${
                snapshot.val().currentUser
              }, and only one person can edit at a time.`,
              {
                duration: 10000,
                position: "bottom-center",
                style: {
                  borderRadius: "8px",
                  padding: "16px",
                  color: "#E72424",
                  backgroundColor: "#FEF0F0",
                  maxWidth: "100%",
                },
                icon: <FiInfo color={"#E72424"} />,
              }
            );
          } else {
            // console.log('in the null case update the firbase')
            updateFBRDBEditStatus({
              isEditing: true,
              currentUser: userContext.user.email,
              lastEdited: Date.now(),
            });
          }
        }
      });
  }, [location]);

  const DateCreated = () => {
    let dateObj = new Date(
      Number(
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
    );
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    // let newdate = year + "/" + month + "/" + day;
    let newdate = day + "/" + month + "/" + year;

    return newdate;
  };

  const calculateDate = () => {
    var date1 = new Date();
    var date2 = new Date(
      Number(
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
    );
    var diffTime = date1.getTime() - date2.getTime();
    var diffDays = diffTime / (1000 * 3600 * 24);
    return Math.floor(diffDays);
  };

  const updateFBRDBEditStatus = (payload) => {
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString() + "/" + "editStatus/")
      // .child("editStatus")
      .set(payload);
  };

  useEffect(() => {
    let logOutTimer = setTimeout(() => {
      updateFBRDBEditStatus({
        isEditing: false,
        currentUser: "null",
        lastEdited: Date.now(),
      });
      history.push("/");
    }, 600000);
    return () => {
      clearTimeout(logOutTimer);
    };
  }, [project]);

  window.onpopstate = history.onpushstate = function (e) {
    updateFBRDBEditStatus({
      isEditing: false,
      currentUser: "null",
      lastEdited: Date.now(),
    });
  };

  useEffect(() => {
    setSpinner(true);
    let projectId = pathname.split("/")[2];
    firebase
      .database()
      .ref("liveBOQ/" + projectId.toString())
      .on("value", (snapshot) => {
        setSpinner(false);
        if (snapshot.exists()) {
          console.log(
            "typeof snapshot.val().rooms one : ",
            typeof snapshot.val().rooms
          );
          const response = {
            ...snapshot.val(),
            rooms: JSON.parse(snapshot.val().rooms),
            Rooms: JSON.parse(snapshot.val().rooms),
          }; // has to replace with rooms or Rooms
          console.log("typeof response one : ", typeof response.rooms);
          setProject(response);
          // setSelectedProject(response);
        }
      });
  }, []);

  useEffect(() => {
    _project.current = project;
  }, [project]);

  // useEffect(() => {
  //   fetch(`${config.utilService}categories`)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setCategories({
  //           materialCategories: res.materialCategories,
  //           vendorCategories: res.vendorCategories,
  //         });
  //         console.log("res.materialCategories", res.materialCategories);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // }, []);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const firmId = await getFirmId();
        const materialsFromFirebase = await fetchMaterial(firmId, false);

        const response = await fetch(`${config.utilService}categories`);
        const res = await response.json();

        if (res.status === 200) {
          let combinedCategories = [...res.materialCategories];

          const uniqueCategories = Array.from(
            new Set(materialsFromFirebase.map((item) => item.category))
          ).map((category) => ({
            id: materialsFromFirebase.find((item) => item.category === category)
              .Id,
            name: category,
            Brands: null,
          }));

          const matchingCategories = uniqueCategories.filter((fbCategory) =>
            combinedCategories.some(
              (existingCat) =>
                existingCat.name.toLowerCase() === fbCategory.name.toLowerCase()
            )
          );

          const newCategories = uniqueCategories.filter(
            (fbCategory) =>
              !combinedCategories.some(
                (existingCat) =>
                  existingCat.name.toLowerCase() ===
                  fbCategory.name.toLowerCase()
              )
          );

          console.log("Unique matching categories:", matchingCategories);
          console.log("Unique new categories:", newCategories);

          combinedCategories = [...combinedCategories, ...newCategories];
          console.log("Combined categories:", combinedCategories);

          setCategories({
            materialCategories: combinedCategories,
            vendorCategories: res.vendorCategories || [],
          });
        }
      } catch (error) {
        console.log(error);
        setCategories({
          materialCategories: [],
          vendorCategories: [],
        });
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    setItemOpenIndex();
    setActiveUnitIndex();
    setActiveComponentIndex();
    if (activeRevision !== null) {
      setSpinner(true);
      let projectId = pathname.split("/")[2];
      firebase
        .database()
        .ref("liveBOQ/" + projectId.toString())
        .once("value", (snapshot) => {
          setSpinner(false);
          if (snapshot.exists() && snapshot.val().Revisions) {
            console.log(
              "typeof snapshot.val().rooms two : ",
              typeof snapshot.val().rooms
            );
            const response = {
              ...snapshot.val(),
              rooms: JSON.parse(snapshot.val().Revisions)[activeRevision].Rooms,
              Rooms: JSON.parse(snapshot.val().Revisions)[activeRevision].Rooms,
            };
            console.log("typeof response two : ", typeof response.rooms);
            setProject(response);
            setSelectedProject(response);
          }
        });
    } else {
      setSpinner(true);
      let projectId = pathname.split("/")[2];
      firebase
        .database()
        .ref("liveBOQ/" + projectId.toString())
        .once("value", (snapshot) => {
          setSpinner(false);
          if (snapshot.exists()) {
            console.log(
              "typeof snapshot.val().rooms three : ",
              typeof snapshot.val().rooms
            );
            const response = {
              ...snapshot.val(),
              rooms: JSON.parse(snapshot.val().rooms),
              Rooms: JSON.parse(snapshot.val().rooms),
            };
            console.log("typeof response three : ", typeof response.rooms);
            setProject(response);
            setSelectedProject(response);
            // console.log(response);
          }
        });
    }
  }, [activeRevision]);

  useEffect(() => {
    if (activeTab === 0) {
      interval.current = setInterval(function () {
        if (_project.current)
          projectService.addProjectBackup(
            _project.current.ProjectId,
            _project.current.ClientName,
            _project.current.Address,
            typeof _project.current.Finance === "string"
              ? _project.current.Finance
              : _project.current.Finance,
            typeof _project.current.rooms === "string"
              ? _project.current.rooms
              : JSON.stringify(_project.current.rooms),
            _project.current.SiteEngineerId,
            _project.current.duration,
            _project.current.ClientNumber
          );
      }, 300000);
    } else {
      clearInterval(interval.current);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [activeTab]);

  const getSuggestiveText = async () => {
    const projectService = new ProjectService();
    const response = await projectService.getSuggestiveText();
    if (response.status == 200) {
      setComponentSuggestions(
        response.componenet.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );

      // setMaterialSuggestions(
      //   response.material.filter(
      //     (item) =>
      //       item.firm == userContext.user.Firm ||
      //       item.firm == "Staart Buildtech" ||
      //       item.firm == null
      //   )
      // );

      let firmId = await getFirmId();
      let materialsFromFirebase = await fetchMaterial(firmId, true);

      setMaterialSuggestions([
        ...response.material.filter(
          (item) =>
            item.firm === userContext.user.Firm ||
            item.firm === "Staart Buildtech" ||
            item.firm === null
        ),
        ...materialsFromFirebase,
      ]);
      setWorkSuggestions(
        response.work.filter(
          (item) =>
            item.firm == userContext.user.Firm ||
            item.firm == "Staart Buildtech" ||
            item.firm == null
        )
      );
    } else {
      toast.error("Error getting suggestions!", {
        backgroundColor: "red",
        color: "black",
      });
    }
  };

  useEffect(() => {
    getSuggestiveText();
  }, []);

  const [workActive, setWorkActive] = useState();
  const [materialActive, setMaterialActive] = useState();
  // only for navigation from unreleased Page

  useEffect(() => {
    setActiveTab(0);
    let compsetIndex;
    let target;
    if (location.state) {
      if (location.state.fromUnreleased || location.state.fromReleased) {
        target = location.state.indexArr; // if the state property set(only from unreleased/released) indexArr is the array with all the indexces for the item
        compsetIndex = location.state.indexArr.componentIndex; // For activating the component in which the item is selected
        setItemOpenIndex((prevIndex) => target.roomIndex);
        setActiveUnitIndex((previndex) => target.unitIndex);
        setSpinner(true); //set the spinner until the timer expires of 3000
        setTimeout(() => {
          setShowComponents((check) => !check);
          setActiveComponentIndex(compsetIndex);
          setSpinner(false);
          if (location.state.materialItem) {
            setMaterialActive(target.materialIndex); //check if work/material item is selected and acitivate the item
          } else {
            setWorkActive(target.workIndex);
          } //else the work Item is selected and activate the item
        }, 3000);
      }
    }
  }, [location]);

  useEffect(() => {
    if (activeUnitIndex !== undefined) {
      document
        .getElementById(
          `roomIndex ${itemOpenIndex} unitIndex ${activeUnitIndex}`
        )
        ?.focus();
    }
    if (activeComponentIndex !== undefined) {
      document
        .getElementById(`componentIndex ${activeComponentIndex}`)
        ?.focus();
    }
    if (workActive != undefined) {
      document.getElementById(`workIndex ${workActive}`)?.focus(); //get the selected element to focus
    }
    if (materialActive != undefined) {
      // document.getElementById(`componentIndex ${activeComponentIndex}`).focus()
      document.getElementById(`materialIndex ${materialActive}`)?.focus();
    }
  }, [
    workActive,
    materialActive,
    activeComponentIndex,
    activeUnitIndex,
    itemOpenIndex,
  ]);

  const setSearchUnitArray = () => {
    let unitArry = [];
    project?.rooms?.map((room, roomIndex) => {
      room.Units.map((unit, unitIndex) => {
        let tempObj = {};
        tempObj["headingToShow"] = room["Room Name"];
        tempObj["label"] = unit["Unit Name"];
        tempObj["indexArr"] = { RoomIndex: roomIndex, UnitIndex: unitIndex };
        unitArry.push(tempObj); // stop the search and return the heading and items for the search Array
      });
    });
    // console.log("unit array", unitArry);
    return unitArry;
  };

  const setSearchComponentArray = () => {
    let unitArry = [];
    project?.rooms?.map((room, roomIndex) => {
      room.Units.map((unit, unitIndex) => {
        unit?.Components?.map((component, compIndex) => {
          let tempObj = {};
          tempObj["headingToShow"] = unit["Unit Name"];
          tempObj["label"] =
            component["description"] +
            `(QTY: ${component["quantity"]} Rate: ${component["rate"]})`;
          tempObj["indexArr"] = {
            RoomIndex: roomIndex,
            UnitIndex: unitIndex,
            ComponentIndex: compIndex,
          };
          unitArry.push(tempObj);
        });
      });
    });
    // console.log("unit array", unitArry);
    return unitArry;
  };

  const setSearchWorkArray = () => {
    let unitArry = [];
    project?.rooms?.map((room, roomIndex) => {
      room.Units.map((unit, unitIndex) => {
        unit?.Components?.map((component, compIndex) => {
          component.Work.map((work, workIndex) => {
            let tempObj = {};
            tempObj["headingToShow"] = unit["Unit Name"];
            tempObj["label"] =
              work["description"] +
              `(QTY: ${work["quantity"]} Rate: ${work["rate"]})`;
            tempObj["indexArr"] = {
              RoomIndex: roomIndex,
              UnitIndex: unitIndex,
              ComponentIndex: compIndex,
              WorkIndex: workIndex,
            };
            unitArry.push(tempObj);
          });
        });
      });
    });
    // console.log("unit array", unitArry);
    return unitArry;
  };

  const setSearchMaterialArray = () => {
    let unitArry = [];
    project?.rooms?.map((room, roomIndex) => {
      room.Units.map((unit, unitIndex) => {
        unit?.Components?.map((component, compIndex) => {
          component.Material.map((material, materialIndex) => {
            let tempObj = {};
            tempObj["headingToShow"] = unit["Unit Name"];
            tempObj["label"] =
              material["specification"] +
              `(QTY: ${material["quantity"]} Rate: ${material["rate"]})`; // adding the QTY to avoid duplicate values
            tempObj["indexArr"] = {
              RoomIndex: roomIndex,
              UnitIndex: unitIndex,
              ComponentIndex: compIndex,
              MaterialIndex: materialIndex,
            };
            unitArry.push(tempObj);
          });
        });
      });
    });
    // console.log("unit array", unitArry);
    return unitArry;
  };

  const [searchInput, setSearchInput] = useState(setSearchUnitArray);
  const [searchBy, setSearchBy] = useState("Item");
  const [showDropdowwn, setShowDropdowwn] = useState("false");

  useEffect(() => {
    // call the function accordingly
    // console.log("in the setting the useeffect");
    if (searchBy === "Item") {
      // console.log("setting to unit");
      setSearchInput(setSearchUnitArray);
    } else if (searchBy === "Description") {
      // console.log("setting to Component");
      setSearchInput(setSearchComponentArray);
    } else if (searchBy === "Work") {
      // console.log("setting to WorkMaterial");
      setSearchInput(setSearchWorkArray);
    } else if (searchBy === "Material") {
      setSearchInput(setSearchMaterialArray);
    }
  }, [searchBy, showDropdowwn]);

  const addSuggestiveTextComponent = async (
    description,
    rate,
    unit,
    works,
    materials
  ) => {
    var componentWorks = JSON.parse(JSON.stringify(works));
    var componentMaterials = JSON.parse(JSON.stringify(materials));
    componentWorks.forEach((item) => {
      delete Object.assign(item, { ["category"]: item["vendorCategory"] })[
        "vendorCategory"
      ];
      [
        "OrderId",
        "heading",
        "milestones",
        "orderStatus",
        "quantity",
        "status",
        "tc",
      ].forEach((key) => delete item[key]);
    });
    componentMaterials.forEach((item) => {
      item.category = item.type;
      [
        "OrderId",
        "type",
        "heading",
        "quantity",
        "milestones",
        "orderStatus",
        "status",
        "tc",
        "item",
      ].forEach((key) => delete item[key]);
    });
    const response = await projectService.addSuggestiveTextComponent(
      description,
      rate,
      unit,
      componentWorks,
      componentMaterials,
      userContext.user.Firm
    );
    // console.log("response of tje component suggestive items", response);
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else if (response.errorCode === 1062) {
      toast.warn("Item Aleady Exists!", {
        backgroundColor: "rgb(145, 199, 230 )",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const addSuggestiveTextWork = async (
    category,
    workType,
    description,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.addSuggestiveTextWork(
      category,
      workType,
      description,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const addSuggestiveTextMaterial = async (
    category,
    specification,
    rate,
    unit,
    gst
  ) => {
    const response = await projectService.addSuggestiveTextMaterial(
      category,
      specification,
      rate,
      unit,
      gst,
      userContext.user.Firm
    );
    if (response.status == 200) {
      toast.success("Suggestion saved successfully", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    } else {
      toast.error("Error saving suggestion!", {
        backgroundColor: "rgb(255, 211, 77)",
        color: "black",
      });
    }
    getSuggestiveText();
  };

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString())
      .set({
        ...payload,
        rooms: JSON.stringify(payload.rooms),
        editStatus: {
          isEditing: true,
          currentUser: userContext.user.email,
          lastEdited: Date.now(),
        },
      });

  const handleSetUploadProgress = (progress) => {
    setUploadProgress(progress);
  };

  var selectedUnitWorkOrderIds = [];

  const renderUploadProgress = () => {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          borderBottom: "1px solid #eaeaeb",
          padding: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: 16, minWidth: 140 }}>
          <p style={{ fontSize: 12, fontWeight: "700" }}>{uploadText}</p>
        </div>

        <div
          style={{
            width: "100%",
            height: 10,
            backgroundColor: "#f4f4f4",
            borderRadius: 5,
            position: "relative",
            overflow: "clip",
          }}
        >
          <div
            style={{
              width: `${uploadProgress}%`,
              height: "inherit",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgb(255, 211, 77)",
            }}
          />
        </div>
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div>
        <div>Items</div>
        <div>
          {project &&
            console.log(
              "ProjectPage renderItems project : ",
              typeof project.rooms
            )}

          {project?.rooms &&
            project?.rooms.map((room, roomIndex) => {
              return (
                <Room
                  key={roomIndex}
                  {...{
                    room,
                    roomIndex,
                    project,
                    setProject,
                    updateFBRDB,
                    itemOpenIndex,
                    setItemOpenIndex,
                    activeUnitIndex,
                    setActiveUnitIndex,
                    setActiveComponentIndex,
                    setShowMaterial,
                    setShowWork,
                    setShowComponents,
                    setShowDrawings,
                    activeRevision,
                    setShowWork,
                    setShowMaterial,
                    isScrollingUp,
                    setDisplayScroll,
                  }}
                />
              );
            })}

          {(!project || project?.rooms.length === 0) &&
            activeRevision === null && (
              <>
                <span
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    color: "#00aaff",
                    gap: 30,
                  }}
                >
                  <Tooltip content="Click to import BOQ" width={140}>
                    {console.log("importing : ", importing)}
                    <FileExplorer
                      accept=".xlsx"
                      onFileSelected={async (e) => {
                        importBOQCheck(e);
                        // const file = e.currentTarget.files[0];
                        // setImporting(true);
                        // await importBOQ(file, project, setProject, updateFBRDB);
                        // setImporting(false);
                      }}
                    >
                      <div style={{ fontSize: 14 }}>Import BOQ</div>
                    </FileExplorer>
                  </Tooltip>

                  <Tooltip
                    content="View/Download Sample BOQ"
                    width={200}
                    left={-180}
                  >
                    <FiDownload size={16} onClick={() => downloadBOQ()} />
                  </Tooltip>
                </span>
                <Button1
                  content="Add Heading"
                  style={{ margin: 10 }}
                  onClick={() => addRoom(0, project, setProject, updateFBRDB)}
                />
              </>
            )}
        </div>
      </div>
    );
  };

  const updateDrawings = () => {
    selectedUnitWorkOrderIds.forEach(async (orderId) => {
      const response = await opportunityService.getOpportunityByOrderId(
        orderId
      );

      const orderData = JSON.parse(response.payload[0].Data);

      const drawings =
        project.rooms[itemOpenIndex].Units[activeUnitIndex].Drawings;

      try {
        let response = await fetch(
          `${config.orderService}serviceOrder/${orderId}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Data: JSON.stringify({
                ...orderData,
                drawings: drawings,
              }),
            }),
          }
        );

        response = await response.json();
        return { ...response };
      } catch (e) {
        return {
          status: 400,
          statusMsg: "Error updating opportunity",
          payload: e,
        };
      }
    });
  };

  const [userInput, setUserInput] = useState([false]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [showInputs, setShowInputs] = useState(false);

  const [activeDimensionIndex, setActiveDimensionIndex] = useState(null);

  const countDecimalPlaces = (value) => {
    const stringValue = value.toString();
    const decimalIndex = stringValue.indexOf(".");

    if (decimalIndex === -1) {
      return 0;
    }
    return stringValue.substring(decimalIndex + 1).length;
  };

  const dimensionRef = useRef(null);
  const quantityRef = useRef(null);
  const nextFieldRef = useRef(null);

  const handleTabKeyPress = (e, nextRef) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      nextRef.current.focus();
    }
  };

  let amount;

  const renderComponents = () => {
    return (
      <div>
        <div>Description</div>
        {activeUnitIndex !== undefined && (
          <div
            id="componentDiv"
            ref={componentContainerRef}
            style={{ overflow: "auto" }}
            onScroll={handleScroll}
          >
            <div>
              <div
                className="heading"
                onClick={() => setShowDrawings((st) => !st)}
              >
                <div>Drawings</div>
                {showDrawings ? <FiChevronUp /> : <FiChevronDown />}
              </div>
              {showUploadProgress && renderUploadProgress()}

              {showDrawings &&
                project.rooms[itemOpenIndex]?.Units[activeUnitIndex] && (
                  <div className="drawings">
                    {project.rooms[itemOpenIndex].Units[
                      activeUnitIndex
                    ].Drawings.map((drawing, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <a href={drawing.url} target="_blank">
                            {drawing.name}
                          </a>
                          <div>
                            {drawing.date &&
                              new Date(drawing.date).toLocaleDateString()}
                          </div>
                          <abbr title="Delete">
                            {activeRevision === null && (
                              <DeleteOutlinedIcon
                                onClick={() => {
                                  project.rooms[itemOpenIndex].Units[
                                    activeUnitIndex
                                  ].Drawings.splice(index, 1);
                                  var rooms = project.rooms;
                                  setProject((dt) => ({ ...dt, rooms }));
                                  updateFBRDB({ ...project, rooms });
                                  updateDrawings();
                                }}
                              />
                            )}
                          </abbr>
                        </div>
                      );
                    })}

                    {project.rooms[itemOpenIndex].Units[
                      activeUnitIndex
                    ].Components?.forEach((item) => {
                      item?.Work?.forEach((wItem) => {
                        selectedUnitWorkOrderIds.push(wItem.OrderId);
                      });
                    })}
                    <div
                      onClick={() => {
                        if (activeRevision === null) {
                          fileDialog()
                            .then(async (files) => {
                              for (let i = 0; i < files.length; i++) {
                                const response = firebase
                                  .storage()
                                  .ref(
                                    "/WebUploads/" +
                                      new Date().getTime() +
                                      files[i].name
                                  )
                                  .put(files[i]);
                                response.on(
                                  "state_changed",
                                  (snapshot) => {
                                    setShowUploadProgress(true);
                                    const progress =
                                      (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                      100;
                                    handleSetUploadProgress(progress);
                                  },
                                  () => {
                                    toast.error("Failed to upload drawing");
                                    setShowUploadProgress(false);
                                    setUploadText("Upload in progress");
                                  },
                                  () => {
                                    setUploadText("Please wait...");
                                    response.snapshot.ref
                                      .getDownloadURL()
                                      .then((url) => {
                                        setShowUploadProgress(false);
                                        setUploadText("Upload in progress");
                                        onChangeUnit(
                                          itemOpenIndex,
                                          activeUnitIndex,
                                          project,
                                          setProject,
                                          updateFBRDB,
                                          "Drawings",
                                          {
                                            name: files[i].name,
                                            url,
                                            date: new Date(),
                                          }
                                        );
                                        updateDrawings();
                                      });
                                  }
                                );
                              }
                            })
                            .catch((e) => {
                              console.log("fileDialog catch :", e);
                            });
                        }
                      }}
                    >
                      <Tooltip content="Upload Drawings">
                        <div
                          style={{
                            backgroundColor: "#fdd34d",
                            color: "rgb(255, 255, 255)",
                            borderRadius: "10px",
                            width: "30px",
                            height: "30px",
                            padding: "5px",
                          }}
                        >
                          <FiUpload size={20} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
            </div>
            <div>
              <div
                className="heading"
                onClick={() => {
                  if (showComponents) {
                    setActiveComponentIndex();
                  }
                  setShowComponents((st) => !st);
                }}
              >
                <div>Components</div>
                {showComponents ? (
                  <FiChevronUp className="FiChevron" />
                ) : (
                  <FiChevronDown className="FiChevron" />
                )}
              </div>
              {displayScroll && (
                <div
                  style={{
                    height: "200px",
                  }}
                />
              )}
              {showComponents &&
                project.rooms[itemOpenIndex]?.Units[activeUnitIndex] && (
                  <div className="components">
                    {project.rooms[itemOpenIndex].Units[
                      activeUnitIndex
                    ].Components.map((component, componentIndex) => {
                      console.log(component, " this is a component 1");
                      const length = parseFloat(component["length"])
                        ? parseFloat(component["length"])
                        : "";
                      const breadth = parseFloat(component["breadth"])
                        ? parseFloat(component["breadth"])
                        : "";
                      const height = parseFloat(component["height"])
                        ? parseFloat(component["height"])
                        : "";

                      const dimensions = [length, breadth, height]
                        .filter((dim) => dim !== "")
                        .map((dim) => parseFloat(dim))
                        .sort((a, b) => b - a)
                        .slice(0, 2);

                      let quantity;
                      if (dimensions.length === 2) {
                        quantity = dimensions
                          .reduce((acc, val) => acc * val, 1)
                          .toFixed(2);
                      } else {
                        quantity = component["quantity"] || "";
                      }

                      const differ = getDifference(
                        (quantity ?? 0) * Number(component?.rate ?? 0), // Ensure `quantity` & `component.rate` exist

                        (amount =
                          (Array.isArray(component?.Work) // Ensure `component.Work` is an array
                            ? component.Work.reduce((total, item) => {
                                const rate =
                                  item?.rate === "quotation"
                                    ? 0
                                    : parseFloat(item?.rate) || 0;
                                const quantity =
                                  parseFloat(item?.quantity) || 0;
                                const gst = parseFloat(item?.gst) || 0;

                                return (
                                  total +
                                  rate * quantity +
                                  (rate * quantity * gst) / 100
                                );
                              }, 0)
                            : 0) + // Default to 0 if `component.Work` is missing
                          (Array.isArray(component?.Material) // Ensure `component.Material` is an array
                            ? component.Material.reduce((total, item) => {
                                const rate =
                                  item?.rate === "quotation"
                                    ? 0
                                    : parseFloat(item?.rate) || 0;
                                const quantity =
                                  parseFloat(item?.quantity) || 0;
                                const gst = parseFloat(item?.gst) || 0;

                                return (
                                  total +
                                  rate * quantity +
                                  (rate * quantity * gst) / 100
                                );
                              }, 0)
                            : 0)) // Default to 0 if `component.Material` is missing
                      );

                      // const differ = getDifference(
                      //   quantity * Number(component["rate"] || 0),

                      //   (amount =
                      //     component.Work.reduce((total, item) => {
                      //       const rate =
                      //         item?.rate === "quotation"
                      //           ? 0
                      //           : parseFloat(item.rate) || 0;
                      //       const quantity = parseFloat(item.quantity) || 0;
                      //       const gst = parseFloat(item.gst) || 0;
                      //       return (
                      //         total +
                      //         rate * quantity +
                      //         (rate * quantity * gst) / 100
                      //       );
                      //     }, 0) +
                      //     component.Material.reduce((total, item) => {
                      //       const rate =
                      //         item?.rate === "quotation"
                      //           ? 0
                      //           : parseFloat(item.rate) || 0;
                      //       const quantity = parseFloat(item.quantity) || 0;
                      //       const gst = parseFloat(item.gst) || 0;
                      //       return (
                      //         total +
                      //         rate * quantity +
                      //         (rate * quantity * gst) / 100
                      //       );
                      //     }, 0))
                      // );

                      {
                        /* if(something  == frightcharges) { return( )} */
                      }

                      return (
                        <div
                          key={componentIndex}
                          tabIndex="1"
                          id={`componentIndex ${componentIndex}`}
                          className="component"
                          style={
                            componentIndex === activeComponentIndex
                              ? { backgroundColor: "rgb(255, 211, 77)" }
                              : {}
                          }
                        >
                          <div>
                            <div>Component {componentIndex + 1}</div>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "end",
                              }}
                              onClick={() => {
                                setActiveDimensionIndex(componentIndex);
                                setShowInputs((prevState) =>
                                  prevState === componentIndex
                                    ? null
                                    : componentIndex
                                );
                              }}
                            >
                              <CiRuler
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: "3vw",
                                }}
                              />
                            </span>

                            {activeRevision === null ? (
                              <PopOverView
                                position="left"
                                component={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 10,
                                      fontSize: 12,
                                      color: "grey",
                                      alignItems: "flex-start",
                                      width: 140,
                                    }}
                                  >
                                    <div
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                        addNewComponent(
                                          itemOpenIndex,
                                          activeUnitIndex,
                                          componentIndex,
                                          project,
                                          setProject,
                                          updateFBRDB
                                        );
                                        setActiveComponentIndex(
                                          project.rooms[itemOpenIndex].Units[
                                            activeUnitIndex
                                          ].Components.length - 1
                                        ); // for focusing to that div
                                      }}
                                      className="menuItem"
                                    >
                                      Add Component Below
                                    </div>
                                    <div
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                        duplicateComponent(
                                          itemOpenIndex,
                                          activeUnitIndex,
                                          componentIndex,
                                          component,
                                          project,
                                          setProject,
                                          updateFBRDB
                                        );
                                      }}
                                      className="menuItem"
                                    >
                                      Duplicate
                                    </div>
                                    <div
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                        deleteComponent(
                                          itemOpenIndex,
                                          activeUnitIndex,
                                          componentIndex,
                                          project,
                                          setProject,
                                          updateFBRDB
                                        );
                                      }}
                                      className="menuItem"
                                    >
                                      Delete
                                    </div>
                                    <div
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                        addSuggestiveTextComponent(
                                          component.description,
                                          component.rate,
                                          component.unit,
                                          component.Work,
                                          component.Material
                                        );
                                      }}
                                      className="menuItem"
                                    >
                                      Add to suggestive items
                                    </div>
                                  </div>
                                }
                              >
                                {project &&
                                  project.rooms[itemOpenIndex]["Room Name"] !==
                                    "Non tender items" && <FiMoreVertical />}
                              </PopOverView>
                            ) : (
                              <FiMoreVertical />
                            )}
                          </div>

                          <TextArea
                            value={component["description"]}
                            placeholder={`Description`}
                            onFocus={() => {
                              if (!userInput) {
                                setUserInput(true);
                              }
                              setShowSuggestions(true);
                            }}
                            onBlur={() => {
                              setShowSuggestions(false);
                            }}
                            onChange={(value, changedBy) => {
                              if (value[value.length - 1] == "#") {
                                setShowInputs(true);
                              }

                              onChangeComponent(
                                itemOpenIndex,
                                activeUnitIndex,
                                componentIndex,
                                project,
                                setProject,
                                updateFBRDB,
                                "description",
                                value,
                                changedBy,
                                componentSuggestions
                              );
                            }}
                            disabled={
                              activeRevision !== null ||
                              accountType === "Project" ||
                              project.rooms[itemOpenIndex]["Room Name"] ===
                                "Non tender items"
                            }
                            suggestions={suggestions}
                            suggestionOptions={
                              userInput
                                ? componentSuggestions
                                    ?.filter((item) =>
                                      item.description
                                        .toLowerCase()
                                        .includes(
                                          component["description"].toLowerCase()
                                        )
                                    )
                                    .map((item) =>
                                      item.description
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')
                                    )
                                : []
                            }
                          />

                          {showInputs !== null &&
                            showInputs === componentIndex &&
                            activeDimensionIndex === componentIndex && (
                              <div className="dimensionInputs">
                                <Input
                                  ref={dimensionRef}
                                  onKeyDown={(e) =>
                                    handleTabKeyPress(e, quantityRef.current)
                                  }
                                  placeholder="Length (feet)"
                                  value={
                                    String(component["length"]).split(".")
                                      .length > 1
                                      ? String(component["length"]).split(
                                          "."
                                        )[0] +
                                        `'` +
                                        (fractionsArray.indexOf(
                                          Number(
                                            "0." +
                                              String(component["length"]).split(
                                                "."
                                              )[1]
                                          )
                                        ) +
                                          1) +
                                        `"` // Adjust rounding logic
                                      : component["length"]
                                      ? String(component["length"]) + `'0"`
                                      : ""
                                  }
                                  // value={component["length"]}
                                  // value={
                                  //   String(component.length).includes('.') ?
                                  //   component["length"].split('.')[0] + `'` + fractionsArray.indexOf(component["length"].split('.')[1] + `"`)
                                  //   : 0
                                  // }

                                  // value={
                                  //   component["length"]
                                  //     ? `${Math.floor(component["length"])}'${Math.round((component["length"] % 1) * (countDecimalPlaces(component["length"]) === 2 ? 100 : 10) * 10) / 10}"` // Adjust rounding logic
                                  //     : ""
                                  // }

                                  type="text"
                                  onChange={(value) => {
                                    value = value.replace(
                                      "<single-quote>",
                                      "'"
                                    );
                                    value = value.replace(
                                      "<double-quote>",
                                      '"'
                                    );

                                    let feet, inches;
                                    // let feet, inches, multiplier;
                                    if (value.includes(".")) {
                                      let splittedValue = value.split(".");
                                      feet = splittedValue[0];
                                      inches = convertFractionIntoInches(
                                        Number("0." + splittedValue[1])
                                      );
                                      // const findClosestNumber = (
                                      //   num,
                                      //   numArray
                                      // ) =>
                                      //   numArray.reduce((a, b) =>
                                      //     Math.abs(b - num) < Math.abs(a - num)
                                      //       ? b
                                      //       : a
                                      //   );
                                      // inches = findClosestNumber(Number(splittedValue[1]), fractionsArray)
                                    } else {
                                      if (value.includes("'")) {
                                        let splittedValue = value.split("'");
                                        if (splittedValue[1] === "") {
                                          feet = splittedValue[0];
                                          inches = 0;
                                        } else {
                                          feet = splittedValue[0];
                                          inches =
                                            fractionsArray[
                                              Number(
                                                splittedValue[1].replace(
                                                  '"',
                                                  ""
                                                )
                                              ) - 1
                                            ];
                                        }
                                      } else {
                                        feet = value;
                                        inches = 0;
                                      }
                                    }

                                    // if (splittedValue.length == 1) {
                                    //   console.log("splitted value", splittedValue.length);
                                    //   if ((parseFloat(splittedValue[0]) % 1).toFixed(2) >= 0) {
                                    //     feet = parseInt(splittedValue[0])
                                    //     multiplier = countDecimalPlaces(parseFloat(splittedValue[0])) === 2 ? 100 : 10;
                                    //     inches = (parseFloat(splittedValue[0]) % 1).toFixed(2) * multiplier
                                    //   }
                                    // } else {
                                    //   feet = splittedValue[0];
                                    //   inches = splittedValue[1];
                                    // }

                                    onChangeComponent(
                                      itemOpenIndex,
                                      activeUnitIndex,
                                      componentIndex,
                                      project,
                                      setProject,
                                      updateFBRDB,
                                      "length",
                                      parseFloat(
                                        String(Number(feet) + Number(inches))
                                      )
                                      // parseFloat(`${Number(feet)}+${Number(inches)}`)
                                    );
                                  }}
                                  disabled={
                                    activeRevision !== null ||
                                    accountType === "Project" ||
                                    project.rooms[itemOpenIndex][
                                      "Room Name"
                                    ] === "Non tender items"
                                  }
                                  placeHolderAlwaysOnTop={true}
                                  InputField="length"
                                />

                                <Input
                                  type="number"
                                  placeholder="Breadth (feet)"
                                  // value={
                                  //   component["breadth"]
                                  //     ? `${Math.floor(component["breadth"])}'${
                                  //         Math.round(
                                  //           (component["breadth"] % 1) *
                                  //             (countDecimalPlaces(
                                  //               component["breadth"]
                                  //             ) === 2
                                  //               ? 100
                                  //               : 10) *
                                  //             10
                                  //         ) / 10
                                  //       }"` // Adjust rounding logic
                                  //     : ""
                                  // }

                                  value={
                                    String(component["breadth"]).split(".")
                                      .length > 1
                                      ? String(component["breadth"]).split(
                                          "."
                                        )[0] +
                                        `'` +
                                        (fractionsArray.indexOf(
                                          Number(
                                            "0." +
                                              String(
                                                component["breadth"]
                                              ).split(".")[1]
                                          )
                                        ) +
                                          1) +
                                        `"` // Adjust rounding logic
                                      : component["breadth"]
                                      ? String(component["breadth"]) + `'0"`
                                      : ""
                                  }
                                  onChange={(value) => {
                                    value = value.replace(
                                      "<single-quote>",
                                      "'"
                                    );
                                    value = value.replace(
                                      "<double-quote>",
                                      '"'
                                    );

                                    // let splittedValue = value.split("'");
                                    // let feet, inches, multiplier;
                                    // if (splittedValue.length == 1) {
                                    //   if (
                                    //     (
                                    //       parseFloat(splittedValue[0]) % 1
                                    //     ).toFixed(2) >= 0
                                    //   ) {
                                    //     feet = parseInt(splittedValue[0]);
                                    //     multiplier =
                                    //       countDecimalPlaces(
                                    //         parseFloat(splittedValue[0])
                                    //       ) === 2
                                    //         ? 100
                                    //         : 10;
                                    //     inches =
                                    //       (
                                    //         parseFloat(splittedValue[0]) % 1
                                    //       ).toFixed(2) * multiplier;
                                    //   }
                                    // } else {
                                    //   feet = splittedValue[0];
                                    //   inches = splittedValue[1];
                                    // }

                                    let feet, inches;
                                    // let feet, inches, multiplier;
                                    if (value.includes(".")) {
                                      let splittedValue = value.split(".");
                                      feet = splittedValue[0];
                                      inches = convertFractionIntoInches(
                                        Number("0." + splittedValue[1])
                                      );
                                      // const findClosestNumber = (
                                      //   num,
                                      //   numArray
                                      // ) =>
                                      //   numArray.reduce((a, b) =>
                                      //     Math.abs(b - num) < Math.abs(a - num)
                                      //       ? b
                                      //       : a
                                      //   );
                                      // inches = findClosestNumber(Number(splittedValue[1]), fractionsArray)
                                    } else {
                                      if (value.includes("'")) {
                                        let splittedValue = value.split("'");
                                        if (splittedValue[1] === "") {
                                          feet = splittedValue[0];
                                          inches = 0;
                                        } else {
                                          feet = splittedValue[0];
                                          inches =
                                            fractionsArray[
                                              Number(
                                                splittedValue[1].replace(
                                                  '"',
                                                  ""
                                                )
                                              ) - 1
                                            ];
                                        }
                                      } else {
                                        feet = value;
                                        inches = 0;
                                      }
                                    }

                                    onChangeComponent(
                                      itemOpenIndex,
                                      activeUnitIndex,
                                      componentIndex,
                                      project,
                                      setProject,
                                      updateFBRDB,
                                      "breadth",
                                      parseFloat(
                                        String(Number(feet) + Number(inches))
                                      )
                                      // parseFloat(`${feet}.${inches}`)
                                    );
                                  }}
                                  disabled={
                                    activeRevision !== null ||
                                    accountType === "Project" ||
                                    project.rooms[itemOpenIndex][
                                      "Room Name"
                                    ] === "Non tender items"
                                  }
                                  placeHolderAlwaysOnTop={true}
                                  InputField="breadth"
                                />

                                <Input
                                  type="number"
                                  placeholder="Height (feet)"
                                  // value={
                                  //   component["height"]
                                  //     ? `${Math.floor(component["height"])}'${
                                  //         Math.round(
                                  //           (component["height"] % 1) *
                                  //             (countDecimalPlaces(
                                  //               component["height"]
                                  //             ) === 2
                                  //               ? 100
                                  //               : 10) *
                                  //             10
                                  //         ) / 10
                                  //       }"` // Adjust rounding logic
                                  //     : ""
                                  // }
                                  value={
                                    String(component["height"]).split(".")
                                      .length > 1
                                      ? String(component["height"]).split(
                                          "."
                                        )[0] +
                                        `'` +
                                        (fractionsArray.indexOf(
                                          Number(
                                            "0." +
                                              String(component["height"]).split(
                                                "."
                                              )[1]
                                          )
                                        ) +
                                          1) +
                                        `"` // Adjust rounding logic
                                      : component["height"]
                                      ? String(component["height"]) + `'0"`
                                      : ""
                                  }
                                  onChange={(value) => {
                                    value = value.replace(
                                      "<single-quote>",
                                      "'"
                                    );
                                    value = value.replace(
                                      "<double-quote>",
                                      '"'
                                    );

                                    // let splittedValue = value.split("'");
                                    // let feet, inches, multiplier;
                                    // if (splittedValue.length == 1) {
                                    //   if (
                                    //     (
                                    //       parseFloat(splittedValue[0]) % 1
                                    //     ).toFixed(2) >= 0
                                    //   ) {
                                    //     feet = parseInt(splittedValue[0]);
                                    //     multiplier =
                                    //       countDecimalPlaces(
                                    //         parseFloat(splittedValue[0])
                                    //       ) === 2
                                    //         ? 100
                                    //         : 10;
                                    //     inches =
                                    //       (
                                    //         parseFloat(splittedValue[0]) % 1
                                    //       ).toFixed(2) * multiplier;
                                    //   }
                                    // } else {
                                    //   feet = splittedValue[0];
                                    //   inches = splittedValue[1];
                                    // }

                                    let feet, inches;
                                    // let feet, inches, multiplier;
                                    if (value.includes(".")) {
                                      let splittedValue = value.split(".");
                                      feet = splittedValue[0];
                                      console.log(
                                        "feet decimal found splittedValue[1] : ",
                                        splittedValue[1]
                                      );
                                      inches = convertFractionIntoInches(
                                        Number("0." + splittedValue[1])
                                      );
                                      // const findClosestNumber = (
                                      //   num,
                                      //   numArray
                                      // ) =>
                                      //   numArray.reduce((a, b) =>
                                      //     Math.abs(b - num) < Math.abs(a - num)
                                      //       ? b
                                      //       : a
                                      //   );
                                      // inches = findClosestNumber(Number(splittedValue[1]), fractionsArray)
                                      console.log("feet inches : ", inches);
                                    } else {
                                      if (value.includes("'")) {
                                        let splittedValue = value.split("'");
                                        if (splittedValue[1] === "") {
                                          feet = splittedValue[0];
                                          inches = 0;
                                        } else {
                                          console.log(
                                            "feet splittedValue : ",
                                            splittedValue
                                          );
                                          console.log(
                                            "feet fractionsArray[Number(splittedValue[1]) - 1] : ",
                                            fractionsArray[
                                              Number(
                                                splittedValue[1].replace(
                                                  '"',
                                                  ""
                                                )
                                              ) - 1
                                            ]
                                          );
                                          feet = splittedValue[0];
                                          inches =
                                            fractionsArray[
                                              Number(
                                                splittedValue[1].replace(
                                                  '"',
                                                  ""
                                                )
                                              ) - 1
                                            ];
                                        }
                                      } else {
                                        feet = value;
                                        inches = 0;
                                      }
                                    }

                                    onChangeComponent(
                                      itemOpenIndex,
                                      activeUnitIndex,
                                      componentIndex,
                                      project,
                                      setProject,
                                      updateFBRDB,
                                      "height",
                                      parseFloat(
                                        String(Number(feet) + Number(inches))
                                      )
                                      // parseFloat(`${feet}.${inches}`)
                                    );
                                  }}
                                  disabled={
                                    activeRevision !== null ||
                                    accountType === "Project" ||
                                    project.rooms[itemOpenIndex][
                                      "Room Name"
                                    ] === "Non tender items"
                                  }
                                  placeHolderAlwaysOnTop={true}
                                  InputField="height"
                                />
                              </div>
                            )}

                          <div
                            className="dimensionInputs"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <Input
                                ref={quantityRef}
                                onKeyDown={(e) =>
                                  handleTabKeyPress(e, nextFieldRef.current)
                                }
                                placeholder="Qty"
                                // value={Math.round(Number(quantity).toFixed(0))}
                                value={quantity}
                                onChange={(value) =>
                                  onChangeComponent(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    componentIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "quantity",
                                    value
                                  )
                                }
                                disabled={
                                  activeRevision !== null ||
                                  accountType === "Project" ||
                                  project.rooms[itemOpenIndex]["Room Name"] ===
                                    "Non tender items"
                                }
                                placeHolderAlwaysOnTop={true}
                                InputField="quantity"
                                style={{ width: "80px" }} // Adjust width as needed
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <Input
                                ref={nextFieldRef}
                                placeholder="Unit"
                                value={component["unit"]}
                                onChange={(value) =>
                                  onChangeComponent(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    componentIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "unit",
                                    value
                                  )
                                }
                                disabled={
                                  activeRevision !== null ||
                                  accountType === "Project" ||
                                  project.rooms[itemOpenIndex]["Room Name"] ===
                                    "Non tender items"
                                }
                                placeHolderAlwaysOnTop={true}
                                InputField="unit"
                                style={{ width: "80px" }} // Adjust width as needed
                              />
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Input
                                placeholder="Rate"
                                value={component["rate"]}
                                type="number"
                                onChange={(value) =>
                                  onChangeComponent(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    componentIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "rate",
                                    value
                                  )
                                }
                                disabled={
                                  activeRevision !== null ||
                                  accountType === "Project" ||
                                  project.rooms[itemOpenIndex]["Room Name"] ===
                                    "Non tender items"
                                }
                                placeHolderAlwaysOnTop={true}
                                InputField="rate"
                                style={{ width: "80px" }} // Adjust width as needed
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              "justify-content": "space-between",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                "font-weight": "700",
                                fontSize: "small",
                              }}
                            >
                              INR{" "}
                              {parseFloat(
                                Math.round(
                                  Number(quantity) *
                                    Number(component["rate"] || 0)
                                )
                              )
                                .toFixed(2)
                                .replace(/\.00$/, ".00")}
                            </div>
                            {accountType != "BOQ" && (
                              <div
                                style={{
                                  color:
                                    differ?.percentage > 0 ? "red" : "green",
                                  fontWeight: "bold",
                                }}
                              >
                                {differ?.percentage &&
                                  (differ.percentage > 0 ? (
                                    <AiFillCaretDown />
                                  ) : (
                                    <AiFillCaretUp />
                                  ))}
                                {differ?.value || ""}
                                {differ?.percentage
                                  ? ` (${differ.percentage
                                      .toString()
                                      .replace("-", "")}%)`
                                  : ""}
                              </div>
                            )}
                            <Tooltip
                              content="Click to add vendor details for work and material"
                              width={200}
                              left={-100}
                            >
                              <div
                                onClick={() => {
                                  setActiveComponentIndex(componentIndex);
                                }}
                                style={{
                                  color: "#275571",
                                  "font-size": "12px",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                              >
                                Add Vendors {">>"}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                    {project?.rooms[itemOpenIndex].Units[activeUnitIndex]
                      .Components.length === 0 &&
                      activeRevision === null && (
                        <Button1
                          content="Add Component"
                          style={{ margin: 10 }}
                          onClick={() =>
                            addNewComponent(
                              itemOpenIndex,
                              activeUnitIndex,
                              0,
                              project,
                              setProject,
                              updateFBRDB
                            )
                          }
                        />
                      )}
                  </div>
                )}{" "}
              <div
                style={{
                  height: "600px",
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (project) {
      fetchDrafts();
    }
  }, [activeTab, project]);

  const [savedDrafts, setSavedDrafts] = useState([]);
  const [draftsOrderId, setDraftsOrderId] = useState([]);

  const fetchDrafts = async () => {
    const draftService = new DraftService();
    const response = await draftService.getDraft(project.ProjectId);
    console.log(`Response from fetchDrafts API: `, response);
    setSavedDrafts(response.payload);
  };

  useEffect(() => {
    if (savedDrafts) {
      let allOrderIdsDrafts = [];
      savedDrafts.map((draft) =>
        JSON.parse(draft.selectedItems).map((item) =>
          allOrderIdsDrafts.push(item.OrderId)
        )
      );
      setDraftsOrderId(allOrderIdsDrafts);
    }
  }, [savedDrafts]);

  const renderWorkMaterial = () => {
    const selectedComponent =
      project?.rooms[itemOpenIndex]?.Units[activeUnitIndex]?.Components[
        activeComponentIndex
      ];
    return (
      <div>
        <div>Work/Material</div>
        {activeComponentIndex !== undefined && (
          <div>
            <div>
              <div className="heading" onClick={() => setShowWork((st) => !st)}>
                <div>Work</div>
                <div>
                  {"INR "}
                  {parseFloat(
                    selectedComponent?.Work?.reduce(
                      (total, work) =>
                        total +
                        (Number(work.quantity) *
                          Number(
                            work?.discountedRate &&
                              work.discountedRate !== "null"
                              ? work.discountedRate
                              : work.rate == "quotation"
                              ? 0
                              : work.rate
                          ) +
                          work.quantity *
                            (work?.discountedRate &&
                            work.discountedRate !== "null"
                              ? work.discountedRate
                              : work.rate == "quotation"
                              ? 0
                              : work.rate) *
                            (work.gst / 100)),
                      0
                    )
                  ).toFixed(2)}
                </div>
                {showWork ? (
                  <FiChevronUp
                    className="FiChevron"
                    onClick={() => {
                      record(
                        TAGS[
                          ("@hide_work_icon_click_success",
                          { description: "Hide work icon click success" })
                        ]
                      );
                    }}
                  />
                ) : (
                  <FiChevronDown
                    className="FiChevron"
                    onClick={() => {
                      record(
                        TAGS[
                          ("@show_work_icon_click_success",
                          { description: "Show work icon click success" })
                        ]
                      );
                    }}
                  />
                )}
              </div>
              {showWork && (
                <div className="workmaterials">
                  {selectedComponent?.Work?.map((work, workIndex) => {
                    const isNonTenderItem =
                      project.rooms[itemOpenIndex]["Room Name"] ===
                      "Non tender items";
                    console.log(
                      "this is the non tender items",
                      isNonTenderItem
                    );
                    return (
                      <div
                        key={workIndex}
                        tabIndex="1"
                        id={`workIndex ${workIndex}`}
                        className="workmaterial"
                        style={
                          workActive === workIndex
                            ? { backgroundColor: "rgb(255, 211, 77)" }
                            : {}
                        }
                      >
                        <div>
                          <div>Vendor {workIndex + 1}</div>
                          <PopOverView
                            position="left"
                            component={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                  fontSize: 12,
                                  color: "grey",
                                  alignItems: "flex-start",
                                  width: 140,
                                }}
                              >
                                {isNonTenderItem ? (
                                  <div
                                    className="menuItem"
                                    onMouseDown={(e) => {
                                      if (
                                        accountType == "BOQ" ||
                                        accountType == "Finance"
                                      ) {
                                        toast.error(
                                          "You don't have access to Purchase!",
                                          {
                                            backgroundColor: "red",
                                            color: "black",
                                          }
                                        );
                                      } else {
                                        if (
                                          work.status != "inApproval" &&
                                          work.rate != "quotation"
                                        ) {
                                          e.stopPropagation();
                                          setActiveTab(1);
                                          updateFBRDBEditStatus({
                                            isEditing: false,
                                            currentUser: "null",
                                            lastEdited: Date.now(),
                                          });
                                          history.push(
                                            "/ReleasedOrders/" +
                                              project.ProjectId,
                                            {
                                              data: {
                                                ...project,
                                                rooms: project.rooms,
                                                ProjectId: project.ProjectId,
                                                ClientName: project.ClientName,
                                                Address: project.Address,
                                                AmountRecieved:
                                                  project.AmountReceived,
                                                SiteEngineerId:
                                                  project.SiteEngineersId,
                                              },
                                              state: {
                                                gotoReleased: true,
                                                workItem: true,
                                                selectedIndex: {
                                                  workIndex: workIndex,
                                                  selectedCategory:
                                                    work["vendorCategory"],
                                                  OrderIdSelected:
                                                    work?.OrderId,
                                                  ComponentIndex:
                                                    activeComponentIndex,
                                                  UnitIndex: activeUnitIndex,
                                                  ItemIndex: itemOpenIndex,
                                                },
                                              },
                                            }
                                          );
                                        } else {
                                          toast.warn(
                                            "This Item is either in Approval or in an Quotation"
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    Go to Order
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      onMouseDown={(e) => {
                                        addWork(
                                          itemOpenIndex,
                                          activeUnitIndex,
                                          activeComponentIndex,
                                          workIndex,
                                          project,
                                          setProject,
                                          updateFBRDB
                                        );
                                        e.stopPropagation();
                                      }}
                                      className="menuItem"
                                    >
                                      Add Work Below
                                    </div>
                                    {activeRevision !== null ||
                                    work.status === "ordered" ||
                                    work.status === "inApproval" ||
                                    work.rate == "quotation" ? null : (
                                      <div
                                        onMouseDown={(e) => {
                                          deleteWork(
                                            itemOpenIndex,
                                            activeUnitIndex,
                                            activeComponentIndex,
                                            workIndex,
                                            project,
                                            setProject,
                                            updateFBRDB
                                          );
                                          e.stopPropagation();
                                        }}
                                        className="menuItem"
                                      >
                                        Delete
                                      </div>
                                    )}
                                    {activeRevision !== null ||
                                    work.status === "ordered" ||
                                    work.status === "inApproval" ||
                                    work.rate === "quotation" ? (
                                      <div
                                        className="menuItem"
                                        onMouseDown={(e) => {
                                          if (
                                            accountType === "BOQ" ||
                                            accountType === "Finance"
                                          ) {
                                            toast.error(
                                              "You don't have access to Purchase!",
                                              {
                                                backgroundColor: "red",
                                                color: "black",
                                              }
                                            );
                                          } else {
                                            if (
                                              work.status != "inApproval" &&
                                              work.rate != "quotation"
                                            ) {
                                              e.stopPropagation();
                                              setActiveTab(1);
                                              updateFBRDBEditStatus({
                                                isEditing: false,
                                                currentUser: "null",
                                                lastEdited: Date.now(),
                                              });
                                              history.push(
                                                "/ReleasedOrders/" +
                                                  project.ProjectId,
                                                {
                                                  data: {
                                                    ...project,
                                                    rooms: project.rooms,
                                                    ProjectId:
                                                      project.ProjectId,
                                                    ClientName:
                                                      project.ClientName,
                                                    Address: project.Address,
                                                    AmountRecieved:
                                                      project.AmountReceived,
                                                    SiteEngineerId:
                                                      project.SiteEngineersId,
                                                  },
                                                  state: {
                                                    gotoReleased: true,
                                                    workItem: true,
                                                    selectedIndex: {
                                                      workIndex: workIndex,
                                                      selectedCategory:
                                                        work["vendorCategory"],
                                                      OrderIdSelected:
                                                        work?.OrderId,
                                                      ComponentIndex:
                                                        activeComponentIndex,
                                                      UnitIndex:
                                                        activeUnitIndex,
                                                      ItemIndex: itemOpenIndex,
                                                    },
                                                  },
                                                }
                                              );
                                            } else {
                                              toast.warn(
                                                "This Item is either in Approval or in an Quotation"
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        Go to Order
                                      </div>
                                    ) : (
                                      // null
                                      <div
                                        className="menuItem"
                                        onMouseDown={(e) => {
                                          if (
                                            accountType == "BOQ" ||
                                            accountType == "Finance"
                                          ) {
                                            toast.error(
                                              "You don't have access to Purchase!",
                                              {
                                                backgroundColor: "red",
                                                color: "black",
                                              }
                                            );
                                          } else {
                                            updateFBRDBEditStatus({
                                              isEditing: false,
                                              currentUser: "null",
                                              lastEdited: Date.now(),
                                            });

                                            e.stopPropagation();
                                            setActiveTab(1);
                                            history.push(
                                              "/UnreleasedItems/" +
                                                project.ProjectId,
                                              {
                                                data: {
                                                  ...project,
                                                  rooms: project.rooms,
                                                  ProjectId: project.ProjectId,
                                                  ClientName:
                                                    project.ClientName,
                                                  Address: project.Address,
                                                  AmountRecieved:
                                                    project.AmountReceived,
                                                  SiteEngineerId:
                                                    project.SiteEngineersId,
                                                },
                                                state: {
                                                  gotoUnreleased: true,
                                                  workItem: true,
                                                  selectedIndex: {
                                                    workIndex: workIndex,
                                                    highLightIndexArr:
                                                      activeComponentIndex,
                                                    selectedCategory:
                                                      work["vendorCategory"],
                                                  },
                                                },
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        Go to Item
                                      </div>
                                    )}
                                    <div
                                      onMouseDown={(e) => {
                                        addSuggestiveTextWork(
                                          work.vendorCategory,
                                          work.workType,
                                          work.description,
                                          work.rate,
                                          work.unit,
                                          work.gst
                                        );
                                        e.stopPropagation();
                                      }}
                                      className="menuItem"
                                    >
                                      Add to suggestive items
                                    </div>
                                  </>
                                )}
                              </div>
                            }
                          >
                            <FiMoreVertical />
                          </PopOverView>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip content="Click to choose work category">
                            <DropDownMUI
                              placeholder={"Category"}
                              data={categories?.vendorCategories.map(
                                (category, index) => category.Category
                              )}
                              onChange={(value) => {
                                onChangeWork(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  workIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "vendorCategory",
                                  value
                                );
                              }}
                              value={work.vendorCategory}
                              disabled={
                                activeRevision !== null ||
                                work.status === "ordered" ||
                                work.status === "inApproval" ||
                                work.rate == "quotation"
                                // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                              }
                            />
                          </Tooltip>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Tooltip content="Click to choose work type">
                              <FormControlLabel
                                control={
                                  <MSwitch
                                    color="primary"
                                    checked={work.workType == "Work + Material"}
                                    disabled={
                                      activeRevision !== null ||
                                      work.status === "inApproval" ||
                                      work.status === "ordered"
                                      // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                                    }
                                    onChange={(e) =>
                                      onChangeWork(
                                        itemOpenIndex,
                                        activeUnitIndex,
                                        activeComponentIndex,
                                        workIndex,
                                        project,
                                        setProject,
                                        updateFBRDB,
                                        "workType",
                                        e.target.checked
                                          ? "Work + Material"
                                          : "Only Work"
                                      )
                                    }
                                  />
                                }
                                label={
                                  <div>
                                    <div
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {work.workType}
                                    </div>
                                  </div>
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <div>
                          <Input
                            placeholder="Description"
                            onChange={(value) => {
                              setWorkActive();
                              onChangeWork(
                                itemOpenIndex,
                                activeUnitIndex,
                                activeComponentIndex,
                                workIndex,
                                project,
                                setProject,
                                updateFBRDB,
                                "description",
                                value
                              );
                            }}
                            value={work.description}
                            disabled={
                              activeRevision !== null ||
                              work.status === "ordered" ||
                              work.status === "inApproval" ||
                              work.rate == "quotation"
                              // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                            }
                            placeHolderAlwaysOnTop={true}
                            InputField="description"
                            suggestions={workSuggestions
                              .filter(
                                (item) => item.category == work.vendorCategory
                              )
                              .map((item) =>
                                item.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')
                              )}
                          />
                          {work.description.length > 0 &&
                          !work.description.includes("@") ? (
                            <p style={{ color: "red" }}>Brand is not defined</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <Input
                            placeholder="Qty"
                            onChange={(value) => {
                              setWorkActive();
                              onChangeWork(
                                itemOpenIndex,
                                activeUnitIndex,
                                activeComponentIndex,
                                workIndex,
                                project,
                                setProject,
                                updateFBRDB,
                                "quantity",
                                value
                              );
                            }}
                            value={work.quantity}
                            disabled={
                              activeRevision !== null ||
                              work.status === "ordered" ||
                              work.status === "inApproval" ||
                              work.rate == "quotation"
                              // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                            }
                            placeHolderAlwaysOnTop={true}
                            InputField="quantity"
                          />
                          <Input
                            placeholder="Unit"
                            onChange={(value) => {
                              setWorkActive();
                              onChangeWork(
                                itemOpenIndex,
                                activeUnitIndex,
                                activeComponentIndex,
                                workIndex,
                                project,
                                setProject,
                                updateFBRDB,
                                "unit",
                                value
                              );
                            }}
                            value={work.unit}
                            disabled={
                              activeRevision !== null ||
                              work.status === "ordered" ||
                              work.status === "inApproval" ||
                              work.rate == "quotation"
                              // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                            }
                            placeHolderAlwaysOnTop={true}
                            InputField="unit"
                          />
                          <div>
                            <Input
                              placeholder="Rate"
                              onChange={(value) => {
                                setWorkActive();
                                onChangeWork(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  workIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "rate",
                                  value
                                );
                              }}
                              value={
                                work?.discountedRate
                                  ? work?.discountedRate !== "null"
                                    ? work.discountedRate
                                    : work.rate
                                  : work.rate
                              }
                              disabled={
                                activeRevision !== null ||
                                work.status === "ordered" ||
                                work.status === "inApproval" ||
                                work.rate == "quotation"
                                // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                              }
                              placeHolderAlwaysOnTop={true}
                              InputField="rate"
                            />
                            {work.discountedRate &&
                              work?.discountedRate !== "null" && (
                                <p>O.Rate{work.rate}</p>
                              )}
                          </div>
                          <Input
                            placeholder="GST(%)"
                            onChange={(value) => {
                              setWorkActive();
                              if (value <= 100) {
                                onChangeWork(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  workIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "gst",
                                  value
                                );
                              }
                            }}
                            value={work.gst}
                            disabled={
                              activeRevision !== null ||
                              work.status === "ordered" ||
                              work.status === "inApproval" ||
                              work.rate == "quotation"
                              // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                            }
                            placeHolderAlwaysOnTop={true}
                            InputField="gst"
                          />
                        </div>
                        <div>
                          <div>
                            {"INR "}
                            {work.quantity == undefined ||
                            work.rate == undefined ||
                            work.rate == "quotation" ||
                            work.gst == undefined
                              ? 0
                              : parseInt(work.discountedRate)
                              ? (
                                  Math.round(
                                    (work.quantity * work.discountedRate +
                                      work.quantity *
                                        work.discountedRate *
                                        (work.gst / 100)) *
                                      100
                                  ) / 100
                                ).toFixed(2)
                              : (
                                  Math.round(
                                    (work.quantity * work.rate +
                                      work.quantity *
                                        work.rate *
                                        (work.gst / 100)) *
                                      100
                                  ) / 100
                                ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {activeRevision === null && (
                    <Tooltip content="Click to add new work vendor">
                      <Button1
                        content="+"
                        style={{
                          margin: "10px 0px",
                          backgroundColor: "rgb(208, 208, 208)",
                          borderRadius: 2,
                          fontSize: 30,
                          color: "#2F4858",
                          height: 30,
                        }}
                        onClick={() =>
                          addWork(
                            itemOpenIndex,
                            activeUnitIndex,
                            activeComponentIndex,
                            project?.rooms[itemOpenIndex]?.Units[
                              activeUnitIndex
                            ]?.Components[activeComponentIndex]?.Work?.length -
                              1 || 0,
                            project,
                            setProject,
                            updateFBRDB
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <div>
              <div
                className="heading"
                onClick={() => setShowMaterial((st) => !st)}
              >
                <div>Material</div>
                <div>
                  {"INR "}
                  {parseFloat(
                    selectedComponent?.Material?.reduce(
                      (total, material) =>
                        total +
                        (Number(material.quantity) *
                          Number(
                            material?.discountedRate &&
                              material.discountedRate !== "null"
                              ? material.discountedRate
                              : material.rate == "quotation"
                              ? 0
                              : material.rate
                          ) +
                          material.quantity *
                            (material?.discountedRate &&
                            material.discountedRate !== "null"
                              ? material.discountedRate
                              : material.rate == "quotation"
                              ? 0
                              : material.rate) *
                            (material.gst / 100)),
                      0
                    )
                  ).toFixed(2)}
                </div>
                {showMaterial ? (
                  <FiChevronUp
                    className="FiChevron"
                    onClick={() => {
                      record(
                        TAGS[
                          ("@hide_material_icon_click_success",
                          { description: "Hide material icon click success" })
                        ]
                      );
                    }}
                  />
                ) : (
                  <FiChevronDown
                    className="FiChevron"
                    onClick={() => {
                      record(
                        TAGS[
                          ("@show_material_icon_click_success",
                          { description: "Show material icon click success" })
                        ]
                      );
                    }}
                  />
                )}
              </div>
              {showMaterial && (
                <div className="workmaterials">
                  {selectedComponent?.Material?.map(
                    (material, materialIndex) => {
                      const isNonTenderItem =
                        project.rooms[itemOpenIndex]["Room Name"] ===
                        "Non tender items";
                      return (
                        <div
                          key={materialIndex}
                          className="workmaterial"
                          tabIndex="1"
                          id={`materialIndex ${materialIndex}`}
                          style={
                            materialActive === materialIndex
                              ? { backgroundColor: "rgb(255, 211, 77)" }
                              : {}
                          }
                        >
                          <div>
                            <div>Vendor {materialIndex + 1}</div>
                            <PopOverView
                              position="left"
                              component={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                    fontSize: 12,
                                    color: "grey",
                                    alignItems: "flex-start",
                                    width: 140,
                                  }}
                                >
                                  {isNonTenderItem ? (
                                    <div
                                      className="menuItem"
                                      onMouseDown={(e) => {
                                        if (
                                          accountType == "BOQ" ||
                                          accountType == "Finance"
                                        ) {
                                          toast.error(
                                            "You don't have access to Purchase!",
                                            {
                                              backgroundColor: "red",
                                              color: "black",
                                            }
                                          );
                                        } else {
                                          if (
                                            material.status != "inApproval" &&
                                            material.rate != "quotation"
                                          ) {
                                            e.stopPropagation();
                                            setActiveTab(1);
                                            updateFBRDBEditStatus({
                                              isEditing: false,
                                              currentUser: "null",
                                              lastEdited: Date.now(),
                                            });
                                            history.push(
                                              "/ReleasedOrders/" +
                                                project.ProjectId,
                                              {
                                                data: {
                                                  ...project,
                                                  rooms: project.rooms,
                                                  ProjectId: project.ProjectId,
                                                  ClientName:
                                                    project.ClientName,
                                                  Address: project.Address,
                                                  AmountRecieved:
                                                    project.AmountReceived,
                                                  SiteEngineerId:
                                                    project.SiteEngineersId,
                                                },
                                                state: {
                                                  gotoReleased: true,
                                                  workItem: true,
                                                  selectedIndex: {
                                                    materialIndex:
                                                      materialIndex,
                                                    selectedCategory:
                                                      material[
                                                        "vendorCategory"
                                                      ],
                                                    OrderIdSelected:
                                                      material?.OrderId,
                                                    ComponentIndex:
                                                      activeComponentIndex,
                                                    UnitIndex: activeUnitIndex,
                                                    ItemIndex: itemOpenIndex,
                                                  },
                                                },
                                              }
                                            );
                                          } else {
                                            toast.warn(
                                              "This Item is either in Approval or in an Quotation"
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      Go to Order
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        onMouseDown={(e) => {
                                          addMaterial(
                                            itemOpenIndex,
                                            activeUnitIndex,
                                            activeComponentIndex,
                                            materialIndex,
                                            project,
                                            setProject,
                                            updateFBRDB
                                          );
                                          e.stopPropagation();
                                        }}
                                        className="menuItem"
                                      >
                                        Add Material Below
                                      </div>
                                      {activeRevision !== null ||
                                      material.status === "ordered" ||
                                      material.status === "inApproval" ||
                                      material.rate == "quotation" ? null : (
                                        <div
                                          onMouseDown={(e) => {
                                            deleteMaterial(
                                              itemOpenIndex,
                                              activeUnitIndex,
                                              activeComponentIndex,
                                              materialIndex,
                                              project,
                                              setProject,
                                              updateFBRDB
                                            );
                                            e.stopPropagation();
                                          }}
                                          className="menuItem"
                                        >
                                          Delete
                                        </div>
                                      )}
                                      {activeRevision !== null ||
                                      material.status === "ordered" ||
                                      material.status === "inApproval" ||
                                      material.rate == "quotation" ? (
                                        // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                                        <div
                                          className="menuItem"
                                          onMouseDown={(e) => {
                                            if (
                                              accountType == "BOQ" ||
                                              accountType == "Finance"
                                            ) {
                                              toast.error(
                                                "You don't have access to Purchase!",
                                                {
                                                  backgroundColor: "red",
                                                  color: "black",
                                                }
                                              );
                                            } else {
                                              if (
                                                material.status !=
                                                  "inApproval" &&
                                                material.rate != "quotation"
                                              ) {
                                                updateFBRDBEditStatus({
                                                  isEditing: false,
                                                  currentUser: "null",
                                                  lastEdited: Date.now(),
                                                });
                                                // console.log(
                                                //   "on go to Item ,selected Item : ",
                                                //   material,
                                                //   materialIndex,
                                                //   "Category : ",
                                                //   material["type"]
                                                // );
                                                e.stopPropagation();
                                                setActiveTab(1);
                                                history.push(
                                                  "/ReleasedOrders/" +
                                                    project.ProjectId,
                                                  {
                                                    data: {
                                                      ...project,
                                                      rooms: project.rooms,
                                                      ProjectId:
                                                        project.ProjectId,
                                                      ClientName:
                                                        project.ClientName,
                                                      Address: project.Address,
                                                      AmountRecieved:
                                                        project.AmountReceived,
                                                      SiteEngineerId:
                                                        project.SiteEngineersId,
                                                    },
                                                    state: {
                                                      gotoUnreleased: true,
                                                      MaterialItem: true,
                                                      selectedIndex: {
                                                        materialIndex:
                                                          materialIndex,
                                                        selectedCategory:
                                                          material["type"],
                                                        OrderIdSelected:
                                                          material?.OrderId,
                                                        ComponentIndex:
                                                          activeComponentIndex,
                                                        UnitIndex:
                                                          activeUnitIndex,
                                                        ItemIndex:
                                                          itemOpenIndex,
                                                      },
                                                    },
                                                  }
                                                );
                                              } else {
                                                toast.warn(
                                                  "This Item is either in Approval or in an Quotation"
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          Go to Order
                                        </div>
                                      ) : (
                                        // null
                                        <div
                                          className="menuItem"
                                          onMouseDown={(e) => {
                                            if (
                                              accountType == "BOQ" ||
                                              accountType == "Finance"
                                            ) {
                                              toast.error(
                                                "You don't have access to Purchase!",
                                                {
                                                  backgroundColor: "red",
                                                  color: "black",
                                                }
                                              );
                                            } else {
                                              updateFBRDBEditStatus({
                                                isEditing: false,
                                                currentUser: "null",
                                                lastEdited: Date.now(),
                                              });

                                              e.stopPropagation();
                                              setActiveTab(1);
                                              history.push(
                                                "/UnreleasedItems/" +
                                                  project.ProjectId,
                                                {
                                                  data: {
                                                    ...project,
                                                    rooms: project.rooms,
                                                    ProjectId:
                                                      project.ProjectId,
                                                    ClientName:
                                                      project.ClientName,
                                                    Address: project.Address,
                                                    AmountRecieved:
                                                      project.AmountReceived,
                                                    SiteEngineerId:
                                                      project.SiteEngineersId,
                                                  },
                                                  state: {
                                                    gotoUnreleased: true,
                                                    MaterialItem: true,
                                                    selectedIndex: {
                                                      materialIndex:
                                                        materialIndex,
                                                      selectedCategory:
                                                        material["type"],
                                                      highLightIndexArr:
                                                        activeComponentIndex,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          Go to Item
                                        </div>
                                      )}
                                      <div
                                        onMouseDown={(e) => {
                                          addSuggestiveTextWork(
                                            material.vendorCategory,
                                            material.workType,
                                            material.description,
                                            material.rate,
                                            material.unit,
                                            material.gst
                                          );
                                          e.stopPropagation();
                                        }}
                                        className="menuItem"
                                      >
                                        Add to suggestive items
                                      </div>
                                    </>
                                  )}
                                </div>
                              }
                            >
                              <FiMoreVertical />
                            </PopOverView>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip content="Click to choose material category">
                              <DropDownMUI
                                placeholder={"Category"}
                                data={
                                  categories &&
                                  categories.materialCategories.map(
                                    (x) => x.name
                                  )
                                }
                                onChange={(value) => {
                                  onChangeMaterial(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    activeComponentIndex,
                                    materialIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "type",
                                    value
                                  );
                                }}
                                value={material.type}
                                disabled={
                                  activeRevision !== null ||
                                  material.status === "ordered" ||
                                  material.status === "inApproval" ||
                                  material.rate == "quotation"
                                  // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                                }
                              />
                            </Tooltip>
                            {draftsOrderId.includes(material.OrderId) && (
                              <p
                                style={{
                                  color: "#4685D4",
                                  fontWeight: "bold",
                                }}
                              >
                                Already in draft
                              </p>
                            )}
                          </div>
                          <div style={{ position: "relative" }}>
                            <TextArea
                              filterwork={material}
                              brandSuggestion={true}
                              arry={categories.materialCategories.find(
                                (o) => o.name === material.type
                              )}
                              workIndex={materialIndex}
                              placeholder="Specification"
                              onChange={(value, changedBy) => {
                                if (changedBy === "onChangeAutocomplete") {
                                  const selectedSuggestion =
                                    materialSuggestions.find(
                                      (item) =>
                                        item?.specification
                                          .replace(/<new-line>/g, "\n")
                                          .replace(/<single-quote>/g, "'")
                                          .replace(/<double-quote>/g, '"') ===
                                        value
                                    );

                                  if (selectedSuggestion) {
                                    onChangeMaterial(
                                      itemOpenIndex,
                                      activeUnitIndex,
                                      activeComponentIndex,
                                      materialIndex,
                                      project,
                                      setProject,
                                      updateFBRDB,
                                      "type",
                                      selectedSuggestion.category
                                    );
                                  }
                                }

                                onChangeMaterial(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  materialIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "specification",
                                  value,
                                  changedBy,
                                  materialSuggestions
                                );
                              }}
                              value={material.specification}
                              disabled={
                                activeRevision !== null ||
                                material.status === "ordered" ||
                                material.status === "inApproval" ||
                                material.rate == "quotation"
                              }
                              suggestions={suggestions}
                              setSuggestions={setSuggestions}
                              suggestionOptions={[
                                // Get unique suggestions by using Set
                                ...new Set([
                                  // Current category suggestions
                                  ...materialSuggestions
                                    .filter(
                                      (item) => item.category === material.type
                                    )
                                    .map(
                                      (item) =>
                                        item?.specification
                                          .replace(/<new-line>/g, "\n")
                                          .replace(/<single-quote>/g, "'")
                                          .replace(/<double-quote>/g, '"') || ""
                                    ),
                                  // Divider if needed
                                  ...(materialSuggestions.some(
                                    (item) => item.category === material.type
                                  )
                                    ? ["───────────"]
                                    : []),
                                  // Other categories suggestions
                                  ...materialSuggestions
                                    .filter(
                                      (item) => item.category !== material.type
                                    )
                                    .map(
                                      (item) =>
                                        item?.specification
                                          .replace(/<new-line>/g, "\n")
                                          .replace(/<single-quote>/g, "'")
                                          .replace(/<double-quote>/g, '"') || ""
                                    ),
                                ]),
                              ]}
                            />
                            {material.specification.length > 0 &&
                            !material.specification.includes("@") ? (
                              <p style={{ color: "red" }}>
                                Brand is not defined
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            <Input
                              placeholder="Qty"
                              onChange={(value) => {
                                onChangeMaterial(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  materialIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "quantity",
                                  value
                                );
                              }}
                              value={material.quantity}
                              disabled={
                                activeRevision !== null ||
                                material.status === "ordered" ||
                                material.status === "inApproval" ||
                                material.rate == "quotation"
                                // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                              }
                              placeHolderAlwaysOnTop={true}
                              InputField="quantity"
                            />
                            <Input
                              placeholder="Unit"
                              onChange={(value) => {
                                onChangeMaterial(
                                  itemOpenIndex,
                                  activeUnitIndex,
                                  activeComponentIndex,
                                  materialIndex,
                                  project,
                                  setProject,
                                  updateFBRDB,
                                  "unit",
                                  value
                                );
                              }}
                              value={material.unit}
                              disabled={
                                activeRevision !== null ||
                                material.status === "ordered" ||
                                material.status === "inApproval" ||
                                material.rate == "quotation"
                                // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                              }
                              placeHolderAlwaysOnTop={true}
                              InputField="unit"
                            />
                            <div>
                              <Input
                                placeholder="Rate"
                                onChange={(value) => {
                                  onChangeMaterial(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    activeComponentIndex,
                                    materialIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "rate",
                                    value
                                  );
                                }}
                                value={
                                  material?.discountedRate
                                    ? material?.discountedRate !== "null"
                                      ? material.discountedRate
                                      : material.rate
                                    : material.rate
                                }
                                disabled={
                                  activeRevision !== null ||
                                  material.status === "ordered" ||
                                  material.status === "inApproval" ||
                                  material.rate == "quotation"
                                  // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                                }
                                placeHolderAlwaysOnTop={true}
                                InputField="rate"
                              />
                              {material?.discountedRate > 0 && (
                                <p>O.Rate{material.rate}</p>
                              )}
                            </div>
                            <Input
                              placeholder="GST (%)"
                              onChange={(value) => {
                                if (value <= 100) {
                                  onChangeMaterial(
                                    itemOpenIndex,
                                    activeUnitIndex,
                                    activeComponentIndex,
                                    materialIndex,
                                    project,
                                    setProject,
                                    updateFBRDB,
                                    "gst",
                                    value
                                  );
                                }
                              }}
                              value={material.gst}
                              disabled={
                                activeRevision !== null ||
                                material.status === "ordered" ||
                                material.status === "inApproval" ||
                                material.rate == "quotation"
                                // || ((currentUser == null || currentUser == 'null') ? false : currentUser != userContext.user.email)
                              }
                              placeHolderAlwaysOnTop={true}
                              InputField="gst"
                            />
                          </div>
                          <div>
                            <div>
                              {"INR "}
                              {material.quantity == undefined ||
                              material.rate == undefined ||
                              material.rate == "quotation" ||
                              material.gst == undefined
                                ? 0
                                : parseInt(material.discountedRate)
                                ? (
                                    Math.round(
                                      (material.quantity *
                                        material.discountedRate +
                                        material.quantity *
                                          material.discountedRate *
                                          (material.gst / 100)) *
                                        100
                                    ) / 100
                                  ).toFixed(2)
                                : (
                                    Math.round(
                                      (material.quantity * material.rate +
                                        material.quantity *
                                          material.rate *
                                          (material.gst / 100)) *
                                        100
                                    ) / 100
                                  ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                  {activeRevision === null &&
                    project.rooms[itemOpenIndex]["Room Name"] !==
                      "Non tender items" && (
                      <Tooltip content="Click to add new material vendor">
                        <Button1
                          content="+"
                          style={{
                            margin: "10px 0px",
                            backgroundColor: "rgb(208, 208, 208)",
                            borderRadius: 2,
                            fontSize: 30,
                            color: "#2F4858",
                            height: 30,
                          }}
                          onClick={() =>
                            addMaterial(
                              itemOpenIndex,
                              activeUnitIndex,
                              activeComponentIndex,
                              project?.rooms[itemOpenIndex]?.Units[
                                activeUnitIndex
                              ]?.Components[activeComponentIndex]?.Material
                                ?.length - 1 || 0,
                              project,
                              setProject,
                              updateFBRDB
                            )
                          }
                        />
                      </Tooltip>
                    )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (spinner) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ color: "#fdd34d" }} />
      </div>
    );
  }

  const getValueforHeader = (n1, n2) => {
    if (isNaN(n1) && isNaN(n2)) {
      return;
    }
    if (n2 == 0) {
      return;
    }
    console.log(
      "Math.floor(((n1 - n2) / n1) * 100) : ",
      ((n1 - n2) / n1) * 100
    );
    return {
      percentage: Math.round(((n1 - n2) / n1) * 100) || 0,
      value: Math.abs(n1 - n2) || 0,
    };
  };

  const headerpercentageValue = getValueforHeader(
    (project && getComponentsPrice(project).toFixed(2)) || 0,
    (project && getVendorPrice(project).toFixed(2)) || 0
  );

  return (
    <div id="content">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="clientName">
          {project && (
            <h3>
              {project.clientName ? project.clientName : project.ClientName}
              {` | `}
              <span
                style={{
                  color: headerpercentageValue?.value > 0 ? "green" : "red",
                }}
              >{`  ${headerpercentageValue?.percentage} %`}</span>
            </h3>
          )}
          <div>
            <p style={{ fontSize: "smaller" }}>Start Date {DateCreated()}</p>
            <h5>{calculateDate()} days ago</h5>
          </div>
        </div>
        <div className="tabs-container">
          <div>
            <div
              className={"boqTab"}
              onClick={() => {
                activeRevision === null && setActiveTab(0);
                record(
                  TAGS[
                    ("@boq_tab_click_success",
                    { description: "BOQ tab click success" })
                  ]
                );
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                BOQ{activeRevision !== null && `(R${activeRevision + 1})`}
              </div>
              {(accountType == "Admin" ||
                accountType == "Finance" ||
                accountType == "Owner") && (
                <div style={{ whiteSpace: "nowrap" }}>
                  Rs {(project && getComponentsPrice(project).toFixed(2)) || 0}
                </div>
              )}
            </div>
            <div className={activeTab === 0 ? "active" : ""}></div>
          </div>
          <div>
            <div
              className={"indentTab"}
              onClick={() => {
                if (activeRevision !== null) return;
                if (accountType == "BOQ" || accountType == "Finance") {
                  toast.error("You don't have access to Purchase!", {
                    backgroundColor: "red",
                    color: "black",
                  });
                } else {
                  if (project?.rooms) {
                    setActiveTab(1);
                    updateFBRDBEditStatus({
                      isEditing: false,
                      currentUser: "null",
                      lastEdited: Date.now(),
                    });
                    history.push("/UnreleasedItems/" + project.ProjectId, {
                      data: {
                        ...project,
                        rooms: project.rooms,
                        ProjectId: project.ProjectId,
                        ClientName: project.ClientName,
                        Address: project.Address,
                        AmountRecieved: project.AmountReceived,
                        SiteEngineerId: project.SiteEngineersId,
                      },
                      // setactiveTabValue : setactiveTabValue
                    });
                    saveWorkButtonClicked(project, setSpinner);
                  } else {
                    toast.error("Empty BOQ. Please add to proceed!", {
                      backgroundColor: "red",
                      color: "black",
                    });
                  }
                }
                record(
                  TAGS[
                    ("@purchase_tab_click_success",
                    { description: "Purchase tab click success" })
                  ]
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Purchase</div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (displayPurchaseAmount) {
                      setDisplayPurchaseAmount(false);
                    } else {
                      setDisplayPurchaseAmount(true);
                    }
                  }}
                >
                  {!displayPurchaseAmount ? (
                    <VisibilityOff
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  ) : (
                    <Visibility
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  )}
                </div>
              </div>

              {(accountType == "Admin" ||
                accountType == "Finance" ||
                accountType == "Owner") && (
                <div style={{ whiteSpace: "nowrap" }}>
                  Rs{" "}
                  {(project &&
                    !displayPurchaseAmount &&
                    getVendorPrice(project).toFixed(2)) ||
                    "********"}
                </div>
              )}
            </div>
            <div className={activeTab === 1 ? "active" : ""}></div>
          </div>
        </div>
        <div className="search-container">
          <div className="search-filter">
            <Autocomplete
              // multiple

              disableClearable
              id="checkboxes-tags-demo"
              defaultValue={"Item"}
              options={["Item", "Description", "Work", "Material"]}
              onChange={(e, value) => {
                setSearchBy(value);
              }}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 2 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: "auto" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="SearchBy"
                  placeholder="SearchBy"
                />
              )}
            />
          </div>
          <div className="search-field">
            <Autocomplete
              id="country-select-demo"
              sx={{ width: "auto" }}
              options={searchInput}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box loading="lazy" component="li" sx={{}} {...props}>
                  <div style={{ width: "100%" }}>
                    <p>
                      {option["headingToShow"]} <br />
                      {option.label}
                    </p>
                    <hr
                      style={{
                        marginTop: "0.3em",
                        width: "100%",
                        height: "0.2",
                        opacity: "0.4",
                      }}
                    />
                  </div>
                </Box>
              )}
              onFocus={() => {
                setShowDropdowwn(true);
              }}
              onChange={(e, value) => {
                if (value !== null && searchBy === "Item") {
                  setItemOpenIndex(value.indexArr.RoomIndex);
                  setActiveUnitIndex(value.indexArr.UnitIndex); // for focusing to that div
                  setActiveComponentIndex();
                } else if (value !== null && searchBy === "Description") {
                  setItemOpenIndex(value.indexArr.RoomIndex);
                  setActiveUnitIndex(value.indexArr.UnitIndex);
                  setShowComponents(true);
                  setActiveComponentIndex(value.indexArr.ComponentIndex); // for focusing to that div
                } else if (value !== null && searchBy === "Work") {
                  setItemOpenIndex(value.indexArr.RoomIndex);
                  setActiveUnitIndex(value.indexArr.UnitIndex);
                  setShowComponents(true);
                  setActiveComponentIndex(value.indexArr.ComponentIndex);
                  setMaterialActive();
                  setWorkActive(value.indexArr.WorkIndex); // for focusing to that div
                } else if (value !== null && searchBy === "Material") {
                  setItemOpenIndex(value.indexArr.RoomIndex);
                  setActiveUnitIndex(value.indexArr.UnitIndex);
                  setShowComponents(true);
                  setWorkActive();
                  setActiveComponentIndex(value.indexArr.ComponentIndex);
                  setMaterialActive(value.indexArr.MaterialIndex); // for focusing to that div
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <InventoryDropdown projectId={pathname.split("/")[2]} />
        </div>
      </div>

      {
        <div className="boqPage">
          {renderItems()}
          {renderComponents()}
          {renderWorkMaterial()}
        </div>
      }
      {console.log("progress : ", progress)}
      <ProgressBar isOpen={importing} progress={progress} />
      {/* <ProgressBar isOpen={true} progress={{ completed: 50, total: 100 }} /> */}
    </div>
  );
};

export default drawerTemplate1(ProjectPage);
