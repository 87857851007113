import React, { useEffect, useRef, useState } from "react";
import drawerTemplate from "../../hoc/drawerTemplate";
import { FaClock, FaCheckCircle } from "react-icons/fa";
import DropdownComponent from "./Components/Dropdown";
import fetchMRNProjects from "./Helpers/fetchProjects";
import FilterSearchBar from "./Components/FilterSearchBar";
import SingleItem from "./Components/SingleItem";
import requests from "./Helpers/randomRequests";
import RequestCompletionMessage from "./Components/RequestCompletionMessage";
import getUniqueCategories from "./Helpers/filterCategories";
import fetchRequests from "./Helpers/fetchRequests";
import LoadingIcon from "../Inventory/Components/LoadingIcon";
import Analytics from "./Analytics";

const MaterialRequest = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  // const [ogRequests, setOgRequests] = useState(requests);
  const [ogRequests, setOgRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [userName, setUserName] = useState(null);
  const [searchQuerry, setSearchQuerry] = useState("");
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("Editor");

  const [openAnalytics, setOpenAnalytics] = useState(false);

  const onSelectProject = async (item) => {
    setLoading(true);
    if (item === "o") {
      setLoading(false);
    } else {
      let requestsData = await fetchRequests(JSON.parse(item));
      setOgRequests(requestsData);
      setFilteredRequests(requestsData);
      setSelectedProject(() => JSON.parse(item));
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    console.log('fetchProjects is called here...')
    let tempMrnProjects = await fetchMRNProjects();
    setProjects(tempMrnProjects[0]);
    setSelectedProject(tempMrnProjects[0][0]);
    setUserName(tempMrnProjects[1]);
    onSelectProject(JSON.stringify(tempMrnProjects[0][0]));
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  async function replaceObjectById(array, newObject) {
    return array.map((item) => (item.id === newObject.id ? newObject : item));
  }

  const updateRequestStatus = async (newItem) => {
    console.log(
      "updateRequestStatus this is where we are going to update the things..."
    );
    console.log("updateRequestStatus index newItem : ", newItem);

    let tempOgRequests = [...ogRequests];
    let newOgItems = await replaceObjectById(tempOgRequests, newItem);
    setOgRequests(newOgItems);
    setFilteredRequests(newOgItems);

    console.log("updateRequestStatus newOgItems : ", newOgItems);
  };

  const parentRef = useRef(null);
  const [remainingHeight, setRemainingHeight] = useState("100vh");

  useEffect(() => {
    const updateRemainingHeight = () => {
      if (parentRef.current) {
        // Get the position of the parent div relative to the viewport
        const parentRect = parentRef.current.getBoundingClientRect();

        // Calculate the available space below the parent div
        const availableHeight = window.innerHeight - parentRect.top;

        // Update the state with the remaining height for the parent div
        setRemainingHeight(availableHeight);
      }
    };

    // Run the calculation when the component mounts or when window is resized
    updateRemainingHeight();
    window.addEventListener("resize", updateRemainingHeight);

    return () => {
      window.removeEventListener("resize", updateRemainingHeight); // Cleanup on unmount
    };
  }, []);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [ascending, setAscending] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [checkedIndex, setCheckedIndex] = useState(null);

  const removeFilter = (tag, type) => {
    if (type === "categoryFilter") {
      setCategoryFilter((prevState) => {
        console.log("Before filter:", prevState);
        console.log("Tag to remove:", tag);
        return prevState.filter((item) => item !== tag);
      });
    } else if (type === "filterOptions") {
      setFilterOptions((prevState) => prevState.filter((item) => item !== tag));
    } else {
      setAscending(null);
    }
  };

  const getRequestStatus = (requestHistory) => {
    // Get the last status from the requestHistory
    const lastStatus = requestHistory[requestHistory.length - 1]?.status;

    if (lastStatus === "Requested") return "Pending Items";
    if (lastStatus === "Approved") return "Approved Items";
    if (lastStatus === "Released") return "PO Released";
    if (lastStatus === "Reached") return "Material Reached";
    return null;
  };

  useEffect(() => {
    const filterRequests = () => {
      let filtered = [...ogRequests];
      if (categoryFilter.length > 0) {
        // filtered = filtered.filter((request) =>
        //   request.items.some((item) => categoryFilter.includes(item.category))
        // );
        filtered = filtered
          .map((request) => ({
            ...request,
            items: request.items.filter((item) =>
              categoryFilter.includes(item.category)
            ),
          }))
          .filter((request) => request.items.length > 0);
      }

      if (startDate && endDate) {
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        filtered = filtered.filter(
          (request) => request.id >= start && request.id <= end
        );
      }

      if (filterOptions.length > 0) {
        // filtered = filtered.filter((request) =>
        //   filterOptions.includes(
        //     getRequestStatus(Object.values(request.requestHistory))
        //   )
        // );

        const statusMapping = {
          "Pending Items": "Requested",
          "Approved Items": "Approved",
          "Declined Items": "Declined",
          "PO Released": "Released",
          "Material Reached": "Reached",
        };

        filtered = filtered
          .map((request) => {
            const validStatuses = filterOptions.map(
              (option) => statusMapping[option]
            );
            const items = Array.isArray(request.items) ? request.items : [];

            // Filter items based on valid statuses
            const filteredItems = items.filter((item) =>
              validStatuses.includes(item.status)
            );

            console.log(
              `Request ID ${request.id} - Filtered Items:`,
              filteredItems
            );

            return {
              ...request,
              items: filteredItems,
            };
          })
          .filter((request) => {
            const hasItems = request.items.length > 0;
            if (!hasItems) {
              console.warn(
                `Request ID ${request.id} removed due to no matching items.`
              );
            }
            return hasItems;
          });

        console.log("Final Filtered Requests:", filtered);
      }

      if (ascending !== null) {
        filtered.sort((a, b) => (ascending ? a.id - b.id : b.id - a.id));
      }

      setFilteredRequests(filtered);
    };

    filterRequests();
  }, [
    categoryFilter,
    ascending,
    filterOptions,
    startDate,
    endDate,
    ogRequests,
  ]);

  const resetFilters = () => {
    setCategoryFilter([]);
    setAscending(null);
    setStartDate(null);
    setEndDate(null);
    setFilterOptions([]);
    setSearchQuerry("");
  };

  return (
    <div style={{ padding: "20px", minWidth: "100%", margin: "auto" }}>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {openAnalytics ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#696969"
                strokeWidth="2"
              >
                <path d="M3 3v18h18" />
                <path d="M18 17V9" />
                <path d="M13 17V5" />
                <path d="M8 17v-3" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#696969"
                strokeWidth="2"
              >
                <path d="M20 7h-7M20 12h-9M20 17h-5" />
                <path d="M8 7H4" />
                <path d="M10 12H4" />
                <path d="M14 17H4" />
              </svg>
            )}
            <h2 style={{ color: "#696969", margin: 0 }}>
              {openAnalytics ? "Analytics" : "Material Requests"}
            </h2>
          </div>
          <DropdownComponent items={projects} onSelect={onSelectProject} />
        </div>

        <button
          onClick={() => setOpenAnalytics(!openAnalytics)}
          style={{
            backgroundColor: "#4A90E2",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "8px",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            transition: "all 0.2s ease",
            ":hover": {
              backgroundColor: "#357ABD",
              transform: "translateY(-1px)",
            },
            marginRight: "20px",
          }}
        >
          {openAnalytics ? (
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M20 7h-7M20 12h-9M20 17h-5" />
                <path d="M8 7H4" />
                <path d="M10 12H4" />
                <path d="M14 17H4" />
              </svg>
              View MRN
            </>
          ) : (
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M3 3v18h18" />
                <path d="M18 17V9" />
                <path d="M13 17V5" />
                <path d="M8 17v-3" />
              </svg>
              View Analytics
            </>
          )}
        </button>
      </div>

      {!openAnalytics && (
        <FilterSearchBar
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          ascending={ascending}
          setAscending={setAscending}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          dropdownItems={getUniqueCategories(ogRequests)}
          onDropdownSelect={(item) => {
            console.log("Selected category is : ", item);
            if (categoryFilter.includes(item)) {
              setCategoryFilter((prevState) =>
                prevState.filter((category) => category !== item)
              );
            } else {
              setCategoryFilter((prevState) => [...prevState, item]);
            }
          }}
          onSortClick={(option) => {
            if (option === "Ascending") {
              setAscending(true);
            } else {
              setAscending(false);
            }
          }}
          onFilterClick={(option) => {
            if (filterOptions.includes(option)) {
              setFilterOptions((prevState) =>
                prevState.filter((category) => category !== option)
              );
            } else {
              setFilterOptions((prevState) => [...prevState, option]);
            }
          }}
          searchQuerry={searchQuerry}
          searchPlaceholder={"Search Here"}
          onSearchChange={(value) => {
            setSearchQuerry(value);
          }}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          removeFilter={removeFilter}
          resetFunction={resetFilters}
        />
      )}

      <div style={{ height: 20 }} />

      {loading ? (
        <LoadingIcon />
      ) : openAnalytics ? (
        <Analytics
          requests={filteredRequests}
          setRequests={setOgRequests}
          userName={userName}
          roleType={roleType}
          updateRequestStatus={updateRequestStatus}
          selectedProject={selectedProject}
        />
      ) : (
        <div
          ref={parentRef}
          style={{ overflowY: "auto", height: remainingHeight }}
        >
          {filteredRequests &&
            filteredRequests
              .filter((request) => {
                const query = searchQuerry.toLowerCase();
                const itemsArray = Object.values(request.items);
                return request.items.some(
                  (item) =>
                    item.heading.toLowerCase().includes(query) ||
                    item.subHeading.toLowerCase().includes(query) ||
                    item.category.toLowerCase().includes(query) ||
                    item.description.toLowerCase().includes(query)
                );
              })
              .map((item, index) => (
                <SingleItem
                  index={index}
                  selectedProject={selectedProject}
                  item={item}
                  Editing={false}
                  userName={userName}
                  setItems={setFilteredRequests}
                  roleType={roleType}
                  updateRequestStatusLocally={updateRequestStatus}
                  checkedIndex={checkedIndex}
                  setCheckedIndex={setCheckedIndex}
                />
              ))}
          <RequestCompletionMessage />
        </div>
      )}
    </div>
  );
};

export default drawerTemplate(MaterialRequest);
