import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import ImageScrollBox from "./ImageScrollBox"; // Import the new component
import { FaTimes } from "react-icons/fa"; // For cross icon

const EditableSingleItem = ({
  index,
  open,
  onClose,
  selectedProject,
  item,
  userName,
  roleType,
  updateRequestStatus
}) => {
  
  const [items, setItems] = useState({});
  const [enteredRemark, setEnteredRemark] = useState("");
  const [editedHistory, setEditedHistory] = useState({});

  useEffect(() => {
    if (item) {
      setItems(item);
    }
  }, [item]);

  if (!open) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(5px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={handleBackdropClick}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "1rem",
          maxHeight: "80vh",
          overflowY: "auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          animation: "fadeIn 0.3s ease-out",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: "-10px",
            right: "-10px",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: 1001,
          }}
        >
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "1.5rem",
              backgroundColor: "#fafafa",
              height: "2.5rem",
              width: "2.5rem",
              borderRadius: "50%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
            aria-label="Close"
          >
            <FaTimes />
          </button>
        </div>

        <div style={{ width: "90vw" }}>
          <SingleItem
            index={0}
            selectedProject={selectedProject}
            item={items}
            Editing={true}
            userName={userName}
            setItems={setItems}
            roleType={roleType}
            editedHistory={editedHistory}
            setEditedHistory={setEditedHistory}
          />
        </div>
        
        <ImageScrollBox
          itemIndex={index}
          selectedProject={selectedProject}
          item={item}
          images={item.images ? item.images : []}
          enteredRemark={enteredRemark}
          setEnteredRemark={setEnteredRemark}
          items={items}
          setItems={setItems}
          userName={userName}
          close={onClose}
          updateRequestStatusLocally={updateRequestStatus}
          editedHistory={editedHistory}
        />
      </div>
    </div>
  );
};

export default EditableSingleItem;
