import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  IconButton,
  Box,
  Dialog,
  Tab,
  Tabs,
  Alert,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  FormLabel,
  CardContent,
  Card,
  Tooltip,
  Snackbar,
  LinearProgress, // Add this
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { format } from "date-fns";
import firebase from "firebase/app";
import { Autocomplete } from "@mui/material"; // Add this
import "firebase/storage";
import "firebase/database";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: 1200,
  margin: "auto",
  backgroundColor: "#ffffff",
  borderRadius: 12,
  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
}));

const TabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  "&[hidden]": {
    display: "none",
  },
}));

const DocumentCard = styled(Card)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(2),
  "&:hover .document-actions": {
    opacity: 1,
  },
}));

const DocumentActions = styled(Box)({
  position: "absolute",
  top: 8,
  right: 8,
  opacity: 0,
  transition: "opacity 0.2s ease-in-out",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: 4,
  padding: 4,
});

const MaintenanceCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: "relative",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
  marginTop: theme.spacing(0.5),
}));

const ValidationMessage = ({ errors, name }) => {
  if (!errors[name]) return null;
  return <ErrorText>{errors[name]}</ErrorText>;
};

const VehicleForm = ({
  vehicle,
  onSubmit,
  projects,
  mode = "add",
  loading,
  onClose,
  firmId,
  vehicleTypes,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    readingType: "hrs",
    tag: "",
    vehicleNumber: "",
    vehicleType: "",
    location: "",
    make: "",
    model: "",
    chassisNumber: "",
    totalHoursWorked: "0",
    totalDieselConsumed: "0",
    engine2: {
      totalHoursWorked: "0",
      totalDieselConsumed: "0",
      chassisNumber: "",
    },
    yearOfPurchase: "",
    monthOfPurchase: "",
    insuranceStartDate: null,
    insuranceEndDate: null,
    insuranceProvider: "",
    permitNo: "",
    permitRange: "",
    permitValidUpto: null,
    fitnessCertificateNo: "",
    fcValidUpto: null,
    roadTaxValidUpto: null,
    remarks: "",
    invoiceStatus: false,
    documents: [],
    maintenanceSchedule: [],
    projectId: "",
    sos: false,
    transferred: false,
    transferredFromAnotherSite: false,
    totalKilometers: "0",
    projectId: "",
    projectName: "", // Add this field
  });
  const filterModal = React.useRef(null);
  const [engine2Enabled, setEngine2Enabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedProject, setSelectedProject] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [currentMaintenanceItem, setCurrentMaintenanceItem] = useState({
    part: "",
    service: "",
    lastService: new Date(),
    cycle: "",
    cycleType: "days",
    serviceHistory: {},
  });

  const [editingMaintenanceIndex, setEditingMaintenanceIndex] = useState(null);
  const [showMaintenanceForm, setShowMaintenanceForm] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  useEffect(() => {
    if (selectedProject) {
      const project = projects.find(
        (project) => project.projectID === selectedProject
      );

      if (project) {
        setFormData((prev) => ({
          ...prev,
          location: project.projectAdress || "",
          projectName: project.projectName || "",
        }));
      }
    }
  }, [selectedProject, projects]);
  useEffect(() => {
    if (vehicle && mode === "edit") {
      const vehicleData = {
        ...vehicle,
        // ... other existing mappings
        projectId: vehicle.projectId || "",
        projectName: vehicle.projectName || "",
      };

      setFormData(vehicleData);
      setSelectedProject(vehicle.projectId);

      // Optional: Find and set the full project details
      const project = projects.find((p) => p.projectID === vehicle.projectId);

      if (project) {
        setFormData((prev) => ({
          ...prev,
          location: project.projectAdress || "",
        }));
      }
    }
  }, [vehicle, mode, projects]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Sanitize vehicle number and chassis number
    if (name === "vehicleNumber" || name === "chassisNumber") {
      // Remove special characters
      const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");

      setFormData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleEngine2Change = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      engine2: {
        ...prev.engine2,
        [name]: value,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showNotification = (message, severity = "info") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const generateTag = (vehicleType, identifier) => {
    // Remove any special characters and spaces
    const sanitizedIdentifier = identifier.replace(/[^a-zA-Z0-9]/g, "");
    return (
      vehicleType.toUpperCase() + sanitizedIdentifier.slice(-4)
    ).toUpperCase();
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.vehicleNumber && !formData.chassisNumber) {
      newErrors.vehicleIdentification =
        "Either Vehicle Number or Chassis Number is required";
    }
    if (!formData.vehicleType) {
      newErrors.vehicleType = "Vehicle Type is required";
    }
    if (!formData.projectId) {
      newErrors.projectId = "Project is required";
    }

    if (formData.totalHoursWorked && isNaN(formData.totalHoursWorked)) {
      newErrors.totalHoursWorked = "Must be a number";
    }
    if (formData.totalDieselConsumed && isNaN(formData.totalDieselConsumed)) {
      newErrors.totalDieselConsumed = "Must be a number";
    }

    if (engine2Enabled) {
      if (
        formData.engine2.totalHoursWorked &&
        isNaN(formData.engine2.totalHoursWorked)
      ) {
        newErrors.engine2_totalHoursWorked = "Must be a number";
      }
      if (
        formData.engine2.totalDieselConsumed &&
        isNaN(formData.engine2.totalDieselConsumed)
      ) {
        newErrors.engine2_totalDieselConsumed = "Must be a number";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Please fix all errors before submitting",
        severity: "error",
      });
      return;
    }
    let tag =
      mode === "add"
        ? generateTag(
            formData.vehicleType,
            formData.vehicleNumber || formData.chassisNumber
          )
        : formData.tag;
    try {
      const submitData = {
        ...formData,
        engine2: engine2Enabled ? formData.engine2 : null,
        documents: documents,
        maintenanceSchedule: maintenanceItems,
        tag: tag,
      };

      console.log("Form data being submitted:", submitData);
      await onSubmit(submitData);
    } catch (error) {
      console.error("Error in form submission:", error);
      setErrors((prev) => ({ ...prev, submit: "Error submitting form" }));
      setSnackbar({
        open: true,
        message: "Error submitting form",
        severity: "error",
      });
    }
  };

  const handleFileUpload = async (files) => {
    if (!files || files.length === 0) return;

    setUploading(true);
    setUploadProgress(0);

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = firebase
          .storage()
          .ref(`vehicleDocuments/${firmId}`)
          .child(`${file.name}_${new Date().getTime()}`);

        const uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload error:", error);
            showNotification("Error uploading file", "error");
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            setDocuments((prev) => [
              ...prev,
              {
                name: file.name,
                type: file.type,
                url: downloadURL,
                timestamp: new Date().toISOString(),
              },
            ]);

            if (i === files.length - 1) {
              setUploading(false);
              setUploadProgress(0);
              showNotification("Files uploaded successfully", "success");
            }
          }
        );
      }
    } catch (error) {
      console.error("Error in file upload:", error);
      setUploading(false);
      setUploadProgress(0);
      showNotification("Error uploading files", "error");
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFileUpload(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    handleFileUpload(files);
  };

  const removeDocument = async (index) => {
    try {
      const doc = documents[index];
      if (doc.url) {
        const fileRef = firebase.storage().refFromURL(doc.url);
        await fileRef.delete();
      }

      setDocuments((prev) => prev.filter((_, i) => i !== index));
      showNotification("Document removed successfully", "success");
    } catch (error) {
      console.error("Error removing document:", error);
      showNotification("Error removing document", "error");
    }
  };

  const handleMaintenanceSubmit = (e) => {
    e.preventDefault();
    if (!currentMaintenanceItem.part || !currentMaintenanceItem.service) {
      showNotification("Please fill all maintenance fields", "error");
      return;
    }

    if (editingMaintenanceIndex !== null) {
      setMaintenanceItems((prev) =>
        prev.map((item, index) =>
          index === editingMaintenanceIndex ? currentMaintenanceItem : item
        )
      );
    } else {
      setMaintenanceItems((prev) => [...prev, currentMaintenanceItem]);
    }

    setCurrentMaintenanceItem({
      part: "",
      service: "",
      lastService: new Date(),
      cycle: "",
      cycleType: "days",
      serviceHistory: {},
    });
    setEditingMaintenanceIndex(null);
    setShowMaintenanceForm(false);
    showNotification("Maintenance schedule updated", "success");
  };

  const removeMaintenance = (index) => {
    setMaintenanceItems((prev) => prev.filter((_, i) => i !== index));
    showNotification("Maintenance item removed", "success");
  };
  const handleProjectChange = (event, newValue) => {
    // When using Autocomplete
    if (typeof newValue === "object" && newValue !== null) {
      setFormData((prev) => ({
        ...prev,
        projectId: newValue.projectID,
        projectName: newValue.projectName,
        location: newValue.projectAdress || "",
      }));
      setSelectedProject(newValue.projectID);
    }
    // When using Select
    else {
      const selectedProject = projects.find(
        (project) => project.projectID === event.target.value
      );

      if (selectedProject) {
        setFormData((prev) => ({
          ...prev,
          projectId: selectedProject.projectID,
          projectName: selectedProject.projectName,
          location: selectedProject.projectAdress || "",
        }));
        setSelectedProject(selectedProject.projectID);
      }
    }
  };

  const editMaintenance = (index) => {
    console.log("Editing maintenance item:", maintenanceItems[index]);
    setCurrentMaintenanceItem(maintenanceItems[index]);
    setEditingMaintenanceIndex(index);
    setShowMaintenanceForm(true);
  };

  const renderBasicInformation = () => (
    <TabPanel value={activeTab} index={0}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={projects}
            getOptionLabel={(option) => option.projectName || ""}
            value={
              projects.find(
                (project) => project.projectID === formData.projectId
              ) || null
            }
            onChange={(event, newValue) => {
              // When using Autocomplete
              if (typeof newValue === "object" && newValue !== null) {
                setFormData((prev) => ({
                  ...prev,
                  projectId: newValue.projectID,
                  projectName: newValue.projectName,
                  location: newValue.projectAdress || "",
                }));
                setSelectedProject(newValue.projectID);
              } else {
                // Handle clearing the selection
                setFormData((prev) => ({
                  ...prev,
                  projectId: "",
                  projectName: "",
                  location: "",
                }));
                setSelectedProject("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project"
                error={!!errors.projectId}
                helperText={errors.projectId}
                fullWidth
              />
            )}
            disabled={mode === "edit"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Vehicle Number"
            name="vehicleNumber"
            value={formData.vehicleNumber}
            onChange={handleChange}
            error={!!errors.vehicleNumber}
            helperText={errors.vehicleNumber}
            inputProps={{
              pattern: "[a-zA-Z0-9]*",
              title: "Only alphanumeric characters are allowed",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            freeSolo
            disabled={mode === "edit"}
            options={vehicleTypes || []}
            value={formData.vehicleType}
            onChange={(event, newValue) => {
              handleChange({
                target: {
                  name: "vehicleType",
                  value: newValue || "",
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicle Type"
                name="vehicleType"
                error={!!errors.vehicleType}
                helperText={errors.vehicleType}
                fullWidth
              />
            )}
            ListboxProps={{
              style: { maxHeight: 200 },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Chassis Number"
            name="chassisNumber"
            value={formData.chassisNumber}
            onChange={handleChange}
            error={!!errors.chassisNumber}
            helperText={errors.chassisNumber}
            inputProps={{
              pattern: "[a-zA-Z0-9]*",
              title: "Only alphanumeric characters are allowed",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Make"
            name="make"
            value={formData.make}
            onChange={handleChange}
            error={!!errors.make}
            helperText={errors.make}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Model"
            name="model"
            value={formData.model}
            onChange={handleChange}
            error={!!errors.model}
            helperText={errors.model}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={true}
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );

  const renderEngineDetails = () => (
    <TabPanel value={activeTab} index={1}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Reading Type</FormLabel>
            <RadioGroup
              row
              name="readingType"
              value={formData.readingType}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "readingType",
                    value: e.target.value,
                  },
                })
              }
            >
              <FormControlLabel value="hrs" control={<Radio />} label="Hours" />
              <FormControlLabel
                value="km"
                control={<Radio />}
                label="Kilometers"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color="primary">
            Engine 1
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Total Hours Worked"
            name="totalHoursWorked"
            type="number"
            value={formData.totalHoursWorked}
            onChange={handleChange}
            error={!!errors.totalHoursWorked}
            helperText={errors.totalHoursWorked}
          />
        </Grid>
        {formData.readingType === "km" && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Total Kilometers Travelled"
              name="totalKilometers"
              type="number"
              value={formData.totalKilometers}
              onChange={handleChange}
              error={!!errors.totalKilometers}
              helperText={errors.totalKilometers}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Total Diesel Consumed (L)"
            name="totalDieselConsumed"
            type="number"
            value={formData.totalDieselConsumed}
            onChange={handleChange}
            error={!!errors.totalDieselConsumed}
            helperText={errors.totalDieselConsumed}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <FormControlLabel
            control={
              <Switch
                checked={engine2Enabled}
                onChange={(e) => setEngine2Enabled(e.target.checked)}
              />
            }
            label="Enable Engine 2"
          />
        </Grid>

        {engine2Enabled && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="primary">
                Engine 2
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Chassis Number"
                name="chassisNumber"
                value={formData.engine2.chassisNumber}
                onChange={handleEngine2Change}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  formData.readingType === "hrs"
                    ? "Total Hours Worked"
                    : "Total Kilometers Travelled"
                }
                name="totalHoursWorked"
                type="number"
                value={formData.engine2.totalHoursWorked}
                onChange={handleEngine2Change}
                error={!!errors.engine2_totalHoursWorked}
                helperText={errors.engine2_totalHoursWorked}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Total Diesel Consumed (L)"
                name="totalDieselConsumed"
                type="number"
                value={formData.engine2.totalDieselConsumed}
                onChange={handleEngine2Change}
                error={!!errors.engine2_totalDieselConsumed}
                helperText={errors.engine2_totalDieselConsumed}
              />
            </Grid>
          </>
        )}
      </Grid>
    </TabPanel>
  );

  const renderDocumentation = () => (
    <TabPanel value={activeTab} index={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color="primary">
            Purchase Information
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Year of Purchase"
            name="yearOfPurchase"
            value={formData.yearOfPurchase}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Month of Purchase"
            name="monthOfPurchase"
            value={formData.monthOfPurchase}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.invoiceStatus}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "invoiceStatus",
                      value: e.target.checked,
                    },
                  })
                }
              />
            }
            label="Invoice Status"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom color="primary">
            Insurance Details
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Insurance Start Date"
              value={formData.insuranceStartDate}
              onChange={(date) => handleDateChange("insuranceStartDate", date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Insurance End Date"
              value={formData.insuranceEndDate}
              onChange={(date) => handleDateChange("insuranceEndDate", date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Insurance Provider"
            name="insuranceProvider"
            value={formData.insuranceProvider}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom color="primary">
            Permit and Fitness Details
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Permit Number"
            name="permitNo"
            value={formData.permitNo}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Permit Range"
            name="permitRange"
            value={formData.permitRange}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Permit Valid Upto"
              value={formData.permitValidUpto}
              onChange={(date) => handleDateChange("permitValidUpto", date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Fitness Certificate Number"
            name="fitnessCertificateNo"
            value={formData.fitnessCertificateNo}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="FC Valid Upto"
              value={formData.fcValidUpto}
              onChange={(date) => handleDateChange("fcValidUpto", date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Road Tax Valid Upto"
              value={formData.roadTaxValidUpto}
              onChange={(date) => handleDateChange("roadTaxValidUpto", date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <Box
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{
              border: "2px dashed #ccc",
              borderRadius: 2,
              p: 3,
              textAlign: "center",
              backgroundColor: "#fafafa",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            <input
              type="file"
              multiple
              onChange={handleFileSelect}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <CloudUploadIcon sx={{ fontSize: 48, color: "primary.main" }} />
              <Typography variant="h6" gutterBottom>
                Drag and drop files here or click to upload
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Supported formats: PDF, JPG, PNG
              </Typography>
            </label>
          </Box>
        </Grid>

        {uploading && (
          <Grid item xs={12}>
            <Box sx={{ width: "100%", mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="textSecondary" align="center">
                Uploading: {Math.round(uploadProgress)}%
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {documents.map((doc, index) => (
              <Grid item xs={12} md={4} key={index}>
                <DocumentCard>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      {doc.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(doc.timestamp).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                  <DocumentActions className="document-actions">
                    <IconButton
                      size="small"
                      onClick={() => window.open(doc.url, "_blank")}
                    >
                      <FileUploadIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => removeDocument(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </DocumentActions>
                </DocumentCard>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );

  const renderMaintenance = () => (
    <TabPanel value={activeTab} index={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddPhotoAlternateIcon />}
            onClick={() => setShowMaintenanceForm(true)}
            sx={{ mb: 3 }}
          >
            Add Maintenance Schedule
          </Button>
        </Grid>

        {showMaintenanceForm && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                {editingMaintenanceIndex !== null
                  ? "Edit Maintenance Schedule"
                  : "New Maintenance Schedule"}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Part"
                    value={currentMaintenanceItem.part}
                    onChange={(e) =>
                      setCurrentMaintenanceItem((prev) => ({
                        ...prev,
                        part: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Service"
                    value={currentMaintenanceItem.service}
                    onChange={(e) =>
                      setCurrentMaintenanceItem((prev) => ({
                        ...prev,
                        service: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Last Service Date"
                      value={new Date(currentMaintenanceItem.lastService)}
                      onChange={(date) =>
                        setCurrentMaintenanceItem((prev) => ({
                          ...prev,
                          lastService: date,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Cycle"
                    type="number"
                    value={currentMaintenanceItem.cycle}
                    onChange={(e) =>
                      setCurrentMaintenanceItem((prev) => ({
                        ...prev,
                        cycle: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Cycle Type</FormLabel>
                    <RadioGroup
                      row
                      value={currentMaintenanceItem.cycleType}
                      onChange={(e) =>
                        setCurrentMaintenanceItem((prev) => ({
                          ...prev,
                          cycleType: e.target.value,
                        }))
                      }
                    >
                      <FormControlLabel
                        value="days"
                        control={<Radio />}
                        label="Days"
                      />
                      <FormControlLabel
                        value="km"
                        control={<Radio />}
                        label="Kilometers"
                      />
                      <FormControlLabel
                        value="hours"
                        control={<Radio />}
                        label="Hours"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowMaintenanceForm(false);
                        setEditingMaintenanceIndex(null);
                        setCurrentMaintenanceItem({
                          part: "",
                          service: "",
                          lastService: new Date(),
                          cycle: "",
                          cycleType: "days",
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleMaintenanceSubmit}
                    >
                      {editingMaintenanceIndex !== null ? "Update" : "Add"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {maintenanceItems.map((item, index) => (
          <Grid item xs={12} key={index}>
            <MaintenanceCard>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {item.part}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                    >
                      Service: {item.service}
                    </Typography>
                    <Typography variant="body2">
                      Last Service:{" "}
                      {format(new Date(item.lastService), "dd/MM/yyyy")}
                    </Typography>
                    <Typography variant="body2">
                      Cycle: {item.cycle} {item.cycleType}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => editMaintenance(index)}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => removeMaintenance(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </MaintenanceCard>
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );

  return (
    <StyledPaper>
      {loading && (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      )}

      <form onSubmit={handleFormSubmit}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Basic Information" />
          </Tabs>
        </Box>

        {renderBasicInformation()}
        {renderEngineDetails()}
        {renderDocumentation()}
        {renderMaintenance()}

        <Box
          sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ minWidth: 150 }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : mode === "add" ? (
              "Add Vehicle"
            ) : (
              "Update Vehicle"
            )}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </StyledPaper>
  );
};

export default VehicleForm;
