import { FiEdit2 } from "react-icons/fi";
import { PiImagesSquareThin } from "react-icons/pi";
import { useState } from "react";
import EditableSingleItem from "./EditableSingleItem";
import ImageModal from "../../Inventory/Components/ImageModal";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TooltipIcon from "./TooltipIcon";

const TopHeader = ({
  index,
  selectedProject,
  item,
  Editing,
  userName,
  setItems,
  roleType,
  updateRequestStatus,
  checkedIndex,
  setCheckedIndex,
  checkedSet,
  setCheckedSet,
}) => {
  
  const [openEdit, setOpenEdit] = useState(false);

  const imageItems = item.images ? Object.values(item.images) : [];
  const [displayImage, setDisplayImage] = useState(false);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Format time
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    return `${day}-${month}-${year} | ${hours}:${minutes} ${ampm}`;
  }

  function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits for day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const findOldStatus = (oldStatus) => {
    let newStatus;
    if (oldStatus === "Approved") {
      newStatus = "Requested";
    } else if (oldStatus === "Released") {
      newStatus = "Approved";
    } else {
      newStatus = "Requested";
    }
    return newStatus;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#FFE9A2",
        height: 60,
        alignItems: "center",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 40,
            width: 40,
            borderRadius: 40,
            backgroundColor:
              Object.values(item.requestHistory)[
                Object.values(item.requestHistory).length - 1
              ]["status"] === "Requested" || "Declined"
                ? "#FA722F"
                : Object.values(item.requestHistory)[
                    Object.values(item.requestHistory).length - 1
                  ]["status"] === "Approved"
                ? "#00D226"
                : Object.values(item.requestHistory)[
                    Object.values(item.requestHistory).length - 1
                  ]["status"] === "Released"
                ? "#1485AE"
                : "#828282",
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {checkedSet !== null && (
            <TooltipIcon
              tooltipText="Uncheck All"
              icon={UnpublishedIcon}
              onClick={() => {
                setCheckedSet(null);
                setCheckedIndex(null);
                setItems((prevState) => {
                  const newState = [...JSON.parse(JSON.stringify(prevState))];

                  const updatedItem = { ...newState[index] };

                  // Update all items in the `items` array
                  updatedItem["items"] = updatedItem["items"].map((item) => {
                    // Only update items where checked is true
                    if (item?.checked === true) {
                      return {
                        ...item,
                        checked: false, // Set checked to false for items that are currently checked
                        status: findOldStatus(item.status), // Update status using findOldStatus
                      };
                    }
                    return item; // Return item as is if checked is false
                  });

                  newState[index] = updatedItem;

                  return newState;
                });
              }}
              iconStyle={{ fontSize: 30, color: "#353535" }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: 10,
          }}
        >
          <p>
            {`MR${item.id}  (${
              Object.values(item.requestHistory)[
                Object.values(item.requestHistory).length - 1
              ]["status"]
            } by ${
              Object.values(item.requestHistory)[
                Object.values(item.requestHistory).length - 1
              ]["name"]
            }) | ${selectedProject.projectName}`}
            <span style={{ fontWeight: "500" }}>{` | Due date assigned is ${
              item.dueDate === "" ? "NA" : formatDateToDDMMYYYY(item.dueDate)
            } | Total Items : ${Object.values(item.items).length}`}</span>
          </p>
          <p style={{ fontSize: 12, marginTop: 5 }}>{`${formatTimestamp(
            item.id
          )}`}</p>
        </div>

        {/* <div style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
          <p>Requested Items : 2</p>
          <p>Approved Items : 2</p>
          <p>Decline Items : 2</p>
          <p>Released Items : 2</p>
          <p>Reached Items : 2</p>
        </div> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Requested Items : 2</p>
            <p>Approved Items : 2</p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Decline Items : 2</p>
            <p>Released Items : 2</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Reached Items : 2</p>
          </div>
        </div> */}
      </div>

      {!Editing && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {roleType !== "Viewer" && (
            <FiEdit2
              style={{ cursor: "pointer" }}
              size={20}
              color={"#696969"}
              title="Edit"
              onClick={() => setOpenEdit(true)}
            />
          )}
          <PiImagesSquareThin
            style={{ marginRight: 10, cursor: "pointer" }}
            size={30}
            color={"#696969"}
            title="View Images"
            onClick={() => setDisplayImage(true)}
          />
        </div>
      )}

      <EditableSingleItem
        index={index}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        selectedProject={selectedProject}
        item={item}
        userName={userName}
        roleType={roleType}
        updateRequestStatus={updateRequestStatus}
      />

      {displayImage && (
        <ImageModal
          images={imageItems}
          selectedImageIndex={0}
          onClose={() => {
            setDisplayImage(false);
          }}
        />
      )}
    </div>
  );
};

export default TopHeader;
