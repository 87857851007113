import React from 'react';
import PropTypes from 'prop-types';

// Single Tag Component
const Tag = ({ label, onRemove }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        border: '1px solid #696969',
        color: '#696969',
        padding: '5px 10px',
        margin: '5px',
        borderRadius: '20px',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
    >
      {label}
      <span
        onClick={onRemove}
        style={{
          marginLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
        //   backgroundColor: '#696969',
          color: '#353535',
          fontSize: '24px',
          fontWeight: '500',
          cursor: 'pointer',
        }}
      >
        &times;
      </span>
    </div>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Tag;
