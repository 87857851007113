import { React, useContext, useState, useEffect } from "react";
import "./drawerTemplate.scss";
import {
  FiHome,
  FiInbox,
  FiSettings,
  FiServer,
  FiArchive,
  FiUsers,
} from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../containers/DashboardPage/Logo/index";
import {
  Input,
  Divider,
  TextArea,
  Button,
  FinanceCard,
  SearchBar,
  OrderCard,
  NewOrderCard,
  Label,
  FileExplorer,
} from "../components";
import { UserContext } from "../Context/UserContext";
import { useAnalyticsContext, TAGS } from "../Context/Analytics";
import { BiRupee } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { HiOutlineTruck } from "react-icons/hi2";
import { IoStorefrontOutline } from "react-icons/io5";
import { RiPassPendingLine } from "react-icons/ri";

export default (OldComponent, setSortedProjects, projects) => {
  const NewComponent = (props) => {
    const history = useHistory();
    const location = useLocation();
    const userContext = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const { record } = useAnalyticsContext();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMouseEnter = () => {
      setIsExpanded(true);
    };

    const handleMouseLeave = () => {
      setIsExpanded(false);
    };

    // Get first letter of firm name for compact view
    const firmInitial = userContext.user.Firm
      ? userContext.user.Firm.charAt(0).toUpperCase()
      : "";

    return (
      <div id="drawerTemplate">
        <div
          id="dpDrawer"
          className={isExpanded ? "expanded" : "collapsed"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            style={{
              alignItems: "center",
              minHeight: '30vh',
              maxHeight: '30vh',
              height: '30vh',
            }}
          >
            <div
              id="dpdpic"
              className={isExpanded ? "" : "compact"}
              style={{
                marginBottom: isExpanded ? 5 : 200,
                marginTop: isExpanded ? 50 : 5,
              }}
            >
              {isExpanded ? (
                <abbr title="Click to upload Image">
                  <Logo />
                </abbr>
              ) : (
                <div className="firmInitialCircle">{firmInitial}</div>
              )}
            </div>

            {isExpanded && (
              <>
                <div id="dpdCompanyName">
                  <Label
                    content={userContext.user.Firm}
                    size="30px"
                    textAlign="center"
                    fontWeight="bold"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#b1b1b1",
                    fontSize: "small",
                    margin: "0px auto",
                  }}
                >
                  <Label content={userContext.user.email} size="small" />
                </div>
                <div
                  id="dpdEmail"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#b1b1b1",
                    fontSize: "small",
                    margin: "0px auto",
                  }}
                >
                  <Label content={userContext.user.userName} size="small" />
                </div>
              </>
            )}
          </div>

          <div
            className={`dpdMenuItem ${
              location.pathname == "/" && "dpdMenuItemActive"
            } ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              history.push("/");
              record("@home_icon_click_success", {
                description: "home icon click success",
              });
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FiHome size={20} />
                <p style={{ fontSize: 12, marginTop: 5, fontWeight: "400" }}>Home</p>
              </div>
            )}
            {isExpanded && <FiHome size={20} />}
            {isExpanded && <div>Home</div>}
          </div>

          {(userContext.user.accountType === "Admin" ||
            userContext.user.accountType === "Owner" ||
            userContext.user.accountType === "Finance" ||
            userContext.user.accountType === "Project") && (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/Analytics" && "dpdMenuItemActive"
              } ${!isExpanded && "iconOnly"}`}
              onClick={() => {
                history.push("/Analytics");
                record("@analytics_icon_click_success", {
                  description: "analytics icon click success",
                });
              }}
            >
              {!isExpanded && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FiUsers size={20} />
                  <p style={{ fontSize: 12, marginTop: 5, fontWeight: "400" }}>Users</p>
                </div>
              )}

              {isExpanded && <FiUsers size={20} />}
              {isExpanded && <div>Users</div>}
            </div>
          )}

          {(userContext.user.accountType === "Admin" ||
            userContext.user.accountType === "Owner") && (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/SuggestiveItems" && "dpdMenuItemActive"
              } ${!isExpanded && "iconOnly"}`}
              onClick={() => {
                history.push("/SuggestiveItems");
                record("@suggestive_items_icon_click_success", {
                  description: "suggestive items icon click success",
                });
              }}
            >
              {!isExpanded && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FiServer size={20} />
                  <p style={{ fontSize: 12, marginTop: 5, fontWeight: "400" }}>SgIt.</p>
                </div>
              )}

              {isExpanded && <FiServer size={20} />}
              {isExpanded && <div>Suggestive Items</div>}
            </div>
          )}

          {(userContext.user.accountType === "Admin" ||
            userContext.user.accountType === "Owner" ||
            userContext.user.accountType === "Finance" ||
            userContext.user.accountType === "Project") && (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/FinanceRecords" && "dpdMenuItemActive"
              } ${!isExpanded && "iconOnly"}`}
              onClick={() => {
                history.push("/FinanceRecords");
                record("@financeApprovals request_icon_click_success", {
                  description: "financeApprovals icon click success",
                });
              }}
            >
              {!isExpanded && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <BiRupee size={20} />
                  <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>FinAp.</p>
                </div>
              )}

              {isExpanded && <BiRupee size={20} />}
              {isExpanded && <div>Finance Approvals</div>}
            </div>
          )}

          <div
            className={`dpdMenuItem ${
              location.pathname == "/POAnalytics" && "dpdMenuItemActive"
            } ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              history.push("/POAnalytics");
              console.log("Go to analytics button pressed...");
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <GoGraph size={20} />
                <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>Analytics</p>
              </div>
            )}

            {isExpanded && <GoGraph size={20} />}
            {isExpanded && <div>Analytics</div>}
          </div>

          <div
            className={`dpdMenuItem ${
              location.pathname == "/MaterialRequest" && "dpdMenuItemActive"
            } ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              history.push("/MaterialRequest");
              console.log("Go to material request button pressed...");
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RiPassPendingLine size={20} />
                <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>MRN</p>
              </div>
            )}

            {isExpanded && <RiPassPendingLine size={20} />}
            {isExpanded && <div>Material Requests</div>}
          </div>

          <div
            className={`dpdMenuItem ${
              location.pathname == "/Inventory" && "dpdMenuItemActive"
            } ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              history.push("/Inventory");
              console.log("Go to inventory button pressed...");
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IoStorefrontOutline size={20} />
                <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>Inventory</p>
              </div>
            )}

            {isExpanded && <IoStorefrontOutline size={20} />}
            {isExpanded && <div>Inventory</div>}
          </div>

          <div
            className={`dpdMenuItem ${
              location.pathname == "/Vehicles" && "dpdMenuItemActive"
            } ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              history.push("/Vehicles");
              console.log("Go to vehicles button pressed...");
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <HiOutlineTruck size={20} />
                <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>Assets</p>
              </div>
            )}

            {isExpanded && <HiOutlineTruck size={20} />}
            {isExpanded && <div>Assets</div>}
          </div>

          <div
            className={`dpdMenuItem ${!isExpanded && "iconOnly"}`}
            onClick={() => {
              record("@exit_icon_click_success", {
                description: "Exit icon click success",
              });
              history.push("/");
              props.onAuthChanged(null);
              localStorage.clear();
              sessionStorage.clear();
            }}
          >
            {!isExpanded && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FiLogOut size={20} />
                <p style={{ fontSize: 12, marginTop: 5,  fontWeight: "400" }}>Logout</p>
              </div>
            )}

            {isExpanded && <FiLogOut size={20} />}
            {isExpanded && <div>Logout</div>}
          </div>
        </div>
        <div id="drawerContent">
          <OldComponent {...props} />
        </div>
      </div>
    );
  };

  return NewComponent;
};
