import { secondDatabase } from "../../../config/firebase";

const fetchMRNProjects = async () => {
  let tempProjects = [];
  let userName = '';

  const localStorageData = JSON.parse(localStorage.getItem("User"));
  console.log('data received from the local storage localStorageData : ', localStorageData);
  const tempFirmId = localStorageData.firmId;

  if (!tempFirmId) {
    console.error("Firm ID not found in local storage.");
    return [tempProjects, userName];
  }
  console.log('fetchMRNProjects tempFirmId : ', tempFirmId)
  const useRef = secondDatabase.ref(`userDetails/${tempFirmId}`);

  // Wrap Firebase data fetch in a Promise
  return new Promise((resolve, reject) => {
    useRef.once("value", (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        tempProjects = Object.values(data.projects || {});
      }

      if (localStorageData.userName?.length > 0) {
        userName = localStorageData.userName;
      } else {
        if (data !== null) {
          userName = data?.personalDetails?.name || '';
        }
      }

      resolve([tempProjects, userName]);
    }, (error) => {
      console.error("Error fetching data:", error);
      reject(error);
    });
  });
};

export default fetchMRNProjects;