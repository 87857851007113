import React, { useEffect, useState } from "react";
import { AiOutlineFileSearch, AiOutlineEdit } from "react-icons/ai";
import ImageModal from "../Components/ImageModal";
import { secondDatabase } from "../../../config/firebase";

const TopInfoBar = ({
  fields = {},
  inTime = "",
  outTime = "",
  itemData = {},
  isRequestRecord = false,
}) => {
  // States to handle the ImageModal
  const [displayImage, setDisplayImage] = useState(false);
  const [imageItems, setImageItems] = useState([]);
  const [signatureInfo, setSignatureInfo] = useState("");

  // States for editing fields
  const [isEditing, setIsEditing] = useState(false);
  const [editFields, setEditFields] = useState({
    poNumber: "",
    mrnNo: "",
    vehicleNo: "",
    invoiceNo: "",
    invoiceDate: "",
    gatePassNo: "",
    dcNumber: "",
    supplier: "",
    inTime: "",
    outTime: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  // Check if this record is editable - only request records that don't have a dueDate property
  // This means we only allow editing for request records that have NOT been converted
  const isEditable = isRequestRecord && !itemData.dueDate;

  // Function to open the ImageModal and set images
  const invoiceImages =
    itemData?.invoices && Object.keys(itemData.invoices || {}).length > 0
      ? itemData.invoices[Object.keys(itemData.invoices)[0]]?.images
      : null;

  const handleViewBills = () => {
    let dummyImages = itemData.images ? Object.values(itemData.images) : [];

    if (
      itemData?.invoices &&
      Object.keys(itemData.invoices || {}).length > 0 &&
      Object.keys(
        itemData.invoices[Object.keys(itemData.invoices)[0]] || {}
      ).includes("images")
    ) {
      dummyImages = [
        ...dummyImages,
        ...Object.values(
          itemData.invoices[Object.keys(itemData.invoices)[0]]?.images || {}
        ),
      ];
    }

    if (dummyImages.length > 0) {
      setImageItems(dummyImages);
      setSignatureInfo("");
      setDisplayImage(true);
    } else {
      alert("No bills are uploaded");
    }
  };

  // Function to handle signature display
  const handleViewSignature = () => {
    if (isRequestRecord && itemData.receivedData?.metadata?.signature) {
      const signature = itemData.receivedData.metadata.signature;

      // Extract signature type (data format)
      const signatureType =
        signature.substring(0, signature.indexOf(";")) || "Unknown format";

      // Log the signature data to console for debugging
      console.log("Signature Metadata:", {
        preview: signature.substring(0, 100) + "...", // Show first 100 chars
        fullLength: signature.length,
        type: signatureType,
        receivedDate:
          itemData.receivedData?.metadata?.receivedDate || "Not provided",
        inTime: itemData.receivedData?.metadata?.inTime || "Not provided",
        outTime: itemData.receivedData?.metadata?.outTime || "Not provided",
        fullMetadata: itemData.receivedData?.metadata,
      });

      // Set signature info for display in modal
      setSignatureInfo(
        `Signature Data | Type: ${signatureType} | Length: ${
          signature.length
        } characters | Received: ${
          itemData.receivedData?.metadata?.receivedDate
            ? new Date(
                itemData.receivedData.metadata.receivedDate
              ).toLocaleString()
            : "Not provided"
        }`
      );

      // Set the image for display
      setImageItems([signature]);
      setDisplayImage(true);
    }
  };

  // Function to open edit modal
  const handleEditClick = () => {
    if (!isEditable) {
      alert(
        "This record cannot be edited because it has been converted from a request."
      );
      return;
    }

    // Initialize edit fields with current values
    setEditFields({
      poNumber:
        displayFields?.["01"]?.value === "Not provided"
          ? ""
          : displayFields?.["01"]?.value || "",
      mrnNo:
        displayFields?.["02"]?.value === "Not provided"
          ? ""
          : displayFields?.["02"]?.value || "",
      vehicleNo:
        displayFields?.["03"]?.value === "Not provided"
          ? ""
          : displayFields?.["03"]?.value || "",
      invoiceNo:
        displayFields?.["04"]?.["01"]?.value === "Not provided"
          ? ""
          : displayFields?.["04"]?.["01"]?.value || "",
      invoiceDate:
        displayFields?.["04"]?.["02"]?.value === "Not provided"
          ? ""
          : displayFields?.["04"]?.["02"]?.value || "",
      gatePassNo:
        displayFields?.["05"]?.["01"]?.value === "Not provided"
          ? ""
          : displayFields?.["05"]?.["01"]?.value || "",
      dcNumber:
        displayFields?.["06"]?.["01"]?.value === "Not provided"
          ? ""
          : displayFields?.["06"]?.["01"]?.value || "",
      supplier:
        displayFields?.["07"]?.value === "Not provided"
          ? ""
          : displayFields?.["07"]?.value || "",
      inTime: displayInTime === "Not provided" ? "" : displayInTime || "",
      outTime: displayOutTime === "Not provided" ? "" : displayOutTime || "",
    });
    setIsEditing(true);
  };

  // Function to handle input changes
  const handleInputChange = (field, value) => {
    setEditFields({
      ...editFields,
      [field]: value,
    });
  };

  // Function to save edited fields
  const handleSaveEdit = async () => {
    if (!isEditable) {
      alert(
        "This record cannot be edited because it has been converted from a request."
      );
      return;
    }

    setIsSaving(true);

    try {
      // Get the necessary IDs from itemData
      const firmId = itemData.firmId;
      const projectId = itemData.projectId;
      const recordId = itemData.id;

      if (!firmId || !projectId || !recordId) {
        throw new Error("Missing required IDs for updating record");
      }

      // Create the update object
      let updates = {};

      // For request-converted records, update all fields
      updates[`${firmId}/MRNRequest/${projectId}/${recordId}/key`] =
        editFields.poNumber || null;
      updates[`${firmId}/MRNRequest/${projectId}/${recordId}/mrnId`] =
        editFields.mrnNo || null;
      updates[`${firmId}/MRNRequest/${projectId}/${recordId}/userName`] =
        editFields.supplier || null;

      // Update metadata fields
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/inTime`
      ] = editFields.inTime || null;
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/outTime`
      ] = editFields.outTime || null;

      // For invoice date, we need to convert it to a timestamp if it's a valid date
      if (editFields.invoiceDate) {
        try {
          const date = new Date(editFields.invoiceDate);
          if (!isNaN(date.getTime())) {
            updates[
              `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/receivedDate`
            ] = date.getTime();
          }
        } catch (error) {
          console.error("Invalid date format:", error);
        }
      }

      // For vehicle number, gate pass, and DC number, store in metadata
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/vehicleNo`
      ] = editFields.vehicleNo || null;
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/gatePassNo`
      ] = editFields.gatePassNo || null;
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/dcNumber`
      ] = editFields.dcNumber || null;

      // Mark the record as modified
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/isModified`
      ] = true;

      // Get current user from localStorage
      const currentUser = JSON.parse(localStorage.getItem("User"));
      const entryBy =
        currentUser?.userName ||
        currentUser?.personalDetails?.name ||
        "Unknown User";

      // Store who made the edit
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/modifiedBy`
      ] = entryBy;
      updates[
        `${firmId}/MRNRequest/${projectId}/${recordId}/receivedData/metadata/modifiedAt`
      ] = Date.now();

      // Update the database
      await secondDatabase.ref().update(updates);

      // Update local state to reflect changes
      console.log("Fields updated successfully:", editFields);

      // Close the edit modal
      setIsEditing(false);

      // Update the displayed data in itemData
      // Update main fields
      itemData.key = editFields.poNumber || null;
      itemData.mrnId = editFields.mrnNo || null;
      itemData.userName = editFields.supplier || null;
      itemData.isModified = true;

      // Update metadata
      if (!itemData.receivedData) {
        itemData.receivedData = { metadata: {} };
      } else if (!itemData.receivedData.metadata) {
        itemData.receivedData.metadata = {};
      }

      itemData.receivedData.metadata.inTime = editFields.inTime || null;
      itemData.receivedData.metadata.outTime = editFields.outTime || null;
      itemData.receivedData.metadata.vehicleNo = editFields.vehicleNo || null;
      itemData.receivedData.metadata.gatePassNo = editFields.gatePassNo || null;
      itemData.receivedData.metadata.dcNumber = editFields.dcNumber || null;
      itemData.receivedData.metadata.modifiedBy = entryBy;
      itemData.receivedData.metadata.modifiedAt = Date.now();

      // Update invoice date if valid
      if (editFields.invoiceDate) {
        try {
          const date = new Date(editFields.invoiceDate);
          if (!isNaN(date.getTime())) {
            itemData.receivedData.metadata.receivedDate = date.getTime();
          }
        } catch (error) {
          console.error("Invalid date format:", error);
        }
      }

      // Reload the page to reflect changes
      window.location.reload();
    } catch (error) {
      console.error("Error updating fields:", error);
      alert(`Failed to update fields: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  // For request-converted records, map data to match regular record format
  let displayFields = fields;
  let displayInTime = inTime;
  let displayOutTime = outTime;

  if (isRequestRecord) {
    // Create a fields object that matches the structure of regular records
    displayFields = {
      "01": {
        // PO Number
        value: itemData.key || "Not provided",
      },
      "02": {
        // MRN No
        value: itemData.mrnId?.toString() || "Not provided",
      },
      "03": {
        // Vehicle No
        value: itemData.receivedData?.metadata?.vehicleNo || "Not provided",
      },
      "04": {
        // Invoice details
        "01": {
          value: itemData.id?.toString() || "Not provided", // Invoice No
        },
        "02": {
          value: itemData.receivedData?.metadata?.receivedDate
            ? new Date(
                itemData.receivedData.metadata.receivedDate
              ).toLocaleDateString()
            : "Not provided", // Invoice Date
        },
      },
      "05": {
        // Gate Pass
        "01": {
          value: itemData.receivedData?.metadata?.gatePassNo || "Not provided",
        },
      },
      "06": {
        // DC Number
        "01": {
          value: itemData.receivedData?.metadata?.dcNumber || "Not provided",
        },
      },
      "07": {
        // Supplier - Always show "Not provided" for request-converted records with dueDate
        value: itemData.dueDate
          ? "Not provided"
          : itemData.userName || "Not provided",
      },
    };

    displayInTime = itemData.receivedData?.metadata?.inTime || "Not provided";
    displayOutTime = itemData.receivedData?.metadata?.outTime || "Not provided";
  }

  return (
    <div style={styles.container}>
      <div style={styles.field}>
        <strong>Invoice No:</strong>{" "}
        {displayFields?.["04"]?.["01"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>Invoice Date:</strong>{" "}
        {displayFields?.["04"]?.["02"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>MRN No:</strong>{" "}
        {displayFields?.["02"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>Supplier:</strong>{" "}
        {displayFields?.["07"]?.value || "Not provided"}
        {itemData.isModified && itemData.receivedData?.metadata?.modifiedBy && (
          <span style={styles.modifiedTag}> (Modified)</span>
        )}
      </div>
      <div style={styles.field}>
        <strong>in Time - </strong> {displayInTime || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>Gate Pass No:</strong>{" "}
        {displayFields?.["05"]?.["01"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>DC Number:</strong>{" "}
        {displayFields?.["06"]?.["01"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>Vehicle No:</strong>{" "}
        {displayFields?.["03"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>PO Number:</strong>{" "}
        {displayFields?.["01"]?.value || "Not provided"}
      </div>
      <div style={styles.field}>
        <strong>out Time -</strong> {displayOutTime || "Not provided"}
      </div>

      {/* {isEditable && (
        <AiOutlineEdit
          style={styles.editAllIcon}
          title="Edit All Fields"
          onClick={handleEditClick}
        />
      )} */}

      {(itemData?.images ||
        invoiceImages ||
        itemData.receivedData?.metadata?.signature) && (
        <AiOutlineFileSearch
          style={styles.icon}
          title="View Bills/Signature"
          onClick={() => {
            if (isRequestRecord && itemData.receivedData?.metadata?.signature) {
              handleViewSignature();
            } else {
              handleViewBills();
            }
          }}
        />
      )}

      {displayImage && (
        <ImageModal
          images={imageItems || []} // List of images
          selectedImageIndex={0} // Index of the initial image
          onClose={() => {
            setDisplayImage(false); // Close the modal
            setImageItems([]); // Clear images
            setSignatureInfo(""); // Clear signature info
          }}
          title={signatureInfo || "Image Preview"} // Show signature info if available
        />
      )}

      {/* Edit Modal */}
      {isEditing && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h3 style={styles.modalTitle}>Edit Record Details</h3>
            <p style={styles.modalSubtitle}>
              Entry by:{" "}
              {JSON.parse(localStorage.getItem("User"))?.userName ||
                JSON.parse(localStorage.getItem("User"))?.personalDetails
                  ?.name ||
                "Unknown User"}
            </p>

            <div style={styles.formGrid}>
              <div style={styles.formGroup}>
                <label style={styles.label}>PO Number:</label>
                <input
                  type="text"
                  value={editFields.poNumber}
                  onChange={(e) =>
                    handleInputChange("poNumber", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter PO Number"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>MRN No:</label>
                <input
                  type="text"
                  value={editFields.mrnNo}
                  onChange={(e) => handleInputChange("mrnNo", e.target.value)}
                  style={styles.input}
                  placeholder="Enter MRN No"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>Vehicle No:</label>
                <input
                  type="text"
                  value={editFields.vehicleNo}
                  onChange={(e) =>
                    handleInputChange("vehicleNo", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter Vehicle No"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>Invoice Date:</label>
                <input
                  type="text"
                  value={editFields.invoiceDate}
                  onChange={(e) =>
                    handleInputChange("invoiceDate", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter Invoice Date (MM/DD/YYYY)"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>Gate Pass No:</label>
                <input
                  type="text"
                  value={editFields.gatePassNo}
                  onChange={(e) =>
                    handleInputChange("gatePassNo", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter Gate Pass No"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>DC Number:</label>
                <input
                  type="text"
                  value={editFields.dcNumber}
                  onChange={(e) =>
                    handleInputChange("dcNumber", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter DC Number"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>Supplier:</label>
                <input
                  type="text"
                  value={editFields.supplier}
                  onChange={(e) =>
                    handleInputChange("supplier", e.target.value)
                  }
                  style={styles.input}
                  placeholder="Enter Supplier Name"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>In Time:</label>
                <input
                  type="text"
                  value={editFields.inTime}
                  onChange={(e) => handleInputChange("inTime", e.target.value)}
                  style={styles.input}
                  placeholder="Enter In Time"
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>Out Time:</label>
                <input
                  type="text"
                  value={editFields.outTime}
                  onChange={(e) => handleInputChange("outTime", e.target.value)}
                  style={styles.input}
                  placeholder="Enter Out Time"
                />
              </div>
            </div>

            <div style={styles.buttonGroup}>
              <button
                style={styles.cancelButton}
                onClick={() => setIsEditing(false)}
                disabled={isSaving}
              >
                Cancel
              </button>
              <button
                style={styles.saveButton}
                onClick={handleSaveEdit}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    backgroundColor: "#fdf8e8",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "20px",
    position: "relative",
  },
  field: {
    fontSize: "14px",
    color: "#333",
    padding: "5px 0",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "24px",
    color: "#333",
    cursor: "pointer",
  },
  editIcon: {
    marginLeft: "5px",
    fontSize: "16px",
    color: "#007bff",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  editAllIcon: {
    position: "absolute",
    top: "10px",
    right: "45px",
    fontSize: "24px",
    color: "#007bff",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    width: "700px",
    maxWidth: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "center",
  },
  modalSubtitle: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "20px",
    textAlign: "center",
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "15px",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    fontSize: "14px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "20px",
  },
  cancelButton: {
    padding: "8px 16px",
    backgroundColor: "#f1f1f1",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  saveButton: {
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  modifiedTag: {
    fontSize: "12px",
    color: "#dc3545",
    fontStyle: "italic",
  },
};

export default TopInfoBar;
