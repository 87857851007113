import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  styled,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";
import CloseIcon from "@mui/icons-material/Close";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: 12,
  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
}));

const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
});

const ConsumptionModal = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  onEdit,
  projects,
  vehicles,
  firmData,
  loading,
  consumptionHistoryData,
}) => {
  const [formData, setFormData] = useState({
    timestamp: new Date().getTime(),
    startReading: "",
    endReading: "",
    hours: "",
    purpose: "",
    tag: "",
    quantity: "",
    amount: "",
    engine2StartReading: "",
    engine2EndReading: "",
    engine2Hours: "0",
    kmsStartReading: "",
    kmsEndReading: "",
    kmsTravelled: "0",
    projectId: "",
    vehicleId: "",
    itemKey: "",
    entryId: "",
    readingType: "", // Add this line
  });

  const [errors, setErrors] = useState({});
  const [entries, setEntries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const hasEngine2Vehicles = vehicles.some((v) => v.engine2);
  const hasKmVehicles = vehicles.some((v) => v.readingType === "km");

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setFormData({
      timestamp: new Date().getTime(),
      startReading: "",
      endReading: "",
      hours: "",
      purpose: "",
      tag: "",
      quantity: "",
      amount: "",
      engine2StartReading: "",
      engine2EndReading: "",
      engine2Hours: "0",
      kmsStartReading: "",
      kmsEndReading: "",
      kmsTravelled: "0",
      projectId: "",
      vehicleId: "",
      itemKey: "",
      entryId: "",
      readingType: "", // Add this line
    });
    setErrors({});
    setEditingIndex(null);
    setIsEditing(false);
  };
  const handleProjectChange = (projectId) => {
    let entryId = "";
    const fuelItemKey = Object.entries(
      firmData?.projects[projectId]?.records?.allOutward || {}
    ).reduce((key, [outwardId, outward]) => {
      const fuelItem = Object.entries(outward.outwardItems || {}).find(
        ([itemKey, item]) => item.category === "Fuel"
      );
      if (fuelItem) {
        entryId = outwardId;
      }
      return fuelItem ? fuelItem[0] : key;
    }, "");

    setFormData((prev) => ({
      ...prev,
      projectId,
      vehicleId: "",
      entryId: parseInt(entryId),
      tag: "",
    }));
  };

  const handleVehicleChange = (vehicle) => {
    // Find the latest entry for this vehicle's tag
    const latestEntry = consumptionHistoryData
      ?.filter((entry) => entry.tag === vehicle.tag)
      ?.sort((a, b) => b.timestamp - a.timestamp)[0];

    setFormData((prev) => ({
      ...prev,
      vehicleId: vehicle.id,
      tag: vehicle.tag,
      // Set start readings from the last entry's end readings
      startReading: latestEntry ? latestEntry.endReading : "",
      engine2StartReading: latestEntry ? latestEntry.engine2EndReading : "",
      kmsStartReading: latestEntry ? latestEntry.kmsEndReading : "",
      readingType: vehicle.readingType || "hrs",
      // Clear end readings and calculated fields
      endReading: "",
      engine2EndReading: "",
      kmsEndReading: "",
      hours: "",
      kmsTravelled: "0",
      engine2Hours: "0",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const vehicle = vehicles.find((v) => v.id === formData.vehicleId);
    const readingType = vehicle?.readingType || "hrs";

    if (!formData.projectId) newErrors.projectId = "Project is required";
    if (!formData.vehicleId) newErrors.vehicleId = "Vehicle is required";
    if (!formData.startReading)
      newErrors.startReading = "Engine 1 start reading is required";
    if (!formData.endReading)
      newErrors.endReading = "Engine 1 end reading is required";

    if (parseFloat(formData.endReading) <= parseFloat(formData.startReading)) {
      newErrors.endReading =
        "Engine 1 end reading must be greater than start reading";
    }

    if (vehicle?.engine2) {
      if (!formData.engine2StartReading) {
        newErrors.engine2StartReading = "Engine 2 start reading is required";
      }
      if (!formData.engine2EndReading) {
        newErrors.engine2EndReading = "Engine 2 end reading is required";
      }
      if (!formData.engine2Hours) {
        newErrors.engine2Hours = "Engine 2 hours is required";
      }
      if (
        parseFloat(formData.engine2EndReading) <=
        parseFloat(formData.engine2StartReading)
      ) {
        newErrors.engine2EndReading =
          "Engine 2 end reading must be greater than start reading";
      }
    }

    if (readingType === "km") {
      if (!formData.kmsStartReading)
        newErrors.kmsStartReading = "KM start reading is required";
      if (!formData.kmsEndReading)
        newErrors.kmsEndReading = "KM end reading is required";
      if (
        parseFloat(formData.kmsEndReading) <=
        parseFloat(formData.kmsStartReading)
      ) {
        newErrors.kmsEndReading =
          "KM end reading must be greater than start reading";
      }
    } else {
      if (!formData.hours) newErrors.hours = "Hours is required";
    }

    if (!formData.purpose) newErrors.purpose = "Purpose is required";
    if (!formData.amount) newErrors.amount = "Amount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculateReadings = () => {
    const vehicle = vehicles.find((v) => v.id === formData.vehicleId);
    const readingType = vehicle?.readingType || "hrs";

    if (readingType === "km") {
      const kmsTravelled =
        parseFloat(formData.kmsEndReading || 0) -
        parseFloat(formData.kmsStartReading || 0);
      setFormData((prev) => ({
        ...prev,
        kmsTravelled: kmsTravelled.toString(),
      }));
    }
  };

  useEffect(() => {
    calculateReadings();
  }, [formData.kmsStartReading, formData.kmsEndReading]);

  const handleAddEntry = () => {
    if (!validateForm()) return;

    let updatedFormData = { ...formData };

    if (isEditing && editingIndex !== null) {
      onEdit(updatedFormData);
    } else {
      onSubmit(updatedFormData);
    }

    setEntries((prevEntries) => {
      if (isEditing && editingIndex !== null) {
        const updated = [...prevEntries];
        updated[editingIndex] = updatedFormData;
        return updated;
      } else {
        return [...prevEntries, updatedFormData];
      }
    });

    resetForm();
  };

  const handleEdit = (index) => {
    const entryToEdit = entries[index];
    setFormData({
      ...entryToEdit,
      timestamp: entries[index].timestamp,
    });
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleDelete = async (index) => {
    const entry = entries[index];
    try {
      await onDelete(entry);
      const newEntries = entries.filter((_, i) => i !== index);
      setEntries(newEntries);
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };
  useEffect(() => {
    if (formData.vehicleId && formData.tag) {
      const latestEntry = consumptionHistoryData
        ?.filter((entry) => entry.tag === formData.tag)
        ?.sort((a, b) => b.timestamp - a.timestamp)[0];

      if (latestEntry) {
        setFormData((prev) => ({
          ...prev,
          startReading: latestEntry.endReading || prev.startReading,
          engine2StartReading:
            latestEntry.engine2EndReading || prev.engine2StartReading,
          kmsStartReading: latestEntry.kmsEndReading || prev.kmsStartReading,
        }));
      }
    }
  }, [consumptionHistoryData, formData.vehicleId, formData.tag]);
  const projectVehicles = vehicles.filter((vehicle) => {
    console.log(vehicles);
    // Normalize the project IDs for comparison
    const vehicleProjectId = String(
      vehicle.projectId || vehicle.projectID || ""
    ).trim();
    const formProjectId = String(formData.projectId || "").trim();

    return vehicleProjectId === formProjectId && !vehicle.transferred;
  });

  const selectedVehicle = vehicles.find((v) => v.id === formData.vehicleId);
  const readingType = selectedVehicle?.readingType || "hrs";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperComponent={StyledPaper}
    >
      {loading && (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      )}

      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <TimelineIcon sx={{ color: "primary.main" }} />
            <Typography variant="h6">Vehicle Usage Entry</Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3} marginTop={1}>
          {/* Project Selection */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.projectId}>
              <InputLabel>Project</InputLabel>
              <Select
                value={formData.projectId}
                onChange={(e) => handleProjectChange(e.target.value)}
                label="Project"
              >
                {projects.map((project) => (
                  <MenuItem key={project.projectID} value={project.projectID}>
                    {project.projectName}
                  </MenuItem>
                ))}
              </Select>
              {errors.projectId && (
                <Typography color="error" variant="caption">
                  {errors.projectId}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Vehicle Selection */}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={!!errors.vehicleId}
              disabled={!formData.projectId}
            >
              <InputLabel>Vehicle</InputLabel>
              <Select
                value={formData.vehicleId}
                onChange={(e) => {
                  const selectedVehicle = projectVehicles.find(
                    (v) => v.id === e.target.value
                  );
                  if (selectedVehicle) {
                    handleVehicleChange(selectedVehicle);
                  }
                }}
                label="Vehicle"
              >
                {projectVehicles.map((vehicle) => (
                  <MenuItem key={vehicle.id} value={vehicle.id}>
                    {vehicle.vehicleNumber} - {vehicle.vehicleType}
                    {console.log(vehicle)}
                  </MenuItem>
                ))}
              </Select>
              {errors.vehicleId && (
                <Typography color="error" variant="caption">
                  {errors.vehicleId}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Engine 1 Fields */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Engine 1 Start Reading"
              type="number"
              value={formData.startReading}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  startReading: e.target.value,
                }))
              }
              fullWidth
              error={!!errors.startReading}
              helperText={errors.startReading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Engine 1 End Reading"
              type="number"
              value={formData.endReading}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  endReading: e.target.value,
                }))
              }
              fullWidth
              error={!!errors.endReading}
              helperText={errors.endReading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Engine 1 Hours"
              type="number"
              value={formData.hours}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  hours: e.target.value,
                }))
              }
              fullWidth
              error={!!errors.hours}
              helperText={errors.hours}
            />
          </Grid>

          {/* Engine 2 Fields */}
          {selectedVehicle?.engine2 && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Engine 2 Start Reading"
                  type="number"
                  value={formData.engine2StartReading}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      engine2StartReading: e.target.value,
                    }))
                  }
                  fullWidth
                  error={!!errors.engine2StartReading}
                  helperText={errors.engine2StartReading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Engine 2 End Reading"
                  type="number"
                  value={formData.engine2EndReading}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      engine2EndReading: e.target.value,
                    }))
                  }
                  fullWidth
                  error={!!errors.engine2EndReading}
                  helperText={errors.engine2EndReading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Engine 2 Hours"
                  type="number"
                  value={formData.engine2Hours}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      engine2Hours: e.target.value,
                    }))
                  }
                  fullWidth
                  error={!!errors.engine2Hours}
                  helperText={errors.engine2Hours}
                />
              </Grid>
            </>
          )}

          {/* KM Fields */}
          {readingType === "km" && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  label="KM Start Reading"
                  type="number"
                  value={formData.kmsStartReading}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      kmsStartReading: e.target.value,
                    }))
                  }
                  fullWidth
                  error={!!errors.kmsStartReading}
                  helperText={errors.kmsStartReading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="KM End Reading"
                  type="number"
                  value={formData.kmsEndReading}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      kmsEndReading: e.target.value,
                    }))
                  }
                  fullWidth
                  error={!!errors.kmsEndReading}
                  helperText={errors.kmsEndReading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="KMs Travelled"
                  type="number"
                  value={formData.kmsTravelled}
                  disabled
                  fullWidth
                />
              </Grid>
            </>
          )}

          {/* Other Fields */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Fuel Quantity (L)"
              type="number"
              value={formData.quantity}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, quantity: e.target.value }))
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Amount (per liter)"
              type="number"
              value={formData.amount}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, amount: e.target.value }))
              }
              fullWidth
              error={!!errors.amount}
              helperText={errors.amount}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={new Date(formData.timestamp)}
                onChange={(newDate) =>
                  setFormData((prev) => ({
                    ...prev,
                    timestamp: newDate
                      ? newDate.getTime()
                      : new Date().getTime(),
                  }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.timestamp}
                    helperText={errors.timestamp}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Purpose"
              value={formData.purpose}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, purpose: e.target.value }))
              }
              fullWidth
              multiline
              rows={2}
              error={!!errors.purpose}
              helperText={errors.purpose}
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6" color="primary">
                Total Amount: ₹
                {(
                  parseFloat(formData.quantity || 0) *
                  parseFloat(formData.amount || 0)
                ).toFixed(2)}
              </Typography>
              <Button
                variant="contained"
                onClick={handleAddEntry}
                disabled={loading}
                sx={{
                  bgcolor: "#fdd34d",
                  color: "black",
                  "&:hover": { bgcolor: "#fdd34d" },
                  borderRadius: 12,
                  padding: "4px 14px",
                }}
              >
                {isEditing ? "Update Entry" : "Add Entry"}
              </Button>
            </Box>
          </Grid>

          {/* Entries Table */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Vehicle</TableCell>
                    <TableCell>Engine 1 Start</TableCell>
                    <TableCell>Engine 1 End</TableCell>
                    <TableCell>Engine 1 Hours</TableCell>
                    {hasEngine2Vehicles && (
                      <>
                        <TableCell>Engine 2 Start</TableCell>
                        <TableCell>Engine 2 End</TableCell>
                        <TableCell>Engine 2 Hours</TableCell>
                      </>
                    )}
                    {hasKmVehicles && (
                      <>
                        <TableCell>KMs Start</TableCell>
                        <TableCell>KMs End</TableCell>
                        <TableCell>KMs Travelled</TableCell>
                      </>
                    )}
                    <TableCell>Fuel (L)</TableCell>
                    <TableCell>Amount (₹/L)</TableCell>
                    <TableCell>Total Amount (₹)</TableCell>
                    <TableCell>Purpose</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries.map((entry, index) => {
                    const vehicle = vehicles.find(
                      (v) => v.id === entry.vehicleId
                    );
                    const hasEngine2 = vehicle?.engine2;
                    const isKmVehicle = vehicle?.readingType === "km";

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {new Date(entry.timestamp).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{`${vehicle?.vehicleNumber} ${vehicle?.vehicleType}`}</TableCell>
                        <TableCell>{entry.startReading}</TableCell>
                        <TableCell>{entry.endReading}</TableCell>
                        <TableCell>{entry.hours}</TableCell>
                        {hasEngine2Vehicles && (
                          <>
                            <TableCell>
                              {hasEngine2 ? entry.engine2StartReading : "-"}
                            </TableCell>
                            <TableCell>
                              {hasEngine2 ? entry.engine2EndReading : "-"}
                            </TableCell>
                            <TableCell>
                              {hasEngine2 ? entry.engine2Hours : "-"}
                            </TableCell>
                          </>
                        )}
                        {hasKmVehicles && (
                          <>
                            <TableCell>
                              {isKmVehicle ? entry.kmsStartReading : "-"}
                            </TableCell>
                            <TableCell>
                              {isKmVehicle ? entry.kmsEndReading : "-"}
                            </TableCell>
                            <TableCell>
                              {isKmVehicle ? entry.kmsTravelled : "-"}
                            </TableCell>
                          </>
                        )}
                        <TableCell>{entry.quantity}</TableCell>
                        <TableCell>{entry.amount}</TableCell>
                        <TableCell>
                          {(
                            parseFloat(entry.quantity || 0) *
                            parseFloat(entry.amount || 0)
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>{entry.purpose}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(index)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleDelete(index)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConsumptionModal;
