import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./FilterSearchBar.css";
import { FaSearch, FaTimes } from "react-icons/fa";
import { GiExplosiveMaterials } from "react-icons/gi";
import { FaSort } from "react-icons/fa";
import { CiFilter } from "react-icons/ci";
import { DropdownButton } from "react-bootstrap";
import MaterialButton from "./DropdownButton";
import Tag from "./Tag";
import DateRangePicker from "./DateRangePicker";
import ResetButton from "../Helpers/ResetButton";
import ExportButton from "./ExportButton";

const FilterSearchBar = ({
  categoryFilter,
  setCategoryFilter,
  ascending,
  setAscending,
  filterOptions,
  setFilterOptions,
  dropdownItems,
  onDropdownSelect,
  onSortClick,
  onFilterClick,
  searchPlaceholder,
  searchQuerry,
  onSearchChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  removeFilter,
  resetFunction,
}) => {
  const [sortDirection, setSortDirection] = useState("asc");

  const [materialOption, setMaterialOption] = useState(false);

  const materialOptions = ["option one", "option two", "option three"];

  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMaterialOption(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MaterialButton
            name={"Categories"}
            options={dropdownItems}
            onSubmit={(option) => {
              onDropdownSelect(option);
            }}
            icon={<GiExplosiveMaterials color={"#696969"} size={15} />}
          />
          <MaterialButton
            name={"Sort By"}
            options={["Ascending", "Descending"]}
            onSubmit={(option) => {
              console.log("this is the selcted option here", option);
              onSortClick(option);
            }}
            icon={<FaSort color={"#696969"} size={15} />}
          />
          <MaterialButton
            name={"Filters"}
            options={[
              "Pending Items",
              "Approved Items",
              "Declined Items",
              "PO Released",
              "Material Reached",
            ]}
            onSubmit={(option) => {
              onFilterClick(option);
            }}
            icon={<CiFilter color={"#696969"} size={15} />}
          />
        </div>

        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginRight: 50,
          }}
        >
          <input
            type="text"
            style={{
              height: 50,
              width: "400px",
              paddingLeft: "40px", // Space for the icon
              paddingRight: "40px", // Space for the cross icon
              borderRadius: "25px", // Rounded corners
              border: "1px solid #ddd", // Light border
              backgroundColor: "#f9f9f9", // Soft background color
              fontSize: "16px", // Readable text size
              color: "#333", // Text color
              outline: "none",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)", // Light shadow for depth
              transition: "all 0.3s ease", // Smooth transition for focus
            }}
            value={searchQuerry}
            placeholder={searchPlaceholder || "Search here..."}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <FaSearch
            style={{
              position: "absolute",
              top: "50%",
              left: "12px",
              transform: "translateY(-50%)", // Center the icon vertically
              color: "#888", // Icon color
              fontSize: "20px",
              pointerEvents: "none", // Prevent interaction with the icon
            }}
          />
          {searchQuerry.length > 0 && (
            <FaTimes
              style={{
                position: "absolute",
                top: "50%",
                right: "12px",
                transform: "translateY(-50%)", // Center the icon vertically
                color: "#888", // Icon color
                fontSize: "20px",
                cursor: "pointer", // Make the cross icon clickable
              }}
              onClick={() => onSearchChange("")} // Clear the input when clicked
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 20,
          }}
        >
          <ResetButton resetFunction={resetFunction} />

          <ExportButton data={[]} />
        </div>
      </div>
      {(categoryFilter.length > 0 ||
        ascending !== null ||
        filterOptions.length > 0) && (
        <p
          style={{
            fontWeight: "500",
            marginTop: 5,
            marginBottom: 5,
            color: "#353535",
          }}
        >
          Active Filter or Filters
        </p>
      )}
      {categoryFilter.map((item, index) => (
        <Tag
          label={item}
          onRemove={() => removeFilter(item, "categoryFilter")}
        />
      ))}

      {ascending !== null && (
        <Tag
          label={ascending === true ? "Ascending" : "Descending"}
          onRemove={() => removeFilter(null, "ascending")}
        />
      )}

      {filterOptions.map((item, index) => (
        <Tag
          label={item}
          onRemove={() => removeFilter(item, "filterOptions")}
        />
      ))}
    </>
  );
};

export default FilterSearchBar;
