import React, { useState, useEffect } from "react";

const DateRangeSelector = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  // const [startDate, setStartDate] = useState(new Date(2025, 2, 3)); // Mar 3, 2025
  // const [endDate, setEndDate] = useState(new Date(2025, 2, 8)); // Mar 8, 2025

  // const yesterday = new Date();
  // yesterday.setDate(yesterday.getDate() - 1); // Get yesterday's date

  // const sevenDaysBeforeYesterday = new Date();
  // sevenDaysBeforeYesterday.setDate(yesterday.getDate() - 7); // 7 days before yesterday

  // const [startDate, setStartDate] = useState(sevenDaysBeforeYesterday);
  // const [endDate, setEndDate] = useState(yesterday);

  // console.log("DateRangeSelector Start Date:", startDate);
  // console.log("DateRangeSelector End Date:", endDate);

  const [showCalendar, setShowCalendar] = useState(false);
  const [currentStartMonth, setCurrentStartMonth] = useState(
    new Date(2025, 1, 1)
  ); // Feb 1, 2025
  const [activeInput, setActiveInput] = useState(null); // 'start' or 'end'

  // Format date to display
  const formatDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  // Get the number of days in a month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Get the first day of the month (0 = Sunday, 1 = Monday, etc.)
  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  // Move to previous month
  const prevMonth = () => {
    setCurrentStartMonth(
      new Date(
        currentStartMonth.getFullYear(),
        currentStartMonth.getMonth() - 1,
        1
      )
    );
  };

  // Move to next month
  const nextMonth = () => {
    setCurrentStartMonth(
      new Date(
        currentStartMonth.getFullYear(),
        currentStartMonth.getMonth() + 1,
        1
      )
    );
  };

  // Check if a date is the same as another date
  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  // Check if a date is between start and end dates
  const isInRange = (date) => {
    return date > startDate && date < endDate;
  };

  // Handle date selection
  const selectDate = (date) => {
    if (activeInput === "start") {
      if (date > endDate) {
        setEndDate(date);
      }
      setStartDate(date);
    } else {
      if (date < startDate) {
        setStartDate(date);
      }
      setEndDate(date);
    }
    setShowCalendar(false);
  };

  // Modern styling with improved colors and transitions
  const containerStyle = {
    display: "flex",
    alignItems: "flex-start",
    fontFamily: "'Segoe UI', Roboto, Arial, sans-serif",
    position: "relative",
  };

  const dateBoxStyle = {
    backgroundColor: "#f8f9fa",
    padding: "12px 16px",
    borderRadius: "6px",
    width: "160px",
    cursor: "pointer",
    boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
    transition: "all 0.2s ease",
    border: "1px solid #e0e0e0",
  };

  const startDateBoxStyle = {
    ...dateBoxStyle,
    marginRight: "10px",
    borderColor: activeInput === "start" ? "#4a6cf7" : "#e0e0e0",
    boxShadow:
      activeInput === "start"
        ? "0 0 0 2px rgba(74, 108, 247, 0.2)"
        : "0 1px 3px rgba(0,0,0,0.05)",
  };

  const endDateBoxStyle = {
    ...dateBoxStyle,
    borderColor: activeInput === "end" ? "#4a6cf7" : "#e0e0e0",
    boxShadow:
      activeInput === "end"
        ? "0 0 0 2px rgba(74, 108, 247, 0.2)"
        : "0 1px 3px rgba(0,0,0,0.05)",
  };

  const labelStyle = {
    fontSize: "12px",
    color: "#666",
    display: "block",
    marginBottom: "6px",
    fontWeight: "500",
  };

  const dateValueStyle = {
    fontSize: "16px",
    color: "#333",
    fontWeight: "500",
  };

  const toTextStyle = {
    margin: "30px 14px 0",
    color: "#666",
    fontSize: "14px",
    fontWeight: "500",
  };

  const calendarContainerStyle = {
    position: "absolute",
    top: "90px",
    left: "0",
    width: "580px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "20px",
    display: showCalendar ? "flex" : "none",
    zIndex: 1000,
    opacity: showCalendar ? 1 : 0,
    transform: showCalendar ? "translateY(0)" : "translateY(-10px)",
    transition: "opacity 0.3s ease, transform 0.3s ease",
    gap: "16px",
  };

  const monthContainerStyle = {
    flex: 1,
    padding: "0 8px",
    minWidth: "260px",
  };

  const monthHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  };

  const monthTitleStyle = {
    fontSize: "15px",
    fontWeight: "600",
    color: "#333",
    textAlign: "center",
  };

  const calendarGridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "1px",
    textAlign: "center",
  };

  const dayLabelStyle = {
    padding: "8px 0",
    fontSize: "12px",
    color: "#888",
    fontWeight: "600",
  };

  const dayStyle = {
    padding: "4px",
    fontSize: "13px",
    borderRadius: "50%",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    transition: "all 0.15s ease",
  };

  const selectedDayStyle = {
    ...dayStyle,
    backgroundColor: "#4a6cf7",
    color: "white",
    fontWeight: "600",
  };

  const inRangeStyle = {
    ...dayStyle,
    backgroundColor: "#e8efff",
    color: "#4a6cf7",
  };

  const arrowButtonStyle = {
    background: "none",
    border: "1px solid #eaeaea",
    borderRadius: "50%",
    width: "28px",
    height: "28px",
    fontSize: "16px",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s ease",
  };

  const disabledDateStyle = {
    ...dayStyle,
    color: "#ddd",
    cursor: "default",
  };

  // Render month calendar
  const renderMonthCalendar = (monthDate) => {
    const year = monthDate.getFullYear();
    const month = monthDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const monthName = monthDate.toLocaleString("default", { month: "long" });

    const days = [];

    // Add empty cells for days before the first day of month
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} style={dayStyle}></div>);
    }

    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isStart = isSameDate(date, startDate);
      const isEnd = isSameDate(date, endDate);
      const isInDateRange = isInRange(date);

      let style = { ...dayStyle };
      if (isStart || isEnd) {
        style = { ...selectedDayStyle };
      } else if (isInDateRange) {
        style = { ...inRangeStyle };
      }

      days.push(
        <div
          key={day}
          style={style}
          onClick={() => selectDate(date)}
          onMouseOver={(e) => {
            if (!isStart && !isEnd && !isInDateRange) {
              e.currentTarget.style.backgroundColor = "#f0f0f0";
            }
          }}
          onMouseOut={(e) => {
            if (!isStart && !isEnd && !isInDateRange) {
              e.currentTarget.style.backgroundColor = "";
            }
          }}
        >
          {day}
        </div>
      );
    }

    // Calculate how many more cells we need to fill to complete the grid nicely
    // This ensures the calendar always has a complete grid of weeks
    const totalCells = days.length;
    const rowsNeeded = Math.ceil(totalCells / 7);
    const totalNeeded = rowsNeeded * 7;
    const remainingCells = totalNeeded - totalCells;

    // Add empty cells after the last day of month if needed
    for (let i = 0; i < remainingCells; i++) {
      days.push(<div key={`empty-end-${i}`} style={dayStyle}></div>);
    }

    return (
      <div style={monthContainerStyle}>
        <div style={monthHeaderStyle}>
          {month === currentStartMonth.getMonth() && (
            <button
              style={arrowButtonStyle}
              onClick={prevMonth}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "";
              }}
            >
              ‹
            </button>
          )}
          {month !== currentStartMonth.getMonth() && (
            <div style={{ width: "28px" }}></div>
          )}
          <div style={monthTitleStyle}>
            {monthName} {year}
          </div>
          {month === currentStartMonth.getMonth() + 1 && (
            <button
              style={arrowButtonStyle}
              onClick={nextMonth}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "";
              }}
            >
              ›
            </button>
          )}
          {month !== currentStartMonth.getMonth() + 1 && (
            <div style={{ width: "28px" }}></div>
          )}
        </div>

        <div style={calendarGridStyle}>
          {/* Day headers */}
          <div style={dayLabelStyle}>S</div>
          <div style={dayLabelStyle}>M</div>
          <div style={dayLabelStyle}>T</div>
          <div style={dayLabelStyle}>W</div>
          <div style={dayLabelStyle}>T</div>
          <div style={dayLabelStyle}>F</div>
          <div style={dayLabelStyle}>S</div>

          {days}
        </div>
      </div>
    );
  };

  // Close calendar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCalendar && !event.target.closest(".date-picker-container")) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  return (
    <div className="date-picker-container" style={{ position: "relative" }}>
      <div style={containerStyle}>
        <div
          style={startDateBoxStyle}
          onClick={() => {
            setShowCalendar(true);
            setActiveInput("start");
          }}
        >
          <span style={labelStyle}>Start Date</span>
          <div style={dateValueStyle}>{formatDate(startDate)}</div>
        </div>

        <div style={toTextStyle}>to</div>

        <div
          style={endDateBoxStyle}
          onClick={() => {
            setShowCalendar(true);
            setActiveInput("end");
          }}
        >
          <span style={labelStyle}>End Date</span>
          <div style={dateValueStyle}>{formatDate(endDate)}</div>
        </div>
      </div>

      {/* Calendar dropdown */}
      <div style={calendarContainerStyle}>
        {/* First Month */}
        {renderMonthCalendar(currentStartMonth)}

        {/* Second Month */}
        {renderMonthCalendar(
          new Date(
            currentStartMonth.getFullYear(),
            currentStartMonth.getMonth() + 1,
            1
          )
        )}
      </div>
    </div>
  );
};

export default DateRangeSelector;
