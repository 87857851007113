import { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import InwardEntryModal from "../InwardEntryModal";
import deleteInwardEntry from "../WriteFunction/DeleteInwardEntry";
import ConfirmationModal from "../Components/ConfirmationModal";

const ItemTableBottom = ({
  userName,
  itemData,
  project,
  projectName,
  items,
  expanded,
  setExpanded,
  isRequestRecord = false,
}) => {
  const [openEntryModal, setOpenEntryModal] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const formatDate = (timestamp) => {
    if (!timestamp) return "Not provided";

    try {
      const date = new Date(timestamp); // Convert timestamp to Date object
      if (isNaN(date.getTime())) return timestamp; // Return original if invalid date

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    } catch (error) {
      return timestamp; // Return original on error
    }
  };

  // Get the appropriate recorded by information based on record type
  const getRecordedByInfo = () => {
    if (isRequestRecord) {
      // For request-converted records
      return {
        recordedBy: itemData.dueDate
          ? "Not provided"
          : itemData.userName || "Not provided",
        date: itemData.receivedData?.metadata?.receivedDate
          ? formatDate(itemData.receivedData.metadata.receivedDate)
          : formatDate(itemData.id),
      };
    } else {
      // For regular inward entries
      if (
        !itemData.requestHistory ||
        Object.keys(itemData.requestHistory).length === 0
      ) {
        return null;
      }

      const lastHistoryEntry = Object.values(itemData.requestHistory)[
        Object.values(itemData.requestHistory).length - 1
      ];

      return {
        recordedBy: lastHistoryEntry["doneBy"] || "Not provided",
        date: itemData.timeStamp
          ? typeof itemData.timeStamp === "number"
            ? formatDate(itemData.timeStamp)
            : itemData.timeStamp
          : formatDate(itemData.id),
      };
    }
  };

  const recordedByInfo = getRecordedByInfo();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <button
        style={{
          ...styles.viewMoreButton,
          opacity: items.length > 2 ? 1 : 0,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <>
            Collapse <AiOutlineUp style={styles.iconButton} />
          </>
        ) : (
          <>
            View More <AiOutlineDown style={styles.iconButton} />
          </>
        )}
      </button>

      {recordedByInfo && (
        <div>
          <p style={{ color: "#353535" }}>
            <span style={{ color: "#353535", fontWeight: "600" }}>
              Recorded by
            </span>{" "}
            : {recordedByInfo.recordedBy}{" "}
            <span style={{ color: "#353535", fontWeight: "600" }}>
              entry on :{" "}
            </span>
            {recordedByInfo.date}
          </p>
        </div>
      )}

      <div style={styles.infoWrapper}>
        {Array.isArray(project) && (
          <h2 style={{ color: "#8f8f8f", marginRight: 10 }}>
            {projectName} |{" "}
          </h2>
        )}
        <h2 style={{ color: "#6b6b6b" }}>{`Total Items : ${items.length}`}</h2>
        {!isRequestRecord && (
          <div style={styles.iconWrapper}>
            <AiOutlineEdit
              size={20}
              style={styles.icon}
              onClick={() => setOpenEntryModal(true)}
            />
            <AiOutlineDelete
              size={20}
              style={styles.icon}
              onClick={() => {
                setConfirm(true);
              }}
            />
          </div>
        )}
      </div>

      {openEntryModal && !isRequestRecord && (
        <InwardEntryModal
          userName={userName}
          categoryItems={[]}
          project={project}
          entryType={"add"}
          close={() => {
            setOpenEntryModal(false);
          }}
          editing={true}
          itemData={itemData}
        />
      )}

      {confirm && !isRequestRecord && (
        <ConfirmationModal
          message={"Are you sure you want to delete this?"}
          onConfirm={() =>
            deleteInwardEntry(
              project.firmDetailsID,
              project.projectID,
              itemData.id,
              itemData
            )
          }
          onCancel={() => setConfirm(false)}
        />
      )}
    </div>
  );
};

const styles = {
  viewMoreButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "8px 16px",
    backgroundColor: "#f5f5f5",
    color: "#353535",
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "1px solid #fdd34d", // Subtle border for a formal look
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)", // Soft shadow for a subtle depth
    transition: "background-color 0.2s ease, transform 0.2s ease",
  },
  viewMoreButtonHover: {
    backgroundColor: "#e6e6e6", // Slightly darker on hover
    transform: "scale(1.02)", // Slightly larger on hover
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  icon: {
    marginLeft: "8px",
    cursor: "pointer",
    color: "#555",
  },
  iconButton: {
    fontSize: "18px",
  },
};

export default ItemTableBottom;
