import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Customized,
  Label,
  ResponsiveContainer,
} from "recharts";
import firebase from "../../config/firebase";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { CircularProgress } from "@material-ui/core";

import "./dropdown.css";
import Modal from "./Modal";

const AnalyticsGraphs = ({ width }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForModal, setDataForModal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analysissPercentage, setAnalysisPercentage] = useState(96);
  const [selectedGraph, setSelectedGraph] = useState("Daily");
  const [maxGraphValue, setMaxGraphValue] = useState(0);
  const [firmBaseValue, setFirmBaseValue] = useState(0);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const id = localStorage.getItem("User"); // this where the id has to go !!!!!

  console.log("id from depth : ", id);

  // const id = true
  if (!id) {
    console.error("User ID not found in session storage.");
  }

  const parsedId = JSON.parse(id);


  if (!parsedId || !parsedId.firmId) {
    console.error("Firm ID not found in parsed user data.");
  }

  const [FirmId, setFirmId] = useState('');


  const fetchAndAssignFirmId = (firmNumber) => {
    // write the code here
    console.log('AnalyticsGraphs parsedId firmNumber : ', firmNumber)
    const poHistoryRef = firebase
      .database()
      .ref("mobileNUmberTopoInformationId/" + firmNumber + "/poInformationId");
    // const topNodesQuery = poHistoryRef.limitToLast(156);
    poHistoryRef.once("value", async (snapshot) => {
      if (snapshot.exists()) {
        console.log("AnalyticsGraphs parsedId snapshot 3 : ", snapshot.val());
        let data = snapshot.val();
        setFirmId(data);
        await sleep(2000);
        setLoading(false);
      } else {
        console.log("No data found here for the PO history");
        await sleep(2000);
        setLoading(false);
      }
    });
  }




  const [data, setData] = useState([
    { name: "Monday", pv: 4000, uv: 8000 },
    { name: "Tuesday", pv: 4000, uv: 3000 },
    { name: "Wednesday", pv: 4000, uv: 2000 },
    { name: "Thursday", pv: 4000, uv: 2780 },
    { name: "Friday", pv: 4000, uv: 1890 },
    { name: "Saturday", pv: 4000, uv: 2390 },
    { name: "Sunday", pv: 4000, uv: 3490 },
  ]);

  useEffect(() => {
    console.log('AnalyticsGraphs parsedId : ', parsedId);
    console.log('AnalyticsGraphs parsedId : ', parsedId.firmId);
    console.log('AnalyticsGraphs parsedId : ', typeof parsedId.firmId);
    fetchAndAssignFirmId(parsedId.firmId);
    console.log('AnalyticsGraphs parsedId  setData : ', data);
  }, [parsedId])


  useEffect(() => {
    console.log('AnalyticsGraphs parsedId fetchDaily is being called...')
    fetchDaily();
  } ,[FirmId]);




  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function groupByWeek(daysArray) {
    // Helper function to get the day index based on the day name
    function getDayIndex(name) {
      const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      return daysOfWeek.indexOf(name);
    }

    // Initialize the result array and a buffer for the current week
    let weeks = [];
    let currentWeek = [];
    let lastDayIndex = -1;
    let currentWeekCount = 0;
    let currentWeekValue = 0;
    let currentWeekTopHistory = {};
    let currentWeekId = null;
    let currentWeekStartDate = null;

    // Iterate over the input daysArray
    daysArray.forEach((dayObj) => {
      const dayIndex = getDayIndex(dayObj.name);

      // Start a new week if the day index is less than or equal to the last day index
      if (dayIndex <= lastDayIndex && currentWeek.length > 0) {
        const currentWeekEndDate = currentWeek[currentWeek.length - 1].date;
        weeks.push({
          id: currentWeekId,
          count: currentWeekCount,
          value: currentWeekValue,
          topHistory: currentWeekTopHistory,
          date: `${currentWeekStartDate}-${currentWeekEndDate}`,
          name: `${currentWeekStartDate}-${currentWeekEndDate}`,
        });
        currentWeek = [];
        currentWeekCount = 0;
        currentWeekValue = 0;
        currentWeekTopHistory = {};
        currentWeekId = null;
      }

      if (currentWeek.length === 0) {
        currentWeekStartDate = dayObj.date;
      }

      currentWeek.push(dayObj);
      currentWeekCount += dayObj.count;
      currentWeekValue += dayObj.value;
      currentWeekId = currentWeekId === null ? dayObj.id : currentWeekId;

      // Merge topHistory
      Object.keys(dayObj.topHistory).forEach((key) => {
        currentWeekTopHistory[key] = dayObj.topHistory[key];
      });

      lastDayIndex = dayIndex;
    });

    // Push the last week if there are any remaining days
    if (currentWeek.length > 0) {
      const currentWeekEndDate = currentWeek[currentWeek.length - 1].date;
      weeks.push({
        id: currentWeekId,
        count: currentWeekCount,
        value: currentWeekValue,
        topHistory: currentWeekTopHistory,
        date: `${currentWeekStartDate}-${currentWeekEndDate}`,
        name: `${currentWeekStartDate}-${currentWeekEndDate}`,
      });
    }

    return weeks;
  }

  function aggregateMonthlyData(dataArray) {
    const monthlyData = {};

    dataArray.forEach((item) => {
      let modifiedDate = item.date.split("/");
      const date = new Date(
        modifiedDate[1] + "/" + modifiedDate[0] + "/" + modifiedDate[2]
      );
      const month = date.getMonth();
      const year = date.getFullYear();
      const monthYear = `${month + 1}/${year}`; // Get the month and year in "MM/YYYY" format

      if (!monthlyData[monthYear]) {
        console.log("item : ", item);
        console.log("date : ", date);
        monthlyData[monthYear] = {
          id: item.id,
          count: 0,
          value: 0,
          name: date.toLocaleString("default", { month: "long" }), // Get the month name
          date: {
            start: `${year}-${String(month + 1).padStart(2, "0")}-01`, // First day of the month
            end: `${year}-${String(month + 1).padStart(2, "0")}-${new Date(
              year,
              month + 1,
              0
            ).getDate()}`, // Last day of the month
          },
          topHistory: [],
        };
      }

      monthlyData[monthYear].count += item.count;
      monthlyData[monthYear].value += item.value;

      // Merge topHistory entries

      const currentTopHistories = [
        item.topHistory.countdbeAmyTimestamp1,
        item.topHistory.countdbeAmyTimestamp2,
        item.topHistory.countdbeAmyTimestamp3,
      ];

      monthlyData[monthYear].topHistory.push(...currentTopHistories);
    });

    // Process the topHistory to keep only the top 3 entries for each month
    Object.keys(monthlyData).forEach((monthYear) => {
      monthlyData[monthYear].topHistory.sort((a, b) => b.value - a.value);
      monthlyData[monthYear].topHistory = monthlyData[
        monthYear
      ].topHistory.slice(0, 3);
    });

    // Sort the keys by month and year
    const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
      const [aMonth, aYear] = a.split("/").map(Number);
      const [bMonth, bYear] = b.split("/").map(Number);
      return aYear - bYear || aMonth - bMonth;
    });

    // Return the sorted array of monthly data
    return sortedKeys.map((monthYear) => monthlyData[monthYear]);
  }

  const fetchMonthly = async () => {
    const poHistoryRef = firebase
      .database()
      .ref("poInformation/" + FirmId + "/poAnalytics");
    const topNodesQuery = poHistoryRef.limitToLast(156);
    topNodesQuery.once("value", async (snapshot) => {
      if (snapshot.exists()) {
        console.log("snapshot 3 : ", snapshot.val());
        let data = snapshot.val();
        // here I am going to update the things...
        let baseValue = data["FirmBaseValue"];
        delete data.FirmBaseValue;
        // we are getting all the values here as per the week here
        let allValues = aggregateMonthlyData(Object.values(data));
        setFirmBaseValue(baseValue * 4 * 5);
        console.log("allValues : ", JSON.stringify(allValues));
        setData(() => {
          return Object.values(allValues)
            .splice(-5)
            .map((obj) => ({
              ...obj,
              baseValue: baseValue * 4 * 5,
            }));
        });
        await sleep(2000);
        setLoading(false);
      } else {
        console.log("No data found here for the PO history");
        await sleep(2000);
        setLoading(false);
      }
    });
  };

  const fetchWeekly = async () => {
    const poHistoryRef = firebase
      .database()
      .ref("poInformation/" + FirmId + "/poAnalytics");
    const topNodesQuery = poHistoryRef.limitToLast(32);
    topNodesQuery.once("value", (snapshot) => {
      if (snapshot.exists()) {
        console.log("snapshot 3 : ", snapshot.val());
        let data = snapshot.val();
        // here I am going to update the things...
        let baseValue = data["FirmBaseValue"];
        delete data.FirmBaseValue;
        // we are getting all the values here as per the week here
        let allValues = groupByWeek(Object.values(data));
        console.log("allValues : ", JSON.stringify(allValues));
        setFirmBaseValue(baseValue * 4);
        setData(() => {
          return Object.values(allValues)
            .splice(-5)
            .map((obj) => ({
              ...obj,
              baseValue: baseValue * 4,
            }));
        });
      } else {
        console.log("No data found here for the PO history");
      }
    });

    await sleep(2000);
    setLoading(false);
  };

  const fetchDaily = async () => {
    console.log('AnalyticsGraphs parsedId fetch Daoly is called...', FirmId, typeof FirmId)
    const poHistoryRef = firebase
      .database()
      .ref("poInformation/" + FirmId + "/poAnalytics");
    const topNodesQuery = poHistoryRef.limitToLast(8);
    topNodesQuery.on("value", (snapshot) => {
      console.log('AnalyticsGraphs parsedId snapshot.exists() : ', snapshot.exists())
      if (snapshot.exists()) {
        // here we found the snapshot
        let data = snapshot.val();
        let baseValue = 0;
        if (data.hasOwnProperty("FirmBaseValue")) {
          baseValue = data["FirmBaseValue"];
          delete data.FirmBaseValue;
          setFirmBaseValue(baseValue);
        } else {
          baseValue = 500000;
          setFirmBaseValue(500000);
          firebase
            .database()
            .ref("poInformation/" + FirmId + "/poAnalytics/FirmBaseValue/")
            .set(500000);
        }

        setData(() => {
          return Object.values(data).map((obj) => ({
            ...obj,
            baseValue: baseValue,
          }));
        });
      } else {
        console.log("No data found here for the PO history");
      }
    });
    await sleep(1000);
    setLoading(false);
  };

  useEffect(() => {
    fetchDaily();
  }, []);

  function maxValues(array) {
    return array.reduce((max, currentObject) => {
      return currentObject.value > max ? currentObject.value : max;
    }, -Infinity);
  }

  useEffect(() => {
    setMaxGraphValue(() =>
      maxValues(data) > firmBaseValue ? maxValues(data) : firmBaseValue
    );
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    try{
      if (active && payload && payload.length > 0) {
        let history = Object.values(payload[1].payload.topHistory);
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#EBEBF0",
              padding: "20px",
              borderRadius: "8px",
              fontFamily: "Arial, sans-serif",
            }}>
            {history.map(
              (item, index) => (
                console.log("item from CustomTooltip : ", item),
                (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}>
                        <p
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                          }}>
                          {item?.name}
                        </p>
                        <p style={{ opacity: 0 }}>C</p>
                        <p
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                          }}>
                          {item?.value}
                        </p>
                      </div>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          color: "#999",
                          maxWidth: "200px",
                          overflowWrap: "break-word",
                        }}>
                        {item?.category}
                      </p>
                    </div>
                  </div>
                )
              )
            )}
            <p
              style={{
                margin: 0,
                textAlign: "center",
                fontSize: "14px",
                color: "#3A58AE",
                cursor: "pointer",
              }}>
              Click to see more
            </p>
          </div>
        );
      }
      return null;
    }catch(error){
      return null;
    }
  };

  const CustomLabel = ({ x, y, value, index, viewBox }) => {
    const { width, height } = viewBox;
    const offsetX = 0;
    const offsetY = -10;

    const adjustedX = x + offsetX > width - 50 ? x - 30 : x + offsetX;

    // Adjust for the top edge
    const adjustedY = y + offsetY < 10 ? y + 20 : y + offsetY;

    return (
      <g>
        <foreignObject
          x={adjustedX - 50}
          y={adjustedY - 40}
          width="100"
          height="50">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ backgroundColor: index == 0 ? "white" : null }}>
            {/* <div style={{ backgroundColor: 'white', border: '1px solid black', borderRadius: '5px', padding: '2px', textAlign: 'center' }}> */}
            <p style={{ fontSize: 14, fontWeight: "600" }}>
              {"₹ "}
              {value?.toLocaleString("en-IN")}
              {<br />}({data[index]?.count}
              {" POs"})
            </p>
          </div>
        </foreignObject>
      </g>
    );
  };

  const handleClick = (point) => {
    if (selectedGraph !== "Monthly") {
      console.log("Clicked on point : ", point);
      // Your custom click logic here
      try{
        let data = point?.activePayload[1].payload;
        setDataForModal(data);
        openModal();
      }catch(error){
        return;
      }
    }
  };

  const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Daily");
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
      console.log("dropdown option clicked...");
      setIsOpen(!isOpen);
    };

    const options = ["Daily", "Weekly", "Monthly"];

    const handleOptionClick = async (option) => {
      
      setSelectedGraph(option);
      if (option == "Weekly") {
        // write the code here
        setLoading(true);
        fetchWeekly();
      } else if (option == "Monthly") {
        setLoading(true);
        fetchMonthly();
      } else {
        // write the code here
        console.log("reached in the else of all");
        setLoading(true);
        fetchDaily();
      }

      setSelectedOption(option);
      setIsOpen(false);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };

    }, []);

    return (
      <div className="dropdown" ref={dropdownRef}>
        <button
          className="dropdown-toggle"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={toggleDropdown}>
          <p>{selectedOption} </p>{" "}
          {!isOpen ? <FaChevronDown /> : <FaChevronUp />}
        </button>
        {isOpen && (
          <ul className="dropdown-menu">
            {options.map((option, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  function submitOnEnter(event) {
    console.log("event : ", event);
    // event.preventDefault();
    console.log("these are the other things", firmBaseValue);

    const poHistoryRef = firebase
      .database()
      .ref("poInformation/" + FirmId + "/poAnalytics/FirmBaseValue/");

    poHistoryRef.set(firmBaseValue);

    event.preventDefault();
  }

  return (
    <div
      style={{
        width: width,
        maxWidth: width,
        minWidth: width,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        marginLeft: 50,
        // alignItems: 'center'
      }}>
      <div style={{ height: "20vh", width: "100%", marginBottom: 50 }}>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 50,
            }}>
            Amount vs Date Graph
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <form id="myForm" onSubmit={submitOnEnter}>
              <input
                id="enterBaseValue"
                type="text"
                value={firmBaseValue}
                placeholder="Enter Base Value"
                onkeydown={submitOnEnter}
                onFocus={(e) => {
                  e.target.style.borderColor = "#0AC50D";
                  e.target.style.outline = "none";
                  e.target.style.boxShadow = "0 0 5px blue";
                }}
                onChange={(e) => {
                  setFirmBaseValue(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#ccc";
                  e.target.style.outline = "none";
                  e.target.style.boxShadow = null;
                }}
                style={{
                  borderRadius: "5px",
                  marginRight: "10px",
                  padding: "8px",
                  border: "1px solid #ccc",
                  height: "50px",
                  width: "200px",
                }}
              />
            </form>

            {Dropdown()}
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            alignSelf: "center",
            marginTop: "25vh",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}>
          <CircularProgress style={{ color: "#fdd34d" }} />
          <p style={{ color: "#fdd34d", fontSize: "16px" }}>
            Analysing...{analysissPercentage}
            {" %"}
          </p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            paddingRight: 100,
          }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={1500}
              height={1000}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              onClick={handleClick}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              {selectedGraph === "Monthly" ? (
                <YAxis
                  domain={[0, "dataMax + 5000000"]}
                  tickFormatter={(tick) => `${(tick / 100000).toFixed(2)}L`}
                />
              ) : maxGraphValue > 1000000 ? (
                <YAxis domain={[0, "dataMax + 500000"]} />
              ) : maxGraphValue < 1000000 && maxGraphValue > 100000 ? (
                <YAxis domain={[0, "dataMax + 100000"]} />
              ) : maxGraphValue < 100000 && maxGraphValue > 10000 ? (
                <YAxis domain={[0, "dataMax + 10000"]} />
              ) : (
                <YAxis domain={[0, "dataMax + 1000"]} />
              )}
              <Tooltip content={<CustomTooltip />} />

              <Legend />

              <Line
                type="monotone"
                dataKey="baseValue"
                stroke="#F9A826"
                strokeWidth={3}
              />

              <Line
                type="monotone"
                dataKey="value"
                stroke="#0AC50D"
                strokeWidth={1}
                dot={{ stroke: "blue", strokeWidth: 1, r: 5 }}
                activeDot={{ r: 10 }}
                label={<CustomLabel viewBox={{ width: 1500, height: 1000 }} />}
              />

              {/* <Customized>
                {data.map((entry, index) => (
                  <Label
                    key={`label-${index}`}
                    value={`Point ${index + 1}`}
                    offset={10}
                    position="top"
                    valueFormatter={(value) => value}
                    content={() => (
                      <text x={index * 100} y={entry.pv} textAnchor="middle">
                        {`Point ${index + 1}`}
                      </text>
                    )}
                  />
                ))}
              </Customized> */}

            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      <Modal
        key={1}
        isOpen={isModalOpen}
        dataForModal={dataForModal}
        onClose={closeModal}
        firmId={FirmId}
        selectedGraph={selectedGraph}
      />
    </div>
  );
};

export default AnalyticsGraphs;
