import React from 'react';

const EditHistory = ({ historyData }) => {
  const containerStyle = {
    width: '100%',
    overflowX: 'auto',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    boxSizing: 'border-box',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  };

  const thStyle = {
    borderBottom: '2px solid #ddd',
    padding: '8px',
    fontWeight: 'bold',
    backgroundColor: '#f9f9f9',
  };

  const tdStyle = {
    borderBottom: '1px solid #ddd',
    padding: '8px',
  };

  
  function formatTimestamp(timestamp) {

    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 becomes 12)
  
    return `${day}/${month}/${year} | ${hours}:${minutes} ${ampm}`;

  }

  return (
    <div style={containerStyle}>
      <table style={tableStyle}>
        <thead>
          <tr>
            {/* <th style={thStyle}>Field</th> */}
            <th style={thStyle}>Item Description</th>
            <th style={thStyle}>Previous Value</th>
            <th style={thStyle}>Updated Value</th>
            <th style={thStyle}>Updated By</th>
            <th style={thStyle}>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {historyData.map((item, index) => (
            <tr key={index}>
              <td style={tdStyle}>{item.itemDescription.replaceAll('<single-quote>', "'").replaceAll('<double-quote>', '"')}</td>
              <td style={tdStyle}>{item.previousValue}</td>
              <td style={tdStyle}>{item.updatedValue}</td>
              <td style={tdStyle}>{item.updatedBy}</td>
              <td style={tdStyle}>{formatTimestamp(item.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditHistory;
