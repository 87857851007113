import config from "../config/config";

class SmsService {
  async sendSms(message, mobile) {
    try {
      const response = await (
        await fetch(`${config.smsService}sendSms`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ message, mobile }),
        })
      ).json();
      console.log("Response from sendSms API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }
async sendWhatsapp({ to, firm, orderType, category, link }) {
  try {
    const response = await (
      await fetch(
        `${config.whatsAppService.URL}`,
        {
          method: "POST",
          headers: {
            Authorization:
            `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: to,
            type: "template",
            template: {
              name: "po_notify_to_vendor",
              language: {
                code: "en",
              },
              components: [
                {
                  type: "body",
                  parameters: [
                    {
                      type: "text",
                      text: firm,
                    },
                    {
                      type: "text",
                      text: orderType,
                    },
                    {
                      type: "text",
                      text: category[0],
                    },
                    {
                      type: "text",
                      text: orderType,
                    },
                    {
                      type: "text",
                      text: link,
                    },
                  ],
                },
              ],
            },
          }),
        }
      )
    ).json();
    console.log("Response form sendWhatsapp API: ", response);
    return response;
  } catch (e) {
    console.log(e);
    return { status: 400, statusMsg: e };
  }
}





async notifyUnreleasedsms ({to, firm, category}){
  console.log('in the sms service , ' ,to , firm ,category)
  try{
    const response = await (
      await fetch(
        `${config.whatsAppService.URL}`,
        {
          method: "POST",
          headers: {
            Authorization:
            `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: to,
            type: "template",
            template: {
              name: "notifyunreleasedpo",
              language: {
                code: "en_US",
              },
              components: [
                {
                  type: "body",
                  parameters: [
                    {
                      type: "text",
                      text: firm,
                    },
                    {
                      type: "text",
                      text: category,
                    },
                    {
                      type: "text",
                      text: "Site Engineer",
                    },
                  ],
                },
              ],
            },
          }),
        }
      )
    ).json()
    // console.log("Response form sendWhatsapp API: ", response);
    return response
  }
  catch(e){
    console.log(e);
    return { status: 400, statusMsg: e };
  }
}

//use this for any template and pass the data accordingly

// async whatsAppSMS(data){
//   console.log('data received to update ',data)
//   try{
//     const response = await (
//       await fetch(
//         `${config.whatsAppService.URL}`,
//         {
//           method: "POST",
//           headers: {
//             Authorization:
//             `Bearer ${config.whatsAppService.AuthKey}`,
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify({...data}),
//         }
//       )
//     ).json()
//     // console.log("Response form sendWhatsapp API: ", response);
//     return response
//   }
//   catch(e){
//     console.log(e);
//     return { status: 400, statusMsg: e };
//   }

// }

async whatsAppSMS(send_to,template_name,template_language_code,components){
  // console.log('data received to update ',template_name,template_language_code,components,send_to)
  try{
    const response = await (
      await fetch(
        `${config.whatsAppService.URL}`,
        {
          method: "POST",
          headers: {
            Authorization:
            `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: send_to,
            type: "template",
            template: {
              name: template_name,
              language: {
                code: template_language_code,
              },
              components: components,
            },
          }),
        }
      )
    ).json()
    console.log("Response form sendWhatsapp API: ", response);
    return response
  }
  catch(e){
    console.log(e);
    return { status: 400, statusMsg: e };
  }

}

}
export default SmsService;
