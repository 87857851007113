import React, { useState } from "react";
import ActionButtons from "./ActionButtons";
import toast from "react-hot-toast";
import { CgFileDocument } from "react-icons/cg";
import updateRequestStatus from "../Helpers/updateRequestStatus";
import ImageModal from "../../Inventory/Components/ImageModal";
import BorderBottomInput from "./BorderBottomInput";
import SaveButton from "./SaveButton";

const ImageScrollBox = ({
  itemIndex,
  selectedProject,
  item,
  images,
  enteredRemark,
  setEnteredRemark,
  items,
  setItems,
  userName,
  close,
  updateRequestStatusLocally,
  editedHistory,
}) => {
  const [actionOccured, setActionOccured] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const imageItems = Object.values(images);
  const [displayImage, setDisplayImage] = useState(false);

  return (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // padding: "0.5rem 1rem",
        backgroundColor: "#f9f9f9",
        borderTop: "1px solid #ddd",
        boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
        zIndex: 50000,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          overflowX: "auto",
          maxWidth: "50%",
        }}
      >
        {images &&
          Object.values(images).map((image, index) => (
            <div
              key={index}
              style={{
                flex: "0 0 auto",
                width: "100px",
                height: "100px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedImageIndex(index);
                setDisplayImage(true);
              }}
            >
              <img
                src={image.url}
                alt={`Image ${index + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
      </div>

      <BorderBottomInput
        width="35%"
        value={enteredRemark}
        onChange={(e) => setEnteredRemark(e.target.value)}
        placeholder="Enter you remark"
      />

      {/* <ActionButtons
        actionOccured={actionOccured}
        item={
          Object.values(item.requestHistory)[
            Object.values(item.requestHistory).length - 1
          ]
        }
        onApprove={async () => {
          setActionOccured(true);
          let timestamp = new Date().getTime();
          let tempItems = JSON.parse(JSON.stringify(item));
          tempItems["requestHistory"][timestamp] = {
            id: timestamp,
            name: userName,
            remark: enteredRemark,
            status: "Approved",
          };
          await updateRequestStatus(
            `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${item.id}/`,
            tempItems
          );
          setActionOccured(false);
          setItems(tempItems);
          close();
          updateRequestStatusLocally(tempItems);
          toast.success("This request has been approved", {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} />,
          });
        }}
        onDecline={async () => {
          let timestamp = new Date().getTime();
          let tempItems = { ...items };
          tempItems["requestHistory"][timestamp] = {
            id: timestamp,
            name: userName,
            remark: enteredRemark,
            status: "Declined",
          };
          setActionOccured(true);
          await updateRequestStatus(
            `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${item.id}/`,
            tempItems
          );
          setActionOccured(false);
          setItems(tempItems);
          updateRequestStatusLocally(tempItems);
          toast.success("This request has been declined", {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} />,
          });
        }}
        onReleasePO={async () => {
          setActionOccured(true);
          await updateRequestStatus(
            "tempRun/1782736/2796493/28347/requestHistory/8294758393",
            { vroom: "bitch" }
          );
          setActionOccured(false);
          // setItems(tempItems);
          toast.success("wait while we take you to the dream!", {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} />,
          });
        }}
      /> */}

      <SaveButton
        onSave={async () => {
          let timestamp = new Date().getTime();
          let tempItems = JSON.parse(JSON.stringify(item));
          
          const updatedData = {};
          Object.keys(editedHistory).forEach((key, index) => {
            
            const newKey = `${timestamp}`; // Update the key (e.g., newKey1, newKey2, etc.)
            updatedData[newKey] = {
              ...editedHistory[key],
              id: newKey, // Add the timestamp
            };
            timestamp = timestamp + 1
          });
          if (tempItems.hasOwnProperty("editHistory")) {
            tempItems["editHistory"] = {
              ...tempItems["editHistory"],
              ...updatedData,
            };
          } else {
            tempItems["editHistory"] = updatedData;
          }
          await updateRequestStatus(
            `${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${item.id}/`,
            tempItems
          );
          setItems(tempItems);
          close();
          updateRequestStatusLocally(tempItems);
          toast.success("This request has been updated", {
            duration: 4000,
            position: "bottom-center",
            style: {
              borderRadius: "8px",
              padding: "16px",
              color: "#1A85B1",
              backgroundColor: "#F1FBFF",
              maxWidth: "100%",
            },
            icon: <CgFileDocument color={"#1A85B1"} />,
          });
        }}
      />

      {displayImage && (
        <ImageModal
          images={imageItems}
          selectedImageIndex={selectedImageIndex}
          onClose={() => {
            setDisplayImage(false);
          }}
        />
      )}
    </div>
  );
};

export default ImageScrollBox;
