import React from "react";

const ProgressBar = ({ item, completeItem }) => {
  function createMilestones(completeItem) {
    const milestonesTemplate = [
      { name: "Material Requested", key: "Requested" },
      { name: "Material Approved", key: "Approved" },
      { name: "PO Released", key: "Released" },
      { name: "Material Reached", key: "Reached" },
    ];

    if (!completeItem.items || completeItem.items.length === 0) {
      return milestonesTemplate.map((milestone) => ({
        ...milestone,
        completed: false,
        completedCount: 0,
        totalItems: 0,
      }));
    }

    const itemStatuses = completeItem.items.map((item) => item.status);

    return milestonesTemplate.map((milestone) => {
      return {
        name: milestone.name,
        key: milestone.key,
        completedCount: itemStatuses.filter((status) => {
          const statusOrder = ["Requested", "Approved", "Released", "Reached"];
          return (
            statusOrder.indexOf(status) >= statusOrder.indexOf(milestone.key)
          );
        }).length,
        totalItems: itemStatuses.length,
      };
    });
  }

  const milestones = createMilestones(completeItem);

  return (
    <div style={{ width: "100%", position: "relative", padding: "40px 0" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          width: "80%", // Keeps space at both ends
          margin: "0 auto",
        }}
      >
        {/* Horizontal Line (Starts after "Material Requested" and ends before "Material Reached") */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "12%", // Starts after "Material Requested"
            width: "76%", // Ends before "Material Reached"
            height: "4px",
            background: "#e0e0e0",
            zIndex: 0,
            transform: "translateY(-50%)",
          }}
        />

        {milestones.map((milestone, index) => {
          const completionPercentage =
            milestone.totalItems > 0
              ? (milestone.completedCount / milestone.totalItems) * 100
              : 0;

          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                position: "relative",
                textAlign: "center",
              }}
            >
              {/* Completed Line */}
              {index > 0 && milestone.completedCount > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "-50%",
                    width: `${completionPercentage}%`,
                    height: "4px",
                    background: "#32CD32",
                    zIndex: 1,
                    transform: "translateY(-50%)",
                  }}
                />
              )}

              {/* Partially completed dashed line */}
              {index > 0 &&
                milestone.completedCount > 0 &&
                milestone.completedCount < milestone.totalItems && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: `-50%`,
                      width: `${100 - completionPercentage}%`,
                      height: "4px",
                      borderBottom: "4px dashed #FA722F",
                      marginLeft: `${completionPercentage}%`,
                      zIndex: 1,
                      transform: "translateY(-50%)",
                    }}
                  />
                )}

              {/* Count above the line */}
              {/* Count above the line (Placeholder to maintain spacing) */}
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: milestone.completedCount > 0 ? "#666" : "transparent", // Hide when inactive
                  marginBottom: "12px", // Ensures consistent spacing
                  minHeight: "16px", // Fixed height to prevent shifting
                }}
              >
                {milestone.completedCount > 0
                  ? `${milestone.completedCount}/${milestone.totalItems}`
                  : "0/0"}
              </div>

              {/* Milestone circle properly centered on the trimmed line */}
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  backgroundColor:
                    milestone.completedCount === milestone.totalItems
                      ? "#32CD32"
                      : milestone.completedCount > 0
                      ? "#FA722F"
                      : "#e0e0e0",
                  border: "3px solid white",
                  boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                  zIndex: 2,
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />

              {/* Status text below the line (with spacing fix) */}
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: milestone.completedCount > 0 ? "600" : "500",
                  color: milestone.completedCount > 0 ? "#333" : "#777",
                  marginTop: "30px", // Added spacing so text doesn’t touch the line
                }}
              >
                {milestone.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
