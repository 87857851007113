import { useEffect, useState } from "react";
import DrawerTemplate from "../../hoc/drawerTemplate";
import AnalyticsBrowser from "./AnalyticsBrowser";
import AnalyticsGraphs from "./AnalyticsGraphs";
import RenderSharedStatus from "./RenderSharedStatus";
import getProjectsHere from "./fetchNMRDataStatus";
import LoadingComponent from "../../components/LoadingComponent";
import NoDataComponent from "../../components/NoDataComponent";
import exportJsonToExcel from "./exportJsonToExcel";
import VendorPaymentDashboard from "./VendorPaymentGrid";

const POAnalytics = () => {

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1); // Get yesterday's date

  const sevenDaysBeforeYesterday = new Date();
  sevenDaysBeforeYesterday.setDate(yesterday.getDate() - 7); // 7 days before yesterday

  const [startDate, setStartDate] = useState(sevenDaysBeforeYesterday);
  const [endDate, setEndDate] = useState(yesterday);

  const [loading, setLoading] = useState(true);
  const [selectedGraph, setSelectedGraph] = useState("poGraph");
  const [sampleData, setSampleData] = useState(null); // Handle empty/null data properly
  const [poData, setPOData] = useState(null)
  const [noDays, setNoDays] = useState(0);
  const [processedDays, setProcessedDays] = useState(0);
  const [numLoading, setNumLoading] = useState(false);

  const gettingData = async () => {

    
    if(sampleData === null){
      setLoading(true);
    }else{
      setNumLoading(true);
    }

    try {
      let samData = await getProjectsHere(startDate, endDate, setProcessedDays, setNoDays);
      
      setSampleData(samData);
      setLoading(false);
      setProcessedDays(0);
      setNumLoading(false)
    } catch (error) {
      setSampleData(null);
      setLoading(false);
    }

  };

  const getDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Difference in milliseconds
    const diffInMs = end - start;

    // Convert milliseconds to days
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

};

  useEffect(() => {
    if (selectedGraph === "nmrGraph" && sampleData === null) {
      gettingData();
    }
  }, [selectedGraph]);
  
  useEffect(() => {

    if (selectedGraph === "nmrGraph") {
      gettingData();
    }
    
  }, [startDate, endDate]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* Left Sidebar */}
      <AnalyticsBrowser
        width={"20%"}
        setSelectedGraph={setSelectedGraph}
        selectedGraph={selectedGraph}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        exportJsonToExcel={() => {
          exportJsonToExcel(sampleData)
        }}
        progressNumber={(processedDays/noDays).toFixed(2)*100 || 0}
        numLoading={numLoading}
      />

      {/* Right Content Area */}
      <div style={{ width: "80%", height: "100%", position: "relative" }}>
        {/* Show AnalyticsGraphs only when selectedGraph is "poGraph" */}
        {selectedGraph === "poGraph" && <div style={{
          width: '95%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <AnalyticsGraphs width={"100%"} setPOData={setPOData} />
          {
            console.log('VendorPaymentDashboard poData ; ', poData)
          }
          {/* {poData !== null && <VendorPaymentDashboard data={poData}/>} */}
          </div>}

        {/* Show Loading Animation for "nmrGraph" */}
        {selectedGraph === "nmrGraph" &&
          (loading ? (
            <LoadingComponent progressState={processedDays/noDays ? (processedDays/noDays).toFixed(2) : 0} />
          ) : sampleData && Object.keys(sampleData).length > 0 ? (
            <RenderSharedStatus siteStatusData={sampleData} numLoading={numLoading} />
          ) : (
            <NoDataComponent />
          ))}

        {(selectedGraph === "nopovsdate" ||
          selectedGraph === "paidvsunpaid") && <NoDataComponent />}
      </div>
    </div>
  );
};

export default DrawerTemplate(POAnalytics);
