import React, { useEffect, useState } from "react";
import drawerTemplate from "../../hoc/drawerTemplate";
import InwardEntryModal from "./InwardEntryModal";
import MaterialDetails from "./MaterialDetails";
import { MdArrowBackIos, MdAdd } from "react-icons/md";
import { GrFormSubtract } from "react-icons/gr";
import TabComponent from "./TabComponent";
import generateInwardData from "./Saviours/UpdateInwardData";
import generateOutwardData from "./Saviours/UpdateOutwardData";
import netInventoryData from "./Saviours/UpdateNetInventoryData";
import InwardCard from "./Cards/InwardCard";
import OutwardCard from "./Cards/OutwardCards/OutwardCard";
import { secondDatabase } from "../../config/firebase";
import InventoryItem from "./Components/InventoryItem";
import LoadingIcon from "./Components/LoadingIcon";
import NoData from "./Components/NoData";
import fetchItems from "./WriteFunction/fetchCategoryItems";
import handleExportExcelInward from "./Components/ExportToExcelComponent";
import SearchAndFilterComponent from "./Components/SearchAndFilterComponent";
import handleExportExcelOutward from "./Components/handleExportExcelOutward";
import { useHistory, useLocation } from "react-router-dom";

const Inventory = () => {
  const location = useLocation();
  const history = useHistory();
  // Create a local state to track comingFromVehicles
  const [isComingFromVehicles, setIsComingFromVehicles] = useState(
    location.state?.comingFromVehicles || false
  );
  const [projectIdFromVehicle, setProjectIdFromVehicle] = useState(
    location.state?.projectId || null
  );
  // Add this useEffect near your other useEffect hooks
  useEffect(() => {
    console.log("isComingFromVehicles : ", isComingFromVehicles);
    console.log("projectIdFromVehicle : ", projectIdFromVehicle);
  }, [isComingFromVehicles]);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [project, setProject] = useState({});
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterial] = useState([]);
  const [outwardItems, setOutwardItems] = useState([]);
  const [filteredOutwardItems, setFilteredOutwardItems] = useState([]);
  const [inwardItems, setInwardItems] = useState([]);
  const [filteredInwardItems, setFilteredInwardItems] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [entryType, setEntryType] = useState(null);
  const [material, setMaterial] = useState({});
  const [viewMaterialDetails, setViewMaterialDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All Materials");
  const [categoryItems, setCategoryItems] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // useEffect(() => {
  //   if (isComingFromVehicles && materials.length > 0) {
  //     const fuelMaterial = materials.find(
  //       (material) => material.category === "Fuel"
  //     );
  //     if (fuelMaterial) {
  //       setViewMaterialDetails(true);
  //       setMaterial(fuelMaterial);
  //     }
  //   }
  // }, [isComingFromVehicles, materials]);
  const prePrepareData = async () => {
    const localStorageData = JSON.parse(localStorage.getItem("User"));

    const tempFirmId = localStorageData.firmId;

    const useRef = await secondDatabase.ref(`userDetails/${tempFirmId}`);

    useRef.once("value", function (snapshot) {
      let data = snapshot.val();

      if (data !== null) {
        let tempProjects = Object.values(data.projects);
        setAllProjects(tempProjects);
        setProject(tempProjects[0]);
      } else {
        setAllProjects([]);
        setProject({});
      }

      if (localStorageData.userName.length > 0) {
        setUserName(localStorageData.userName);
      } else {
        if (data !== null) {
          setUserName(data?.personalDetails?.name);
        }
      }
    });
  };

  useEffect(() => {
    prePrepareData();
  }, []);

  const fetchData = async () => {
    // Fetch materials data
    setLoading(true);
    if (selectedTab === "All Materials") {
      if (!Array.isArray(project)) {
        const materialsData = await netInventoryData(project);
        setMaterials(materialsData);
      } else {
        const materialsData = await netInventoryData(allProjects);
        setMaterials(materialsData);
      }
    } else if (selectedTab === "Inward") {
      let inwardItems = await generateInwardData(project);
      setInwardItems(inwardItems);
    } else if (selectedTab === "Outward") {
      let outwardItem = await generateOutwardData(project);
      setOutwardItems(outwardItem);
    }
    setLoading(false);
    let materialItems = await fetchItems(
      project.firmDetailsID,
      project.projectID
    );
    setCategoryItems(materialItems);
  };

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      setFilteredMaterial([]);
      setFilteredOutwardItems([]);
      setFilteredInwardItems([]);
      fetchData(); // Call the async function
    }
  }, [project, selectedTab]);

  useEffect(() => {
    if (materials.length > 0 && selectedTab === "All Materials") {
      const filteredMaterials = materials.filter(
        (material) =>
          material.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          material.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
          material.status.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMaterial(filteredMaterials);
    } else {
      selectedTab === "All Materials" && setFilteredMaterial([]);
    }
  }, [materials, searchQuery]);

  useEffect(() => {
    if (outwardItems.length > 0 && selectedTab === "Outward") {
      const outwardItemsArray = Object.values(outwardItems);

      const getAllValues = (obj) => {
        let values = [];
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // Recursively collect values for nested objects
            values = values.concat(getAllValues(obj[key]));
          } else {
            // Add primitive values
            values.push(String(obj[key] || "").toLowerCase());
          }
        }
        return values;
      };

      const filteredMaterials = outwardItemsArray.filter((material) => {
        const allValues = getAllValues(material); // Get all values from the object
        return allValues.some((value) =>
          value.includes(searchQuery.toLowerCase())
        ); // Check if any value includes searchQuery
      });

      // Update the state with the filtered materials
      setFilteredOutwardItems(filteredMaterials);
    } else {
      setFilteredOutwardItems([]);
    }
  }, [outwardItems, searchQuery]);

  useEffect(() => {
    if (inwardItems.length > 0 && selectedTab === "Inward") {
      // Convert inwardItems object to an array if it exists
      const inwardItemsArray = Object.values(inwardItems || {});

      // Function to recursively extract all values from an object
      const getAllValues = (obj) => {
        let values = [];
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // Recursively collect values for nested objects
            values = values.concat(getAllValues(obj[key]));
          } else {
            // Add primitive values
            values.push(String(obj[key] || "").toLowerCase());
          }
        }
        return values;
      };

      // Special handling for request-converted records
      const getSearchableValues = (item) => {
        // For request-converted records (with dueDate property)
        if (item.dueDate) {
          return [
            String(item.key || "").toLowerCase(), // PO Number
            String(item.mrnId || "").toLowerCase(), // MRN No
            String(item.id || "").toLowerCase(), // Invoice No
            String(item.userName || "").toLowerCase(), // Supplier
            String(item.receivedData?.metadata?.vehicleNo || "").toLowerCase(), // Vehicle No
            String(item.receivedData?.metadata?.gatePassNo || "").toLowerCase(), // Gate Pass No
            String(item.receivedData?.metadata?.dcNumber || "").toLowerCase(), // DC Number
            // Include item descriptions
            ...(item.items
              ? item.items.map((i) => String(i.description || "").toLowerCase())
              : []),
          ].filter(Boolean);
        }

        // For regular inward entries, use the getAllValues function
        return getAllValues(item);
      };

      // Filter items where searchQuery is found in any value
      const filteredMaterials = inwardItemsArray.filter((item) => {
        if (!searchQuery.trim()) return true; // Show all if no search query

        const searchableValues = getSearchableValues(item);
        return searchableValues.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
      });

      // Update the state with the filtered materials
      setFilteredInwardItems(filteredMaterials);
    } else {
      setFilteredInwardItems([]);
    }
  }, [inwardItems, searchQuery, selectedTab]);

  const getPlusIconColor = () => {
    switch (selectedTab) {
      case "Inward":
        return "#28a745"; // Light green
      case "Outward":
        return "#dc3545"; // Light red
      default:
        return "transparent"; // Hide icon for 'All Materials'
    }
  };

  const openModal = (e, material, type) => {
    e.stopPropagation();
    setEntryType(type);
    setSelectedMaterial({ ...material, type });
  };

  const closeModal = () => {
    setSelectedMaterial(null);
  };

  const onItemSelection = async (selectedItem) => {
    console.log("I am called : ");
    setLoading(true);

    if (viewMaterialDetails) {
      console.log("viewMaterialDetails : ", viewMaterialDetails);
      let materialsData;

      if (selectedItem) {
        console.log("selectedItem inside this : ", selectedItem);
        // Single project selected
        materialsData = await netInventoryData(selectedItem);
        const updatedMaterial = materialsData.find(
          (m) => m.name === material.name && m.category === material.category
        );

        if (!updatedMaterial) {
          alert("No data found for this material in selected project");
          setLoading(false);
          return false;
        } else {
        }

        setMaterial(updatedMaterial);
        setProject(selectedItem);
      } else {
        console.log("allProjects : ", allProjects);
        // All sites selected
        materialsData = await netInventoryData(allProjects);

        // Filter materials with same name and category across all projects
        const relevantMaterials = materialsData.filter(
          (m) => m.name === material.name && m.category === material.category
        );

        if (relevantMaterials.length === 0) {
          alert("No data found for this material across any projects");
          return false;
        }

        // Create consolidated material object for all sites
        const consolidatedMaterial = {
          name: material.name,
          category: material.category,
          unit: material.unit,
          quantity: relevantMaterials.reduce((sum, m) => sum + m.quantity, 0),
          historyDetails: [], // Will be populated below
          isAllSites: true,
        };

        // Combine history details from all projects
        relevantMaterials.forEach((projectMaterial) => {
          if (projectMaterial.historyDetails) {
            const projectHistoryEntries = Object.entries(
              projectMaterial.historyDetails
            ).map(([timestamp, entry]) => ({
              ...entry,
              projectName: projectMaterial.projectName,
              timeStamp: timestamp,
              quantity: parseFloat(entry.quantity),
            }));
            consolidatedMaterial.historyDetails.push(...projectHistoryEntries);
          }
        });

        // Sort history details by timestamp (most recent first)
        consolidatedMaterial.historyDetails.sort(
          (a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp)
        );

        setMaterial(consolidatedMaterial);
        setProject(allProjects);
      }
    } else {
      console.log(
        "selectedItem inside the view material Details : ",
        selectedItem
      );
      // Regular project selection (not in material details view)
      if (selectedItem) {
        setProject(selectedItem);
      } else {
        setProject(allProjects);
      }
    }
    setLoading(false);
    return true;
  };

  const filterDataWRTDate = async () => {
    if (selectedTab === "Inward") {
      const filteredData = inwardItems.filter((entry) => {
        let entryDate;

        // For request-converted records (with dueDate property)
        if (entry.dueDate) {
          // Use receivedData.metadata.receivedDate if available
          if (entry.receivedData?.metadata?.receivedDate) {
            entryDate = new Date(entry.receivedData.metadata.receivedDate);
          } else {
            // Fallback to entry.id (timestamp) if receivedDate is not available
            entryDate = new Date(parseInt(entry.id));
          }
        }
        // For regular inward entries
        else if (typeof entry.timeStamp === "number") {
          entryDate = new Date(entry.timeStamp);
        } else if (typeof entry.timeStamp === "string") {
          const entryDateParts = entry.timeStamp.split("/");
          if (entryDateParts.length === 3) {
            entryDate = new Date(
              `${entryDateParts[2]}-${entryDateParts[1]}-${entryDateParts[0]}`
            );
          } else {
            // Try to parse the string as a timestamp
            entryDate = new Date(parseInt(entry.timeStamp));
          }
        } else if (entry.id) {
          // Use entry.id as fallback if it's a number
          entryDate = new Date(parseInt(entry.id));
        }

        // If entryDate is invalid or missing, exclude the entry
        if (!entryDate || isNaN(entryDate.getTime())) {
          return false;
        }

        const startDateObj = startDate ? new Date(startDate) : null;
        const endDateObj = endDate ? new Date(endDate) : null;

        // Set end date to end of day for inclusive filtering
        if (endDateObj) {
          endDateObj.setHours(23, 59, 59, 999);
        }

        return (
          (!startDateObj || entryDate >= startDateObj) &&
          (!endDateObj || entryDate <= endDateObj)
        );
      });

      setFilteredInwardItems(filteredData);
    }
  };

  const handleExcelExport = () => {
    if (selectedTab === "Inward") {
      if (filteredInwardItems.length === 0) {
        alert("No data found for the selected date range.");
        return;
      }

      try {
        handleExportExcelInward(filteredInwardItems, project);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        alert("An error occurred while exporting to Excel. Please try again.");
      }
    } else if (selectedTab === "Outward") {
      handleExportExcelOutward(filteredOutwardItems, project);
    }
  };

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      filterDataWRTDate();
    }
  }, [startDate, endDate]);

  // Add effect to trigger date filtering when dates change
  useEffect(() => {
    if (selectedTab === "Inward" && (startDate || endDate)) {
      filterDataWRTDate();
    }
  }, [startDate, endDate, selectedTab, inwardItems]);

  useEffect(() => {
    const handleInitialSetup = async () => {
      setLoading(true);

      // First check if we have materials and coming from vehicles
      if (isComingFromVehicles && materials.length > 0) {
        const fuelMaterial = materials.find(
          (material) => material.category === "Fuel"
        );

        if (fuelMaterial) {
          // Set the initial fuel material
          setMaterial(fuelMaterial);
          setViewMaterialDetails(true);

          // Then handle project selection if projectIdFromVehicle exists
          if (projectIdFromVehicle) {
            if (projectIdFromVehicle === "All Sites") {
              const materialsData = await netInventoryData(allProjects);

              const relevantMaterials = materialsData.filter(
                (m) => m.name === fuelMaterial.name && m.category === "Fuel"
              );

              if (relevantMaterials.length > 0) {
                const consolidatedMaterial = {
                  name: fuelMaterial.name,
                  category: "Fuel",
                  unit: fuelMaterial.unit,
                  quantity: relevantMaterials.reduce(
                    (sum, m) => sum + m.quantity,
                    0
                  ),
                  historyDetails: [],
                  isAllSites: true,
                };

                relevantMaterials.forEach((projectMaterial) => {
                  if (projectMaterial.historyDetails) {
                    const projectHistoryEntries = Object.entries(
                      projectMaterial.historyDetails
                    ).map(([timestamp, entry]) => ({
                      ...entry,
                      projectName: projectMaterial.projectName,
                      timeStamp: timestamp,
                      quantity: parseFloat(entry.quantity),
                    }));
                    consolidatedMaterial.historyDetails.push(
                      ...projectHistoryEntries
                    );
                  }
                });

                consolidatedMaterial.historyDetails.sort(
                  (a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp)
                );

                setMaterial(consolidatedMaterial);
                setProject(allProjects);
              }
            } else {
              const selectedProject = allProjects.find(
                (p) => p.projectID === projectIdFromVehicle
              );

              if (selectedProject) {
                const materialsData = await netInventoryData(selectedProject);
                const updatedMaterial = materialsData.find(
                  (m) => m.name === fuelMaterial.name && m.category === "Fuel"
                );

                if (updatedMaterial) {
                  setMaterial(updatedMaterial);
                  setProject(selectedProject);
                }
              }
            }
          }
        }
      }
      // If not coming from vehicles but have projectIdFromVehicle
      else if (projectIdFromVehicle) {
        if (projectIdFromVehicle === "All Sites") {
          setProject(allProjects);
        } else {
          const selectedProject = allProjects.find(
            (p) => p.projectID === projectIdFromVehicle
          );
          if (selectedProject) {
            setProject(selectedProject);
          }
        }
      }

      setLoading(false);
    };

    handleInitialSetup();
  }, [isComingFromVehicles, materials, projectIdFromVehicle, allProjects]);
  
  const handleBackClick = () => {
    if (isComingFromVehicles) {
      history.push("/Vehicles");
    } else {
      setViewMaterialDetails(false);
      setIsComingFromVehicles(false); // Reset the local state
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.headingWrapper}>
        {viewMaterialDetails && (
          <button onClick={handleBackClick} style={styles.backIcon}>
            <MdArrowBackIos size={25} />
          </button>
        )}

        {viewMaterialDetails ? (
          <h1 style={styles.heading}>
            {`${material.category} | ${material.name} | Current Quantity : ${material.quantity} ${material.unit}`}
          </h1>
        ) : (
          <TabComponent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setSearchQuery={setSearchQuery}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}
      </div>

      {/* <div style={styles.searchContainer}>
        <select
          style={styles.dropdown}
          onChange={(e) => onItemSelection(allProjects[e.target.value])} // Pass the full object using the index
          disabled={loading}
        >
          {allProjects.map((item, index) => (
            <option key={index} value={index}>
              {item.projectName}
            </option> // Set value as index
          ))}
          <option key={allProjects.length} value={"o"}>
            All sites
          </option>
        </select>

        {!viewMaterialDetails && (
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={styles.searchInput}
            placeholder="Search for materials..."
          />
        )}

        <div style={styles.row}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={styles.dateInput}
            />
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={styles.dateInput}
            />
          </div>

          <div style={styles.inputGroup}>
            <button
              onClick={handleExcelExport}
              style={{
                ...styles.button2,
                ...(hovered && styles.buttonHover),
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Export to Excel
            </button>
          </div>
        </div>
      </div> */}

      <SearchAndFilterComponent
        allProjects={allProjects}
        onItemSelection={onItemSelection}
        loading={loading}
        setLoading={setLoading} // add this prop
        viewMaterialDetails={viewMaterialDetails}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleExcelExport={handleExcelExport}
        selectedTab={selectedTab}
        projectIdFromVehicle={projectIdFromVehicle}
        currentProject={project} // Add this prop
      />

      {loading && <LoadingIcon />}

      <div style={{ overflowY: "auto", width: "100%" }}>
        {viewMaterialDetails ? (
          !loading ? (
            <MaterialDetails material={material} />
          ) : null
        ) : selectedTab === "Inward" ? (
          filteredInwardItems.map((item, index) => (
            <InwardCard itemData={item} project={project} userName={userName} />
          ))
        ) : selectedTab === "Outward" ? (
          filteredOutwardItems.map((item, index) => (
            <OutwardCard
              index={index}
              item={item}
              project={project}
              userName={userName}
            />
          ))
        ) : (
          filteredMaterials.map((material) => (
            <div
              key={material.id}
              style={styles.materialCard}
              onClick={() => {
                setViewMaterialDetails(true);
                setMaterial(material);
              }}
            >
              {/* <div style={styles.imagePlaceholder}></div> */}
              <InventoryItem material={material} />
              <div style={styles.details}>
                <div style={styles.materialName}>
                  {project.length > 1 && `${material.projectName} | `}
                  {`${material.category} | ${material.name}`}
                </div>
                <div style={styles.materialInfo}>
                  Available:{" "}
                  <span
                    style={
                      material.quantity > 0
                        ? styles.materialStatus
                        : styles.outOfStock
                    }
                  >
                    {material.quantity.toFixed(2)} {material.unit}
                  </span>
                </div>

                <div style={styles.materialInfo}>
                  Last updated:{" "}
                  {new Date(
                    parseInt(
                      Object.keys(material.historyDetails)[
                        Object.keys(material.historyDetails).length - 1
                      ]
                    )
                  ).toLocaleDateString()}
                </div>
              </div>
              {!Array.isArray(project) && (
                <div style={styles.buttonContainer}>
                  <button
                    style={styles.addButton}
                    onClick={(e) => openModal(e, material, "add")}
                  >
                    Add Stock
                  </button>
                  <button
                    style={styles.subtractButton}
                    onClick={(e) => openModal(e, material, "subtract")}
                  >
                    Remove Stock
                  </button>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {selectedMaterial && (
        <InwardEntryModal
          userName={userName}
          categoryItems={categoryItems}
          project={project}
          entryType={entryType}
          close={() => {
            setEntryType(null);
            setSelectedMaterial(false);
          }}
          selectedMaterial={selectedMaterial}
          fetchData={fetchData}
        />
      )}

      {selectedTab !== "All Materials" && !Array.isArray(project) && (
        <button
          style={{
            position: "fixed",
            bottom: "50px",
            right: "50px",
            backgroundColor: getPlusIconColor(),
            borderRadius: "50%", // Ensures the button is a perfect circle
            width: "60px", // Set fixed width and height for the button to maintain a circle shape
            height: "60px", // Fixed height for the button
            display: "flex",
            alignItems: "center", // Centers the icon vertically
            justifyContent: "center", // Centers the icon horizontally
            padding: "0", // Remove padding to maintain the perfect circle
            border: "none",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            zIndex: 10,
            transition: "background-color 0.3s",
          }}
          onClick={() => {
            if (selectedTab === "Inward") {
              setEntryType("add");
              setSelectedMaterial(true);
            } else {
              setEntryType("subtract");
              setSelectedMaterial(true);
            }
          }}
        >
          {selectedTab === "Inward" ? (
            <MdAdd size={30} color="#fff" />
          ) : (
            <GrFormSubtract size={30} color="#fff" />
          )}
        </button>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    overflowY: "auto",
    maxHeight: "100vh",
    width: "100%",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#2f4858",
    backgroundColor: "#fff",
    zIndex: 10,
    padding: "10px 0",
    width: "100%",
    display: "flex",
    alignSelf: "center",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    width: "100%",
    //   maxWidth: '1200px',
    justifyContent: "space-between",
    position: "sticky",
    top: "60px", // Adjust based on the height of the title
    zIndex: 9,
    backgroundColor: "#fff",
    padding: "10px 0",
  },
  searchInput: {
    width: "80%",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  dropdown: {
    width: "250px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    fontSize: "16px",
    marginLeft: "10px",
  },
  materialCard: {
    width: "100%",
    padding: "15px",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    cursor: "pointer",
  },
  imagePlaceholder: {
    width: "80px",
    height: "100px",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    backgroundImage: 'url("https://via.placeholder.com/80x100")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  details: {
    flex: 1,
    marginLeft: "15px",
  },
  materialName: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#333",
  },
  materialInfo: {
    fontSize: "14px",
    color: "#666",
    margin: "4px 0",
  },
  materialStatus: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#28a745",
  },
  outOfStock: {
    color: "#dc3545",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px", // Space between buttons
    marginTop: "15px",
  },
  addButton: {
    padding: "12px 20px",
    backgroundColor: "#f8f9fa", // Light grey background
    color: "#28a745", // Green text
    border: "2px solid #28a745", // Green border
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  subtractButton: {
    padding: "12px 20px",
    backgroundColor: "#f8f9fa", // Light grey background
    color: "#dc3545", // Red text
    border: "2px solid #dc3545", // Red border
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  addButtonHover: {
    backgroundColor: "#e9ecef", // Slightly darker grey on hover
    transform: "scale(1.05)",
  },
  subtractButtonHover: {
    backgroundColor: "#e9ecef", // Slightly darker grey on hover
    transform: "scale(1.05)",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    width: "300px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "12px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  modalButton: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 10,
    width: "100%",
    padding: "10px 0",
    marginBottom: "20px",
  },
  backIcon: {
    marginRight: "10px",
    cursor: "pointer",
    fontSize: "20px",
    background: "none",
    border: "none",
    color: "#2f4858",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#2f4858",
    width: "100%",
  },
  label: {
    fontSize: "16px",
    color: "#555",
    display: "block",
    marginBottom: "5px",
    marginTop: "10px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px", // Add some space between elements
  },
  inputGroup: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "5px", // Add spacing between the label and input
  },
  button2: {
    flex: "none", // Ensure button does not stretch
    padding: "12px",
    backgroundColor: "#0066cc",
    color: "white",
    border: "none",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#0055bb",
  },
  dateInput: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
    transition: "all 0.3s ease",
  },
  dateInputFocus: {
    borderColor: "#0066cc",
  },
};

export default drawerTemplate(Inventory);
