import React, { useState } from "react";
import {
  FaTimes,
  FaArrowLeft,
  FaArrowRight,
  FaTrashAlt,
  FaDownload,
} from "react-icons/fa";
import ImageWithLoader from "./ImageWithLoader";

const ImageModal = ({
  images,
  selectedImageIndex,
  onClose,
  onDelete,
  title,
}) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);

  // Navigate to the previous image
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next image
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Delete current image
  const handleDeleteCurrentImage = () => {
    onDelete(images[currentIndex].id);
    if (images.length === 1) {
      onClose();
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex
      );
    }
  };

  // Download current image
  const handleDownloadImage = () => {
    const currentImage = images[currentIndex];
    const imageUrl =
      currentImage.id || currentImage.url || currentImage.uri || currentImage;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `image-${Date.now()}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Check if current image is a signature (base64 data)
  const isSignature = (image) => {
    const imageStr = typeof image === "string" ? image : "";
    return imageStr.startsWith("data:image");
  };

  // Get current image source
  const getCurrentImageSrc = () => {
    const currentImage = images[currentIndex];
    return (
      currentImage.id || currentImage.url || currentImage.uri || currentImage
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FaTimes size={30} />
      </button>

      {/* Display title if provided */}
      {title && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            fontSize: "16px",
            maxWidth: "80%",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "5px 10px",
            borderRadius: "5px",
            wordBreak: "break-word",
          }}
        >
          {title}
        </div>
      )}

      {/* Conditional rendering for empty images */}
      {images.length === 0 ? (
        <div
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          No image uploaded
        </div>
      ) : (
        <>
          {images.length > 1 && (
            <button
              onClick={handlePrevious}
              style={{
                position: "absolute",
                left: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              <FaArrowLeft size={30} />
            </button>
          )}
          <div style={{ position: "relative", textAlign: "center" }}>
            <div
              style={{
                maxWidth: "90vw",
                maxHeight: "80vh",
                overflow: "auto",
                backgroundColor: isSignature(getCurrentImageSrc())
                  ? "#fff"
                  : "transparent",
                padding: isSignature(getCurrentImageSrc()) ? "20px" : "0",
                borderRadius: "5px",
              }}
            >
              <ImageWithLoader
                src={getCurrentImageSrc()}
                alt={`Image ${currentIndex + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "70vh",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                color: "white",
                marginTop: "10px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {`${currentIndex + 1}/${images.length}`}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                marginTop: "10px",
              }}
            >
              {onDelete && (
                <FaTrashAlt
                  size={24}
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={handleDeleteCurrentImage}
                  title="Delete image"
                />
              )}
              <FaDownload
                size={24}
                color="white"
                style={{ cursor: "pointer" }}
                onClick={handleDownloadImage}
                title="Download image"
              />
            </div>
          </div>
          {images.length > 1 && (
            <button
              onClick={handleNext}
              style={{
                position: "absolute",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              <FaArrowRight size={30} />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ImageModal;
