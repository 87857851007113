import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import ResetIcon from '@mui/icons-material/RestartAlt';

const ResetButton = ({ resetFunction }) => {
  return (
    <Tooltip title="Reset all filters">
      <IconButton onClick={resetFunction} color="primary">
        <ResetIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ResetButton;
