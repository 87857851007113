import React from "react";

const ExportButton = ({ exportJsonToExcel, progressNumber = 0 }) => {
  // Determine if process is active (not at 0% or 100%)
  const isProcessing = progressNumber > 0 && progressNumber < 100;
  const isComplete = progressNumber >= 100;

  // Color based on progress
  const getProgressColor = () => {
    if (isComplete) return "#10B981"; // Modern green when complete
    if (isProcessing) return "#F59E0B"; // Modern amber while in progress
    return "#3B82F6"; // Modern blue in initial state
  };

  // Control text visibility
  const showText = !isProcessing;

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(240, 249, 255, 0.8)",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
          border: "none",
          width: "90%",
          height: "48px",
          position: "relative",
          overflow: "hidden",
          transition: "all 0.3s ease-in-out",
          alignSelf: "center",
          marginTop: 20,
          backdropFilter: "blur(8px)",
        }}
      >
        {/* Progress bar with gradient */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${progressNumber}%`,
            background: isComplete
              ? "linear-gradient(135deg, rgba(16, 185, 129, 0.2) 0%, rgba(16, 185, 129, 0.3) 100%)"
              : isProcessing
              ? "linear-gradient(135deg, rgba(245, 158, 11, 0.2) 0%, rgba(245, 158, 11, 0.3) 100%)"
              : "linear-gradient(135deg, rgba(59, 130, 246, 0.2) 0%, rgba(59, 130, 246, 0.3) 100%)",
            transition: "width 0.5s ease-in-out, background 1s ease-in-out",
          }}
        />

        {/* Button Content Container */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isProcessing ? "center" : "flex-start",
            width: "100%",
            zIndex: 2,
            padding: "0 16px",
            transition: "all 0.5s ease-in-out",
          }}
        >
          {/* Excel Icon with better design */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: isProcessing
                ? "rgba(245, 158, 11, 0.1)"
                : isComplete
                ? "rgba(16, 185, 129, 0.1)"
                : "rgba(59, 130, 246, 0.1)",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              transition: "all 0.3s ease-in-out",
              transform: isProcessing ? "scale(1.1)" : "scale(1)",
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              style={{
                transition: "all 0.3s ease-in-out",
              }}
            >
              <path
                fill={getProgressColor()}
                d="M14,2H6C4.89,2 4,2.89 4,4V20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20V8L14,2M18,20H6V4H13V9H18V20M10,19L12,15H9V10H15V15L13,19H10Z"
              />
            </svg>
          </div>

          {/* Text with download icon - visible only when not processing */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              opacity: showText ? 1 : 0,
              maxWidth: showText ? "200px" : "0px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              transition: "opacity 0.8s ease-in-out, max-width 0.8s ease-in-out",
            }}
          >
            <span
              style={{
                color: getProgressColor(),
                marginLeft: "12px",
                fontWeight: "600",
                fontSize: "15px",
                fontFamily: "system-ui, -apple-system, sans-serif",
                letterSpacing: "0.02em",
              }}
            >
              Click Export to Excel
            </span>

            {/* Download icon */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              style={{
                marginLeft: "8px",
                fill: getProgressColor(),
                alignSelf: "flex-end",
                transition: "transform 0.3s ease",
                transform: "translateY(0px)",
              }}
            >
              <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
            </svg>
          </div>

          {/* Progress Percentage - shows only during processing */}
          {isProcessing && (
            <span
              style={{
                color: getProgressColor(),
                fontWeight: "bold",
                fontSize: "15px",
                marginLeft: "10px",
                opacity: 1,
                fontFamily: "system-ui, -apple-system, sans-serif",
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              {Math.round(progressNumber)}%
            </span>
          )}
        </div>

        {/* Clickable area - full button */}
        <div
          onClick={exportJsonToExcel}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer",
            zIndex: 3,
          }}
        />
      </div>
      {isProcessing && (
        <p
          style={{
            margin: "10px 0 0 0",
            fontSize: "14px",
            color: "#F59E0B",
            fontFamily: "system-ui, -apple-system, sans-serif",
            fontStyle: "normal",
            textAlign: "center",
            opacity: 0.8,
            animation: "fadeInOut 1.5s ease-in-out infinite",
            fontWeight: "500",
          }}
        >
          Generating the data...
        </p>
      )}

      <style jsx>{`
        @keyframes fadeInOut {
          0%,
          100% {
            opacity: 0.6;
          }
          50% {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

export default ExportButton;