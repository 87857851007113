import React, { useState, useMemo } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";

import RegisterItemModal from "./RegisterItemModal";
import ImportViaExcel from "./ImportViaExcel";

const containerStyle = {
  maxWidth: "100%",
  margin: "0 auto",
  padding: "1.5rem"
};

const headerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  marginBottom: "1.5rem",
  justifyContent: 'space-between'
};

const filterContainerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "0.75rem"
};

const labelStyle = {
  fontSize: "1.125rem",
  fontWeight: 600,
  color: "#374151"
};

const selectStyle = {
  padding: "0.5rem 1rem",
  fontSize: "1rem",
  border: "1px solid #d1d5db",
  borderRadius: "0.5rem",
  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  outline: "none"
};

const buttonContainerStyle = {
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap"
};

const buttonStyle = {
  padding: "0.75rem 1.5rem",
  backgroundColor: "#fef3c7",
  color: "#1f2937",
  fontWeight: 600,
  borderRadius: "0.5rem",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  transition: "background-color 0.2s"
};

const tableContainerStyle = {
  overflowX: "auto",
  borderRadius: "0.5rem",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
};

const tableStyle = {
  width: "100%",
  backgroundColor: "white",
  borderCollapse: "collapse",
  tableLayout: "fixed",
};

const thStyle = {
  padding: "1rem 1.5rem",
  textAlign: "left",
  fontSize: "1rem",
  fontWeight: 600,
  color: "#374151",
  backgroundColor: "#f9fafb",
  borderBottom: "1px solid #e5e7eb",
  wordWrap: "break-word", // Ensures long text wraps within the cell
  overflowWrap: "break-word", // Adds support for breaking words
  whiteSpace: "normal", // Allows text to wrap onto the next line
};

const tdStyle = {
  padding: "1rem 1.5rem",
  fontSize: "1rem",
  color: "#374151",
  borderBottom: "1px solid #e5e7eb",
  wordWrap: "break-word", // Ensures long text wraps within the cell
  overflowWrap: "break-word", // Adds support for breaking words
  whiteSpace: "normal", // Allows text to wrap onto the next line
  textAlign: "left",
};

const actionCellStyle = {
  padding: "1rem 1.5rem",
  borderBottom: "1px solid #e5e7eb",
  textAlign: "center"
};

const actionButtonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: "0.25rem",
  transition: "color 0.2s"
};

const ItemTable = ({
  items,
  onEdit,
  onDelete,
  updateSuggestiveTextMaterial,
  updateItemToFirebase,
  addSuggestiveTextMaterial,
  toast
}) => {
  
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [addItem, setAddItem] = useState(false);
  const [importExcel, setImportExcel] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);

  const categories = useMemo(() => {
    const uniqueCategories = [...new Set(items.map((item) => item.category))];
    return ["All", ...uniqueCategories];
  }, [items]);

  const filteredItems = useMemo(() => {
    return selectedCategory === "All"
      ? items
      : items.filter((item) => item.category === selectedCategory);
  }, [selectedCategory, items]);

  return (
    <div style={containerStyle}>

      <div style={headerContainerStyle}>
        <div style={filterContainerStyle}>
          <label htmlFor="categoryFilter" style={labelStyle}>
            Filter by Category:
          </label>
          <select
            id="categoryFilter"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={selectStyle}
          >
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          <p>total {filteredItems.length} items</p>
        </div>
        
        <div style={buttonContainerStyle}>
          <button 
            onClick={() => setImportExcel(true)}
            style={buttonStyle}
          >
            Import items via Excel
          </button>
          <button 
            onClick={() => setAddItem(true)}
            style={buttonStyle}
          >
            + Add New Item
          </button>
        </div>

      </div>

      <div style={tableContainerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Category</th>
              <th style={{...thStyle, width: "25%"}}>Specification</th>
              <th style={{...thStyle, width: "15%"}}>Rate</th>
              <th style={{...thStyle, width: "15%"}}>Unit</th>
              <th style={{...thStyle, width: "15%"}}>GST</th>
              <th style={{...thStyle, width: "15%", textAlign: "center"}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, index) => (
              <tr
                key={item.Id}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#f9fafb"
                }}
              >
                <td style={tdStyle}>{item.category}</td>
                <td style={tdStyle}>
                  {item.specification
                    ? item.specification.replace("<single-quote>", "")
                    : item.description || ""}
                </td>
                <td style={tdStyle}>{item.rate ? item.rate : "-"}</td>
                <td style={tdStyle}>{item.unit || item.uom}</td>
                <td style={tdStyle}>{item.gst ? item.gst : "-"}</td>
                <td style={actionCellStyle}>
                  <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                    <button
                      onClick={() => {
                        console.log('item : ', item)
                        setAddItem(true);
                        setItemToEdit(item);
                        onEdit(item);
                      }}
                      style={actionButtonStyle}
                      title="Edit"
                    >
                      <FaEdit size={20} color="#9ca3af" style={{ ":hover": { color: "#2563eb" } }} />
                    </button>
                    <button
                      onClick={() => onDelete(index, item)}
                      style={actionButtonStyle}
                      title="Delete"
                    >
                      <FaTrash size={20} color="#9ca3af" style={{ ":hover": { color: "#dc2626" } }} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Keeping modal components */}
      <RegisterItemModal
        isOpen={addItem}
        onClose={() => {
          setItemToEdit(null);
          setAddItem(false);
        }}
        onSubmit={(item) => {
          if (itemToEdit) {
            if(itemToEdit.specification ){
              updateSuggestiveTextMaterial(
                item.Id,
                item.category,
                item.specification,
                item.rate,
                item.unit,
                item.gst
              );
            }else{
              // write the code here
              updateItemToFirebase(item, itemToEdit);
            }

          } else {
            if (
              item.category.length <= 0 ||
              item.specification.length <= 0 ||
              item.rate.length <= 0 ||
              item.unit.length <= 0 ||
              item.gst.length <= 0
            ) {
              toast.error("Please fill all the details!");
            } else {
              console.log('RegisterItemModal items : ', items);
              if (items.length > 0) {
                console.log('item length found greater than zero...');
                // let isSpecEqual = items.map((val) =>
                //   val.specification.localeCompare(item.specification) || val.description.localeCompare(item.specification)
                // );

                // let isSpecEqual = items.map((val) => {
                //   console.log('val : ', JSON.stringify(val))
                //   const compareValue = val.specification || val.description; // Use specification if available, otherwise description
                //   const itemCompareValue = item.specification || item.description; // Use specification if available, otherwise description
                //   console.log('val.description : ', val.description);
                //   console.log('item.specification : ', item.specification);
                //   console.log('val.description : ', val.description);
                //   console.log('item.specification : ', item.specification);
                //   console.log('compareValue : ', compareValue);
                //   return compareValue.localeCompare(itemCompareValue);
                // });

                let isSpecEqual = items.map((val) => {
                  console.log('val : ', JSON.stringify(val));
                  
                  // Check if val.specification or val.description is an empty string
                  const compareValue = (val.specification && val.specification !== "") ? val.specification : (val.description && val.description !== "") ? val.description : null;
                  const itemCompareValue = (item.specification && item.specification !== "") ? item.specification : (item.description && item.description !== "") ? item.description : null;
                
                  // Skip if neither value is valid (both are empty)
                  if (compareValue === null || itemCompareValue === null) {
                    return null; // Skip this iteration by returning null
                  }
                
                  return compareValue.localeCompare(itemCompareValue);
                }).filter(val => val !== null); // Remove null results from the array
                


                isSpecEqual.every((x) => x != 0)
                  ? addSuggestiveTextMaterial(
                      item.category,
                      item.specification,
                      item.rate,
                      item.unit,
                      item.gst
                    )
                  : toast.error("Cannot add same specification suggestion!");
              } else {
                addSuggestiveTextMaterial(
                  item.category,
                  item.specification,
                  item.rate,
                  item.unit,
                  item.gst
                );
              }
            }
          }
        }}
        material={items}
        editingItem={itemToEdit}
      />

      <ImportViaExcel
        items={items}
        isOpen={importExcel}
        onClose={() => setImportExcel(false)}
        addSuggestiveTextMaterial={addSuggestiveTextMaterial}
      />
    </div>
  );
};

export default ItemTable;