import React from "react";

const ViewMoreModal = ({ isOpen, onClose, data, type }) => {
  if (!isOpen) return null;
  const COLORS = {
    pieChart: [
      "#FA722F", // Requested
      "#00D226", // Approved
      "#1485AE", // Released
      "#828282", // Reached
      "red", // Declined
    ],
    timeBars: {
      poToReach: "#FF6B42",
      requestToPo: "#4169E1",
      requestToApprove: "#32CD32",
    },
    status: {
      Released: "#1485AE",
      Approved: "#00D226",
      Requested: "#FA722F",
      Declined: "red",
      Reached: "#828282",
    },
  };
  const getTitle = () => {
    const titles = {
      quantities: "Quantity/Item Variations",
      unattended: "Unattended Material Requests",
      delayed: "Delayed Materials",
      transit: "Materials in Transit",
      recent: "Recent Activities",
    };
    return titles[type] || "Details";
  };

  const getTableHeaders = () => {
    const headers = {
      quantities: [
        "MRN No",
        "Category/Item",
        "Requested",
        "Rejected",
        "Approved",
        "PO released",
        "Received",
        "Date",
      ],
      unattended: ["MRN No.", "No. of Days", "Approved on"],
      delayed: [
        "MRN No.",
        "Category/Item",
        "Quantity",
        "Status",
        "Release Date",
        "Due Date",
        "Delayed by",
      ],
      transit: [
        "MRN No",
        "Category/Item",
        "Quantity",
        "Unit",
        "Release Date",
        "Due date",
      ],
      recent: ["MRN No.", "Date of requested", "Status", "Requested by"],
    };
    return headers[type] || [];
  };

  const renderTableHeaders = () => (
    <tr>
      {getTableHeaders().map((header, index) => (
        <th key={index} style={styles.tableHeader}>
          {header}
        </th>
      ))}
    </tr>
  );

  const renderQuantityRows = () => {
    return data.map((request) => {
      if (!request?.items) return null;

      return request.items.map((item, itemIndex) => {
        // Get quantities for each status from request history
        const historyEntries = request.requestHistory || {};

        // Find quantities for each status
        const requestedQty = Object.values(historyEntries).find(
          (entry) => entry.status === "Requested"
        )?.quantities?.[itemIndex];
        const declinedQty = Object.values(historyEntries).find(
          (entry) => entry.status === "Rejected"
        )?.quantities?.[itemIndex];
        const approvedQty = Object.values(historyEntries).find(
          (entry) => entry.status === "Approved"
        )?.quantities?.[itemIndex];
        const releasedQty = Object.values(historyEntries).find(
          (entry) => entry.status === "Released"
        )?.quantities?.[itemIndex];
        const reachedQty = Object.values(historyEntries).find(
          (entry) => entry.status === "Reached"
        )?.quantities?.[itemIndex];

        return (
          <tr
            key={`${request.id}-${itemIndex}`}
            style={itemIndex % 2 === 0 ? styles.evenRow : styles.oddRow}>
            {itemIndex === 0 && (
              <td
                style={{ ...styles.tableCell, ...styles.mrnCell }}
                rowSpan={request.items.length}>
                #{request.id}
              </td>
            )}
            <td style={styles.tableCell}>
              <div style={styles.categoryCell}>
                <span style={styles.categoryText}>{item.category}</span>
                <span style={styles.headingText}>Item Item {item.heading}</span>
              </div>
            </td>
            <td style={styles.tableCell}>{item.quantity || "---"}</td>
            <td style={styles.tableCell}>
              {item.quantity - approvedQty || "---"}
            </td>
            <td style={styles.tableCell}>{approvedQty || "---"}</td>
            <td style={styles.tableCell}>{releasedQty || "---"}</td>
            <td style={styles.tableCell}>{reachedQty || "---"}</td>
            {itemIndex === 0 && (
              <td
                style={{ ...styles.tableCell, ...styles.dateCell }}
                rowSpan={request.items.length}>
                {new Date(request.creationDate).toLocaleDateString()}
              </td>
            )}
          </tr>
        );
      });
    });
  };
  const renderDelayedRows = () => {
    return data.map((item, index) => (
      <tr
        key={`${item.mrnNo}-${index}`}
        style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
        <td style={{ ...styles.tableCell, ...styles.mrnCell }}>
          #{item.mrnNo}
        </td>
        <td style={styles.tableCell}>
          <div style={styles.categoryCell}>
            <span style={styles.categoryText}>{item.category}</span>
            <span style={styles.headingText}>{item.heading}</span>
          </div>
        </td>
        <td style={styles.tableCell}>
          {item.quantity} {item.unit}
        </td>
        <td style={styles.tableCell}>
          <span style={{ color: COLORS.status[item.status] || "#000" }}>
            {item.status}
          </span>
        </td>
        <td style={styles.tableCell}>{item.releaseDate}</td>
        <td style={styles.tableCell}>{item.dueDate}</td>
        <td style={{ ...styles.tableCell, ...styles.dateCell }}>
          {item.days} Days
        </td>
      </tr>
    ));
  };

  const renderTransitRows = () => {
    return data.map((request) => {
      if (!request?.items) return null;

      return request.items.map((item, itemIndex) => (
        <tr
          key={`${request.mrnNo}-${itemIndex}`}
          style={itemIndex % 2 === 0 ? styles.evenRow : styles.oddRow}>
          {itemIndex === 0 && (
            <td
              style={{ ...styles.tableCell, ...styles.mrnCell }}
              rowSpan={request.items.length}>
              {request.mrnNo}
            </td>
          )}
          <td style={styles.tableCell}>
            <div style={styles.categoryCell}>
              <span style={styles.categoryText}>{item.category}</span>
              <span style={styles.headingText}>Item Item {item.heading}</span>
            </div>
          </td>
          <td style={styles.tableCell}>{item.quantity}</td>
          <td style={styles.tableCell}>{item.unit}</td>
          {itemIndex === 0 && (
            <td
              style={{ ...styles.tableCell, ...styles.dateCell }}
              rowSpan={request.items.length}>
              {request.releaseDate}
            </td>
          )}
          {itemIndex === 0 && (
            <td
              style={{ ...styles.tableCell, ...styles.dateCell }}
              rowSpan={request.items.length}>
              {request.dueDate}
            </td>
          )}
        </tr>
      ));
    });
  };
  const renderTableRows = () => {
    switch (type) {
      case "quantities":
        return renderQuantityRows();
      case "unattended":
        return data.map((item, idx) => (
          <tr key={idx} style={idx % 2 === 0 ? styles.evenRow : styles.oddRow}>
            <td style={styles.tableCell}>{item.mrnNo}</td>
            <td style={styles.tableCell}>{item.days} Days</td>
            <td style={styles.tableCell}>{item.approvedOn}</td>
          </tr>
        ));
      case "delayed":
        return renderDelayedRows();
      case "transit":
        return renderTransitRows();
      case "recent":
        return data.map((item, idx) => (
          <tr key={idx} style={idx % 2 === 0 ? styles.evenRow : styles.oddRow}>
            <td style={styles.tableCell}>{item.mrnNo}</td>
            <td style={styles.tableCell}>{item.date}</td>
            <td
              style={{
                ...styles.tableCell,
                color: COLORS.status[item.status] || "#000",
              }}>
              {item.status}
            </td>
            <td style={styles.tableCell}>{item.requestedBy}</td>
          </tr>
        ));
      default:
        return null;
    }
  };

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>{getTitle()}</h2>
          <button style={styles.closeButton} onClick={onClose}>
            ×
          </button>
        </div>
        <div style={styles.modalBody}>
          <div style={styles.tableWrapper}>
            <table style={styles.table}>
              <thead style={styles.thead}>{renderTableHeaders()}</thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        </div>
        <div style={styles.modalFooter}>
          <button style={styles.closeButtonPrimary} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    backdropFilter: "blur(5px)",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: "16px",
    width: "90%",
    maxWidth: "1200px",
    maxHeight: "85vh",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    padding: "20px 24px",
    borderBottom: "1px solid #eee",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  modalTitle: {
    margin: 0,
    fontSize: "20px",
    fontWeight: "600",
    color: "#1a1a1a",
  },
  closeButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#666",
    padding: "8px",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody: {
    flex: 1,
    overflowY: "auto",
    padding: "0",
    maxHeight: "calc(85vh - 130px)",
  },
  tableWrapper: {
    position: "relative",
    overflowX: "auto",
    width: "100%",
  },
  thead: {
    position: "sticky",
    top: 0,
    backgroundColor: "#f8f9fa",
    zIndex: 1,
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    fontSize: "14px",
    backgroundColor: "white",
  },
  tableHeader: {
    padding: "16px 20px",
    textAlign: "center",
    backgroundColor: "#f8f9fa",
    color: "#495057",
    fontWeight: 600,
    borderBottom: "2px solid #dee2e6",
    whiteSpace: "nowrap",
  },
  tableCell: {
    padding: "14px 20px",
    color: "#495057",
    borderBottom: "1px solid #e9ecef",
    textAlign: "center",
    verticalAlign: "middle",
  },
  mrnCell: {
    backgroundColor: "#f8f9fa",
    fontWeight: 500,
    color: "#2b50ed",
    borderRight: "2px solid #cbd5e1", // Darker and thicker right border
    borderBottom: "2px solid #cbd5e1", // Darker and thicker bottom border
    boxShadow: "1px 0 3px rgba(0,0,0,0.1)", // Subtle shadow for depth
    position: "relative", // For pseudo-element borders
    "&::after": {
      // Pseudo-element for additional border effect
      content: '""',
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "2px",
      backgroundColor: "#94a3b8", // Darker border color
    },
  },
  dateCell: {
    backgroundColor: "#f8f9fa",
    borderLeft: "1px solid #e9ecef",
  },
  categoryCell: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  categoryText: {
    fontWeight: 500,
    color: "#495057",
  },
  headingText: {
    fontSize: "13px",
    color: "#6c757d",
  },
  evenRow: {
    backgroundColor: "#fff",
  },
  oddRow: {
    backgroundColor: "#f8f9fa",
  },
  modalFooter: {
    padding: "16px 24px",
    borderTop: "1px solid #eee",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  closeButtonPrimary: {
    padding: "10px 24px",
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#4F4CFF",
    color: "white",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    transition: "background-color 0.2s",
  },
};

export default ViewMoreModal;
