import { BorderColor } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ItemTableBottom from "./ItemTableBottom";
import ImageModal from "../Components/ImageModal";

const ItemsTable = ({
  userName = "",
  itemData = {},
  project = "",
  projectName = "",
  invoices = {},
  items = [],
  isRequestRecord = false,
  onViewMore = () => {},
  onImageClick = () => {},
}) => {
  const [expanded, setExpanded] = useState(false);
  const [imageItems, setImageItems] = useState([]);
  const [displayImage, setDisplayImage] = useState(false);

  // Function to format description by removing <single-quote> and <double-quote> tags
  const formatDescription = (description) => {
    if (!description) return "Not provided";

    // Replace <single-quote> with actual single quote
    let formatted = description.replace(/<single-quote>/g, "'");

    // Replace <double-quote> with actual double quote
    formatted = formatted.replace(/<double-quote>/g, '"');

    return formatted;
  };

  // Handle different data structures based on record type
  let displayItems = [];

  if (isRequestRecord) {
    // For request-converted records, use the items array directly
    // Filter out null items and only include items with valid data
    displayItems = items.filter((item) => item !== null && item.category);
  } else {
    // For regular inward entries, extract items from invoices
    displayItems = Object.values(invoices || {}).flatMap((invoice) =>
      Object.values(invoice?.invoiceItems || {})
    );
  }

  const visibleItems = expanded ? displayItems : displayItems.slice(0, 2);

  return (
    <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.column}>Category</th>
            <th style={styles.column}>Item</th>
            <th style={styles.column}>Quantity</th>
            <th style={styles.column}>Unit</th>
            <th style={styles.column}>Remark</th>
            <th style={styles.column}>Images</th>
          </tr>
        </thead>
        <tbody>
          {visibleItems.map((item = {}, index) => {
            // Handle different item structures based on record type
            const itemData = isRequestRecord
              ? {
                  category: item.category || "Not provided",
                  description: formatDescription(item.description),
                  quantity:
                    item.quantity !== undefined
                      ? item.quantity
                      : "Not provided",
                  unit: item.unit || "Not provided",
                  remark: item.remark || "Not provided",
                  images: item.images || null,
                }
              : {
                  category: item.category || "Not provided",
                  description: formatDescription(item.description),
                  quantity:
                    item.quantityAccepted !== undefined
                      ? item.quantityAccepted
                      : "Not provided",
                  unit: item.uom || "Not provided",
                  remark: item.remark || "Not provided",
                  images: item.images || null,
                };

            return (
              <tr
                key={index}
                style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
              >
                <td style={styles.cell}>{itemData.category}</td>
                <td style={styles.cell}>{itemData.description}</td>
                <td style={styles.cell}>{itemData.quantity}</td>
                <td style={styles.cell}>{itemData.unit}</td>
                <td style={styles.cell}>{itemData.remark}</td>
                <td style={styles.cell}>
                  {itemData.images &&
                  Object.keys(itemData.images).length > 0 ? (
                    <button
                      style={styles.button}
                      onClick={() => {
                        setDisplayImage(true);
                        setImageItems(() =>
                          Object.values(itemData.images || {})
                        );
                      }}
                    >
                      View Image
                    </button>
                  ) : (
                    <button style={{ ...styles.button, ...{ color: "#aaa" } }}>
                      No Images
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
          {visibleItems.length === 0 && (
            <tr>
              <td colSpan="6" style={{ ...styles.cell, textAlign: "center" }}>
                No items available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {displayImage && (
        <ImageModal
          images={imageItems || []}
          selectedImageIndex={0}
          onClose={() => {
            setDisplayImage(false);
            setImageItems([]);
          }}
        />
      )}

      <ItemTableBottom
        userName={userName}
        itemData={itemData}
        project={project}
        projectName={projectName}
        items={displayItems}
        expanded={expanded}
        setExpanded={setExpanded}
        isRequestRecord={isRequestRecord}
      />
    </div>
  );
};

const styles = {
  container: {
    marginTop: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  },
  column: {
    width: "16.66%",
    textAlign: "left",
    padding: "8px",
  },
  cell: {
    padding: "10px",
    textAlign: "left",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "normal",
  },
  rowEven: {
    backgroundColor: "#f1f1f1",
  },
  rowOdd: {
    backgroundColor: "#ffffff",
  },
  button: {
    color: "#007bff",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
};

export default ItemsTable;
