import { secondDatabase } from "../../config/firebase";

const deleteItemFromFirebase = async (firmId, descriptionID) => {
  try {
    await secondDatabase.ref(`${firmId}/projectMasterFile/materials/${descriptionID}`).remove();
    console.log(`Item with ID ${descriptionID} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting item:", error);
  }
};

export default deleteItemFromFirebase;
