import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/storage";
import "firebase/database";
import { environment } from "./config";
// import { environment2 } from "./config";

let firebaseConfig = {};
let secondFirebaseConfig = {};
// let environment2 = "staging2";
// let environment2 = "staging";
let environment2 = "production";

if (environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCJVQl2nkZXe36-mhmfG0xQ2PSgaDBP3v8",
    authDomain: "charming-shield-300804.firebaseapp.com",
    projectId: "charming-shield-300804",
    storageBucket: "charming-shield-300804.appspot.com",
    messagingSenderId: "303239150168",
    appId: "1:303239150168:web:e5a1bd2adbcdc752cae82d",
    measurementId: "G-68LY2278VG",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDh1xGKmWaRStY2z_XLCMgrLs6Onvj2xYI",
    authDomain: "startstaging.firebaseapp.com",
    projectId: "startstaging",
    storageBucket: "startstaging.appspot.com",
    messagingSenderId: "546936271294",
    appId: "1:546936271294:web:cf223dde266eb0354e89ef",
    measurementId: "G-NYB7DCYEPY",
  };
}

if (environment2 === "production") {
  secondFirebaseConfig = {
    apiKey: "AIzaSyAzj0tWac_xG4sGoZS8FTaXwgeOdRienH0",
    authDomain: "staart-module.firebaseapp.com",
    projectId: "staart-module",
    storageBucket: "staart-module.appspot.com",
    messagingSenderId: "70343114972",
    appId: "1:70343114972:web:eb7471ca7abd5609f93419",
    measurementId: "G-8KC9RVY15R",
  };
} else if (environment2 == "staging") {
  secondFirebaseConfig = {
    apiKey: "AIzaSyCoYHDs-4054SiWa0MKIVEnKtw7hVTGZAA",
    authDomain: "staart-module-staging.firebaseapp.com",
    projectId: "staart-module-staging",
    storageBucket: "staart-module-staging.appspot.com",
    messagingSenderId: "813326565307",
    appId: "1:813326565307:web:92df87c0f5c70a5145ce9e",
    measurementId: "G-40MJYWGHC0",
  };
} else {
  secondFirebaseConfig = {
    apiKey: "AIzaSyAa9DosthPICSIvb4r1T8cAIZubdgyUY3o",
    authDomain: "start-module-staging-2.firebaseapp.com",
    databaseURL: "https://start-module-staging-2-default-rtdb.firebaseio.com",
    projectId: "start-module-staging-2",
    storageBucket: "start-module-staging-2.appspot.com",
    messagingSenderId: "300241786799",
    appId: "1:300241786799:web:05fc9473dce7fa1337015a",
    measurementId: "G-HMDQYTK9FR",
  };
}

// Initialize Firebase
const firstApp = firebase.initializeApp(firebaseConfig);
const secondApp = firebase.initializeApp(secondFirebaseConfig, "secondary");

export const analytics = firstApp.analytics();
export const messaging = firstApp.messaging();
export const storage = firstApp.storage();
export const database = firstApp.database();

export const secondAnalytics = secondApp.analytics();
export const secondMessaging = secondApp.messaging();
export const secondStorage = secondApp.storage();
export const secondDatabase = secondApp.database();

export const uploadFile = async (file, filePath, useSecondary = false) => {
  const storageInstance = useSecondary ? secondStorage : storage;
  const fileRef = storageInstance.ref(filePath);
  await fileRef.put(file);
  return await fileRef.getDownloadURL();
};

export const updateValues = async (
  method,
  path,
  value,
  useSecondary = false
) => {
  let response = {
    data: null,
    status: "failed",
  };
  
  const db = useSecondary ? secondDatabase : database;
  const dbRef = db.ref(path);

  if (method === "POST") {
    await dbRef.set(value);
    response.status = "Success";
    response.data = value;
  } else if (method === "GET") {
    await dbRef
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          response = {
            data: snapshot.val(),
            status: "Success",
          };
        } else {
          console.log("No data available");
          response.status = "Failed";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else if (method === "DEL") {
    await dbRef
      .remove()
      .then(() => {
        response.status = "Success";
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return response;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default firebase;
