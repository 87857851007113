import { secondDatabase } from "../../../config/firebase";

const groupItemsByCategory = (items) => {
    return items.reduce((acc, item) => {
      // Check if the category already exists in the accumulator
      if (!acc[item.category]) {
        acc[item.category] = []; // Create an empty array if category doesn't exist
      }
      acc[item.category].push(item); // Add the item to the corresponding category array
      return acc;
    }, {});
  };

const fetchItems = async (firmDetailsID, projectID) => {
    try {
        // Construct the path using the provided firmDetailsID and projectID
        const path = `${firmDetailsID}/projectMasterFile/materials/`;

        // Fetch data from Firebase using .once() to retrieve a snapshot
        const snapshot = await secondDatabase.ref(path).once('value');

        // Check if data exists at the given path
        if (snapshot.exists()) {
            const data = snapshot.val(); // Get the data from the snapshot
            return groupItemsByCategory(Object.values(data)); // Return the values of the fetched data
        } else {
            console.log('No data available.');
            return []; // Return an empty array if no data exists
        }
    } catch (error) {
        console.error('Error fetching data from Firebase:', error);
        return []; // Return an empty array on error
    }
};

export default fetchItems;
