import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { AiOutlineDelete, AiOutlineAlert } from "react-icons/ai";
import Summary from "./Summary";
import { toast, ToastContainer } from "react-toast";
import CheckboxComponent from "./CheckboxComponent";
import AdditionalInformation from "./AdditionalInformation";
import saveInwardEntriesToFirebase from "./WriteFunction/WriteInventoryData";
import saveOutwardEntriesToFirebase from "./WriteFunction/writeOutwardInventoryData";
import SavingButton from "./Components/SavingButton";
import extractInwardEntryData from "./WriteFunction/Helpers/extractInwardObject";
import editInwardEntry from "./WriteFunction/EditInwardEntry";
import createInwardEntry from "./WriteFunction/Helpers/createInwardEntry";
import EditOutwardEntry from "./WriteFunction/EditOutwardEntry";
import ImageUpload from "./Components/ImageUpload";
import fetchVehicleInformation from "./WriteFunction/FetchVehicleInformation";
import writeVehicleInformation from "./WriteFunction/WriteVehicleInformation";
import editVehicleDieselConsumed from "./WriteFunction/EditVehicleInformation";

const InwardEntryModal = ({
  userName,
  categoryItems,
  project,
  entryType,
  close,
  editing,
  itemData,
  selectedMaterial,
  fetchData
}) => {

  console.log('InwardEntryModal userName : ', userName)

  let dateNow = new Date();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding leading zero if needed
    const day = date.getDate().toString().padStart(2, "0"); // Adding leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const [items, setItems] = useState([
    {
      id: new Date().getTime(),
      historyId: new Date().getTime(),
      category: "",
      description: "",
      quantityAccepted: "",
      quantityRejected: "",
      unit: "",
      date: formatDate(dateNow),
      purpose: "",
      vehicle: "",
      images: [],
      unsaved: true,
    },
  ]);

  const [formData, setFormData] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    supplierVendor: "",
    poNumber: "", // Added PO Number field
    referralPONo: "",
    mrn: "",
    vehicleNo: "",
    gatePassNo: "",
    gatePassDate: "",
    dcNo: "",
    dcDate: "",
    inTime: "",
    outTime: "",
  });

  const [vehicleInformation, setVehicleInformation] = useState({});

  const [itemsImages, setItemsImages] = useState([[]]);
  const [saving, setSaving] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = [...items];
    newItems[index][name] = value;
    setItems(newItems);
  };

  const modalContentRef = useRef(null);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id: new Date().getTime(),
        historyId: new Date().getTime(),
        category: "",
        description: "",
        quantityAccepted: "",
        quantityRejected: "",
        unit: "",
        date: formatDate(dateNow),
        purpose: "",
        vehicle: "",
        images: [],
        unsaved: true,
      },
    ]);

    setItemsImages([...itemsImages, []]);

    setTimeout(() => {
      if (modalContentRef.current) {
        modalContentRef.current.scrollTo({
          top: modalContentRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  const [additionalInformation, setAdditionalInformation] = useState(false);
  
  const displayAdditionalInformation = () => {
    setAdditionalInformation(!additionalInformation);
    setTimeout(() => {
      if (modalContentRef.current && !additionalInformation) {
        modalContentRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  const handleCancel = () => {
    setItems([
      {
        id: new Date().getTime(),
        historyId: new Date().getTime(),
        category: "",
        description: "",
        quantityAccepted: "",
        quantityRejected: "",
        unit: "",
        date: formatDate(dateNow),
        purpose: "",
        vehicle: "",
        images: [],
        unsaved: true,
      },
    ]);
    setFormData({
      invoiceNumber: "",
      invoiceDate: "",
      supplierVendor: "",
      poNumber: "", // Added PO Number field
      referralPONo: "",
      mrn: "",
      vehicleNo: "",
      gatePassNo: "",
      gatePassDate: "",
      dcNo: "",
      dcDate: "",
      inTime: "",
      outTime: "",
    });
    setItemsImages([[]]);
    close();
  };

  const handleCategorySelect = (option, index) => {
    // setCategory(option);
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, ["category"]: option } : item
      )
    );
  };

  const handleDescriptionSelect = (option, index) => {
    if (option !== "Select Category to fetch Items...") {
      let identityItem = categoryItems[items[index].category].filter(
        (item) => item.description === option
      )[0];

      console.log(' handleDescriptionSelect identityItem : ', identityItem)

      setItems((prevItems) =>
        prevItems.map((item, i) =>
          i === index
            ? {
                ...item,
                ["description"]: option,
                ...{ descriptionId: identityItem.descriptionID },
                ...{unit: identityItem.uom ? identityItem.uom : null}
              }
            : item
        )
      );
    }
  };

  const handleVehicleSelect = (option, index) => {
    let tempItems = [...items];
    tempItems[index]["type"] = "vehicle";
    tempItems[index]["tag"] = option;
    tempItems[index]["vehicle"] = option;
    setItems(tempItems);
  };

  const [previewImages, setPreviewImages] = useState([]);

  //   const handleImageChange = (e, index) => {
  //     const files = e.target.files;
  //     const newItems = [...items];
  //     newItems[index].images = files;
  //     setItems(newItems);
  //   };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewImages = files.map((file) => ({
      file,
      id: URL.createObjectURL(file),
    }));
    console.log("newPreviewImages : ", newPreviewImages);
    setPreviewImages([...previewImages, ...newPreviewImages]);
  };

  const [savedItems, setSavedItems] = useState([]); // Track saved items for Summary
  const [validationErrors, setValidationErrors] = useState({});

  const [unSaved, setUnSaved] = useState([]);

  const isEmptyItem = (item) => {
    return (
      item.category === "" &&
      item.description === "" &&
      item.quantityAccepted === "" &&
      item.quantityRejected === "" &&
      item.unit === "" &&
      item.purpose === "" &&
      item.vehicle === "" &&
      item.images.length === 0 &&
      item.unsaved === true
    );
  };

  useEffect(() => {
    try {
      if (items.length === 1 && isEmptyItem(items[0])) {
        setUnSaved([]);
      } else {
        setUnSaved(items);
      }
    } catch (error) {
      setUnSaved([]);
    }
  }, [items]);

  const checkVehicleUpdates = async (allItems) => {
    // write the code here
    let arrayOfVehicles = Object.values(vehicleInformation);
    for (let item of allItems) {
      if (item.category === "Fuel") {
        writeVehicleInformation(
          project.firmDetailsID,
          project.projectID,
          arrayOfVehicles,
          item.tag,
          item.quantityAccepted
        );
      }
    }
  };


  const checkVehicleUpdatesWhileEditing = async (allItems) => {
    
    let arrayOfVehicles = Object.values(vehicleInformation);
    let oldItems = Object.values(formData.outwardItems)
    for (let index in allItems) {
      if (allItems[index].category === "Fuel") {
        editVehicleDieselConsumed(
          project.firmDetailsID,
          project.projectID,
          arrayOfVehicles,
          oldItems[index],
          allItems[index]
        );
      }
    }
  }

  const handleSave = async () => {

    const errors = {};

    items.forEach((item, index) => {
      if (!item.category) errors[`category_${index}`] = "Category is required";
      if (!item.description)
        errors[`description_${index}`] = "Description is required";
      if (!item.quantityAccepted)
        errors[`quantityAccepted_${index}`] = "Quantity Accepted is required";
      if (!item.unit) errors[`unit_${index}`] = "Unit is required";
      if (item.category === "Fuel" && entryType !== "add" && !item.tag)
        errors[`vehicle_${index}`] = "Vehicle not selected";
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("Fields are incomplete", {
        backgroundColor: "#8e3f3f",
        color: "white",
        style: {
          zIndex: 10000000, // Set a high value to ensure it's on top of the modal
        },
      });
      return; // Prevent save if there are validation errors
    }

    setSaving(true);

    if (entryType === "add") {
      if (editing) {
        let updatedData = await createInwardEntry(
          formData,
          items,
          itemsImages,
          previewImages,
          userName
        );

        await editInwardEntry(
          project.firmDetailsID,
          project.projectID,
          itemData.id,
          updatedData,
          userName
        );

        handleCancel();
      } else {
        await saveInwardEntriesToFirebase(
          project.firmDetailsID,
          project.projectID,
          items,
          formData,
          itemsImages,
          previewImages,
          userName
        );
        setSavedItems([
          ...[formData],
          ...items.map(({ unsaved, ...rest }) => rest), // Remove 'unsaved' from each item
          ...savedItems.filter((item) => !item.unsaved),
        ]);
      }
    } else {
      if (editing) {
        await EditOutwardEntry(
          project.firmDetailsID,
          project.projectID,
          items,
          formData, // contain outward entry as received from the edit button
          itemsImages,
          previewImages,
          userName
        );
        checkVehicleUpdatesWhileEditing(items);
        setSavedItems([
          ...items.map(({ unsaved, ...rest }) => rest), // Remove 'unsaved' from each item
          ...savedItems.filter((item) => !item.unsaved),
        ]);
      } else {
        await saveOutwardEntriesToFirebase(
          project.firmDetailsID,
          project.projectID,
          items,
          formData,
          itemsImages,
          previewImages,
          userName
        );
        await checkVehicleUpdates(items);
        setSavedItems([
          ...items.map(({ unsaved, ...rest }) => rest), // Remove 'unsaved' from each item
          ...savedItems.filter((item) => !item.unsaved),
        ]);
      }
    }

    setItems([
      {
        id: new Date().getTime(),
        historyId: new Date().getTime(),
        category: "",
        description: "",
        quantityAccepted: "",
        quantityRejected: "",
        unit: "",
        date: formatDate(dateNow),
        purpose: "",
        vehicle: "",
        images: [],
        unsaved: true,
      },
    ]);

    setFormData({
      invoiceNumber: "",
      invoiceDate: "",
      supplierVendor: "",
      poNumber: "",
      referralPONo: "",
      mrn: "",
      vehicleNo: "",
      gatePassNo: "",
      gatePassDate: "",
      dcNo: "",
      dcDate: "",
      inTime: "",
      outTime: "",
    });

    setItemsImages([[]]);
    setPreviewImages([]);

    setPreviewImages([]);
    setValidationErrors({});
    setAdditionalInformation(false);

    toast.success("Items saved successfully", {
      backgroundColor: "#4d873f",
      color: "white",
      style: {
        zIndex: 10000000, // Set a high value to ensure it's on top of the modal
      },
    });
    setSaving(false);
    
    try {
      fetchData();
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // You can also set a fallback state or show an error message to the user here
    }
  };

  const handleDeleteSavedItem = (index) => {
    const newSavedItems = savedItems.filter((_, i) => i !== index);
    setSavedItems(newSavedItems);
  };

  // consider the things here for the edting the inward entry

  const updateVehicleInformation = async () => {
    let vehicles = await fetchVehicleInformation(
      project.firmDetailsID,
      project.projectID
    );
    setVehicleInformation(vehicles);
  };

  useEffect(() => {
    if (editing) {
      if (entryType === "add") {
        let results = extractInwardEntryData(itemData);
        setFormData(() => results.additionalInfo);
        setItems(() => results.itemsArray);
        setAdditionalInformation(true);
        setItemsImages(() =>
          results.itemsArray.map((item) => {
            if (item.images && typeof item.images === "object") {
              return Object.values(item.images);
            }
            return [];
          })
        );
        itemData.images &&
          setPreviewImages(() => Object.values(itemData.images));
      } else if (entryType === "subtract") {
        setFormData(() => itemData);
        setItems(() =>
          Object.values(itemData.outwardItems).map((item) => {
            return { ...item, unsaved: true };
          })
        );
        setItemsImages(() =>
          Object.values(itemData.outwardItems).map((item) => {
            if (item.images && typeof item.images === "object") {
              return Object.values(item.images);
            }
            return [];
          })
        );
        itemData.images &&
          setPreviewImages(() => Object.values(itemData.images));
      }
    }
    if (entryType === "subtract") {
      updateVehicleInformation();
    }
    console.log('selectedMaterial : ', selectedMaterial);
    if(selectedMaterial !== undefined && Object.keys(selectedMaterial).length > 0){

      let identityItem = categoryItems[selectedMaterial.category].filter(
        (item) => item.description === selectedMaterial.name
      )[0];



      let tempItem = [
        {
          id: new Date().getTime(),
          historyId: new Date().getTime(),
          category: selectedMaterial.category,
          description: selectedMaterial.name,
          quantityAccepted: "",
          quantityRejected: "",
          unit: identityItem.uom,
          date: formatDate(dateNow),
          purpose: "",
          vehicle: "",
          images: [],
          unsaved: true,
          descriptionId: identityItem.descriptionID
        },
      ]

      setItems(tempItem);
    }
  }, []);

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContainer}>
        <Summary
          items={unSaved.length > 0 ? [...unSaved, ...savedItems] : savedItems}
          onDeleteItem={handleDeleteSavedItem}
        />
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between", // Added spacing between heading and button
                width: "100%",
              }}
            >
              <div>
                <h2 style={styles.heading}>
                  {entryType === "add" ? "Inward " : "Outward "} Entry |{" "}
                  {project && (
                    <span style={{ color: "#808080" }}>
                      {project.projectName}
                    </span>
                  )}
                </h2>
                <p style={styles.headingCount}>
                  {items.length} {items.length === 1 ? "Item" : "Items"}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {entryType === "add" && (
                  <CheckboxComponent
                    isChecked={additionalInformation}
                    handleCheckboxChange={displayAdditionalInformation}
                  />
                )}

                <button style={styles.addButton} onClick={handleAddItem}>
                  + Add An Item
                </button>
              </div>
            </div>

            {/* Error message */}
            {Object.keys(validationErrors).length > 0 && (
              <p style={styles.errorMessage}>
                Red marked fields are incomplete, complete them and try saving
                again.
              </p>
            )}
          </div>

          <div style={styles.modalContent} ref={modalContentRef}>
            {additionalInformation && (
              <AdditionalInformation
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {items.map((item, index) => (
              <div key={index} style={styles.formGroup}>
                <div style={styles.serialNumberHeader}>
                  <p style={styles.serialNumberText}>Item #{index + 1}</p>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setItems(() => items.filter((_, i) => i !== index));
                      toast.success("One item deleted successfully", {
                        backgroundColor: "#4d873f",
                        color: "white",
                        style: {
                          zIndex: 10000000, // Set a high value to ensure it's on top of the modal
                        },
                      });
                    }}
                  >
                    <AiOutlineDelete size={25} />
                  </div>
                </div>

                <div style={styles.formRow}>
                  <div style={styles.inputContainer}>
                    <Dropdown
                      options={Object.keys(categoryItems)}
                      placeholder="Select category..."
                      value={item.category}
                      onChange={(value) =>
                        handleInputChange(
                          { target: { name: "category", value } },
                          index
                        )
                      }
                      onOptionSelect={handleCategorySelect}
                      index={index}
                      validationError={validationErrors[`category_${index}`]}
                    />
                    {validationErrors[`category_${index}`] && (
                      <div style={styles.alertIcon}>
                        <AiOutlineAlert color="red" />
                      </div>
                    )}
                  </div>

                  <div style={styles.inputContainer}>
                    <Dropdown
                      options={
                        // Check if category exists and the categoryItems have corresponding values
                        item.category &&
                        categoryItems[item.category] &&
                        categoryItems[item.category].length > 0
                          ? categoryItems[item.category].map(
                              (categoryItem) => categoryItem.description
                            )
                          : ["Select Category to fetch Items..."]
                      }
                      placeholder="Select item..."
                      value={item.description}
                      onChange={(value) =>
                        handleInputChange(
                          { target: { name: "description", value } },
                          index
                        )
                      }
                      onOptionSelect={handleDescriptionSelect}
                      index={index}
                      validationError={validationErrors[`description_${index}`]}
                    />
                    {validationErrors[`description_${index}`] && (
                      <div style={styles.alertIcon}>
                        <AiOutlineAlert color="red" />
                      </div>
                    )}
                  </div>
                </div>

                <div style={styles.formRow}>
                  <input
                    type="number"
                    name="quantityAccepted"
                    value={item.quantityAccepted}
                    onChange={(e) => handleInputChange(e, index)}
                    style={{
                      ...styles.inputField,
                      borderColor: validationErrors[`quantityAccepted_${index}`]
                        ? "red"
                        : "#ddd",
                    }}
                    placeholder={
                      entryType === "add"
                        ? "Quantity Accepted"
                        : "Quantity Issued"
                    }
                  />

                  {entryType === 'add' && <input
                    type="number"
                    name="quantityRejected"
                    value={item.quantityRejected}
                    onChange={(e) => handleInputChange(e, index)}
                    style={styles.inputField}
                    placeholder="Quantity Rejected"
                  />}
                </div>

                <div style={styles.formRow}>
                  <input
                    type="text"
                    name="unit"
                    value={item.unit}
                    onChange={(e) => handleInputChange(e, index)}
                    style={{
                      ...styles.inputField,
                      borderColor: validationErrors[`unit_${index}`]
                        ? "red"
                        : "#ddd",
                    }}
                    placeholder="Unit"
                    disabled={true}
                  />

                  <div style={styles.inputContainer}>
                    {item.category === "Fuel" && entryType !== "add" && (
                      <>
                        <Dropdown
                          options={Object.keys(vehicleInformation)}
                          placeholder="Select Vehicle..."
                          value={item.tag}
                          onChange={(value) =>
                            handleInputChange(
                              { target: { name: "vehicle", value } },
                              index
                            )
                          }
                          onOptionSelect={handleVehicleSelect}
                          index={index}
                          validationError={validationErrors[`vehicle_${index}`]}
                        />
                        {validationErrors[`vehicle_${index}`] && (
                          <div style={styles.alertIcon}>
                            <AiOutlineAlert color="red" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div style={styles.formRow}>
                  <input
                    type="date"
                    name="date"
                    value={item.date}
                    onChange={(e) => handleInputChange(e, index)}
                    style={styles.inputField}
                  />
                  <div style={{ width: "48%" }}>
                    <ImageUpload
                      parIndex={index}
                      item={item}
                      items={items}
                      setItems={setItems}
                      itemsImages={itemsImages}
                      setItemsImages={setItemsImages}
                    />
                  </div>
                </div>

                <div style={styles.formRow}>
                  <textarea
                    name="purpose"
                    value={item.purpose}
                    onChange={(e) => handleInputChange(e, index)}
                    style={styles.textArea}
                    placeholder="Remark"
                  />
                </div>
              </div>
            ))}
            <div style={{ height: 100 }} />
          </div>

          <div style={styles.fixedBottomSection}>

            {entryType === 'add' && <div style={styles.imagePreviewSection}>
              <div style={styles.imageUploadButton}>
                <input
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  style={styles.fileInput}
                  id="file-input"
                />
                <label htmlFor="file-input" style={styles.uploadLabel}>
                  <span style={styles.uploadIcon}>📎</span> Upload Bills/Invoices
                </label>
              </div>
              <div style={styles.previewContainer}>
                {previewImages.map((image, index) => (
                  <div key={index} style={styles.previewImage}>
                    <img
                      src={image.id || image.url}
                      alt={`Preview ${index}`}
                      style={styles.thumbnail}
                    />
                    <button
                      onClick={() =>
                        setPreviewImages(
                          previewImages.filter((_, i) => i !== index)
                        )
                      }
                      style={styles.removeImageButton}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>}

            <div style={styles.buttonGroup}>

              <button style={styles.cancelButton} onClick={handleCancel}>
                Cancel
              </button>

              <SavingButton
                isLoading={saving}
                onClick={handleSave}
                label="Save Items"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        delay={2000}
        position="bottom-center" // Adjust positioning as needed
        style={{
          position: "fixed",
          zIndex: 100000, // Ensure it stays on top
        }}
      />
    </div>
  );
};

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    height: "90vh",
    backgroundColor: "#fff",
    borderRadius: "12px",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    width: "70%",
    // width: "900px",
    height: "90vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column", // Stack header items vertically
    padding: "20px",
    backgroundColor: "#f1f1f1", // Light background for the header
    borderRadius: "10px 10px 0 0", // Rounded top corners
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // Slight shadow for separation
    marginBottom: "20px",
  },
  modalContent: {
    padding: "20px 50px",
    overflowY: "auto",
    flexGrow: 1,
    marginBottom: "80px", // Space for fixed buttons
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#333",
    margin: 0,
  },
  headingCount: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#666",
    margin: "5px 0 0 0",
  },
  serialNumberHeader: {
    marginBottom: "20px",
    padding: "10px 0",
    borderBottom: "2px solid #eee",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  serialNumberText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
  formGroup: {
    marginBottom: "40px",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "12px",
    border: "1px solid #eee",
  },
  formRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  inputContainer: {
    position: "relative",
    width: "48%",
  },
  inputField: {
    width: "48%",
    padding: "20px",
    borderRadius: "12px",
    border: "1px solid #ddd",
    fontSize: "20px",
    backgroundColor: "#fff",
  },
  textArea: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    border: "1px solid #ddd",
    fontSize: "20px",
    backgroundColor: "#fff",
    height: "150px",
    resize: "vertical",
  },
  fixedButtonGroup: {
    position: "fixed",
    bottom: "5vh",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: "20px",
    padding: "20px",
    backgroundColor: "#fff",
    borderTop: "1px solid #eee",
    width: "800px",
    justifyContent: "flex-end",
    borderRadius: "12px",
    boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: "#4CAF50", // Green background for the button
    color: "white",
    fontSize: "16px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    marginLeft: 10,
  },
  addButtonHover: {
    backgroundColor: "#45a049", // Darker green on hover
  },
  errorMessage: {
    color: "red",
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "5px 0",
  },
  cancelButton: {
    backgroundColor: "#ff6347",
    border: "none",
    padding: "15px 30px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "18px",
    color: "#fff",
    transition: "background-color 0.2s",
    ":hover": {
      backgroundColor: "#ff4125",
    },
  },
  saveButton: {
    backgroundColor: "#4caf50",
    border: "none",
    padding: "15px 30px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "18px",
    color: "#fff",
    transition: "background-color 0.2s",
    ":hover": {
      backgroundColor: "#3d8b40",
    },
  },
  fileInputContainer: {
    width: "100%",
    position: "relative",
  },
  fileDropZone: {
    border: "2px dashed #ddd",
    borderRadius: "12px",
    padding: "40px 20px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border-color 0.3s",
    backgroundColor: "#fff",
  },
  fileDropZoneActive: {
    backgroundColor: "#f9f9f9",
    borderColor: "#bbb",
  },
  fileDropText: {
    fontSize: "18px",
    color: "#666",
  },
  fileDropLink: {
    color: "#4caf50",
    fontWeight: "bold",
    cursor: "pointer",
  },
  fileInput: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  fixedBottomSection: {
    position: "fixed",
    bottom: "5vh",
    left: "60%",
    transform: "translateX(-50%)",
    width: "800px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  imagePreviewSection: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    borderBottom: "1px solid #eee",
    paddingBottom: "10px",
  },

  imageUploadButton: {
    minWidth: "100px",
  },

  uploadLabel: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "8px 15px",
    backgroundColor: "#f0f0f0",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "14px",
    color: "#333",
    transition: "background-color 0.2s",
    ":hover": {
      backgroundColor: "#e0e0e0",
    },
  },

  uploadIcon: {
    fontSize: "16px",
  },

  previewContainer: {
    display: "flex",
    gap: "10px",
    overflowX: "auto",
    flexGrow: 1,
    padding: "5px 0",
  },

  previewImage: {
    position: "relative",
    width: "60px",
    height: "60px",
    flexShrink: 0,
  },

  thumbnail: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "6px",
  },

  removeImageButton: {
    position: "absolute",
    top: -8,
    right: -8,
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#ff6347",
    color: "white",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    padding: 0,
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "15px",
    paddingTop: "10px",
  },

  fileInput: {
    display: "none",
  },
  alertIcon: {
    position: "absolute",
    right: 5,
    top: "50%",
    transform: "translateY(-50%)",
  },
  //   inputField: {
  //     width: "100%",
  //     padding: "10px",
  //     marginBottom: "10px",
  //     borderRadius: "8px",
  //     borderColor: "#ddd",
  //     boxSizing: "border-box",
  //     fontSize: "16px",
  //   },
  inputFieldError: {
    borderColor: "red",
  },
};

export default InwardEntryModal;
