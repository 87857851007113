import React, { useState, useEffect, useRef } from 'react';
import fetchMRNProjects from '../MaterialRequests/Helpers/fetchProjects';
import { secondDatabase } from '../../config/firebase';
import writeBuildProjectId from './writeBuildProjectId';

const InventoryDropdown = ({projectId}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const [showButton, setShowButton] = useState(true);

  const [projects, setProjects] = useState([]);

  const assignProjects = async () => {
    let data;
    data = await fetchMRNProjects();
    let filteredData = data[0].filter(item => !item.buildProjectId);
    let checkedData = data[0].map(item => String(item.buildProjectId) === String(projectId));
    if(checkedData.includes(true)){
        setShowButton(false);
    }
    setProjects(filteredData);
  }

  useEffect(() => {
    assignProjects()
  }, [isOpen])

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
    writeBuildProjectId(value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
      {showButton && <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: 'green',
          color: '#FFF',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          marginLeft: 5
        }}
      >
        Link to Inventory
      </button>}
      {isOpen && (
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            padding: '10px',
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#FFF',
            border: '1px solid #CCC',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1000,
          }}
        >
          {projects.length > 0 && projects.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                handleOptionSelect(option);
                setIsOpen(false);
              }}
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                borderBottom: index < 2 ? '1px solid #EEE' : 'none',
              }}
            >
              {option.projectName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InventoryDropdown;